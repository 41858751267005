import { useState, useRef, useEffect } from "react";
import useAdminLogin from "../../../hooks/useAdminLogin";
import "./AdminLogin.css";
import sha256 from "sha256";
import { Button, Modal } from "react-bootstrap";
import LoadingPortal from "../../../portals/LoadingPortal";
import LoadingModal from "../../../components/loadingmodal/LoadingModal";
import {useNavigate} from "react-router";
import {apiClient} from "../../../api/apiClient";


export default function AdminLogin() {
  const navigate = useNavigate();
  const idRef = useRef();
  const pwRef = useRef();
  const autoChecked = useRef();
  const [isRemoveModalShowing, setIsRemoveModalShowing] = useState({
    isShowing: false,
    text: "",
  });
  const { mutate, isLoading } = useAdminLogin(setIsRemoveModalShowing);

  const handleRemoveModalClose = () => {
    setIsRemoveModalShowing({ isShowing: false, text: "" });
  };

  const  clickAutoLogin = (checked) => {
    autoChecked.current = checked;
  }

  const tryLogin = () => {
    if(autoChecked.current == true){
      localStorage.setItem('autoLogin',true);
    }else{
      localStorage.setItem('autoLogin',false);
    }
    if (isLoading) {
      return;
    }

    mutate({
      loginId: idRef.current.value,
      password: sha256(pwRef.current.value),
    });
  };

  const handleLoginClick = () => {
    tryLogin();
  };

  const handleLoginKeyDown = (event) => {
    if (event.key !== "Enter") {
      return;
    }

    tryLogin();
  };

  useEffect (() => {
    if(localStorage.getItem('autoLogin') == 'true'){
      if(localStorage.getItem('accessToken') !== undefined && localStorage.getItem('accessToken') !== null){
        apiClient({
          url: "/store/me",
          method: "get",
        }).then((response) => {
          console.log(response.status == 200)
          navigate("/storemain");
        }).catch((error)=>{
          console.log(error.response.data)
        });
      }
    }

  }, []);

  return (
    <>
      <div className="demo">
        <div className="TLoginPage admin">
          <div className="container position-absolute start-50 top-50 translate-middle w-100">
            <div className="Inner m-auto p-2">
              <div className="LogoImg text-center">
                <img
                  src="https://www.flexdaycdn.net/public/images/ticket/inje_logo2.png"
                  alt="FLEX"
                />
                <p className="txt mb-0">관리자 페이지입니다.</p>
                <p style={{color:'#d3d3d3',textAlign:'center'}}>{process.env.REACT_APP_VERSION}</p>
              </div>
              <div className="LoginBox">
                <div className="row">
                  <input
                    type="text"
                    className="form-control InputType"
                    placeholder="아이디를 입력해주세요."
                    ref={idRef}
                    onKeyDown={handleLoginKeyDown}
                  />
                </div>
                <div className="row">
                  <input
                    type="password"
                    className="form-control InputType"
                    placeholder="비밀번호를 입력해주세요."
                    ref={pwRef}
                    onKeyDown={handleLoginKeyDown}
                  />
                </div>
                <div className="form-checkbox d-flex align-items-center">
                  <input className="CheckInput1 form-check-input" name="Check" id="Check-01" type="checkbox"
                         onClick={(e) =>clickAutoLogin(e.target.checked)}/>
                    <label className="form-check-label CheckLabel" htmlFor="Check-01">자동 로그인</label>
                </div>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleLoginClick}
                >
                  로그인
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={isRemoveModalShowing.isShowing}
          onHide={handleRemoveModalClose}
          keyboard={false}
          size="sm"
          centered
          backdrop="static"
          className="demoModal modal-prdAdd text-center"
        >
          <Modal.Header>
            <Modal.Title>
              {/* {isRemoveModalShowing.text} */}
              아이디 또는 비밀번호가
              <br />
              틀렸습니다. 다시 입력해주세요.
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button
              variant="modal-primary"
              className="btn-clear w-100"
              onClick={handleRemoveModalClose}
            >
              확인
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {isLoading && (
        <LoadingPortal>
          <LoadingModal />
        </LoadingPortal>
      )}
    </>
  );
}
