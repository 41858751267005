export const getThisWeekDateMenuText = (num) => {
    const days = ["일", "월", "화", "수", "목", "금", "토"];
    const today = new Date();
    const currentDayOfWeek = today.getDay();

    const day = new Date(today);
    day.setDate(today.getDate() - currentDayOfWeek + num);

    // return `${day.getMonth() + 1}/${"00" + day.get()}(${days[day.getDay()]})`;

    // return `${day.getFullYear()}-${("00" + (day.getMonth() + 1)).slice(-2)}-${(
    //     "00" + day.getDate()
    // ).slice(-2)}`;
    
    return `${day.getMonth() + 1}/${day.getDate()}(${days[num]})`;
};

// yyyy-MM-dd
export const getThisWeekDateISO = (num) => {
    const today = new Date();
    const currentDayOfWeek = today.getDay();

    const day = new Date(today);
    day.setDate(today.getDate() - currentDayOfWeek + num);
    return day.toISOString().slice(0, 10);
};

export const getNextWeekDateISO = (num) => {
    const today = new Date();
    today.setDate(today.getDate() + 7)
    const currentDayOfWeek = today.getDay();

    const day = new Date(today);
    day.setDate(today.getDate() - currentDayOfWeek + num);
    return day.toISOString().slice(0, 10);
};

export const getThisWeekDate = (num) => {
    const days = ["일", "월", "화", "수", "목", "금", "토"];
    const today = new Date();
    const currentDayOfWeek = today.getDay();

    const day = new Date(today);
    day.setDate(today.getDate() - currentDayOfWeek + num);

    return `${days[day.getDay()]}`;
};

export const initialState = [
    {
        date: getThisWeekDateISO(1),
        dateString : getThisWeekDateMenuText(1),
        detailDescription: "",
    },
    {
        date: getThisWeekDateISO(2),
        dateString : getThisWeekDateMenuText(2),
        detailDescription: "",
    },
    {
        date: getThisWeekDateISO(3),
        dateString : getThisWeekDateMenuText(3),
        detailDescription: "",
    },
    {
        date: getThisWeekDateISO(4),
        dateString : getThisWeekDateMenuText(4),
        detailDescription: "",
    },
    {
        date: getThisWeekDateISO(5),
        dateString : getThisWeekDateMenuText(5),
        detailDescription: "",
    },
];

export const saleMenuAddReducer = (state, action) => {
    switch (action.type) {
        case "added_menuDesc":
            const copyState = [...state];

            copyState[action.payload.day].detailDescription =
                action.payload.value;

            return copyState;
        case "reset":
            return [
                {
                    date: getThisWeekDateISO(1),
                    dateString : getThisWeekDateMenuText(1),
                    detailDescription: "",
                },
                {
                    date: getThisWeekDateISO(2),
                    dateString : getThisWeekDateMenuText(2),
                    detailDescription: "",
                },
                {
                    date: getThisWeekDateISO(3),
                    dateString : getThisWeekDateMenuText(3),
                    detailDescription: "",
                },
                {
                    date: getThisWeekDateISO(4),
                    dateString : getThisWeekDateMenuText(4),
                    detailDescription: "",
                },
                {
                    date: getThisWeekDateISO(5),
                    dateString : getThisWeekDateMenuText(5),
                    detailDescription: "",
                },
            ];
        default:
            throw Error(`Unknown action ${action.type}`);
    }
};
