import { Spinner } from "react-bootstrap";
import "./LoadingModal.css";

export default function LoadingModal({ type }) {
  if (type === "black") {
    return (
      <div className="backdrop">
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <div className="backdrop custom-backdrop">
      <Spinner animation="border" />
    </div>
  );
}
