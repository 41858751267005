import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Naver from "./pages/sns/Naver";
import Kakao from "./pages/sns/Kakao";
import demoLogin from "./pages/sns/Login";

import Error from "./pages/Error";
import NotFound from "./pages/NotFound";
import Apple from "./pages/sns/Apple";
import Scroll from "./pages/testcode/Scroll";
import UserLogin from "./pages/UserMain/UserLogin";
import NonUserLogin from "./pages/UserMain/NonUserLogin";
import StoreMain from "./pages/UserMain/StoreMain";
import BasicInfo from "./pages/UserMain/BasicInfo";
import Datepicker from "./pages/UserMain/Datepicker";
import Gift from "./pages/UserMain/Gift";
import ItemCreate from "./pages/UserMain/ItemCreate";
import NonUserTicket from "./pages/UserMain/NonUserTicket";
import Payment from "./pages/UserMain/Payment";
import PaymentCancel from "./pages/UserMain/PaymentCancel";
import PaymentList from "./pages/UserMain/PaymentList";
import QR from "./pages/UserMain/QR";
import QRDetail from "./pages/UserMain/QRDetail";
import QRwallet from "./pages/UserMain/QRwallet";
import Register from "./pages/UserMain/Register";
import SalePrd from "./pages/UserMain/SalePrd";
import SettleDetail from "./pages/UserMain/SettleDetail";
import SettleHistory from "./pages/UserMain/SettleHistory";
import SettleHistorySub from "./pages/UserMain/SettleHistorySub";
import StampReward from "./pages/UserMain/StampReward";
import StoreCalculate from "./pages/UserMain/StoreCalculate";
import Success from "./pages/UserMain/Success";
// import Survey from "./pages/UserMain/Survey";
import UserStore from "./pages/UserMain/UserStore";
import EventAdd from "./pages/event/eventadd/EventAdd";
import EventModify from "./pages/event/eventmodify/EventModify";
import AdminLogin from "./pages/admin/login/AdminLogin";
import SaleMenuAdd from "./pages/salemenu/salemenuadd/SaleMenuAdd";
import SaleMenuModify from "./pages/salemenu/salemenumodify/SaleMenuModify";
import PrivateRoute from "./components/common/PrivateRoute";
import SaleMenu from "./pages/salemenu/salemenu/SaleMenu";
import SaleMenuSequence from "./pages/salemenu/salemenusequence/SaleMenuSequence";
import Event from "./pages/event/event/Event";
import EventParticipation from "./pages/event/eventparticipation/EventParticipation";
import PaymentInfo from "./pages/paymentinfo/PaymentInfo";
import CalculateInfo from "./components/paymentinfo/calculateinfo/CalculateInfo";
import SalesInfo from "./components/paymentinfo/salesinfo/SalesInfo";
import Survey from "./pages/survey/Survey";
import StoreEdit from "./pages/storeedit/StoreEdit";
import { Suspense } from "react";
import ErrorBoundary from "./components/common/ErrorBoundary";
import LoadingPortal from "./portals/LoadingPortal";
import LoadingModal from "./components/loadingmodal/LoadingModal";
import PopupManager from "./pages/popup/popupManager/PopupManager";
import PopupSequence from "./pages/popup/popupSequence/PopupSequence";
import PopupAdd from "./pages/popup/popupAdd/PopupAdd";
import PopupModify from "./pages/popup/popupModify/PopupModify";

import { Provider } from "react-redux";
import store from "./store";
import PaymentOrderDetailInfo from "./components/paymentinfo/paymentorderdetailinfo/PaymentOrderDetailInfo";
import TaxBillInfo from "./components/paymentinfo/taxbillinfo/TaxBillInfo";

function App() {
  if (process.env.NODE_ENV !== "production") {
    console.log("domainType : " + localStorage.getItem("domainType"));
  }

  return (
    <Provider store={store}>
      <div className="App">
        <header className="App-header">
          <Router>
            <Routes>
              {/* 관리자 로그인 */}
              <Route path="/admin/login" element={<AdminLogin />}></Route>
              {/* 간편 로그인 */}
              <Route path="/sns/login" element={<demoLogin />}></Route>
              <Route path="/sns/naver" element={<Naver />}></Route>
              <Route path="/sns/kakao" element={<Kakao />}></Route>
              <Route path="/sns/apple" element={<Apple />}></Route>
              {/* 샘플 코드 */}
              <Route path="/testcode/scroll" element={<Scroll />}></Route>
              <Route path="/datepicker" element={<Datepicker />}></Route>
              {/* 인제대 사용자 */}
              <Route path="/login" element={<UserLogin />}></Route>
              {/* 사용자 메인 */}
              <Route path="/userstore" element={<UserStore />}></Route>
              {/* 인제대 비회원 */}
              <Route path="/nonuserlogin" element={<NonUserLogin />}></Route>
              <Route path="/nonuserticket" element={<NonUserTicket />}></Route>
              {/* 스토어 메인 */}
              <Route
                path="/storemain"
                element={
                  <Suspense
                    fallback={
                      <LoadingPortal>
                        <LoadingModal />
                      </LoadingPortal>
                    }
                  >
                    <ErrorBoundary>
                      <StoreMain />
                    </ErrorBoundary>
                  </Suspense>
                }
              ></Route>
              {/* 스토어 메인 */}
              {/* <Route path="/storemain" element={<StoreMain />}></Route> */}
              {/* 스토어 기본정보 */}
              <Route path="/basicinfo" element={<BasicInfo />}></Route>
              {/* 교환권 생성 */}
              <Route path="/itemcreate" element={<ItemCreate />}></Route>
              {/* 선물하기 */}
              <Route path="/gift/:number" element={<Gift />}></Route>
              <Route path="/gift" element={<Gift />}></Route>
              {/* 결제 */}
              <Route path="/payment" element={<Payment />}></Route>
              <Route path="/paymentcancel" element={<PaymentCancel />}></Route>
              <Route path="/paymentlist" element={<PaymentList />}></Route>
              {/* 결제 상세 */}
              <Route path="/qrdetail/:number" element={<QRDetail />}></Route>
              {/* QR 확인, 비회원시 */}
              <Route path="/qr/:number" element={<QR />}></Route>
              <Route path="/qr" element={<QR />}></Route>
              {/* QR 지갑 */}
              <Route path="/qrwallet" element={<QRwallet />}></Route>
              {/* 회원가입 */}
              <Route path="/register" element={<Register />}></Route>
              {/* 스템프 리워드 */}
              <Route path="/saleprd" element={<SalePrd />}></Route>
              {/* 정산 상세 */}
              <Route path="/settledetail" element={<SettleDetail />}></Route>
              <Route path="/settlehistory" element={<SettleHistory />}></Route>
              <Route
                path="/settlehistorysub"
                element={<SettleHistorySub />}
              ></Route>
              <Route path="/stampreward" element={<StampReward />}></Route>
              <Route
                path="/storecalculate"
                element={<StoreCalculate />}
              ></Route>
              {/* 결제완료 */}
              <Route path="/success" element={<Success />}></Route>
              {/* 만족도 조사 */}
              {/* <Route path="/survey/:number" element={<Survey />}></Route>
            <Route path="/survey" element={<Survey />}></Route> */}
              {/* 이벤트 관리 */}
              <Route
                path="/event"
                element={
                  <PrivateRoute>
                    <Event />
                  </PrivateRoute>
                }
              ></Route>
              {/* 이벤트 추가 */}
              <Route
                path="/event/add"
                element={
                  <PrivateRoute>
                    <EventAdd />
                  </PrivateRoute>
                }
              ></Route>
              {/* 이벤트 수정 */}
              <Route
                path="/event/:eventId"
                element={
                  <PrivateRoute>
                    <EventModify />
                  </PrivateRoute>
                }
              ></Route>
              {/* 이벤트 참여 현황 */}
              <Route
                path="/event/:eventId/participation"
                element={
                  <PrivateRoute>
                    <EventParticipation />
                  </PrivateRoute>
                }
              ></Route>
              {/* 판매 메뉴 관리 */}
              <Route
                path="/salemenu"
                element={
                  <PrivateRoute>
                    <SaleMenu />
                  </PrivateRoute>
                }
              ></Route>
              {/* 판매 메뉴 추가 */}
              <Route
                path="/salemenu/add"
                element={
                  <PrivateRoute>
                    <SaleMenuAdd />
                  </PrivateRoute>
                }
              ></Route>
              {/* 판매 메뉴 변경 */}
              <Route
                path="/salemenu/:productId"
                element={
                  <PrivateRoute>
                    <SaleMenuModify />
                  </PrivateRoute>
                }
              ></Route>
              {/* 판매 메뉴 순서 변경 */}
              <Route
                path="/salemenu/sequence"
                element={
                  <PrivateRoute>
                    <SaleMenuSequence />
                  </PrivateRoute>
                }
              ></Route>
              {/* 결제 / 매출 / 정산 */}
              <Route
                path="/paymentInfo"
                element={
                  <PrivateRoute>
                    <PaymentInfo />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/paymentOrderInfo"
                element={
                  <PrivateRoute>
                    <PaymentOrderDetailInfo />
                  </PrivateRoute>
                }
              ></Route>
              {/* 매출 내역 */}
              <Route
                path="/salesInfo"
                element={
                  <PrivateRoute>
                    <SalesInfo />
                  </PrivateRoute>
                }
              ></Route>
              {/* 정산 내역 */}
              <Route
                path="/settlementInfo"
                element={
                  <PrivateRoute>
                    <CalculateInfo />
                  </PrivateRoute>
                }
              ></Route>
              {/* 정산 내역 */}
              <Route
                path="/taxbillInfo"
                element={
                  <PrivateRoute>
                    <TaxBillInfo />
                  </PrivateRoute>
                }
              ></Route>
              {/* 설문 조사 */}
              <Route
                path="/survey"
                element={
                  <PrivateRoute>
                    <Survey />
                  </PrivateRoute>
                }
              ></Route>
              {/* 기본 정보 관리 */}
              <Route
                path="/edit"
                element={
                  <Suspense
                    fallback={
                      <LoadingPortal>
                        <LoadingModal />
                      </LoadingPortal>
                    }
                  >
                    <PrivateRoute>
                      <StoreEdit />
                    </PrivateRoute>
                  </Suspense>
                }
              ></Route>
              {/* 팝업 관리 */}
              <Route
                path="/popup"
                element={
                  <Suspense
                    fallback={
                      <LoadingPortal>
                        <LoadingModal />
                      </LoadingPortal>
                    }
                  >
                    <PrivateRoute>
                      <PopupManager />
                    </PrivateRoute>
                  </Suspense>
                }
              ></Route>
              <Route
                path="/popup/add"
                element={
                  <Suspense
                    fallback={
                      <LoadingPortal>
                        <LoadingModal />
                      </LoadingPortal>
                    }
                  >
                    <PrivateRoute>
                      <PopupAdd />
                    </PrivateRoute>
                  </Suspense>
                }
              ></Route>
              <Route
                path="/popup/sequence"
                element={
                  <Suspense
                    fallback={
                      <LoadingPortal>
                        <LoadingModal />
                      </LoadingPortal>
                    }
                  >
                    <PrivateRoute>
                      <PopupSequence />
                    </PrivateRoute>
                  </Suspense>
                }
              ></Route>
              <Route
                path="/popup/:popupId"
                element={
                  <Suspense
                    fallback={
                      <LoadingPortal>
                        <LoadingModal />
                      </LoadingPortal>
                    }
                  >
                    <PrivateRoute>
                      <PopupModify />
                    </PrivateRoute>
                  </Suspense>
                }
              ></Route>
              {/* <Route path="/demo/userlogin" element={<DemoUserLogin />}></Route>
              <Route path="/demo/userstore/:storeDomain" element={<DemoUserMain />}></Route>
              <Route path="/demo/qr/:number" element={<DemoQR />}></Route>
              <Route path="/demo/qrdetail/:number" element={<DemoQRDetail />}></Route>

              <Route path="/demo/payment" element={<DemoPayment />}></Route>
              <Route path="/demo/paymentlist" element={<DemoPaymentList />}></Route>

              <Route path="/demo/success" element={<DemoSuccess />}></Route>
              <Route path="/demo/paymentcancel" element={<DemoPaymentCancel />}></Route>
              
              <Route path="/demo/store/:storeDomain" element={<DemoStoreMain />}></Route>
              <Route path="/demo/itemcreate" element={<DemoItemCreate />}></Route>
              <Route path="/demo/storecalculate" element={<DemoStoreCalculate />}></Route>
              <Route path="/demo/settlehistory" element={<DemoSettleHistory />}></Route>
              <Route path="/demo/settlehistorysub" element={<DemoSettleHistorySub />}></Route>
              <Route path="/demo/settledetail/:Mode" element={<DemoSettleDetail />}></Route> */}
              <Route path="/" element={<AdminLogin />}></Route>
              <Route path="/error" element={<Error />}></Route>
              <Route path="/404" element={<NotFound />}></Route>
              <Route path="*" element={<NotFound />}></Route>
            </Routes>
          </Router>
        </header>
      </div>
    </Provider>
  );
}

export default App;
