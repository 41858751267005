import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";

import "./Datepicker.css";
import {
  getFormatDate,
  getFormatTime,
  getMaxDate,
} from "../../function/common";

export default function PaymentDay({ eventDate, setEventDate }) {
  // 요일 반환
  const getDayName = (date) => {
    return date
      .toLocaleDateString("ko-KR", {
        weekday: "long",
      })
      .substr(0, 1);
  };

  // 날짜 비교시 년 월 일까지만 비교하게끔
  const createDate = (date) => {
    return new Date(
      new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
    );
  };

  {
    /* 결제/매출/정산 설정 모달 (일자별) */
  }
  return (
    <div className="demo" style={{ margin: "0", maxWidth: "100%" }}>
      <div className="TPaymentDay">
        <div className="container-fluid g-0">
          <div className="DatepickerWrap">
            <div className="DatepickerBox ver1">
              <div className="align-items-center d-flex">
                <DatePicker
                  className="form-control InputType input-datepicker prd-admin"
                  locale={ko}
                  dateFormat="yyyy-MM-dd"
                  selected={new Date(eventDate.startDate)}
                  onChange={(date) => {
                    if (getMaxDate(date, eventDate.endDate)) {
                      setEventDate({
                        startDate: getFormatDate(eventDate.endDate),
                        endDate: getFormatDate(date),
                      });
                      return;
                    }

                    setEventDate((prev) => ({
                      ...prev,
                      startDate: getFormatDate(date),
                    }));
                  }}
                  onFocus={(e) => e.target.blur()}
                  selectsStart
                  //   minDate={new Date()}
                  startDate={new Date(eventDate.startDate)}
                  endDate={new Date(eventDate.endDate)}
                  placeholderText="YYYY-MM-DD"
                  dayClassName={(date) =>
                    getDayName(createDate(date)) === "토"
                      ? "Sat"
                      : getDayName(createDate(date)) === "일"
                      ? "Sun"
                      : undefined
                  }
                  renderCustomHeader={({
                    monthDate,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                    decreaseMonth,
                    increaseMonth,
                  }) => (
                    <div
                      className="header"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="month-day">
                        {monthDate.toLocaleString("ko-KO", {
                          month: "long",
                          year: "numeric",
                        })}
                      </div>

                      <div className="btn-group">
                        <div
                          className="btn_month btn_month-prev"
                          onClick={decreaseMonth}
                          disabled={prevMonthButtonDisabled}
                        >
                          <img
                            className="img-fluid"
                            src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png"
                          />
                        </div>
                        <div
                          className="btn_month btn_month-next"
                          onClick={increaseMonth}
                          disabled={nextMonthButtonDisabled}
                        >
                          <img
                            className="img-fluid"
                            src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                />

                <span className="tilde">~</span>

                <DatePicker
                  className="form-control InputType input-datepicker prd-admin"
                  locale={ko}
                  dateFormat="yyyy-MM-dd"
                  selected={new Date(eventDate.endDate)}
                  onChange={(date) =>
                    setEventDate((prev) => ({
                      ...prev,
                      endDate: getFormatDate(date),
                    }))
                  }
                  onFocus={(e) => e.target.blur()}
                  selectsEnd
                  startDate={new Date(eventDate.startDate)}
                  endDate={new Date(eventDate.endDate)}
                  minDate={new Date(eventDate.startDate)}
                  placeholderText="YYYY-MM-DD"
                  dayClassName={(date) =>
                    getDayName(createDate(date)) === "토"
                      ? "Sat"
                      : getDayName(createDate(date)) === "일"
                      ? "Sun"
                      : undefined
                  }
                  renderCustomHeader={({
                    monthDate,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                    decreaseMonth,
                    increaseMonth,
                  }) => (
                    <div
                      className="header"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="month-day">
                        {monthDate.toLocaleString("ko-KO", {
                          month: "long",
                          year: "numeric",
                        })}
                      </div>

                      <div className="btn-group">
                        <div
                          className="btn_month btn_month-prev"
                          onClick={decreaseMonth}
                          disabled={prevMonthButtonDisabled}
                        >
                          <img
                            className="img-fluid"
                            src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png"
                          />
                        </div>
                        <div
                          className="btn_month btn_month-next"
                          onClick={increaseMonth}
                          disabled={nextMonthButtonDisabled}
                        >
                          <img
                            className="img-fluid"
                            src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
