import { useNavigate } from "react-router";
import { apiClient } from "../api/apiClient";
import { useMutation } from "./common/useMutation";

export default function useEventModify(eventId) {
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(
    async (params) =>
      await apiClient({
        url: `/events/${eventId}`,
        method: "put",
        data: params,
      }),
    {
      onSuccess: () => {
        navigate("/event");
      },
      onError: ({ response }) => {
        alert(response.data.message);
      },
    }
  );

  return { mutate, isLoading };
}
