import React from "react";

import { Container, Row, Col, Form, Image, Button } from 'react-bootstrap';
import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';

import { getApiUrl, PrintConsole, setCommas, sleep } from '../../function/common';
import { GetUserMe } from '../../api/ApiGetStoreUser';
import { GetAuthorize } from '../../api/ApiGetUser';
import { setAccessToken, setNav3 } from '../../api/ApiWebView';

import Header from './components/Header';
import "./QRwallet.css";

export default function QRwallet(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [item, setItem] = useState();
    function getOrderItem() {
        return new Promise( async function(resolve, reject) {
            var obj = {
                isUsed : true
            }
            const response = await axios.get( 
                getApiUrl('users/me/food-tickets'),
                
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8"
            ).then(
                function(response){
                    if(response.status === 200){
                        setItem(response.data);
                    }
                    
                }
            ).catch(function(error){
                console.log('error');
            })
            if (response) {
                return resolve(response);
            }
        });
    };

    useEffect( () => {
        //데이터 로딩
        setLoading(true);

        console.log('effect');
        window.scrollTo(0,0);

        setLoading(false);
    },[])

    if (loading) return <></>

    //개발자 콘솔에서 반응형또는 기기 선택시 해당 os 반환
    // getVersion();
    // setAccessToken();
    
    return (
        <>
        <div className='demo'>

            <div className="TQRwalletPage">
                <div className="container-fluid g-0">

                    <nav className="sub-navbar">
                        <p className="navbar-title">QR 지갑</p>
                        <button type="button" className="btn-clear btn-back" onClick={()=>{navigate(-1)}}>
                            <img className="img-fluid" alt="뒤로가기" src="https://www.flexdaycdn.net/public/images/ticket/back-b.png" />
                        </button>
                        <Header/>                
                    </nav>

                    <div className="PayCateTab">
                        <div className="nav nav-pills justify-content-around mb-4-5" id="pills-tab" role="tablist">
                            <div className="nav-item" role="presentation">
                                <button className="nav-link active PayCateBtn" id="pills-content1-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-content1" type="button" role="tab" aria-controls="pills-content1"
                                    aria-selected="true">
                                    <span>사용 가능 QR(3)</span>
                                </button>
                            </div>
                            <div className="nav-item" role="presentation">
                                <button className="nav-link PayCateBtn" id="pills-content2-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-content2" type="button" role="tab" aria-controls="pills-content2"
                                    aria-selected="false">
                                   <span>사용 완료 QR(2)</span>
                                </button>
                            </div>
                        </div>
                        <div className="tab-content pb-5" id="pills-tabContent">
                            <div className="tab-pane show active" id="pills-content1" role="tabpanel" aria-labelledby="pills-content1-tab">

                                <div className="PayPrdList px-4-5">
                                    <div className="PayListItem">
                                        <div className=" infobox d-flex justify-content-between">
                                            <div className="InfoboxTxt">
                                                <div className="txtbox">
                                                    <p className="prd-name mb-0">
                                                        C코너
                                                    </p>
                                                    <p className="store-name">
                                                        인제대학교 늘빛관(다인)
                                                    </p>
                                                </div>
                                                <Link to={"/gift/1"} className="giftbox">
                                                    <img className="img-fluid gift" alt="" src="https://www.flexdaycdn.net/public/images/ticket/gift_icon.png" />
                                                    <span>
                                                        선물하기
                                                    </span>
                                                    <img className="img-fluid arrow" alt="" src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" />
                                                </Link>
                                            </div>
                                            <Link to={"/qr/1"} className="QRBtn">
                                                <button type="type" className="btn btn-clear text-white">
                                                    <img className="img-fluid" alt="" src="https://www.flexdaycdn.net/public/images/ticket/qr_icon.png" />
                                                    QR사용
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="PayListItem">
                                        <div className=" infobox d-flex justify-content-between">
                                            <div className="InfoboxTxt">
                                                <div className="txtbox">
                                                    <p className="prd-name mb-0">
                                                        스파클링 탄산음료
                                                    </p>
                                                    <p className="store-name">
                                                        인제대학교 늘빛관(다인)
                                                    </p>
                                                </div>
                                                <Link to={""} className="giftbox">
                                                    <img className="img-fluid gift" alt="" src="https://www.flexdaycdn.net/public/images/ticket/gift_icon.png" />
                                                    <span>
                                                        선물하기
                                                    </span>
                                                    <img className="img-fluid arrow" alt="" src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" />
                                                </Link>
                                            </div>
                                            <Link to={""} className="QRBtn">
                                                <button type="type" className="btn btn-clear text-white">
                                                    <img className="img-fluid" alt="" src="https://www.flexdaycdn.net/public/images/ticket/qr_icon.png" />
                                                    QR사용
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="PayListItem">
                                        <div className=" infobox d-flex justify-content-between">
                                            <div className="InfoboxTxt">
                                                <div className="txtbox">
                                                    <p className="prd-name mb-0">
                                                        스파클링 탄산음료
                                                    </p>
                                                    <p className="store-name">
                                                        인제대학교 늘빛관(다인)
                                                    </p>
                                                </div>
                                                <Link to={""} className="giftbox">
                                                    <img className="img-fluid gift" alt="" src="https://www.flexdaycdn.net/public/images/ticket/gift_icon.png" />
                                                    <span>
                                                        선물하기
                                                    </span>
                                                    <img className="img-fluid arrow" alt="" src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" />
                                                </Link>
                                            </div>
                                            <Link to={""} className="QRBtn">
                                                <button type="type" className="btn btn-clear text-white">
                                                    <img className="img-fluid" alt="" src="https://www.flexdaycdn.net/public/images/ticket/qr_icon.png" />
                                                    QR사용
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="PayListItem">
                                        <div className=" infobox d-flex justify-content-between">
                                            <div className="InfoboxTxt">
                                                <div className="txtbox">
                                                    <p className="prd-name mb-0">
                                                        스파클링 탄산음료
                                                    </p>
                                                    <p className="store-name">
                                                        인제대학교 늘빛관(다인)
                                                    </p>
                                                </div>
                                                <Link to={""} className="giftbox">
                                                    <img className="img-fluid gift" alt="" src="https://www.flexdaycdn.net/public/images/ticket/gift_icon.png" />
                                                    <span>
                                                        선물하기
                                                    </span>
                                                    <img className="img-fluid arrow" alt="" src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" />
                                                </Link>
                                            </div>
                                            <Link to={""} className="QRBtn">
                                                <button type="type" className="btn btn-clear text-white">
                                                    <img className="img-fluid" alt="" src="https://www.flexdaycdn.net/public/images/ticket/qr_icon.png" />
                                                    QR사용
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="PayListItem">
                                        <div className=" infobox d-flex justify-content-between">
                                            <div className="InfoboxTxt">
                                                <div className="txtbox">
                                                    <p className="prd-name mb-0">
                                                        스파클링 탄산음료
                                                    </p>
                                                    <p className="store-name">
                                                        인제대학교 늘빛관(다인)
                                                    </p>
                                                </div>
                                                <Link to={""} className="giftbox">
                                                    <img className="img-fluid gift" alt="" src="https://www.flexdaycdn.net/public/images/ticket/gift_icon.png" />
                                                    <span>
                                                        선물하기
                                                    </span>
                                                    <img className="img-fluid arrow" alt="" src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" />
                                                </Link>
                                            </div>
                                            <Link to={""} className="QRBtn">
                                                <button type="type" className="btn btn-clear text-white">
                                                    <img className="img-fluid" alt="" src="https://www.flexdaycdn.net/public/images/ticket/qr_icon.png" />
                                                    QR사용
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                {/* 임시로 숨김처리 */}
                                <div className="d-none PayPrdList px-4-5">
                                    {
                                        item && item.data.map( row => (
                                            row.status['code'] === 1 ? 
                                                <div className="PayListItem">
                                                    <div
                                                        className="infobox d-flex justify-content-between">
                                                        <div className="InfoboxTxt">
                                                            <div className="txtbox">
                                                                <p className="prd-name mb-0">
                                                                    {row.productName}
                                                                </p>
                                                                <p className="store-name">
                                                                    {row.storeName}
                                                                </p>                              
                                                                {/* <p className="prd-price mb-0">
                                                                    <span>{setCommas(row.price)}</span>원
                                                                </p> */}
                                                            </div>
                                                            <Link to={"/demo/gift"+row.couponId} className="giftbox">
                                                                <img className="img-fluid gift" alt="" src="https://www.flexdaycdn.net/public/images/ticket/gift_icon.png" />
                                                                <span>
                                                                    선물하기
                                                                </span>
                                                                <img className="img-fluid arrow" alt="" src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" />
                                                            </Link>
                                                        </div>
                                                        <Link to={"/demo/qr/"+row.couponId} className="QRBtn">
                                                            <button className="btn btn-clear text-white"> 
                                                                <img className="img-fluid" alt="" src="https://www.flexdaycdn.net/public/images/ticket/qr_icon.png" />
                                                                QR사용
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                    ))
                                }

                                </div>
                            </div>

                            <div className="tab-pane" id="pills-content2" role="tabpanel" aria-labelledby="pills-content2-tab">
                                <div className="PayPrdList px-4-5">
                                    <div className="PayListItem used">
                                        <div className=" infobox d-flex justify-content-between">
                                            <div className="InfoboxTxt">
                                                <div className="txtbox">
                                                    <p className="prd-date">
                                                        사용일시 2022-12-20 11:40
                                                    </p>
                                                    <p className="prd-name mb-0">
                                                        A코너
                                                    </p>
                                                    <p className="store-name">
                                                        인제대학교 늘빛관(다인)
                                                    </p>
                                                    <img className="img-fluid prd-stamp" alt=""
                                                        src="https://www.flexdaycdn.net/public/images/ticket/QR_ticket_stamp2.png" />
                                                </div>
                                            </div>
                                            <Link to={"/survey/1"} className="QRBtn">
                                                <button type="type" className="btn btn-clear text-white">
                                                    만족도 <br />
                                                    조사
                                                </button>
                                            </Link>
                                        </div>
                                    </div>

                                    {/* 임시로 숨김처리 / 만족도 조사 완료되었을경우 class "survey-done" 추가 */}
                                    <div className="PayListItem used survey-done">
                                        <div className=" infobox d-flex justify-content-between">
                                            <div className="InfoboxTxt">
                                                <div className="txtbox">
                                                    <p className="prd-date">
                                                        사용일시 2022-12-19 12:30
                                                    </p>
                                                    <p className="prd-name mb-0">
                                                        스파클링 탄산음료
                                                    </p>
                                                    <p className="store-name">
                                                        인제대학교 늘빛관(다인)
                                                    </p>
                                                    <img className="img-fluid prd-stamp" alt=""
                                                        src="https://www.flexdaycdn.net/public/images/ticket/QR_ticket_stamp2.png" />
                                                </div>
                                            </div>
                                            <Link to={"/survey/1"} className="QRBtn">
                                                <button type="type" className="btn btn-clear text-white">
                                                    만족도 <br />
                                                    조사
                                                </button>
                                            </Link>
                                        </div>
                                    </div>

                                    {/* 임시로 숨김처리 / 만족도 조사 완료되었을경우 class "survey-done" 추가 */}
                                    <div className="d-none PayPrdList px-4-5">
                                    {
                                        item && item.data.map( row => (
                                            row.status['code'] === 2 ? 
                                                <div className="PayListItem used">
                                                    <div
                                                        className=" infobox d-flex justify-content-between">
                                                        <div className="InfoboxTxt">
                                                            <div className="txtbox">
                                                                <p className="prd-date">
                                                                    사용일시 2022-12-20 11:40
                                                                </p>
                                                                <p className="prd-name mb-0">
                                                                    {row.productName}
                                                                </p>
                                                                <p className="store-name">
                                                                    {row.storeName}
                                                                </p>
                                                                {/* <p className="prd-price mb-0">
                                                                    <span>{setCommas(row.price)}</span>원
                                                                </p> */}
                                                                <img className="img-fluid prd-stamp" alt="" src="https://www.flexdaycdn.net/public/images/ticket/QR_ticket_stamp2.png" />
                                                            </div>
                                                        </div>
                                                        <Link  to={"/demo/survey/"+row.couponId} className="QRBtn">
                                                            <button className="btn btn-clear text-white">
                                                                만족도 <br />
                                                                조사
                                                            </button>
                                                        </Link>
                                                    </div>

                                                </div>
                                                :
                                                <></>
                                        ))
                                    }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </div>
        </>
    )
}