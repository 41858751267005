import { Container, Row, Col, Form, Image, Button } from 'react-bootstrap';
// import CheckBox from '../../../components/common/CheckBox';

// import ListGroupH from '../../../components/ListGroupH';
// import { GetAuthorize, GetUserMe } from './../../../api/ApiGetUser';
import { useState } from 'react';
// import { getImgUrl, LocationUrl } from '../../../function/common';
// import { getVersion, setAccessToken, deviceType, setNav3 } from './../../../api/ApiWebView';
// import { sleep, includeStr, getSubDomain, PrintConsole } from './../../../function/common';
import { useNavigate, Link } from 'react-router-dom';
import { useEffect } from 'react';
// import SideNav from './../../../components/common/SideNav';

import axios from 'axios';

import { getApiUrl, PrintConsole, setCommas, sleep } from '../../function/common';
import { GetUserMe } from '../../api/ApiGetStoreUser';
import { GetAuthorize } from '../../api/ApiGetUser';
import { setAccessToken, setNav3 } from '../../api/ApiWebView';

import Modal from 'react-bootstrap/Modal';
import Header from './components/Header';
import "./PaymentList.css";

export default function PaymentList(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    // 모달
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [item, setItem] = useState();
    function getOrderItem() {
        return new Promise( async function(resolve, reject) {
            var obj = {
                isUsed : true
            }
            const response = await axios.get( 
                getApiUrl('users/me/food-tickets'),
                
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8"
            ).then(
                function(response){
                    if(response.status === 200){
                        setItem(response.data);
                    }
                    
                }
            ).catch(function(error){
                console.log('error');
            })
            if (response) {
                return resolve(response);
            }
        });
    };

    useEffect( () => {
        // localStorage.removeItem('accessToken');
        //데이터 로딩
        setLoading(true);

        console.log('effect');
        getOrderItem();

        // try{
        //     setNav3();
        // }catch(e){}
        setLoading(false);
    },[])

    if (loading) return <></>

    
    

    //개발자 콘솔에서 반응형또는 기기 선택시 해당 os 반환
    // getVersion();
    // setAccessToken();
    
    return (
        <>
        <div className='demo'>

            <div className="TPaymentList2Page">
                <div className="container-fluid g-0">

                    <nav className="sub-navbar">
                        <p className="navbar-title">결제 내역</p>
                        <button type="button" className="btn-clear btn-back" onClick={()=>{navigate(-1)}}>
                            <img className="img-fluid" alt="뒤로가기" src="https://www.flexdaycdn.net/public/images/ticket/back-b.png" />
                        </button>
                        <Header/>                
                    </nav>

                    <div className="PaymentFilter px-4-5 pt-4-5">
                        <div className="sort" onClick={handleShow}>
                            <span className="filter-txt">최근 3개월</span>
                            <img className="img-fluid arrow"
                                src="https://www.flexdaycdn.net/public/images/ticket/arrow-down.png" alt="" />
                        </div>
                    </div>

                    <div className="PrdList">
                        <div className="Listitem px-4-5 my-4">
                            <div className="align-items-center d-flex justify-content-between">
                                <img className="img-fluid logo" alt=""
                                    src="https://www.flexdaycdn.net/public/images/ticket/logo.png" />
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        2022-12-29 11:50
                                    </p>
                                    <p className="price">
                                        <span className="num">4,500원</span>
                                    </p>
                                </div>
                                <div className="moreDatil">
                                    <Link to={"/qrdetail/1"}>
                                        <button className="btn btn-clear w-100" type="button">
                                            상세내역
                                            <img className="img-fluid"
                                                src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="Listitem px-4-5 my-4">                        
                            <div className="align-items-center d-flex justify-content-between">
                                <img className="img-fluid logo" alt=""
                                    src="https://www.flexdaycdn.net/public/images/ticket/logo.png" />
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        2022-12-28 11:43
                                    </p>
                                    <p className="price">
                                        <span className="num">4,000원</span>
                                    </p>
                                </div>
                                <div className="moreDatil">
                                    <Link to={"/qrdetail/1"}>
                                        <button className="btn btn-clear w-100" type="button">
                                        상세내역
                                        <img className="img-fluid"
                                            src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="Listitem cancel px-4-5 my-4">
                            {/* 확인 : 취소된 내역 / class 'cancel' 추가 */}
                            <div className="align-items-center d-flex justify-content-between">
                                <img className="img-fluid logo" alt=""
                                    src="https://www.flexdaycdn.net/public/images/ticket/logo.png" />
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        2022-12-27 11:50
                                    </p>
                                    <p className="price">
                                        <span className="num">5,800원</span>
                                    </p>
                                </div>
                                <div className="moreDatil">
                                    <Link to={"/qrdetail/1"}>
                                        <button className="btn btn-clear w-100" type="button">
                                        상세내역
                                        <img className="img-fluid"
                                            src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 결제내역 Modal */}
                <Modal
                    show={show}
                    onHide={handleClose}
                    keyboard={false}
                    size="sm"
                    className="demoModal TPaymentList-modal"
                    dialogClassName="modal-prdfilter"
                    >
                    <div className="modal-bar py-3">
                        <div className="bar"></div>    
                    </div>
                    <Modal.Header className='pb-4'>
                        <Modal.Title className='fw-bold'>
                            조회기간 선택
                        </Modal.Title>
                        <button type="button" className="btn btn-clear" onClick={handleClose}>
                            <img className="img-fluid" alt="" src="https://www.flexdaycdn.net/public/images/ticket/close.png" />
                        </button>
                    </Modal.Header>
                    <Modal.Body className='pb-4'>
                        <div className="prd-sortbox">
                            <div className="form-sort">
                                <input className="form-radio-input" id="survey-radio-1" type="radio"
                                     name="flexRadioDefault" defaultChecked/>
                                <label className="form-radio-label" htmlFor="survey-radio-1">
                                    최근 3개월
                                    <img className="img-fluid check" alt=""  src="https://www.flexdaycdn.net/public/images/ticket/check-icon.png" />
                                </label>
                            </div>
                            <div className="form-sort">
                                <input className="form-radio-input" id="survey-radio-2" type="radio" name="flexRadioDefault" />
                                <label className="form-radio-label" htmlFor="survey-radio-2">
                                    최근 6개월
                                    <img className="img-fluid check" alt="" src="https://www.flexdaycdn.net/public/images/ticket/check-icon.png" />
                                </label>
                            </div>
                            <div className="form-sort">
                                <input className="form-radio-input" id="survey-radio-3" type="radio" name="flexRadioDefault" />
                                <label className="form-radio-label" htmlFor="survey-radio-3">
                                    최근 9개월
                                    <img className="img-fluid check" alt=""  src="https://www.flexdaycdn.net/public/images/ticket/check-icon.png" />
                                </label>
                            </div>
                            <div className="form-sort">
                                <input className="form-radio-input" id="survey-radio-4" type="radio" name="flexRadioDefault" />
                                <label className="form-radio-label" htmlFor="survey-radio-4">
                                    전체
                                    <img className="img-fluid check" alt="" src="https://www.flexdaycdn.net/public/images/ticket/check-icon.png" />
                                </label>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal> 
            </div>

        </div>
        </>
    )
}