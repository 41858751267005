import { useNavigate } from "react-router";
import { apiClient } from "../api/apiClient";
import { useMutation } from "./common/useMutation";

export default function useSaleMenuAdd(setErrorText, setIsRemoveModalShowing) {
    const navigate = useNavigate();

    const { mutate, isLoading } = useMutation(
        async (params) =>
            await apiClient({
                url: "/products",
                method: "post",
                data: params,
            }),
        {
            onSuccess: () => {
                navigate("/salemenu");
            },
            onError: ({ response }) => {
                setErrorText(response.data.message);
                setIsRemoveModalShowing(true);
            },
        }
    );

    return { mutate, isLoading };
}
