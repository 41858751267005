import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiClient } from "../../api/apiClient";
const initialState = {
    storeName : null,
    businessHours : null,
    logoUrl : null,
    location : null,
    files : [],
    notice : null,
    useNotice : null,
    storeClosedPeriods : [],
    storeType : null
};

const storeSlice = createSlice({
    name : "store",
    initialState,
    reducers: {
        init: (state, action) => {
            state.storeName = "테스트";
            console.log("action : " + action);
        }
    },
    extraReducers: (builder) => {
        // builder는 Case Reducer로 액션별로 나눠서 액션을 처리할 수 있음.
        // extraReducer를 사용한 이유는 맵핑된 내부 액션 타입이 아니라 외부 액션을 참조하려는 것임.

        builder.addCase(fetchStoreInfo.fulfilled, (state, action) => {
            
          
            return { ...state, ...action.payload };
        })
    }
});


export const fetchStoreInfo = createAsyncThunk(
    'store/Info',
    async ( _, {rejectWithValue}) => {
        try {
            const response = await apiClient({
                url : "/store/me",
                method : "get",
            });
            return { ...response.data};
        }catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
)



export default storeSlice;
export const {init} = storeSlice.actions;
