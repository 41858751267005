import { useRef } from "react";
import { apiClient } from "../../api/apiClient";
import { useMutation } from "../common/useMutation";

export default function useOrderCancel(setCancelShowingModal, refetch) {
  const { mutate, isLoading } = useMutation(
    async (orderNumber) => {
      const { data } = await apiClient({
        url: `/payments/${orderNumber}`,
        method: "get",
      });

      return data;
    },
    {
      onSuccess: () => {
        setCancelShowingModal(false);
        refetch();
      },
      onError: (response) => {
        alert(response.message);
      },
    }
  );

  return { mutate, isRemoveLoading: isLoading };
}
