import axios from "axios";
import { getRedirctUrl, LocationUrl } from "../function/common";
import { getApiUrl } from './../function/common';
import sha256 from 'sha256';

export const GetAuthorize2 = async (obj) => {
    try {
        var variables = {
            "clientId": "10000",
            "redirectUri": getRedirctUrl(''),
            "responseType": "string"
        }
        //서비스 호출
        const res = await axios.get(
            getApiUrl('oauth/authorize')
            , { 
                params:variables
            }
            , {
                headers : {
                    "Access-Control-Allow-Origin": "*"
                }
            }
        )

        console.log(obj);

        if(res.data['shouldRedirectLogin'] === true){
            //테스트 - 로그인 페이지 이동
            await GetLogin(obj);
        }else if(res.data['shouldRedirectLogin'] === false){
            //url로 이동
            await GetLogin(obj);
        }else{
            console.log('error');
        }
    }catch (e) {
        console.log(e);
        console.log('GetAuthorize');
    }
}

export const GetLogin = async (obj) => {
    let res;

    try {
        var variables = {
            "loginId": obj.Id,
            "password": sha256(obj.Password),
            "clientId": "10000", //사용자 10000, 가맹점 10001
            "autoLogin": true
        }

        //서비스 호출
        res = await axios.post(
            getApiUrl('oauth/login')
            , variables
            , {headers : {
                "Access-Control-Allow-Origin": "*"
            }}
        )
        if(res.data['code'] !== null){
            await GetToken(obj, res.data['code']);
        }
    } catch (e) {
        alert(e.response.data.message);
        console.log('GetLogin');
    }
}

export const GetToken = async (obj, code) => {

    try {
        let res;

        //스토어 redirectUri
        var variables = {
            "clientId": "10000",
            "code":code
        }

        //서비스 호출
        res = await axios.post(
            getApiUrl('oauth/token')
            , variables
            , {headers : {
                "Access-Control-Allow-Origin": "*"
            }}
        ).then(
            function(response){
                console.log(response);
                if(response.status === 200){
                    
                    localStorage.setItem('accessToken', response.data['accessToken']);
                    localStorage.setItem('refreshToken', response.data['refreshToken']);

                    // console.log("localStorage : " + localStorage.getItem('accessToken'));
                    // console.log("localStorage : " + localStorage.getItem('refreshToken'));

                    // GetUserMe();
                }
            }
        )

    } catch (e) {
        console.log(e);
        console.log('GetToken');
    }
}



export const GetUserMe = async (obj) => {
    
    console.log('GetUserMe Start');

    // if(localStorage.getItem('accessToken') !== null || localStorage.getItem('accessToken') !== undefined){
        
        try {
            let response;

            //스토어 redirectUri
            var variables = {
                "clientId": "10000",
                "redirectUri": getRedirctUrl("home"),
                // "responseType": "string"
            }
            
            console.log(localStorage.getItem('accessToken'));
    
            //서비스 호출
            response = await axios.get(
                getApiUrl('users/me')
                , variables
                , axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8"
            )

            console.log(response);

            return response;
    
            
        } catch (e) {
            console.log(e);
        }
    // }
}

export function getStoreAuth(){
    try{
        console.log(localStorage.getItem('myStores'));
        if(localStorage.getItem('myStores') !== null){
            let myStores = JSON.parse(localStorage.getItem('myStores'));
            console.log(myStores);
            return myStores;
        }else{
            return null;
        }
    }catch(e){
        console.log(e);
    }
}