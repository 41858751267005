import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";

import { getFormatDate, getMaxDate } from "../../function/common";

export default function Datepicker({
  id,
  eventDate,
  nonOperationTimeDispatch,
}) {
  // 요일 반환
  const getDayName = (date) => {
    return date
      .toLocaleDateString("ko-KR", {
        weekday: "long",
      })
      .substr(0, 1);
  };

  // 날짜 비교시 년 월 일까지만 비교하게끔
  const createDate = (date) => {
    return new Date(
      new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
    );
  };

  // 월별 캘린더 !! 수정해야함 !!
  const ver2_year = "0000";
  const ver2_months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  //   const ver2_locale = {
  //     localize: {
  //       month: (n) => ver2_year + "-" + ver2_months[n],
  //     },
  //     formatLong: {
  //       date: () => "yyyy-MM",
  //     },
  //   };

  return (
    <>
      <div className="DatepickerBox ver1">
        <div className="align-items-center d-flex ver2">
          <DatePicker
            className="form-control InputType input-datepicker storeEdit-add"
            locale={ko}
            dateFormat="yyyy-MM-dd"
            selected={new Date(eventDate.startDate)}
            onChange={(date) => {
              if (getMaxDate(date, eventDate.endDate)) {
                nonOperationTimeDispatch({
                  type: "modified_nonOperationTime",
                  payload: {
                    id,
                    startDate: getFormatDate(eventDate.endDate),
                    endDate: getFormatDate(date),
                  },
                });
                return;
              }

              nonOperationTimeDispatch({
                type: "modified_startNonOperationTime",
                payload: { id, startDate: getFormatDate(date) },
              });
            }}
            onFocus={(e) => e.target.blur()}
            selectsStart
            minDate={new Date()}
            startDate={new Date(eventDate.startDate)}
            endDate={new Date(eventDate.endDate)}
            placeholderText="YYYY-MM-DD"
            dayClassName={(date) =>
              getDayName(createDate(date)) === "토"
                ? "Sat"
                : getDayName(createDate(date)) === "일"
                ? "Sun"
                : undefined
            }
            renderCustomHeader={({
              monthDate,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
              decreaseMonth,
              increaseMonth,
            }) => (
              <div
                className="header"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="month-day">
                  {monthDate.toLocaleString("ko-KO", {
                    month: "long",
                    year: "numeric",
                  })}
                </div>

                <div className="btn-group">
                  <div
                    className="btn_month btn_month-prev"
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    <img
                      className="img-fluid"
                      src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png"
                    />
                  </div>
                  <div
                    className="btn_month btn_month-next"
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    <img
                      className="img-fluid"
                      src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png"
                    />
                  </div>
                </div>
              </div>
            )}
          />
          <span className="tilde">~</span>
          <DatePicker
            className="form-control InputType input-datepicker storeEdit-add"
            locale={ko}
            dateFormat="yyyy-MM-dd"
            selected={new Date(eventDate.endDate)}
            onChange={(date) =>
              nonOperationTimeDispatch({
                type: "modified_endNonOperationTime",
                payload: { id, endDate: getFormatDate(date) },
              })
            }
            onFocus={(e) => e.target.blur()}
            selectsEnd
            startDate={new Date(eventDate.startDate)}
            endDate={new Date(eventDate.endDate)}
            minDate={new Date(eventDate.startDate)}
            placeholderText="YYYY-MM-DD"
            dayClassName={(date) =>
              getDayName(createDate(date)) === "토"
                ? "Sat"
                : getDayName(createDate(date)) === "일"
                ? "Sun"
                : undefined
            }
            renderCustomHeader={({
              monthDate,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
              decreaseMonth,
              increaseMonth,
            }) => (
              <div
                className="header"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="month-day">
                  {monthDate.toLocaleString("ko-KO", {
                    month: "long",
                    year: "numeric",
                  })}
                </div>

                <div className="btn-group">
                  <div
                    className="btn_month btn_month-prev"
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    <img
                      className="img-fluid"
                      src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png"
                    />
                  </div>
                  <div
                    className="btn_month btn_month-next"
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    <img
                      className="img-fluid"
                      src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png"
                    />
                  </div>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </>
  );
}
