import { useNavigate } from "react-router";
import { apiClient } from "../api/apiClient";
import { useMutation } from "./common/useMutation";

export default function useAdminLogin(setIsRemoveModalShowing) {
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(
    async (params) =>
      await apiClient({
        url: "/auth/login",
        method: "post",
        data: params,
      }),
    {
      onSuccess: (response) => {
        localStorage.setItem(
          "accessToken",
          response.data.loginToken["accessToken"]
        );
        localStorage.setItem(
          "refreshToken",
          response.data.loginToken["refreshToken"]
        );

        navigate("/storemain");
      },
      onError: ({ response }) => {
        setIsRemoveModalShowing({
          isShowing: true,
          text: response.data.message,
        });
      },
    }
  );

  return { mutate, isLoading };
}
