import React from "react";
import { useEffect, useState, useRef } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { getApiUrl } from "../../function/common";
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

export default function Scroll(){

    const { name } = useParams();

    // 인피니티 스크롤
    const [loading, setLoading] = useState(true);
    const pageNum = useRef(1);
    const scrollTarget = useRef(null);
    const [data, setData] = useState(["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"]);

    const observer = useRef(
        new IntersectionObserver(
            (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        callback();
                    }
                });
            },{ threshold: 1 }
        )
    );
    const callback = async () =>{
        setLoading(true);
        console.log('Page : ' + pageNum.current);
        console.log('CallBack 통신');
        pageNum.current = pageNum.current + 1;
        setLoading(false);
    }
    const observe = (element) => {
        observer.current.observe(element);
    };
    const unobserve = (element) => {
        observer.current.unobserve(element);
    };
    useEffect(() => {
        if (pageNum.current === 1) observe(scrollTarget.current);
        const N = data.length;
    }, [data]);
    // 인피니티 스크롤

    const AppleLogin = async () => {
        let obj = {
            "oauthAccessToken": window.location.href.split('?code=')[1],
            "oauthType": "APPLE"
        }
        console.log(obj);
        const response = await axios.post(
            getApiUrl('v1/oauth/login' ),
            obj,
            axios.defaults.headers.common['ContentType'] = "application/json;",
        ).then(
            function(response){
                console.log('----------------------------------');
                console.log(response);
                if(response.status === 200){
                    console.log(response)
                }
            }
        ).catch(function(error){
            console.log('error');
        })
    }



    useEffect (() => {
        // AppleLogin();

    }, []);

    // console.log(data);

    // const weekArr = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

    return (
        <>
            <Container>
                {
                    data && data.map((row, i) =>(
                        <Row key={i} style={{height:'300px'}}>
                            <Col>
                                {row}
                            </Col>
                        </Row>
                    ))
                }
                
                <Row ref={scrollTarget}></Row>
                    
            </Container>
        </>
    )
}