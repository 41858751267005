import { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import uuid from "react-uuid";
import Datepicker from "../../../components/common/Datepicker";
import LoadingModal from "../../../components/loadingmodal/LoadingModal";
import { getFormatDate, vaildateEvent } from "../../../function/common";
import useRemoveBodyStyle from "../../../hooks/common/useRemoveBodyStyle";
import useEventAdd from "../../../hooks/useEventAdd";
import useProductList from "../../../hooks/useProductList";
import LoadingPortal from "../../../portals/LoadingPortal";
import AdminHeader from "../../UserMain/components/AdminHeader";
import "./EventAdd.css";

export default function EventAdd() {
  useRemoveBodyStyle();
  const navigate = useNavigate();
  const [eventDate, setEventDate] = useState({
    startDate: getFormatDate(),
    endDate: getFormatDate(),
  });
  const [errorText, setErrorText] = useState("");
  const [isRemoveModalShowing, setIsRemoveModalShowing] = useState(false);
  const [eventType, setEventType] = useState("STAMP");
  const eventNameInputRef = useRef();
  const [stampList, setStampList] = useState([
    {
      id: uuid(),
      rewardProductId: null,
      stampCount: 0,
      rewardType: "INNER_PRODUCT",
      reward: null,
    },
  ]);
  const { products } = useProductList();
  const { mutate, isLoading } = useEventAdd(
    setErrorText,
    setIsRemoveModalShowing
  );

  if (!products) {
    return null;
  }

  const handleRemoveModalShow = () => {
    setIsRemoveModalShowing(true);
  };

  const handleRemoveModalClose = () => {
    setIsRemoveModalShowing(false);
  };

  const handleStampChange = ({ target }, stampChangeId) => {
    if (parseInt(target.value) > 20) {
      return;
    }

    setStampList((prev) =>
      prev.map((reward) => {
        if (reward.id === stampChangeId)
          return { ...reward, stampCount: parseInt(target.value) };

        return reward;
      })
    );
  };

  const handleProductChange = ({ target }, productChangeId) => {
    if (target.value === "direct") {
      setStampList((prev) =>
        prev.map((reward) => {
          if (reward.id === productChangeId) {
            return {
              ...reward,
              rewardType: "ETC",
              rewardProductId: null,
            };
          }

          return reward;
        })
      );
      return;
    }

    setStampList((prev) =>
      prev.map((reward) => {
        if (reward.id === productChangeId) {
          return {
            ...reward,
            reward: null,
            rewardType: "INNER_PRODUCT",
            rewardProductId: target.value,
          };
        }

        return reward;
      })
    );
  };

  const handleNewEventRegisterClick = () => {
    if (isLoading) {
      return;
    }

    const newEvent = {
      eventName: eventNameInputRef.current.value,
      startDate: eventDate.startDate,
      endDate: eventDate.endDate,
      eventType,
      stampEvent: {
        stamp: stampList,
      },
    };

    if (!vaildateEvent(newEvent, setErrorText, setIsRemoveModalShowing)) {
      return;
    }

    mutate(newEvent);
  };

  const handleRewardRemoveClick = (rewardRemoveId) => {
    if (stampList.length <= 1) {
      return;
    }

    setStampList((prev) =>
      prev.filter((reward) => reward.id !== rewardRemoveId)
    );
  };

  const handleRewardAddClick = () =>
    setStampList((prev) => [
      ...prev,
      {
        id: uuid(),
        rewardProductId: null,
        stampCount: 0,
        rewardType: "INNER_PRODUCT",
        reward: "",
      },
    ]);

  const handleProductInputChange = ({ target }, productChangeId) => {
    setStampList((prev) =>
      prev.map((reward) => {
        if (reward.id === productChangeId) {
          return {
            ...reward,
            reward: target.value,
          };
        }

        return reward;
      })
    );
  };

  const mapedProductList = products.map(({ productId, productName }, index) => (
    <option key={productId} value={productId}>
      {productName}
    </option>
  ));

  const mapedRewardList = stampList.map(({ id, rewardType }, index) => (
    <div className="EventAdd-inputbox reward d-flex" key={id}>
      <div className="input-box1 d-flex">
        <span className="num">{index + 1}</span>
        스탬프
        <input
          type="number"
          className="form-control InputType input1"
          defaultValue=""
          value={stampList[index].stampCount}
          placeholder="1"
          onChange={(e) => handleStampChange(e, id)}
          onWheel={(e) => e.target.blur()}
        />
        개,
      </div>
      <div className="input-box2 d-flex">
        <select
          type="select"
          className={`form-control InputType input2 ${
            rewardType === "ETC" && "show"
          }`}
          id="select1"
          onChange={(e) => handleProductChange(e, id)}
          defaultValue="default"
        >
          <option value="default" disabled hidden>
            상품 선택
          </option>
          {mapedProductList}
          <option value="direct">직접 입력 (추첨 상품용)</option>
        </select>
        지급
        <input
          type="text"
          className="form-control InputType direct-input"
          placeholder="리워드를 입력해주세요. (예시: 아이패드)"
          onChange={(e) => handleProductInputChange(e, id)}
        />
      </div>
      <div className="btn-box">
        <button
          type="button"
          className={`btn btn-clear ${stampList.length <= 1 && "disabled"}`}
          onClick={() => handleRewardRemoveClick(id)}
        >
          <img
            className="img-fluid"
            alt=""
            src="https://www.flexdaycdn.net/public/images/ticket/delete_icon.png"
          />
        </button>
      </div>
    </div>
  ));

  return (
    <>
      <div className="demo">
        <div className="TEventAddPage">
          <div className="container-fluid g-0">
            <nav className="sub-navbar">
              <p className="navbar-title">이벤트 추가</p>
              <button
                type="button"
                className="btn-clear btn-back"
                onClick={() => navigate("/event")}
              >
                <img
                  className="img-fluid"
                  alt="뒤로가기"
                  src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                />
              </button>
              <button
                className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
              >
                <img
                  className="img-fluid"
                  alt="메뉴"
                  src="https://www.flexdaycdn.net/public/images/ticket/menu3.png"
                />
              </button>
              <AdminHeader />
            </nav>

            <div className="EventAddBox py-4-5 px-4-5">
              <div className="EventAdd-Form">
                <div className="EventAdd-Formitem pb-5">
                  <p className="EventAdd-tit">
                    1. 이벤트명<span>*</span>
                  </p>
                  <div className="EventAdd-inputbox">
                    <input
                      type="text"
                      className="form-control InputType"
                      placeholder="이벤트명을 입력해 주세요."
                      ref={eventNameInputRef}
                    />
                  </div>
                </div>

                <div className="EventAdd-Formitem pb-5">
                  <p className="EventAdd-tit mb-0">
                    2. 운영 기간<span>*</span>
                  </p>
                  <p className="EventAdd-infoTxt">
                    (운영 기간이 지나면 사용자 스탬프는 소멸됩니다.)
                  </p>
                  <Datepicker
                    eventDate={eventDate}
                    setEventDate={setEventDate}
                  />
                </div>

                <div className="EventAdd-Formitem">
                  <p className="EventAdd-tit mb-0">
                    3. 리워드 생성<span>*</span>
                  </p>
                  {mapedRewardList}
                </div>
              </div>

              <div className="EventAdd-Btnbox">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={handleRewardAddClick}
                >
                  + 리워드 추가
                </button>
              </div>

              <div className="EventAdd-Btnbox">
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={handleNewEventRegisterClick}
                >
                  등록
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={isRemoveModalShowing}
        onHide={handleRemoveModalShow}
        keyboard={false}
        size="sm"
        centered
        className="demoModal modal-prdAdd text-center"
      >
        <Modal.Header>
          <Modal.Title>{errorText}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="modal-primary"
            className="btn-clear w-100"
            onClick={handleRemoveModalClose}
          >
            확인
          </Button>
        </Modal.Footer>
      </Modal>
      {isLoading && (
        <LoadingPortal>
          <LoadingModal type="black" />
        </LoadingPortal>
      )}
    </>
  );
}
