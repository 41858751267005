import { useNavigate } from "react-router-dom";
import AdminHeader from "../../UserMain/components/AdminHeader";
import "./SaleMenuSequence.css";
import { useRef } from "react";
import { getDeepCopy } from "../../../function/common";
import { useState } from "react";
import { useEffect } from "react";
import useSequence from "../../../hooks/sale/useSequence";
import useSaleMenu from "../../../hooks/useSaleMenu";
import useRemoveBodyStyle from "../../../hooks/common/useRemoveBodyStyle";
import LoadingPortal from "../../../portals/LoadingPortal";
import LoadingModal from "../../../components/loadingmodal/LoadingModal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export default function SaleMenuSequence() {
    useRemoveBodyStyle();
    const draggingRef = useRef();
    const draggingOverRef = useRef();
    const originalValue = useRef();
    const navigate = useNavigate();
    const { data: products, isProductsLoading } = useSaleMenu();
    const [dragProducts, setDragProducts] = useState();
    const { mutate, isLoading } = useSequence();

    useEffect(() => {
        setDragProducts(products);
    }, [products]);

    if (isProductsLoading) {
        return (
            <LoadingPortal>
                <LoadingModal />
            </LoadingPortal>
        );
    }

    if (!products || !dragProducts) {
        return null;
    }

    const handleDragStart = (result) => {
        draggingRef.current = result.source.index;
        originalValue.current = {
            productId: dragProducts[result.source.index].productId,
            ordering: dragProducts[result.source.index].ordering,
        };
    };

    const handleDragUpdate = (result) => {
        if (!result.destination) {
            return;
        }

        draggingOverRef.current = result.destination.index;

        const copyProducts = getDeepCopy(dragProducts);
        const dragProductContent = copyProducts[draggingRef.current];

        copyProducts.splice(draggingRef.current, 1);
        copyProducts.splice(draggingOverRef.current, 0, dragProductContent);

        const temp = copyProducts[draggingRef.current].ordering;
        copyProducts[draggingRef.current].ordering =
            copyProducts[draggingOverRef.current].ordering;
        copyProducts[draggingOverRef.current].ordering = temp;

        draggingRef.current = draggingOverRef.current;
        draggingOverRef.current = null;

        setDragProducts(copyProducts);
    };

    const handleDragEnd = () => {
        draggingRef.current = null;
    };

    const handleSequenceClick = () => {
        if (isLoading) {
            return;
        }

        const mapedDragProducts = dragProducts.map(
            ({ productId, ordering }) => ({
                productId,
                ordering,
            })
        );

        mutate(mapedDragProducts);
    };

    return (
        <>
            <div className="demo">
                <div className="TPrdOrderPage">
                    <div className="container-fluid g-0">
                        <nav className="sub-navbar">
                            <p className="navbar-title">메뉴 순서 편집</p>
                            <button
                                type="button"
                                className="btn-clear btn-back"
                                onClick={() => navigate("/salemenu")}
                            >
                                <img
                                    className="img-fluid"
                                    alt="뒤로가기"
                                    src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                                />
                            </button>
                            <button
                                className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasNavbar"
                                aria-controls="offcanvasNavbar"
                            >
                                <img
                                    className="img-fluid"
                                    alt="메뉴"
                                    src="https://www.flexdaycdn.net/public/images/ticket/menu3.png"
                                />
                            </button>
                            <AdminHeader />
                        </nav>

                        <div className="PrdOrderBox py-4-5 px-4-5">
                            <DragDropContext
                                onDragStart={handleDragStart}
                                onDragUpdate={handleDragUpdate}
                                onDragEnd={handleDragEnd}
                            >
                                <Droppable droppableId="menulists">
                                    {(provided) => {
                                        return (
                                            <div
                                                className="menulists PrdOrder-Itembox"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {dragProducts.map(
                                                    (product, index) => {
                                                        const {
                                                            productId,
                                                            ordering,
                                                            productName,
                                                            productDescription,
                                                        } = product;

                                                        return (
                                                            <Draggable
                                                                draggableId={`${productId}`}
                                                                index={index}
                                                                key={`${productId}`}
                                                            >
                                                                {(
                                                                    provided,
                                                                    snapshot
                                                                ) => {
                                                                    return (
                                                                        <div
                                                                            className="menulists PrdOrder-Item d-flex"
                                                                            ordering={
                                                                                ordering
                                                                            }
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            ref={
                                                                                provided.innerRef
                                                                            }
                                                                        >
                                                                            <div className="prd-infobox">
                                                                                <p className="prd-tit">
                                                                                    {
                                                                                        productName
                                                                                    }
                                                                                </p>
                                                                                <p className="prd-desc">
                                                                                    {
                                                                                        productDescription
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                            <div className="prd-iconbox d-flex">
                                                                                <img
                                                                                    className="img-fluid"
                                                                                    alt=""
                                                                                    src="https://www.flexdaycdn.net/public/images/ticket/order_move_icon.png"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }}
                                                            </Draggable>
                                                        );
                                                    }
                                                )}
                                                {provided.placeholder}
                                            </div>
                                        );
                                    }}
                                </Droppable>
                            </DragDropContext>
                            <div className="PrdOrder-Btnbox">
                                <button
                                    type="button"
                                    className="btn btn-default"
                                    onClick={handleSequenceClick}
                                >
                                    등록
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && (
                <LoadingPortal>
                    <LoadingModal type="black" />
                </LoadingPortal>
            )}
        </>
    );
}
