import { useNavigate } from "react-router-dom";
import { apiClient } from "../api/apiClient";
import { useMutation } from "./common/useMutation";

export default function useEventAdd(setErrorText, setIsRemoveModalShowing) {
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(
    async (params) =>
      await apiClient({
        url: "/events",
        method: "post",
        data: params,
      }),
    {
      onSuccess: () => {
        navigate("/event", { replace: true });
      },
      onError: ({ response }) => {
        setErrorText(response.data.message);
        setIsRemoveModalShowing(true);
      },
    }
  );

  return { mutate, isLoading };
}
