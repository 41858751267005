import { useEffect, useReducer, useRef } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import LoadingModal from "../../components/loadingmodal/LoadingModal";
import useRemoveBodyStyle from "../../hooks/common/useRemoveBodyStyle";
import useImageUpload from "../../hooks/edit/useImageUpload";
import useStoreInfo from "../../hooks/edit/useStoreInfo";
import useStoreInfoModify from "../../hooks/edit/useStoreInfoModify";
import LoadingPortal from "../../portals/LoadingPortal";
import AdminHeader from "../UserMain/components/AdminHeader";
import "./StoreEdit.css";
import CloseTimeList from "../../components/storeedit/CloseTimeList";
import Notice from "../../components/storeedit/Notice";
import {
    initialState,
    storeEditReducer,
} from "../../reducers/storeEdit/storeEditReducer";
import { Button, Modal } from "react-bootstrap";
import uuid from "react-uuid";
import { getFormatDate } from "../../function/common";

function resize({ target }) {
    target.style.height = "1px";
    target.style.height = target.scrollHeight + "px";
}

export default function StoreEdit() {
    useRemoveBodyStyle();
    const navigate = useNavigate();
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        defaultValues: {
            businessHours: "",
            location: "",
            notice: "",
        },
    });
    const imgUploadRef = useRef();
    const [imgs, setImgs] = useState();
    const [fileImgs, setFileImgs] = useState();
    const [newImgs, setNewImgs] = useState([]);
    const [imageFormData, setImageFormData] = useState([]);
    const [isNotice, setIsNotice] = useState(false);
    const [nonOperationTimes, nonOperationTimeDispatch] = useReducer(
        storeEditReducer,
        initialState
    );
    const [isCloseTimeList, setIsCloseTimeList] = useState(false);
    const { data } = useStoreInfo(
        reset,
        setImgs,
        setFileImgs,
        setIsNotice,
        setIsCloseTimeList,
        nonOperationTimeDispatch
    );
    const { mutateImgUpload, isLoading } = useImageUpload();
    const { mutateStoreInfoModify } = useStoreInfoModify();
    const [isShowingModal, setIsShowingModal] = useState(false);
    const [isShowingCloseTimeModal, setIsShowingCloseTimeModal] =
        useState(false);
    const businessRef = useRef();

    useEffect(() => {
        if (businessRef.current !== undefined) {
            businessRef.current.style.height = `${businessRef.current.scrollHeight}px`;
        }
    }, [businessRef, data]);

    if (!fileImgs) {
        return;
    }

    const handleHideModal = () => {
        setIsShowingModal(false);
    };

    const handleHideCloseTimeModal = () => {
        setIsShowingCloseTimeModal(false);
    };

    const locationRegister = register("location", {
        required: {
            value: true,
            message: "위치 정보를 입력해 주세요.",
        },
    });

    const { ref: bussinessHoursRef, ...businessHoursRegister } = register(
        "businessHours",
        {
            required: {
                value: true,
                message: "운영 시간을 입력해 주세요.",
            },
        }
    );

    const { ref: noticeRef, ...noticeRegister } = register("notice", {
        required: {
            value: isNotice,
            message:
                "안내사항을 입력해주세요. 안내사항을 사용하지 않으실 경우, 사용중 버튼을 off 해주시기 바랍니다.",
        },
    });

    const checkOverlapedNonOperationTimes = () => {
        for (let i = 0; i < nonOperationTimes.length; i++) {
            for (let j = i + 1; j < nonOperationTimes.length; j++) {
                if (
                    new Date(nonOperationTimes[i].endDate) <
                        new Date(nonOperationTimes[j].startDate) ||
                    new Date(nonOperationTimes[j].endDate) <
                        new Date(nonOperationTimes[i].startDate)
                ) {
                    continue;
                }

                return false;
            }
        }

        return true;
    };

    const onSubmit = (data) => {
        const { businessHours, location, notice } = data;

        const mapedNonOperationTimes = nonOperationTimes.map(
            (operationTime) => {
                const { id, startDate, endDate } = operationTime;

                return { id, startDate, endDate };
            }
        );

        if (!checkOverlapedNonOperationTimes()) {
            setIsShowingCloseTimeModal(true);
            return;
        }

        registerImgs();

        if (!isCloseTimeList) {
            mutateStoreInfoModify({
                businessHours,
                location,
                closedPeriods: [],
                notice: isNotice ? notice : "",
                useNotice: isNotice,
            });
            return;
        }

        mutateStoreInfoModify({
            businessHours,
            location,
            closedPeriods: mapedNonOperationTimes,
            notice: isNotice ? notice : "",
            useNotice: isNotice,
        });
    };

    const registerImgs = () => {
        const mapedImageFormData = imageFormData.map((_, index) => ({
            priority: index + 1,
        }));

        const mapedImgs = imgs.map(({ id }, index) => ({
            id,
            priority: imageFormData.length + index + 1,
        }));

        const tempData = [...mapedImageFormData, ...mapedImgs];

        const blobRequests = new Blob([JSON.stringify(tempData)], {
            type: "application/json",
        });
        const formData = new FormData();

        imageFormData.forEach((file) => {
            formData.append("images", file);
        });
        fileImgs.forEach((file) => {
            formData.append("images", file);
        });
        formData.append("request", blobRequests);

        mutateImgUpload(formData);
    };

    const handleImageUploadChange = ({ target }) => {
        const fileList = [...target.files];

        if (!fileList.length) {
            return;
        }

        if (imgs.length + newImgs.length + 1 > 10) {
            setIsShowingModal(true);
            target.value = "";
            return;
        }

        const imageList = fileList.map((file) => {
            const url = URL.createObjectURL(file);
            const [type] = file.type.split("/");

            return {
                file,
                thumbnail: url,
                type,
            };
        });

        target.value = "";
        setImageFormData((prev) => [...fileList, ...prev]);
        setNewImgs((prev) => [...imageList, ...prev]);
    };

    const handleImageRemoveBtnClick = (index) => {
        const copyedImgs = [...imgs];
        const copyedFileImgs = [...fileImgs];

        copyedImgs.splice(index, 1);
        copyedFileImgs.splice(index, 1);

        setImgs([...copyedImgs]);
        setFileImgs([...copyedFileImgs]);
    };

    const handleNewImageRemoveBtnClick = (index) => {
        const copyedImgs = [...newImgs];
        const copyedFormData = [...imageFormData];

        copyedImgs.splice(index, 1);
        copyedFormData.splice(index, 1);

        setImageFormData([...copyedFormData]);
        setNewImgs([...copyedImgs]);
    };

    if (!data || !imgs) {
        return;
    }

    const mapedImgFiles = imgs.map(({ url }, index) => (
        <div className="col-4" key={url}>
            <div className="PhotoImgbox">
                <img className="img-fluid" alt="" src={url} />
                <button
                    className="deleteBtn btn-clear"
                    type="button"
                    onClick={() => handleImageRemoveBtnClick(index)}
                >
                    <img
                        className="img-fluid"
                        alt="메뉴"
                        src="https://www.flexdaycdn.net/public/images/ticket/delete_icon.png"
                    />
                </button>
            </div>
        </div>
    ));

    const mapedNewImgs = newImgs.map(({ thumbnail }, index) => {
        return (
            <div className="col-4" key={thumbnail}>
                <div className="PhotoImgbox">
                    <img className="img-fluid" alt="" src={thumbnail} />
                    <button
                        className="deleteBtn btn-clear"
                        type="button"
                        onClick={() => handleNewImageRemoveBtnClick(index)}
                    >
                        <img
                            className="img-fluid"
                            alt="메뉴"
                            src="https://www.flexdaycdn.net/public/images/ticket/delete_icon.png"
                        />
                    </button>
                </div>
            </div>
        );
    });

    return (
        <>
            <div className="demo">
                <div className="TBasicInfoPage">
                    <div className="container-fluid g-0">
                        <nav className="sub-navbar">
                            <p className="navbar-title">기본 정보 관리</p>
                            <button
                                type="button"
                                className="btn-clear btn-back"
                                onClick={() => navigate("/storemain")}
                            >
                                <img
                                    className="img-fluid"
                                    alt="뒤로가기"
                                    src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                                />
                            </button>
                            <button
                                className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasNavbar"
                                aria-controls="offcanvasNavbar"
                            >
                                <img
                                    className="img-fluid"
                                    alt="메뉴"
                                    src="https://www.flexdaycdn.net/public/images/ticket/menu3.png"
                                />
                            </button>
                            <AdminHeader />
                        </nav>

                        <div className="BasicInfoBox py-4-5 px-4-5">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="BasicInfo-Form">
                                    <div className="BasicInfo-Formitem pb-5">
                                        <p className="BasicInfo-tit">
                                            1. 대표 이미지
                                        </p>
                                        <div className="BasicInfo-inputbox">
                                            <div className="PhotoList">
                                                <div className="row">
                                                    <div
                                                        className="File-Box col-4"
                                                        onClick={() =>
                                                            imgUploadRef.current.click()
                                                        }
                                                    >
                                                        <input
                                                            type="file"
                                                            ref={imgUploadRef}
                                                            multiple
                                                            style={{
                                                                display: "none",
                                                            }}
                                                            onChange={
                                                                handleImageUploadChange
                                                            }
                                                        />
                                                        <div className="text-center">
                                                            <img
                                                                className="img-fluid"
                                                                alt=""
                                                                src="https://www.flexdaycdn.net/public/images/ticket/plus2.png"
                                                            />
                                                        </div>
                                                    </div>
                                                    {mapedNewImgs}
                                                    {mapedImgFiles}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="BasicInfo-Formitem pb-5">
                                        <p className="BasicInfo-tit">
                                            2. 운영시간
                                        </p>
                                        <div className="BasicInfo-inputbox">
                                            <textarea
                                                className={`form-control InputType ${
                                                    errors.businessHours &&
                                                    "active"
                                                }`}
                                                placeholder="예) 11:00~18:30 (Break time 15:00~17:00)"
                                                onKeyDown={resize}
                                                onKeyUp={resize}
                                                {...businessHoursRegister}
                                                ref={(e) => {
                                                    bussinessHoursRef(e);
                                                    businessRef.current = e;
                                                }}
                                            />

                                            {errors.businessHours && (
                                                <div className="form-text info-txt">
                                                    {
                                                        errors.businessHours
                                                            .message
                                                    }
                                                </div>
                                            )}
                                            <div className="checkbox">
                                                <input
                                                    className="form-check-input"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    type="checkbox"
                                                    id="non_open"
                                                    checked={isCloseTimeList}
                                                    onChange={() => {
                                                        setIsCloseTimeList(
                                                            (prev) => !prev
                                                        );
                                                        nonOperationTimeDispatch(
                                                            {
                                                                type: "inited_nonOperationTime",
                                                                payload: [
                                                                    {
                                                                        tempId: uuid(),
                                                                        startDate:
                                                                            getFormatDate(),
                                                                        endDate:
                                                                            getFormatDate(),
                                                                    },
                                                                ],
                                                            }
                                                        );
                                                    }}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    htmlFor="non_open"
                                                >
                                                    미운영 기간이 있습니다.
                                                </label>
                                                {isCloseTimeList && (
                                                    <CloseTimeList
                                                        nonOperationTimes={
                                                            nonOperationTimes
                                                        }
                                                        nonOperationTimeDispatch={
                                                            nonOperationTimeDispatch
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="BasicInfo-Formitem pb-5">
                                        <p className="BasicInfo-tit">3. 위치</p>
                                        <div className="BasicInfo-inputbox">
                                            <input
                                                type="text"
                                                className={`form-control InputType ${
                                                    errors.location && "active"
                                                }`}
                                                placeholder="예) 늘빛관 1층"
                                                {...locationRegister}
                                            />
                                            {errors.location && (
                                                <div className="form-text info-txt">
                                                    {errors.location.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <Notice
                                    noticeRegister={noticeRegister}
                                    errors={errors}
                                    isNotice={isNotice}
                                    setIsNotice={setIsNotice}
                                    ref={noticeRef}
                                />
                                <div className="BasicInfo-Btnbox">
                                    <button
                                        type="submit"
                                        className="btn btn-default"
                                    >
                                        등록
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && (
                <LoadingPortal>
                    <LoadingModal type="black" />
                </LoadingPortal>
            )}
            <Modal
                show={isShowingModal}
                onHide={handleHideModal}
                keyboard={false}
                size="sm"
                centered
                className="demoModal modal-prdAdd text-center"
            >
                <Modal.Header>
                    <Modal.Title>
                        이미지 개수 초과
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        대표 이미지는 최대 10장까지
                        <br />
                        등록이 가능합니다.
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="modal-primary"
                        className="btn-clear w-100"
                        onClick={handleHideModal}
                    >
                        확인
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={isShowingCloseTimeModal}
                onHide={handleHideCloseTimeModal}
                keyboard={false}
                size="sm"
                centered
                className="demoModal modal-prdAdd text-center"
            >
                <Modal.Header>
                    <Modal.Title>
                        미운영 기간 중복
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        중복되는 미운영 기간이 있습니다.
                        <br />
                        확인해주세요
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="modal-primary"
                        className="btn-clear w-100"
                        onClick={handleHideCloseTimeModal}
                    >
                        확인
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
