import { Fragment, useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  getFormatDate,
  getKoreanDayByDayNumber,
  getSessionValue,
  getTodayYearAndMonth,
} from "../../../function/common";
import useRemoveBodyStyle from "../../../hooks/common/useRemoveBodyStyle";
import useDetailSalesInfo from "../../../hooks/paymentinfo/useDetailSalesInfo";
import useSaleExcelDownload from "../../../hooks/paymentinfo/useSaleExcelDownload";
import AdminHeader from "../../../pages/UserMain/components/AdminHeader";
import LoadingPortal from "../../../portals/LoadingPortal";
import PaymentDay from "../../common/PaymentDay";
import PaymentTimes from "../../common/PaymentTimes";
import YearDatepicker from "../../common/YearDatepicker";
import LoadingModal from "../../loadingmodal/LoadingModal";
import AccountingGNB from "../../../pages/accounting/header/AccountingGNB";
const STATUS_LIST = {
  TODAY: "TODAY",
  DAY: "BY_DAY",
  MONTH: "BY_MONTH",
};

function ToggleSelected() {
  const Target = document.querySelector(".yearTarget");
  const List = document.querySelector(".SelectedList");
  Target.classList.toggle("active");
  List.classList.toggle("active");
}

window.addEventListener("load", () => {
  SelectedYear();
});

function SelectedYear() {
  const input = document.querySelector(".yearTarget");

  const checkInputSelector = document.querySelector(
    "input[name=yearItem]:checked + label"
  );

  if (!checkInputSelector) {
    return;
  }

  const CheckInput = checkInputSelector.innerHTML.trim();

  input.value = CheckInput;
}

export default function SalesInfo() {
  useRemoveBodyStyle();
  const navigate = useNavigate();
  const [showingStatus, setShowingStatus] = useState(
    getSessionValue("saleStatus") || STATUS_LIST.TODAY
  );
  const [status, setStatus] = useState(
    getSessionValue("saleStatus") || STATUS_LIST.TODAY
  );
  const modalRef = useRef();
  const [todayInfoDate, setTodayInfoDate] = useState({
    totalDate: getFormatDate(),
    day: new Date().getDay(),
  });
  const [showingMonth, setShowingMonth] = useState(
    getSessionValue("saleMonth") || getTodayYearAndMonth()
  );
  const [paymentMonth, setPaymentMonth] = useState(
    getSessionValue("saleMonth") || getTodayYearAndMonth()
  );
  const [showingDate, setShowingDate] = useState({
    startDate: getSessionValue("saleStartDate") || getFormatDate(),
    endDate: getSessionValue("saleEndDate") || getFormatDate(),
  });
  const [paymentDate, setPaymentDate] = useState({
    startDate: getSessionValue("saleStartDate") || getFormatDate(),
    endDate: getSessionValue("saleEndDate") || getFormatDate(),
  });
  const [showingTime, setShowingTime] = useState({
    start: getSessionValue("saleStartTime") || "00:00",
    end: getSessionValue("saleEndTime") || "23:00",
  });
  const [paymentTime, setPaymentTime] = useState({
    startTime: getSessionValue("saleStartTime") || "00:00",
    endTime: getSessionValue("saleEndTime") || "23:00",
  });
  const tableRef = useRef();

  const { salesInfos, refetch, isLoading } = useDetailSalesInfo(
    status,
    paymentDate,
    paymentTime,
    paymentMonth,
    setShowingTime,
    setShowingDate,
    setShowingMonth,
    setShowingStatus,
    modalRef
  );
  const { excelMutate, isExcelLoading } = useSaleExcelDownload({
    status: showingStatus,
    paymentDate: showingDate,
    paymentTime: showingTime,
    paymentMonth: showingMonth,
  });

  useEffect(() => {
    return () => {
      sessionStorage.clear();
    };
  }, []);

  if (!salesInfos) {
    return (
      <LoadingPortal>
        <LoadingModal />
      </LoadingPortal>
    );
  }

  const handleSearchBtnClick = () => {
    if (isLoading) {
      return;
    }

    refetch();
  };

  const getDateByMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  const getTitleByStatus = () => {
    if (showingStatus === STATUS_LIST.TODAY) {
      return `${todayInfoDate.totalDate} ${getKoreanDayByDayNumber(
        todayInfoDate.day
      )}요일 (${showingTime.start}~${showingTime.end})`;
    } else if (showingStatus === STATUS_LIST.DAY) {
      return `${showingDate.startDate} ${getKoreanDayByDayNumber(
        new Date(showingDate.startDate).getDay()
      )}요일 ~ ${showingDate.endDate} ${getKoreanDayByDayNumber(
        new Date(showingDate.endDate).getDay()
      )}요일`;
    }

    const [year, month] = showingMonth.split("-");
    const date = getDateByMonth(year, month);

    const startDay = getKoreanDayByDayNumber(
      new Date(`${year}-${month}-01`).getDay()
    );
    const endDay = getKoreanDayByDayNumber(
      new Date(`${year}-${month}-${date}`).getDay()
    );

    return `${year}-${month}-01 (${startDay}) ~ ${year}-${month}-${date} (${endDay})`;
  };
  const handleExcelDownloadClick = () => {
    if (showingStatus === STATUS_LIST.MONTH) {
      excelMutate({
        status: showingStatus,
        paymentDate: {
          startDate: `${showingMonth}-01`,
          endDate: `${showingMonth}-01`,
        },
        paymentTime: showingTime,
      });
      return;
    }

    excelMutate({
      status: showingStatus,
      paymentDate: showingDate,
      paymentTime: showingTime,
    });
  };

  // let monthOptions = [];
  // let yearItemIndex = 0;

  // for (let i = 22; i <= new Date().getFullYear() - 2000; i++) {
  //   for (let j = 1; j < 13; j++) {
  //     yearItemIndex++;

  //     // if (i === 23 && j <= 2) {
  //     //   continue;
  //     // }

  //     const transformStringDate = ("00" + j).slice(-2);

  //     monthOptions.push(
  //       <div className="radioBox" key={`${i}${j}`}>
  //         <input
  //           className="form-check-input yearItem"
  //           onClick={({ target }) => {
  //             SelectedYear();
  //             setPaymentMonth(target.value);
  //           }}
  //           id={`yearItem-${yearItemIndex}`}
  //           type="radio"
  //           name="yearItem"
  //           value={`20${i}-${transformStringDate}`}
  //         />
  //         <label className="RadioLabel" htmlFor={`yearItem-${yearItemIndex}`}>
  //           20{i}-{j}
  //         </label>
  //       </div>
  //     );
  //   }
  // }

  const mapedSaleInfos = salesInfos.sales.map((saleInfo, index) => {
    const { amount, menuName, count } = salesInfos.sum;
    const {
      amount: productAmount,
      menuName: productName,
      count: productCount,
    } = saleInfo;

    if (!index) {
      return (
        <Fragment key={index}>
          <tr className="total">
            <td data-txt="메뉴">{menuName}</td>
            <td data-txt="사용 현황">{count}</td>
            <td data-txt="결제 금액">{amount.toLocaleString()}원</td>
          </tr>
          <tr>
            <td data-txt="메뉴">{productName}</td>
            <td data-txt="사용 현황">{productCount}</td>
            <td data-txt="결제 금액">{productAmount.toLocaleString()}원</td>
          </tr>
        </Fragment>
      );
    }

    return (
      <tr key={index}>
        <td data-txt="메뉴">{productName}</td>
        <td data-txt="사용 현황">{productCount}</td>
        <td data-txt="결제 금액">{productAmount.toLocaleString()}원</td>
      </tr>
    );
  });

  return (
    <>
      <AccountingGNB />
      <div className="demo mw-100">
        <div className="TPrdAdminPage">
          <div className="container-fluid g-0">
            {/* <nav className="sub-navbar">
              <p className="navbar-title">결제 / 매출 / 정산</p>
              <button
                type="button"
                className="btn-clear btn-back"
                onClick={() => {
                  sessionStorage.clear();
                  navigate("/storemain");
                }}
              >
                <img
                  className="img-fluid"
                  alt="뒤로가기"
                  src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                />
              </button>
              <button
                className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
              >
                <img
                  className="img-fluid"
                  alt="메뉴"
                  src="https://www.flexdaycdn.net/public/images/ticket/menu3.png"
                />
              </button>
              <AdminHeader />
            </nav> */}

            <div className="PayCateTab">
              {/* <div
                className="nav nav-pills justify-content-around mb-4-5 px-4-5"
                id="pills-tab"
                role="tablist"
              >
                <div className="nav-item" role="presentation">
                  <Link to="/paymentInfo">
                    <button
                      className="nav-link PayCateBtn"
                      id="pills-content1-tab"
                      type="button"
                      role="tab"
                    >
                      결제/사용 현황
                    </button>
                  </Link>
                </div>
                <div className="nav-item" role="presentation">
                  <Link to="/salesInfo">
                    <button
                      className="nav-link PayCateBtn active"
                      id="pills-content2-tab"
                      type="button"
                      role="tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-content2"
                      aria-selected="true"
                    >
                      매출 내역
                    </button>
                  </Link>
                </div>
                <div className="nav-item" role="presentation">
                  <Link to="/settlementInfo">
                    <button
                      className="nav-link PayCateBtn"
                      id="pills-content3-tab"
                      type="button"
                      role="tab"
                    >
                      정산 내역
                    </button>
                  </Link>
                </div>
              </div> */}
              <div className="SalesList">
                <div className="PrdAdmin-Top pb-4 px-4-5 d-flex">
                  <p className="PageTit mb-0">{getTitleByStatus(status)}</p>
                  <button
                    type="button"
                    className="btn btn-light w-auto h-auto btn-sort"
                    data-bs-toggle="modal"
                    data-bs-target="#Modal-05"
                  >
                    <img
                      className="img-fluid"
                      alt="메뉴"
                      src="https://www.flexdaycdn.net/public/images/ticket/sort_icon.png"
                    />
                    설정
                  </button>
                </div>
                <div className="PrdAdmin-Bottom pb-5 px-4-5">
                  <div className="PrdTbl-excelbox pc">
                    <button
                      type="button"
                      className="btn-clear PrdTbl-down"
                      onClick={handleExcelDownloadClick}
                      style={{ width: "auto" }}
                    >
                      <span>엑셀 다운로드</span>
                      <img
                        className="img-fluid"
                        alt=""
                        src="https://www.flexdaycdn.net/public/images/ticket/down_icon.png"
                      />
                    </button>
                  </div>
                  <div className="PrdTbl-excelbox tablet">
                    PC에서 엑셀 파일 다운로드가 가능합니다.
                  </div>
                  <div className="PrdTbl-wrap">
                    <table className="PrdTbl table" ref={tableRef}>
                      <thead>
                        <tr>
                          <th>메뉴</th>
                          <th>사용 현황</th>
                          <th>결제 금액</th>
                        </tr>
                      </thead>
                      <tbody>{mapedSaleInfos}</tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="modal fade" id="Modal-05" tabIndex="-1">
                <div className="modal-dialog modal-fullscreen-lg-down modal-dialog-centered modal-sorting">
                  <div className="modal-content border-0">
                    <div className="modal-header p-0">
                      <nav className="sub-navbar w-100">
                        <p className="navbar-title">설정</p>
                        <button
                          type="button"
                          className="btn-clear btn-close"
                          data-bs-dismiss="modal"
                          ref={modalRef}
                        >
                          <img
                            className="img-fluid"
                            alt="창닫기"
                            src="https://www.flexdaycdn.net/public/images/ticket/close.png"
                          />
                        </button>
                      </nav>
                    </div>
                    <div className="modal-body p-4-5">
                      <p className="sroting-tit">조회 기간</p>

                      <div className="nav sorting-pills" role="tablist">
                        <div className="nav-item" role="presentation">
                          <button
                            className="nav-link btn-clear active"
                            id="sorting-content1-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#sorting-content1"
                            type="button"
                            role="tab"
                            onClick={() => setStatus(STATUS_LIST.TODAY)}
                          >
                            오늘
                          </button>
                        </div>
                        <div className="nav-item" role="presentation">
                          <button
                            className="nav-link btn-clear"
                            id="sorting-content2-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#sorting-content2"
                            type="button"
                            role="tab"
                            onClick={() => setStatus(STATUS_LIST.DAY)}
                          >
                            일자별
                          </button>
                        </div>
                        <div className="nav-item" role="presentation">
                          <button
                            className="nav-link btn-clear"
                            id="sorting-content3-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#sorting-content3"
                            type="button"
                            role="tab"
                            onClick={() => setStatus(STATUS_LIST.MONTH)}
                          >
                            월별
                          </button>
                        </div>
                      </div>

                      <div className="tab-content" id="sorting-tabContent">
                        <div
                          className="tab-pane show active"
                          id="sorting-content1"
                          role="tabpanel"
                        >
                          <div className="sorting-box">
                            <p className="sroting-tit">시간대 설정</p>
                            <PaymentTimes
                              eventDate={paymentDate}
                              setEventDate={setPaymentDate}
                              eventTime={paymentTime}
                              setEventTime={setPaymentTime}
                            />
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="sorting-content2"
                          role="tabpanel"
                        >
                          <div className="sorting-box">
                            <p className="sroting-tit">일자 설정</p>
                            <PaymentDay
                              eventDate={paymentDate}
                              setEventDate={setPaymentDate}
                            />
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="sorting-content3"
                          role="tabpanel"
                        >
                          <div className="sorting-box">
                            {/* <div
                              className="inputbox2"
                              onClick={() => ToggleSelected()}
                            >
                              <input
                                type="text"
                                className="form-control InputType yearTarget active"
                                defaultValue={`${new Date().getFullYear()}-${
                                  new Date().getMonth() + 1
                                }`}
                                readOnly
                              />
                              <img
                                className="img-fluid icon"
                                alt=""
                                src="https://www.flexdaycdn.net/public/images/ticket/arrow_down_b.png"
                              />
                            </div> */}
                            <YearDatepicker
                              paymentMonth={paymentMonth}
                              setPaymentMonth={setPaymentMonth}
                            />
                            {/* <div className="inputbox2-list SelectedList active">
                              <div className="scroll-wrap">
                                {monthOptions.reverse()}
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>

                      <div className="sorting-confirm PaymentDetailInfo pt-4-5">
                        <button
                          type="button"
                          className="btn btn-default"
                          data-bs-toggle="modal"
                          data-bs-target="#Modal-05"
                          onClick={handleSearchBtnClick}
                        >
                          {/* {isRefetching ? (
                            <div>
                              <Spinner animation="border" />
                            </div>
                          ) : (
                            "조회"
                          )} */}
                          조회
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isExcelLoading && (
        <LoadingPortal>
          <LoadingModal type="black" />
        </LoadingPortal>
      )}
    </>
  );
}
