import uuid from "uuid";
import { getFormatDate } from "../../function/common";

export const initialState = [
    {
        id: uuid(),
        startDate: getFormatDate(),
        endDate: getFormatDate(),
    },
];

export const storeEditReducer = (state, action) => {
    switch (action.type) {
        case "inited_nonOperationTime":
            return action.payload;
        case "added_nonOperationTime":
            return [
                ...state,
                {
                    tempId: uuid(),
                    startDate: getFormatDate(),
                    endDate: getFormatDate(),
                },
            ];
        case "removed_nonOperationTime":
            const filteredState = state.filter(
                (nonOperationTime) => nonOperationTime.tempId !== action.payload
            );
            return filteredState;
        case "modified_nonOperationTime":
            return state.map((nonOperationTime) => {
                if (nonOperationTime.tempId === action.payload.id) {
                    if (nonOperationTime.id) {
                        return {
                            ...nonOperationTime,
                            id: null,
                            startDate: action.payload.startDate,
                            endDate: action.payload.endDate,
                        };
                    }

                    return {
                        ...nonOperationTime,
                        startDate: action.payload.startDate,
                        endDate: action.payload.endDate,
                    };
                }

                return nonOperationTime;
            });
        case "modified_startNonOperationTime":
            return state.map((nonOperationTime) => {
                if (nonOperationTime.tempId === action.payload.id) {
                    if (nonOperationTime.id) {
                        return {
                            ...nonOperationTime,
                            id: null,
                            startDate: action.payload.startDate,
                        };
                    }

                    return {
                        ...nonOperationTime,
                        startDate: action.payload.startDate,
                    };
                }

                return nonOperationTime;
            });
        case "modified_endNonOperationTime":
            return state.map((nonOperationTime) => {
                if (nonOperationTime.tempId === action.payload.id) {
                    if (nonOperationTime.id) {
                        return {
                            ...nonOperationTime,
                            id: null,
                            endDate: action.payload.endDate,
                        };
                    }

                    return {
                        ...nonOperationTime,
                        endDate: action.payload.endDate,
                    };
                }

                return nonOperationTime;
            });
        default:
            return Error(`Unknow action type: ${action.type}`);
    }
};
