import { forwardRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import useProductSale from "../../../hooks/main/useProductSale";
import useProducts from "../../../hooks/main/useProducts";
import {useAppSelector} from "../../../store";
import {isDefaultStore} from "../../../function/common";

function SaleProductList(props, ref) {
    const { saleProducts } = useProducts();
    const [productId, setProductId] = useState();
    const [isStopModal, setIsStopModal] = useState({
        title: "",
        text: "",
        showing: false,
    });
    const { mutate, isLoading } = useProductSale({ setIsStopModal, ref });
    const storeType = useAppSelector((state) => {return state.storeReducer.storeType});

    const handleStopModalShow = (productId) => {
        setProductId(productId);
        setIsStopModal({
            title: "판매 중지",
            text: "해당 메뉴를 중지하시겠습니까?",
            showing: true,
        });
    };

    const handleStopModalClose = () => {
        setIsStopModal({
            title: "",
            text: "",
            showing: false,
        });
    };

    const handleProductStopClick = () => {
        if (isLoading) {
            return;
        }

        mutate(productId);
    };

    const getTimeHHMM = (time) => {
        const [hour, minute, _] = time.split(":");

        return `${hour}:${minute}`;
    };

    const mapedProducts = saleProducts?.map((product) => {
        const {
            productId,
            productName,
            productDescription,
            toDayDescription,
            descriptionType,
            price,
            additionalDescription,
            quantityLimit: {
                daySellLimit,
                useQuantityLimit,
                useTimeLimit,
                countOfMaxSold,
                countOfSold,
                startTimeOfQuantityLimit,
                endTimeOfQuantityLimit,
                canSmartOrder,
            },
            kitchenAlive,
        } = product;

        return (
            <div className="prd-item" key={productId}>
                <p className="prd-tit">{productName}</p>
                <p className="prd-sub" style={{ whiteSpace: "pre-line" }}>
                    {descriptionType.code === 1
                        ? productDescription
                        : toDayDescription}
                </p>
                {additionalDescription && (
                    <div className="prd-time">
                        <p
                            className="operating"
                            style={{ whiteSpace: "pre-line" }}
                        >
                            {additionalDescription}
                        </p>
                    </div>
                )}

                {
                    isDefaultStore(storeType)===true?
                        <p className="prd-price">
                            <span className="price">{price.toLocaleString()}</span>원
                        </p>
                        :
                        <div className="prd-bottom">
                            <p className="prd-price">
                                <span className="price">{price.toLocaleString()}</span>원
                            </p>
                            {(canSmartOrder === true && kitchenAlive === true) ?
                                <div className="prd-order on">
                                    <div className="dot"></div>
                                    주문가능
                                </div>
                                :
                                <div className="prd-order off">
                                    <div className="dot"></div>
                                    주문불가
                                </div>
                            }
                        </div>
                    }

                {useQuantityLimit && daySellLimit && (
                    <div className="quantity-txt">
                        <img
                            className="img-fluid icon"
                            alt=""
                            src="https://www.flexdaycdn.net/public/images/ticket/cart-icon.png"
                        />
                        {countOfMaxSold - countOfSold}개 남음
                    </div>
                )}
                <div className="prd-btnbox d-flex">
                    <button
                        type="button"
                        className="btn type13 btn-default"
                        onClick={() => handleStopModalShow(productId)}
                    >
                        판매 중지하기
                    </button>
                </div>
            </div>
        );
    });

    return (
        <>
            <div className="ProductList">{mapedProducts}</div>
            {/* 상품 중지 확인 모달 */}
            <Modal
                show={isStopModal.showing}
                onHide={handleStopModalClose}
                keyboard={false}
                size="sm"
                centered
                className="demoModal modal-prdAdd text-center"
            >
                <Modal.Header>
                    <Modal.Title>{isStopModal.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   {isStopModal.text}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="modal-secondary"
                        className="btn-clear"
                        onClick={handleStopModalClose}
                    >
                        취소
                    </Button>
                    <Button
                        variant="modal-primary"
                        className="btn-clear"
                        onClick={handleProductStopClick}
                    >
                        확인
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default forwardRef(SaleProductList);
