import { getFormatDate } from "../../function/common";
import { useFetch } from "../common/useFetch";
import { apiClient } from "../../api/apiClient";

const getAPIUrl = (status, paymentDate, paymentTime, paymentMonth) => {
  if (status === "TODAY") {
    return `/sales?startTime=${getFormatDate(new Date())} ${
      paymentTime.startTime
    }&endTime=${getFormatDate(new Date())} ${
      paymentTime.endTime
    }&dateRangeType=${status}`;
  } else if (status === "BY_MONTH") {
    return `/sales?startTime=${paymentMonth}-01 00:00&endTime=${paymentMonth}-01 00:00&dateRangeType=${status}`;
  }

  return `/sales?startTime=${paymentDate.startDate} ${paymentTime.startTime}&endTime=${paymentDate.endDate} ${paymentTime.endTime}&dateRangeType=${status}`;
};

export default function useDetailSalesInfo(
  status,
  paymentDate,
  paymentTime,
  paymentMonth,
  setShowingTime,
  setShowingDate,
  setShowingMonth,
  setShowingStatus,
  modalRef
) {
  const {
    data: salesInfos,
    refetch,
    isLoading,
  } = useFetch(
    [],
    async () => {
      const { data } = await apiClient({
        url: getAPIUrl(status, paymentDate, paymentTime, paymentMonth),
      });

      return data;
    },
    {
      onSuccess: () => {
        if (!modalRef.current) {
          return;
        }

        modalRef.current.click();

        if (status === "TODAY") {
          setShowingDate({
            startDate: getFormatDate(),
            endDate: getFormatDate(),
          });
          setShowingTime({
            start: paymentTime.startTime,
            end: paymentTime.endTime,
          });
          sessionStorage.setItem("saleStatus", "TODAY");
          sessionStorage.setItem("saleStartTime", paymentTime.startTime);
          sessionStorage.setItem("saleEndTime", paymentTime.endTime);
          setShowingStatus("TODAY");
          return;
        } else if (status === "BY_DAY") {
          setShowingDate({
            startDate: paymentDate.startDate,
            endDate: paymentDate.endDate,
          });
          sessionStorage.setItem("saleStatus", "BY_DAY");
          sessionStorage.setItem("saleStartDate", paymentDate.startDate);
          sessionStorage.setItem("saleEndDate", paymentDate.endDate);
          setShowingStatus("BY_DAY");
          return;
        }

        sessionStorage.setItem("saleStatus", "BY_MONTH");
        sessionStorage.setItem("saleMonth", paymentMonth);
        setShowingMonth(paymentMonth);
        setShowingStatus("BY_MONTH");
      },
      onError: ({ response }) => {
        alert(response.data.message);
      },
    }
  );

  return { salesInfos, refetch, isLoading };
}
