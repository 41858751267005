import { getFormatDate } from "../../function/common";
import { apiClient } from "../../api/apiClient";
import { useFetch } from "../common/useFetch";

export default function useSurvey(showingDate) {
  const { startDate, endDate } = showingDate;
  const formatedStartDate = getFormatDate(startDate);
  const formatedEndDate = getFormatDate(endDate);

  const { data, refetch, isLoading } = useFetch(
    [startDate, endDate],
    async () => {
      const { data } = await apiClient({
        url: `/survey?startDate=${formatedStartDate}&endDate=${formatedEndDate}`,
        method: "get",
      });

      return data;
    },
    {
      onSuccess: () => {
        sessionStorage.setItem("surveyStartDate", startDate);
        sessionStorage.setItem("surveyEndDate", endDate);
      },
      onError: ({ response }) => {
        alert(response.data.message);
      },
    }
  );

  return { data, refetch, isLoading };
}
