import { apiClient } from "../../api/apiClient";
import { useFetch } from "../common/useFetch";

export default function useSlide() {
    const { data: slides } = useFetch(
        ["slide"],
        async () => {
            const { data } = await apiClient({
                url: "/store/me",
                method: "get",
            });
            return data;
        },
        {
            onError: ({ response }) => {
                alert(response.data.message);
            },
            select: (data) => ({
                files: data.files,
                periods: data.storeClosedPeriods,
            }),
        }
    );

    return { slides };
}
