import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import { createStore } from 'redux';
// import rootReducer from './modules';
// import { Provider } from 'react-redux';
// import { composeWithDevTools } from 'redux-devtools-extension';

// const store = createStore (rootReducer, composeWithDevTools()); // 스토어를 만듭니다.
// console.log(store.getState());

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
