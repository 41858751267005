import { useMutation } from "../common/useMutation";
import { apiClient } from "../../api/apiClient";

export default function useProductStop({ setIsSaleModal, ref }) {
  const { mutate, isLoading } = useMutation(
    async (productId) =>
      await apiClient({
        url: `/products/${productId}/start`,
        method: "post",
      }),
    {
      onSuccess: () => {
        setIsSaleModal({
          text: "",
          showing: false,
        });
        ref.current.click();
      },
      onError: ({ response }) => {
        alert(response.data.message);
      },
    }
  );

  return { mutate, isLoading };
}
