import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PaymentDetailInfo from "../../../components/paymentinfo/paymentdetailinfo/PaymentDetailInfo";
import useRemoveBodyStyle from "../../../hooks/common/useRemoveBodyStyle";
import AdminHeader from "../../UserMain/components/AdminHeader";
import { isDefaultStore } from "../../../function/common";
import "../Accounting.css";
import { useAppSelector } from "../../../store";
import { ACCOUNTING_CONTENTS } from "../../../components/constants/CommonConstants";
import SalesInfo from "../../../components/paymentinfo/salesinfo/SalesInfo";
import CalculateInfo from "../../../components/paymentinfo/calculateinfo/CalculateInfo";
import PaymentOrderDetailInfo from "../../../components/paymentinfo/paymentorderdetailinfo/PaymentOrderDetailInfo";
import TaxBillInfo from "../../../components/paymentinfo/taxbillinfo/TaxBillInfo";

const AccountingGNB = (props) => {
  const navigate = useNavigate();
  useRemoveBodyStyle();

  const storeType = useAppSelector((state) => {
    return state.storeReducer.storeType;
  });

  const isDefault = isDefaultStore();

  useEffect(() => {
    // do stuff
  }, [storeType]);
  const [divClassName, setDivClassName] = useState(
    isDefault ? "nav-item" : "nav-item2"
  );
  useEffect(() => {
    // do stuff
  }, [divClassName]);

  const pathName = window.location.pathname;

  const contentsClick = (contents) => {
    switch (contents) {
      case ACCOUNTING_CONTENTS.PAYMENT_ORDER_INFO:
      case ACCOUNTING_CONTENTS.PAYMENTINFO:
      case ACCOUNTING_CONTENTS.SALESINFO:
        navigate(contents);
        // props.contents(<SalesInfo/>);
        break;
      case ACCOUNTING_CONTENTS.SETTLEMENTINFO:
        navigate(contents);
        // props.contents(<CalculateInfo />);
        break;
      case ACCOUNTING_CONTENTS.TAXBILL:
        navigate(contents);
        // props.contents(<TaxBillInfo />);
        break;
      default:
        break;
    }
  };
  return (
    <div className="demo mw-100">
      <div className="TPrdAdminPage">
        <div className="container-fluid g-0">
          <nav className="sub-navbar">
            <p className="navbar-title">결제 / 매출 / 정산</p>
            <button
              type="button"
              className="btn-clear btn-back"
              onClick={() => {
                sessionStorage.clear();
                navigate("/storemain");
              }}
            >
              <img
                className="img-fluid"
                alt="뒤로가기"
                src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
              />
            </button>
            <button
              className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              <img
                className="img-fluid"
                alt="메뉴"
                src="https://www.flexdaycdn.net/public/images/ticket/menu3.png"
              />
            </button>
            <AdminHeader />
          </nav>

          <div className="PayCateTab">
            <div
              className="nav nav-pills justify-content-around mb-4-5 px-4-5"
              id="pills-tab"
              role="tablist"
            >
              <div className={divClassName} role="presentation">
                <button
                  className={
                    pathName === ACCOUNTING_CONTENTS.PAYMENTINFO ||
                    pathName === ACCOUNTING_CONTENTS.PAYMENT_ORDER_INFO
                      ? "nav-link active PayCateBtn"
                      : "nav-link PayCateBtn "
                  }
                  id="pills-content1-tab"
                  type="button"
                  role="tab"
                  onClick={() => {
                    if (isDefault) {
                      contentsClick(ACCOUNTING_CONTENTS.PAYMENTINFO);
                    } else {
                      contentsClick(ACCOUNTING_CONTENTS.PAYMENT_ORDER_INFO);
                    }
                  }}
                >
                  {isDefault ? "결제/사용 현황" : "결제/주문 현황"}
                </button>
              </div>

              <div className={divClassName} role="presentation">
                <button
                  className={
                    pathName === ACCOUNTING_CONTENTS.SALESINFO
                      ? "nav-link active PayCateBtn"
                      : "nav-link PayCateBtn "
                  }
                  id="pills-content2-tab"
                  type="button"
                  role="tab"
                  onClick={() => {
                    contentsClick(ACCOUNTING_CONTENTS.SALESINFO);
                  }}
                >
                  매출 내역
                </button>
              </div>
              <div className={divClassName} role="presentation">
                <button
                  className={
                    pathName === ACCOUNTING_CONTENTS.SETTLEMENTINFO
                      ? "nav-link active PayCateBtn"
                      : "nav-link PayCateBtn "
                  }
                  id="pills-content3-tab"
                  type="button"
                  role="tab"
                  onClick={() => {
                    contentsClick(ACCOUNTING_CONTENTS.SETTLEMENTINFO);
                  }}
                >
                  정산 내역
                </button>
              </div>

              <div className={divClassName} role="presentation">
                <button
                  className={
                    pathName === ACCOUNTING_CONTENTS.TAXBILL
                      ? "nav-link active PayCateBtn"
                      : "nav-link PayCateBtn "
                  }
                  id="pills-content4-tab"
                  type="button"
                  role="tab"
                  onClick={() => {
                    contentsClick(ACCOUNTING_CONTENTS.TAXBILL);
                  }}
                >
                  세금계산서 조회
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountingGNB;
