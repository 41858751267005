import { forwardRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import useProductRemove from "../../../hooks/main/useProductRemove";
import useProductStop from "../../../hooks/main/useProductStop";
import useProducts from "../../../hooks/main/useProducts";

function StopProductList(props, ref) {
    const { saleStopedProducts, refetch } = useProducts();
    const [productId, setProductId] = useState();
    const [isSaleModal, setIsSaleModal] = useState({
        title: "",
        text: "",
        showing: false,
    });
    const [isRemoveModal, setIsRemoveModal] = useState({
        title: "",
        text: "",
        showing: false,
    });
    const { mutate, isLoading } = useProductStop({ setIsSaleModal, ref });
    const { removeMutate, isRemoveLoading } = useProductRemove({
        setIsRemoveModal,
        refetch,
    });

    const handleSaleModalShow = (productId) => {
        setIsSaleModal({
            title: "판매 재시작",
            text: "해당 메뉴를 판매 재시작하시겠습니까?",
            showing: true,
        });
        setProductId(productId);
    };

    const handleSaleModalClose = () => {
        setIsSaleModal({
            title: "",
            text: "",
            showing: false,
        });
    };

    const handleProductsSaleClick = () => {
        if (isLoading) {
            return;
        }

        mutate(productId);
    };

    const handleRemoveModalShow = (productId) => {
        setIsRemoveModal({
            title: "영구 삭제",
            text: "해당 메뉴를 제거 하시겠습니까?",
            showing: true,
        });
        setProductId(productId);
    };

    const handleRemoveModalClose = () => {
        setIsRemoveModal({
            title: "",
            text: "",
            showing: false,
        });
    };

    const handleProductRemoveClick = () => {
        if (isRemoveLoading) {
            return;
        }

        removeMutate(productId);
    };

    const getTimeHHMM = (time) => {
        const [hour, minute, _] = time.split(":");
        console.log(time);
        return `${hour}:${minute}`;
    };

    const mapedProducts = saleStopedProducts?.map((product) => {
        const {
            productName,
            productId,
            toDayDescription,
            descriptionType,
            price,
            productDescription,
            additionalDescription,
            quantityLimit: {
                useQuantityLimit,
                useTimeLimit,
                countOfMaxSold,
                countOfSold,
                startTimeOfQuantityLimit,
                endTimeOfQuantityLimit,
            },
        } = product;

        return (
            <div className="prd-item" key={productId}>
                <p className="prd-tit">{productName}</p>
                <p className="prd-sub" style={{ whiteSpace: "pre-line" }}>
                    {descriptionType.code === 1
                        ? productDescription
                        : toDayDescription}
                </p>
                {additionalDescription && (
                    <div className="prd-time">
                        <p
                            className="operating"
                            style={{ whiteSpace: "pre-line" }}
                        >
                            {additionalDescription}
                        </p>
                    </div>
                )}
                <p className="prd-price">
                    <span className="price">{price.toLocaleString()}</span>원
                </p>
                <div className="prd-btnbox d-flex">
                    <button
                        type="button"
                        className="btn type14 btn-default"
                        onClick={() => handleSaleModalShow(productId)}
                    >
                        판매 재시작
                    </button>
                    <button
                        type="button"
                        className="btn type13 btn-light"
                        onClick={() => handleRemoveModalShow(productId)}
                    >
                        영구 삭제
                    </button>
                </div>
            </div>
        );
    });

    return (
        <>
            <div className="ProductList">{mapedProducts}</div>
            {/* 판매 재시작 확인 모달 */}
            <Modal
                show={isSaleModal.showing}
                onHide={handleSaleModalClose}
                keyboard={false}
                size="sm"
                centered
                className="demoModal modal-prdAdd text-center"
            >
                <Modal.Header>
                    <Modal.Title>{isSaleModal.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   {isSaleModal.text}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="modal-secondary"
                        className="btn-clear"
                        onClick={handleSaleModalClose}
                    >
                        취소
                    </Button>
                    <Button
                        variant="modal-primary"
                        className="btn-clear"
                        onClick={handleProductsSaleClick}
                    >
                        확인
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* 판매 영구 삭제 확인 모달 */}
            <Modal
                show={isRemoveModal.showing}
                onHide={handleRemoveModalClose}
                keyboard={false}
                size="sm"
                centered
                className="demoModal modal-prdAdd text-center"
            >
                <Modal.Header>
                    <Modal.Title>{isRemoveModal.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   {isRemoveModal.text}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="modal-secondary"
                        className="btn-clear"
                        onClick={handleRemoveModalClose}
                    >
                        취소
                    </Button>
                    <Button
                        variant="modal-primary"
                        className="btn-clear"
                        onClick={handleProductRemoveClick}
                    >
                        확인
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default forwardRef(StopProductList);
