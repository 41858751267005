import { useFetch } from "../common/useFetch";
import { apiClient } from "../../api/apiClient";
import uuid from "react-uuid";

const convertURLtoFile = async (url) => {
    const response = await fetch(url);
    const data = await response.blob();
    const ext = url.split(".").pop(); // url 구조에 맞게 수정할 것
    const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
    const metadata = { type: `image/${ext}` };
    return new File([data], !filename, metadata);
};

const tempFunc = async (data, setFileImgs) => {
    const imgs = [];

    for (let i = 0; i < data.files.length; i++) {
        const file = await convertURLtoFile(data.files[i].url);
        imgs.push(file);
    }

    setFileImgs(imgs);
};

export default function useStoreInfo(
    reset,
    setImgs,
    setFileImgs,
    setIsNotice,
    setIsCloseTimeList,
    nonOperationTimeDispatch
) {
    const { data } = useFetch(
        [],
        async () => {
            const { data } = await apiClient({
                url: "/store/me",
                method: "get",
            });
            return data;
        },
        {
            onSuccess: (data) => {
                reset({ ...data });

                if (!data.storeClosedPeriods.length) {
                    setIsCloseTimeList(false);
                    nonOperationTimeDispatch({
                        type: "inited_nonOperationTime",
                        payload: [],
                    });
                } else {
                    const mapedStoreClosedPeriods = data.storeClosedPeriods.map(
                        (period) => ({
                            ...period,
                            tempId: uuid(),
                        })
                    );

                    setIsCloseTimeList(true);
                    nonOperationTimeDispatch({
                        type: "inited_nonOperationTime",
                        payload: mapedStoreClosedPeriods,
                    });
                }

                setIsNotice(data.useNotice);
                tempFunc(data, setFileImgs);
                setImgs([...data.files]);
            },
            onError: (data) => {
                alert(data.response.data.code);
            },
        }
    );

    return { data };
}
