import { useEffect, useMemo } from "react";
import { Pagination } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
    getFormatDate,
    getPageParamsToURL,
    getTodayYearAndMonth,
} from "../../../function/common";

const PAGENATION_SIZE = 5;

const recordPageIndex = ({ search }) => {
    if (!search) {
        return 0;
    }

    return parseInt(search.split("?page=").at(-1));
};

export default function Pagenation({
    page,
    totalPage,
    setPage,
    status,
    paymentDate,
    paymentTime,
    setPaymentDate,
    setPaymentTime,
    setPaymentMonth,
    paymentMonth,
    setStatus,
    setShowingTime,
    setShowingDate,
    setShowingMonth,
    setShowingStatus,
    name,
    setName,
    showingName,
    setShowingName,
    showingPhone,
    setShowingPhone,
    searchType,
    phone,
    setPhone,
    usedType
}) {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const replacedLocation = location.search.replace(
            /\?page=|&startDate=|&endDate=|&startTime=|&endTime=|&month=|&dateRangeType=|(&name=|&phone=)/g,
            " "
        );
        const phoneRegexWithHyphen = /\d{3}-\d{4}-\d{4}/;
        const phoneRegex = /\d{3}\d{4}\d{4}/;

        const [
            _,
            page,
            startDate,
            endDate,
            startTime,
            endTime,
            month,
            dateRangeType,
            nameAndPhone,
        ] = replacedLocation.split(" ");
        if (!page) {
            setPage(0);
            setPaymentDate({
                startDate: getFormatDate(),
                endDate: getFormatDate(),
            });
            setShowingDate({
                startDate: getFormatDate(),
                endDate: getFormatDate(),
            });
            setPaymentTime({
                startTime: "00:00",
                endTime: "23:00",
            });
            setShowingTime({
                start: "00:00",
                end: "23:00",
            });
            setPaymentMonth(getTodayYearAndMonth());
            setShowingMonth(getTodayYearAndMonth());
            setShowingStatus("TODAY");
            setStatus("TODAY");
            return;
        }
        setPage(page);
        setPaymentDate({ startDate: startDate, endDate: endDate });
        setShowingDate({ startDate: startDate, endDate: endDate });
        setPaymentTime({
            startTime: startTime,
            endTime: endTime,
        });
        setShowingTime({
            start: startTime,
            end: endTime,
        });
        setPaymentMonth(month);
        setShowingMonth(month);
        setShowingStatus(dateRangeType);
        setStatus(dateRangeType);
        if (phoneRegexWithHyphen.test() || phoneRegex.test()) {
            setPhone(decodeURI(nameAndPhone));
            setShowingPhone(decodeURI(nameAndPhone));
        } else {
            setName(decodeURI(nameAndPhone));
            setShowingName(decodeURI(nameAndPhone));
        }
    }, [
        location,
        setPage,
        setPaymentDate,
        setShowingDate,
        setPaymentTime,
        setShowingTime,
        setPaymentMonth,
        setShowingMonth,
        setStatus,
        setShowingStatus,
        setName,
        setPhone,
        setShowingName,
        setShowingPhone,
    ]);

    const currentPageIndexList = useMemo(() => {
        const pageIndexList = Array.from(
            {
                length: totalPage,
            },
            (_, index) => index
        );
        const firstPage = page - (page % PAGENATION_SIZE);
        const lastPage = page - (page % PAGENATION_SIZE) + PAGENATION_SIZE;

        return pageIndexList.slice(firstPage, lastPage);
    }, [page, totalPage]);

    if (!totalPage) {
        return null;
    }

    const mapedTotalPageArray = currentPageIndexList.map((pageIndex) => {
        return (
            <Pagination.Item
                key={pageIndex}
                className={pageIndex === parseInt(page) ? "page on" : "page"}
                onClick={() => {
                    sessionStorage.setItem("page", pageIndex);
                    setPage(pageIndex);
                    navigate(
                        `?page=${pageIndex}&startDate=${
                            paymentDate.startDate
                        }&endDate=${paymentDate.endDate}&startTime=${
                            paymentTime.startTime
                        }&endTime=${
                            paymentTime.endTime
                        }&month=${paymentMonth}&dateRangeType=${status}${
                            searchType === "NAME"
                                ? `&name=${name}`
                                : `&phone=${phone}`
                        }`
                    );
                }}
            >
                {pageIndex + 1}
            </Pagination.Item>
        );
    });

    return (
        <Pagination className="align-items-center">
            <Pagination.First
                className="PaginationFirst"
                onClick={() => {
                    if (0 === getPageParamsToURL(location)) {
                        return;
                    }

                    sessionStorage.setItem("page", 0);
                    setPage(0);

                    navigate(
                        `?page=0&startDate=${paymentDate.startDate}&endDate=${
                            paymentDate.endDate
                        }&startTime=${paymentTime.startTime}&endTime=${
                            paymentTime.endTime
                        }&month=${paymentMonth}&dateRangeType=${status}${
                            searchType === "NAME"
                                ? `&name=${name}`
                                : `&phone=${phone}`
                        }`
                    );
                }}
            />
            <Pagination.Prev
                className="PaginationPrev"
                onClick={() => {
                    const movePage = page - 1;

                    if (movePage === -1) {
                        return;
                    }

                    if (page - 1 === getPageParamsToURL(location)) {
                        return;
                    }

                    sessionStorage.setItem("page", page - 1);
                    setPage(page - 1);

                    navigate(
                        `?page=${page - 1}&startDate=${
                            paymentDate.startDate
                        }&endDate=${paymentDate.endDate}&startTime=${
                            paymentTime.startTime
                        }&endTime=${
                            paymentTime.endTime
                        }&month=${paymentMonth}&dateRangeType=${status}${
                            searchType === "NAME"
                                ? `&name=${name}`
                                : `&phone=${phone}`
                        }`
                    );
                }}
            />
            {mapedTotalPageArray}
            <Pagination.Next
                className="PaginationNext"
                onClick={() => {
                    const movePage = parseInt(page) + 1;

                    if (movePage === totalPage) {
                        return;
                    }

                    if (parseInt(page) + 1 === getPageParamsToURL(location)) {
                        return;
                    }

                    sessionStorage.setItem("page", parseInt(page) + 1);
                    setPage(parseInt(page) + 1);

                    navigate(
                        `?page=${parseInt(page) + 1}&startDate=${
                            paymentDate.startDate
                        }&endDate=${paymentDate.endDate}&startTime=${
                            paymentTime.startTime
                        }&endTime=${
                            paymentTime.endTime
                        }&month=${paymentMonth}&dateRangeType=${status}${
                            searchType === "NAME"
                                ? `&name=${name}`
                                : `&phone=${phone}`
                        }`
                    );
                }}
            />
            <Pagination.Last
                className="PaginationLast"
                onClick={() => {
                    if (totalPage - 1 === getPageParamsToURL(location)) {
                        return;
                    }

                    setPage(totalPage - 1);
                    sessionStorage.setItem("page", totalPage - 1);

                    navigate(
                        `?page=${totalPage - 1}&startDate=${
                            paymentDate.startDate
                        }&endDate=${paymentDate.endDate}&startTime=${
                            paymentTime.startTime
                        }&endTime=${
                            paymentTime.endTime
                        }&month=${paymentMonth}&dateRangeType=${status}${
                            searchType === "NAME"
                                ? `&name=${name}`
                                : `&phone=${phone}`
                        }`
                    );
                }}
            />
        </Pagination>
    );
}
