import { apiClient } from "../../api/apiClient";
import { useMutation } from "../common/useMutation";

const getFileName = (status, paymentDate, paymentTime, paymentMonth) => {
  if (status === "TODAY") {
    return `결제주문내역_오늘_(${paymentDate.startDate}, ${paymentTime.start}~${paymentTime.end}).xlsx`;
  } else if (status === "BY_DAY") {
    return `결제주문내역_일별_(${paymentDate.startDate}~${paymentDate.endDate}).xlsx`;
  }

  return `결제주문내역_월별(${paymentMonth}).xlsx`;
};

export default function usePaymentOrderExcelDownload({
  status,
  paymentDate,
  paymentTime,
  paymentMonth,
  smartOrderStatus
}) {
  const { mutate: excelMutate, isLoading: isExcelLoading } = useMutation(
    async ({ status, paymentDate, paymentTime, smartOrderStatus }) =>
      
      apiClient({
        url: `/payments-sorder/excel?startTime=${paymentDate.startDate} ${paymentTime.start}&endTime=${paymentDate.endDate} ${paymentTime.end}&dateRangeType=${status}${smartOrderStatus ? `&smartOrderStatus=${smartOrderStatus}` : ''}` ,
        method: "post",
        responseType: "arraybuffer",
      }),
    {
      onSuccess: (response) => {
        const blob = new Blob([response.data]);
        const fileUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = fileUrl;
        link.style.display = "none";
        link.download = getFileName(
          status,
          paymentDate,
          paymentTime,
          paymentMonth
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
      onError: ({ response }) => {
        alert(response.data.message);
      },
    }
  );

  return { excelMutate, isExcelLoading };
}
