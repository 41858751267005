export const initPopupModalState = {
    text: "",
    isShowing: false,
};

export const popupModifyModalReducer = (state, action) => {
    switch (action.type) {
        case "warning_title":
            return {
                text: "팝업 제목을 입력해주세요.",
                isShowing: true,
            };
        case "warning_image":
            return {
                text: "이미지를 선택해주세요.",
                isShowing: true,
            };
        case "warning_image_detail":
            return {
                text: "이미지를 등록해주세요.",
                isShowing: true,
            };
        case "warning_popup_title":
            return {
                text: "팝업 타이틀을 등록해주세요.",
                isShowing: true,
            };
        case "warning_buttonName":
            return {
                text: "버튼명을 입력해주세요.",
                isShowing: true,
            };
        case "hide_modal":
            return {
                ...state,
                isShowing: false,
            };
        default:
            throw Error(`Unknown Type ${action.type}`);
    }
};
