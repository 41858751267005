import { useNavigate } from "react-router-dom";
import { apiClient } from "../../api/apiClient";
import { useMutation } from "../common/useMutation";

export default function usePopupModify() {
    const navigate = useNavigate();
    const getFormData = (popupInfo) => {
        const {
            storeTitle,
            startDate,
            endDate,
            popupImageType,
            title,
            subTitle,
            useButton,
            buttonText,
            buttonLink,
            imageName,
            formData,
        } = popupInfo;

        const newPopupInfo = {
            storeTitle,
            startDate,
            endDate,
            popupImageType:
                popupImageType.code === 1
                    ? "DEFAULT1"
                    : popupImageType.code === 2
                    ? "DEFAULT2"
                    : "MANUAL",
            title,
            subTitle,
            useButton,
            buttonText,
            buttonLink,
            imageName:
                popupImageType.description === "직접 등록" ? imageName : "",
        };

        formData.append("request", JSON.stringify(newPopupInfo));

        if (!imageName || popupImageType.code !== 3) {
            formData.append("images", "");
        }

        return formData;
    };

    const { mutate, isLoading } = useMutation(
        async ({ popupId, popupInfo }) =>
            await apiClient({
                method: "put",
                url: `/popups/${popupId}`,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                data: getFormData(popupInfo),
            }),
        {
            onSuccess: () => {
                navigate("/popup");
            },
        }
    );

    return { mutate, isLoading };
}
