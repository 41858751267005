import React from "react";
import { useParams } from "react-router-dom";

export default function Naver(){

    const { name } = useParams();

    

    return (
        <div>
                
        </div>
    )
}