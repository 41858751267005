
import { Container, Row, Col, Form, Image, ButtonGroup, Button, InputGroup } from 'react-bootstrap';
import Btn from './../../components/common/Btn';
import { useEffect, useRef } from 'react';
import { useState } from 'react';

import { loadTossPayments } from '@tosspayments/payment-sdk'
import { useSearchParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { AlertError, getApiUrl, PrintConsole } from '../../function/common';
import axios from 'axios';
import Footer from '../../components/common/Footer';
// import QRFooter from './QRFooter';
import { setCommas, getTextLength } from './../../function/common';
import Serviceterms from './../../components/common/ServiceTerms';

import Modal from 'react-bootstrap/Modal';
import Header from './components/Header';
import "./Gift.css";

// const clientKey = 'live_ck_LBa5PzR0ArnR70eLN0krvmYnNeDM'
const clientKey = process.env.REACT_APP_STORE_KEY


export default function Gift(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    // 모달
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [searchParams, setSearchParams]=useSearchParams();
    // const [Params] = useSearchParams();
    console.log(searchParams.get('storeurl'));
    console.log(searchParams.get('productid'));
    // const location = useLocation();
    // console.log(location);
    // var Parameters = [];
    // for (const entry of Params.entries()) {
    //     Parameters.push({ [entry[0]] : entry[1] });
    // }

    const [gift, setGift] = useState({
        recipientName           : "",
        recipientPhoneNumber    : "",
        orderPrice              : ""
    });

    const payMode = useRef();
    
    const onChangeGift = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setGift({
          ...gift,
          [e.target.id]: e.target.value,
        });
        PrintConsole(gift);
    };

    const [isCheck, setIsCheck] = useState([]);
    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);

        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    };

    const [orderId, setOrderId] = useState();
    function getOrderId() {
        document.getElementById('submitBtn').setAttribute("disabled","true");
        // let s1 = document.getElementById('s1');
        // if (!s1.checked){
        //     alert('개인정보 수집에 동의해주세요.');
        //     document.getElementById('submitBtn').removeAttribute("disabled");
        //     return false;
        // }

        console.log(localStorage.getItem('accessToken'));

        let recipientPhoneNumber = '';
        let messageToRecipient = '';
        if(document.getElementById("recipientPhoneNumber")){
            recipientPhoneNumber = document.getElementById('recipientPhoneNumber').value;
        }
        if(document.getElementById("recipientName")){
            messageToRecipient = document.getElementById('recipientName').value;
        }

        
        // if(recipientPhoneNumber === undefined || recipientPhoneNumber === null || recipientPhoneNumber === ''){
        //     alert('받는사람 전화번호를 입력해주세요.');
        //     document.getElementById('submitBtn').removeAttribute("disabled");
        //     return false;
        // }
        
        return new Promise( async function(resolve, reject) {
            let obj = { 
                "isLoggedIn": true,
                "orderPrice"            : '5000',
                "payType"               : payMode.current,
                "recipientName"         : 'A식단',
                "recipientPhoneNumber"  : '01071405615'
            }
            PrintConsole(obj);
            await axios.post( 
                getApiUrl('stores/'+searchParams.get('storeurl')+'/products/'+searchParams.get('productid')+'/food-ticket-orders'),
                obj, 
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json;"
            ).then( 
                function(response){
                    PrintConsole(response.data.orderId);
                    tossloading(response.data.orderId);
                }
            )
            .catch(error => {
                AlertError(error);
                document.getElementById('submitBtn').removeAttribute("disabled");
            });
        });
    };

    async function tossloading(orderId) {

        localStorage.setItem('storeDomain', searchParams.get('storeurl'));
        localStorage.setItem('products', searchParams.get('productid'));
        localStorage.setItem('orderId', orderId);

        const tossPayments = await loadTossPayments(clientKey);
        let tossMode = '';
        if (payMode.current === 'CARD'){
            tossMode = '카드';
        }else if(payMode.current === 'PHONE'){
            tossMode = '휴대폰';
        }else if(payMode.current === 'ACCOUNT_TRANSFER'){
            tossMode = '계좌이체';
        }else{
            alert('결제 모듈 호출에 실패하였습니다.');
        }

        PrintConsole('orderId ' + orderId);
        //계좌이체, 휴대폰
        tossPayments.requestPayment(tossMode, { // 결제 수단 파라미터
            // 결제 정보 파라미터
            amount: 5000, 
            orderId: orderId,
            orderName: 'A식단',
            customerName: "인제대학교" ,
            successUrl: process.env.REACT_APP_BASEURL+'demo/success',
            failUrl: process.env.REACT_APP_BASEURL+'demo/success'
        })
    }
    function setPayMode(mode){
        payMode.current = mode;
        // document.querySelector('#ACCOUNT_TRANSFER').classList.remove('btn-primary');
        // document.querySelector('#CARD').classList.remove('btn-primary');
        // document.querySelector('#PHONE').classList.remove('btn-primary');

        // if(!document.querySelector('#ACCOUNT_TRANSFER').classList.contains('btn-secondary')){
        //     document.querySelector('#ACCOUNT_TRANSFER').classList.add('btn-secondary')
        // }
        // if(!document.querySelector('#CARD').classList.contains('btn-secondary')){
        //     document.querySelector('#CARD').classList.add('btn-secondary')
        // }
        // if(!document.querySelector('#PHONE').classList.contains('btn-secondary')){
        //     document.querySelector('#PHONE').classList.add('btn-secondary')
        // }

        // document.querySelector('#'+mode).classList.remove('btn-secondary');
        // document.querySelector('#'+mode).classList.add('btn-primary');
    }

    useEffect( () => {
        //데이터 로딩
        setLoading(true);

        // getOrderId();
        // getOrderItem();

        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    
    return (
        <>
        <div className='demo'>

            <div className="TGiftPage">
                <div className="container-fluid g-0">

                    <nav className="sub-navbar">
                        <p className="navbar-title">선물하기</p>
                        <button type="button" className="btn-clear btn-back" onClick={() => {navigate(-1);}}>
                            <img className="img-fluid" alt="뒤로가기"
                                src="https://www.flexdaycdn.net/public/images/ticket/back-b.png" />
                        </button>
                        <Header/>                      
                    </nav>

                    <div className="GiftBox my-4-5">
                        <div className="GiftItem pb-4-5 px-4-5">
                            <p className="PageTit2 mb-2">
                                식권 정보
                                <img className="img-fluid"
                                    src="https://www.flexdaycdn.net/public/images/static/flexdya-cms/emoji_hand2.png"
                                    alt="" />
                            </p>
                            <div className="justify-content-around g-0 row">
                                <div className="col">
                                    <p className="prd-info">
                                        식권/수량
                                    </p>
                                </div>
                                <div className="col-7">
                                    <p className="prd-sub">
                                        C코너 / 1개
                                    </p>
                                </div>
                            </div>
                            <div className="justify-content-around g-0 row">
                                <div className="col">
                                    <p className="prd-info">
                                        매장
                                    </p>
                                </div>
                                <div className="col-7">
                                    <p className="prd-sub">
                                        인제대학교 늘빛관(다인)
                                    </p>
                                </div>
                            </div>
                            <div className="justify-content-around g-0 row">
                                <div className="col">
                                    <p className="prd-info">
                                        유효기간
                                    </p>
                                </div>
                                <div className="col-7">
                                    <p className="prd-sub">
                                        ~2023-01-12
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="GiftBox bottom px-4-5 my-4-5">
                        <div className="infobox">
                            <p className="tit">
                                <img className="img-fluid" src="https://www.flexdaycdn.net/public/images/ticket/info-icon.png"
                                    alt="" />
                                안내사항
                            </p>
                            <p className="info-txt">
                                • 선물을 받으시는 분의 휴대폰에
                                해당 어플이 설치되어 있어야 합니다.
                            </p>
                            <p className="info-txt mb-0">
                                • 선물을 받으시는 분이 회원가입되어 있어야 합니다.
                            </p>
                        </div>

                        <div className="recipient">
                            <p className="tit">
                                선물 받으시는 분 정보를 입력해주세요.
                            </p>
                            <div className="Gift-Inputbox mb-2">
                                <input type="text" id='recipientName' className="form-control InputType" placeholder="이름(실명을 입력해주세요.)" />
                            </div>
                            <div className="Gift-Inputbox">
                                <input type="number" id='recipientPhoneNumber' pattern="\d*"  className="form-control InputType" placeholder="연락처(’-’를 제외하고 입력해주세요.)" />
                            </div>
                        </div>

                        <div className="Gift-Confirm pb-4">
                            <button type="button" className="btn btn-default" onClick={handleShow}>
                                선물보내기
                            </button>
                        </div>
                    </div>

                </div>
            </div>

            {/* 선물하기 Modal */}
            <Modal
                    show={show}
                    onHide={handleClose}
                    keyboard={false}
                    size="sm"
                    centered
                    className="demoModal modal-prdAdd text-center"
                    >
                    <Modal.Header>
                        <Modal.Title>
                            ‘이름’님에게 <br />
                            식권을 선물하시겠습니까?
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        본인 QR지갑에서 해당 식권이 삭제됩니다.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="modal-secondary" className='btn-clear' onClick={handleClose}>
                            취소
                        </Button>
                        <Button variant="modal-primary" className='btn-clear' >
                            확인
                        </Button>
                    </Modal.Footer>
                </Modal> 

        </div>
        </>
    )
}