import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storeSlice from './modules/storeSlice';
import paymentOrderSlice from './modules/paymentOrderSlice';
import taxBillSlice from './modules/taxBillSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
// import { createLogger } from 'redux-logger';

// const logger = createLogger();

const rootReducer = combineReducers({
    storeReducer: storeSlice.reducer,
    paymentOrderReducer : paymentOrderSlice.reducer,
    taxBillReducer : taxBillSlice.reducer,
  });

const initialState = {};

export const store = configureStore({
    reducer: rootReducer,
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  preloadedState: initialState,
  enhancers: (defaultEnhancers) => [...defaultEnhancers]
});


export const RootState = store.getState;
export const AppDispatch = store.dispatch;

export const useAppSelector = useSelector;
export const useAppDispatch = () => useDispatch();

export default store;