
import { Container, Row, Col, Form, Image, ButtonGroup, Button, InputGroup } from 'react-bootstrap';
import { useEffect, useRef } from 'react';
import { useState } from 'react';

import { loadTossPayments } from '@tosspayments/payment-sdk'
import { useSearchParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { AlertError, getApiUrl, PrintConsole } from '../../function/common';
import axios from 'axios';



import Modal from 'react-bootstrap/Modal';
import "./NonUserTicket.css";

// const clientKey = 'live_ck_LBa5PzR0ArnR70eLN0krvmYnNeDM'
const clientKey = process.env.REACT_APP_STORE_KEY


export default function NonUserTicket(){

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    // 모달
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
   

    useEffect( () => {
        //데이터 로딩
        setLoading(true);

 

        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    
    return (
        <>
        <div className='demo'>

            <div className="TNonMembersPage">
                <div className="container-fluid g-0">

                    <nav className="sub-navbar">
                        <p className="navbar-title">
                            식권
                        </p>                    
                    </nav>

                    <div className="NonMemberBox Inner">
                        <div className="NonMemberBox-title text-center">

                            <img className="img-fluid tit-img" alt=""
                                src="https://www.flexdaycdn.net/public/images/ticket/non_member_img.png" />

                            <p className="tit mb-2">
                                본인 확인을 위해 <br />
                                <span className="fw-bold">연락처 끝 번호 4자리</span>를 입력해주세요.
                            </p>
                            <p className="sub">
                                예시) 010-****-<span className="color">0000</span>
                            </p>
                        </div>

                        <div className="NonMemberBox-Input mb-4">
                            <input type="number" pattern="\d*" className="InputType form-control mb-3 px-3"
                                placeholder="연락처 끝 번호 4자리를 입력해주세요." />
                            <button type="button" className="btn btn-default" onClick={handleShow}>
                                입력
                            </button>
                        </div>
                    </div>

                </div>

                {/* 비회원 식권 확인Modal */}
                <Modal
                    show={show}
                    onHide={handleClose}
                    keyboard={false}
                    size="sm"
                    centered
                    className="demoModal modal-prdAdd text-center"
                    >
                    <Modal.Header>
                        <Modal.Title>
                            연락처 끝 번호 4자리가 <br />
                            일치하지 않습니다.
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        번호를 확인하신 후 다시 입력해주세요.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="modal-primary" className='btn-clear w-100' onClick={handleClose}>
                            확인
                        </Button>
                    </Modal.Footer>
                </Modal>   

            </div>

        </div>
        </>
    )
}