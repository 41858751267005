import uuid from "react-uuid";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useEventDetail from "../../../hooks/useEventDetail";
import Datepicker from "../../../components/common/Datepicker";
import "./EventModify.css";
import useEventModify from "../../../hooks/useEventModify";
import { getDeepCopy, vaildateEvent } from "../../../function/common";
import useProductList from "../../../hooks/useProductList";
import { Button, Modal } from "react-bootstrap";
import AdminHeader from "../../UserMain/components/AdminHeader";
import useRemoveBodyStyle from "../../../hooks/common/useRemoveBodyStyle";
import LoadingPortal from "../../../portals/LoadingPortal";
import LoadingModal from "../../../components/loadingmodal/LoadingModal";

export default function EventModify() {
  useRemoveBodyStyle();
  const navigate = useNavigate();
  const { eventId } = useParams();
  const [eventInfo, setEventInfo] = useState();
  const [eventDate, setEventDate] = useState();
  const [eventType, setEventType] = useState("STAMP");
  const [errorText, setErrorText] = useState("");
  const [isRemoveModalShowing, setIsRemoveModalShowing] = useState(false);
  const { data, isEventDetailLoading } = useEventDetail(eventId);
  const { products } = useProductList();

  const { mutate, isLoading } = useEventModify(eventId);

  useEffect(() => {
    if (!data) {
      return;
    }

    setEventInfo(data);
    setEventDate({
      startDate: data.startDate,
      endDate: data.endDate,
    });
  }, [data]);

  useEffect(() => {
    if (!eventInfo) return;

    setEventInfo((prev) => {
      const deepCopy = getDeepCopy(prev);

      return {
        ...deepCopy,
        startDate: eventDate.startDate,
        endDate: eventDate.endDate,
      };
    });
  }, [eventDate]);

  if (isEventDetailLoading) {
    return (
      <LoadingPortal>
        <LoadingModal />
      </LoadingPortal>
    );
  }

  if (!data || !eventInfo || !products) {
    return null;
  }

  const handleRemoveModalShow = () => {
    setIsRemoveModalShowing(true);
  };

  const handleRemoveModalClose = () => {
    setIsRemoveModalShowing(false);
  };

  // 이벤트 명 변경
  const handleEventNameChange = ({ target }) => {
    setEventInfo((prev) => ({ ...prev, eventName: target.value }));
  };

  // 리워드 추가
  const handleRewardAddBtnClick = () => {
    setEventInfo((prev) => {
      const deepCopy = getDeepCopy(prev);

      return {
        ...deepCopy,
        stampList: [
          ...prev.stampList,
          {
            id: uuid(),
            productName: null,
            reward: null,
            rewardProductId: "default",
            rewardType: "INNER_PRODUCT",
            stampCount: 0,
          },
        ],
      };
    });
  };

  // 리워드 제거
  const handleRewardRemoveBtnClick = (id) => {
    if (eventInfo.stampList.length <= 1) {
      return;
    }

    setEventInfo((prev) => {
      const deepCopy = getDeepCopy(prev);

      const filteredRewards = prev.stampList.filter(
        (reward) => reward.id !== id
      );

      return {
        ...deepCopy,
        stampList: [...filteredRewards],
      };
    });
  };

  // 리워드 스탬프 수정
  const handleRewardStampChange = ({ target }, id) => {
    if (target.value > 20) {
      return;
    }

    setEventInfo((prev) => {
      const deepCopy = getDeepCopy(prev);

      const mapedProducts = prev.stampList.map((product) => {
        if (product.id === id) {
          return {
            ...product,
            stampCount: parseInt(target.value),
          };
        }

        return product;
      });

      return {
        ...deepCopy,
        stampList: mapedProducts,
      };
    });
  };

  // 리워드 프로덕트 수정
  const handleRewardProductChange = (e, id) => {
    setEventInfo((prev) => {
      const deepCopy = getDeepCopy(prev);

      const mapedProducts = prev.stampList.map((product) => {
        if (product.id === id && e.target.value === "direct") {
          return {
            ...product,
            productName: null,
            reward: null,
            rewardProductId: null,
            rewardType: "ETC",
          };
        } else if (product.id === id && e.target.value !== "direct") {
          return {
            ...product,
            productName: e.target.options[e.target.selectedIndex].text,
            reward: null,
            rewardProductId: e.target.options[e.target.selectedIndex].value,
            rewardType: "INNER_PRODUCT",
          };
        }

        if (product.rewardType === "INNER_PRODUCT") {
          return { ...product, reward: null };
        }

        return { ...product };
      });

      return {
        ...deepCopy,
        stampList: mapedProducts,
      };
    });
  };

  // 리워드 프로덕트 직접 입력
  const handleRewardETCProductChange = (e, id) => {
    setEventInfo((prev) => {
      const deepCopy = getDeepCopy(prev);

      const mapedProducts = prev.stampList.map((product) => {
        if (product.id === id) {
          return {
            ...product,
            productName: null,
            reward: e.target.value,
            rewardProductId: null,
            rewardType: "ETC",
          };
        }

        return product;
      });

      return {
        ...deepCopy,
        stampList: mapedProducts,
      };
    });
  };

  // 수정 버튼
  const handleModifyBtnClick = () => {
    if (isLoading) {
      return;
    }

    const { endDate, eventId, startDate, eventName, stampList } = eventInfo;
    const modifedEvent = {
      endDate,
      eventId,
      startDate,
      eventName,
      eventType,
      stampEvent: { stamp: stampList },
    };

    if (!vaildateEvent(modifedEvent, setErrorText, setIsRemoveModalShowing)) {
      return;
    }

    mutate(modifedEvent);
  };

  // 상품 목록 렌더
  const mapedProducts = products.map(({ productId, productName }) => {
    return (
      <option key={productId} value={productId}>
        {productName}
      </option>
    );
  });

  // 리워드 렌더
  const mapedStamps = eventInfo.stampList.map(
    ({ id, stampCount, rewardProductId, rewardType, reward }, index) => {
      return (
        <div className="EventAdd-inputbox reward d-flex" key={id}>
          <div className="input-box1 d-flex">
            <span className="num">{index + 1}</span>
            스탬프
            <input
              type="number"
              className="form-control InputType input1"
              placeholder="0"
              value={eventInfo.stampList[index].stampCount}
              defaultValue={stampCount || ""}
              onChange={(e) => handleRewardStampChange(e, id)}
              onWheel={(e) => e.target.blur()}
            />
            개,
          </div>
          <div className="input-box2 d-flex">
            <select
              type="select"
              className={`form-control InputType input2 ${
                rewardType === "ETC" && "show"
              }`}
              id="select1"
              onChange={(e) => handleRewardProductChange(e, id)}
              defaultValue={rewardType === "ETC" ? "direct" : rewardProductId}
            >
              <option value="default" disabled hidden>
                상품 선택
              </option>
              {mapedProducts}
              <option value="direct">직접 입력 (추첨 상품용)</option>
            </select>
            지급
            <input
              type="text"
              className="form-control InputType direct-input"
              placeholder="리워드를 입력해주세요. (예시: 아이패드)"
              defaultValue={rewardType === "ETC" ? reward : ""}
              onChange={(e) => handleRewardETCProductChange(e, id)}
            />
          </div>
          <div className="btn-box">
            <button
              type="button"
              className={`btn btn-clear ${
                eventInfo.stampList.length <= 1 && "disabled"
              }`}
              onClick={() => handleRewardRemoveBtnClick(id)}
            >
              <img
                className="img-fluid"
                alt=""
                src="https://www.flexdaycdn.net/public/images/ticket/delete_icon.png"
              />
            </button>
          </div>
        </div>
      );
    }
  );

  return (
    <>
      <div className="demo">
        <div className="TEventAddPage">
          <div className="container-fluid g-0">
            <nav className="sub-navbar">
              <p className="navbar-title">이벤트 수정</p>
              <button
                type="button"
                className="btn-clear btn-back"
                onClick={() => {
                  navigate("/event");
                }}
              >
                <img
                  className="img-fluid"
                  alt="뒤로가기"
                  src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                />
              </button>
              <button
                className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
              >
                <img
                  className="img-fluid"
                  alt="메뉴"
                  src="https://www.flexdaycdn.net/public/images/ticket/menu3.png"
                />
              </button>
              <AdminHeader />
            </nav>

            <div className="EventAddBox py-4-5 px-4-5">
              <div className="EventAdd-Form">
                <div className="EventAdd-Formitem pb-5">
                  <p className="EventAdd-tit">
                    1. 이벤트명<span>*</span>
                  </p>
                  <div className="EventAdd-inputbox">
                    <input
                      type="text"
                      className="form-control InputType"
                      placeholder="이벤트명을 입력해 주세요."
                      defaultValue={data.eventName}
                      onChange={handleEventNameChange}
                    />
                  </div>
                </div>

                <div className="EventAdd-Formitem pb-5">
                  <p className="EventAdd-tit mb-0">
                    2. 운영 기간<span>*</span>
                  </p>
                  <p className="EventAdd-infoTxt">
                    (운영 기간이 지나면 사용자 스탬프는 소멸됩니다.)
                  </p>
                  <Datepicker
                    eventDate={eventDate}
                    setEventDate={setEventDate}
                  />
                </div>

                <div className="EventAdd-Formitem">
                  <p className="EventAdd-tit mb-0">
                    3. 리워드 생성<span>*</span>
                  </p>
                  {mapedStamps}
                </div>
              </div>

              <div className="EventAdd-Btnbox">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={handleRewardAddBtnClick}
                >
                  + 리워드 추가
                </button>
              </div>

              <div className="EventAdd-Btnbox">
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={handleModifyBtnClick}
                >
                  등록
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={isRemoveModalShowing}
        onHide={handleRemoveModalShow}
        keyboard={false}
        size="sm"
        centered
        className="demoModal modal-prdAdd text-center"
      >
        <Modal.Header>
          <Modal.Title>{errorText}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="modal-primary"
            className="btn-clear w-100"
            onClick={handleRemoveModalClose}
          >
            확인
          </Button>
        </Modal.Footer>
      </Modal>
      {isLoading && (
        <LoadingPortal>
          <LoadingModal type="black" />
        </LoadingPortal>
      )}
    </>
  );
}
