export const initialState = {};

export const popupModifyReducer = (state, action) => {
    switch (action.type) {
        case "inited_popupInfo":
            return { ...action.payload, formData: new FormData() };
        case "changed_storeTitle":
            return {
                ...state,
                storeTitle: action.payload,
            };
        case "changed_popupImageType":
            return {
                ...state,
                formData: new FormData(),
                imageName: "",
                popupImageType: {
                    ...action.payload,
                },
            };
        case "changed_title":
            return {
                ...state,
                title: action.payload,
            };
        case "changed_subTitle":
            return {
                ...state,
                subTitle: action.payload,
            };
        case "changed_buttonStatus":
            if (action.payload) {
                return {
                    ...state,
                    useButton: action.payload,
                };
            }

            return {
                ...state,
                useButton: action.payload,
            };
        case "changed_buttonText":
            return {
                ...state,
                buttonText: action.payload,
            };
        case "changed_buttonLink":
            return {
                ...state,
                buttonLink: action.payload,
            };
        case "changed_date":
            return {
                ...state,
                startDate: action.payload.startDate,
                endDate: action.payload.endDate,
            };
        case "changed_startDate":
            return {
                ...state,
                startDate: action.payload.startDate,
            };
        case "changed_endDate":
            return {
                ...state,
                endDate: action.payload.endDate,
            };
        case "added_image":
            state.formData.append("images", action.payload.image);
            return {
                ...state,
                imageName: action.payload.name,
            };
        case "reseted_imageName":
            return {
                ...state,
                formData: new FormData(),
                imageName: "",
            };
        default:
            throw Error(`Unknown action ${action.type}`);
    }
};
