import { useParams } from "react-router-dom";
import { apiClient } from "../../api/apiClient";
import { useFetch } from "../common/useFetch";

export default function useParticipation() {
  const { eventId } = useParams();

  const { data: participationInfo } = useFetch(
    [eventId],
    async () => {
      const { data } = await apiClient({
        url: `/events/${eventId}`,
        method: "get",
      });

      return data;
    },
    {
      onError: ({ response }) => {
        alert(response.data.message);
      },
    }
  );

  return { participationInfo };
}
