import { useInfinityScroll } from "../common/useInfiniteScroll";
import { apiClient } from "../../api/apiClient";

const ITEM_SIZE = 5;

export default function useCalculateInfiniteScroll() {
    const {
        data: calculateInfos,
        hasNextPage,
        setTarget,
    } = useInfinityScroll(
        [],
        async (pageParam = "") => {
            const { data } = await apiClient({
                url: `/settlements?${pageParam ? `cursorId=${pageParam}` : ""}`,
                method: "get",
                params: {
                    size: ITEM_SIZE,
                },
            });

            return data;
        },
        {
            onError: (response) => {
                alert(response.data.message);
            },
        }
    );

    return {
        calculateInfos,
        hasNextPage,
        setTarget,
    };
}
