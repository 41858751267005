import { apiClient } from "../../api/apiClient";
import { useMutation } from "../common/useMutation";

export function useUsedQR({ setUsedShowingModal, refetch }) {
  const { mutate: usedMutate, isLoading: isUsedLoading } = useMutation(
    async ({ qrcode }) =>
      apiClient({
        url: `/coupons/${qrcode}/use-for-pc`,
        method: "post",
      }),
    {
      onSuccess: () => {
        setUsedShowingModal(false);
        refetch();
      },
      onError: ({ response }) => {
        const { message } = response.data;
        alert(message);
      },
    }
  );

  return { usedMutate, isUsedLoading };
}
