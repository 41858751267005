
import { Container, Row, Col, Form, Image, ButtonGroup, Button, InputGroup, Spinner } from 'react-bootstrap';
import Btn from './../../components/common/Btn';
import { useEffect, useRef } from 'react';
import { useState } from 'react';

import { loadTossPayments } from '@tosspayments/payment-sdk'
import { useSearchParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { AlertError, getApiUrl, PrintConsole } from '../../function/common';
import axios from 'axios';
import Footer from '../../components/common/Footer';
// import QRFooter from './QRFooter';
import { setCommas, getTextLength } from './../../function/common';
import Serviceterms from './../../components/common/ServiceTerms';


import "./Success.css";


// const clientKey = 'live_ck_LBa5PzR0ArnR70eLN0krvmYnNeDM'
const clientKey = process.env.REACT_APP_STORE_KEY


export default function Success(){

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [searchParams, setSearchParams]=useSearchParams();
    // const [Params] = useSearchParams();
    console.log(searchParams.get('storeurl'));
    console.log(searchParams.get('productid'));
    
    const [gift, setGift] = useState({
        recipientName           : "",
        recipientPhoneNumber    : "",
        orderPrice              : ""
    });

    const payMode = useRef();
    
    const onChangeGift = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setGift({
          ...gift,
          [e.target.id]: e.target.value,
        });
        PrintConsole(gift);
    };

    const [data, setData] = useState([]);

    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    console.log(localStorage.getItem('storeDomain'));

    function PgResult() {
        return new Promise( async function(resolve, reject) {
            let obj = { 
                "paymentKey"            : searchParams.get('paymentKey'),
                "amount"                : searchParams.get('amount')
            }
            console.log(obj);
            await axios.post( 
                getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/products/'+localStorage.getItem('products')+'/orders/'+localStorage.getItem('orderId')+'/success'),
                obj, 
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json;"
            ).then( 
                function(response){
                    if(process.env.NODE_ENV !== 'production'){
                        console.log(response.data);
                    }

                    if(response.status === 200){
                        setData(response.data);

                        localStorage.removeItem('storeDomain');
                        localStorage.removeItem('products');
                        localStorage.removeItem('orderId');
                        
                        //데이터 로딩 완료
                        setLoading(false);
                    }else{
                        setLoading(false);
                    }
                }
            ).catch(function(error){
                AlertError(error);
                setLoading(false);
            })
        });
    };

    useEffect( () => {
        //데이터 로딩
        setLoading(true);

        // PgResult();

        //데이터 로딩 완료
        setLoading(false);
    }, []);

    // if (loading) return <></>
    if (loading) return <div className='container' style={{position:'absolute',left:'50%',top:'50%', marginLeft:'-18px'}}><Spinner animation="border" /></div>;

     
    return (
        <>
        <div className="demo">

            <div className="TPaymentConfirmPage">
                <div className="container-fluid g-0">

                    <nav className="sub-navbar">
                        <p className="navbar-title">결제 완료</p>
                        <button type="button" className="btn-clear btn-close" onClick={()=>{navigate("/demo/userstore/storeurl")}}>
                            <img className="img-fluid" alt="뒤로가기"
                                src="https://www.flexdaycdn.net/public/images/ticket/close5.png" />
                        </button>
                    </nav>

                    <div className="PaymentBox Inner">
                        <div className="Payment-title my-5 text-center">
                            <img className="img-fluid mb-4"
                                src="https://www.flexdaycdn.net/public/images/ticket/hand-clap.png" alt="" />
                            <p className="PageTit2 mb-2">
                                결제가 성공적으로 <br />
                                완료되었습니다.
                            </p>
                        </div>

                        <div className="PaymentResult_Wrap">
                            <div className="hidebox">
                                <div className="PaymentResult mb-5 px-4-5 py-4-5">
                                    <div className="PaymentResult-Tit mb-4 pb-2 px-2">
                                        주문/결제정보
                                    </div>
                                    <div className="PaymentResult-Item d-flex mb-1 px-2">
                                        <div className="tit w-25">
                                            주문번호
                                        </div>
                                        <div className="price text-end w-75">
                                            20221109-000155
                                            {/* {data && data.orderNumber} */}
                                        </div>
                                    </div>
                                    <div className="PaymentResult-Item d-flex mb-1 px-2">
                                        <div className="tit w-25">
                                            결제방법
                                        </div>
                                        <div className="price text-end w-75">
                                            카드
                                            {/* {data.payType && data.payType.description} */}
                                        </div>
                                    </div>
                                    <div className="PaymentResult-Item d-flex mb-1 px-2">
                                        <div className="tit w-25">
                                            결제금액
                                        </div>
                                        <div className="price text-end w-75">
                                            <span>5,800</span>원
                                            {/* <span>{data && setCommas(data.price)}</span>원 */}
                                        </div>
                                    </div>
                                    <div className="PaymentResult-Item d-flex px-2">
                                        <div className="tit w-25">
                                            결제일자
                                        </div>
                                        <div className="price text-end w-75">
                                            2022-12-27 11:50
                                            {/* {data && data.orderedAt} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Payment-Confirm Inner mb-4">
                        <Link to={"/demo/paymentlist"} className="btn btn-default">
                            결제 내역
                        </Link>
                        {/* 비회원시 숨김처리 */}
                        <Link to={"/demo/qrwallet"} className="btn btn-primary">
                            QR 지갑
                        </Link>
                    </div>

                    <div className="PaymentResult-infotxt Inner text-center my-4-5">
                        <p className="mb-2">
                            비회원 구매 하신 분에게는 <br />
                            식권이 카카오 알림톡으로 발송됩니다.
                        </p>
                        <p className="mb-0">
                            알림톡을 수신하지 못하신 경우, <br />
                            고객센터(051-621-0203)로 연락 바랍니다.
                        </p>
                    </div>


                </div>
            </div>

            </div>
        </>
    )
}