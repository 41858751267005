import { useNavigate } from "react-router-dom";
import AdminHeader from "../../UserMain/components/AdminHeader";
import "./PopupAdd.css";
import { useReducer, useState } from "react";
import {
    initialState,
    popupReducer,
} from "../../../reducers/popupAdd/popupAddReducer";
import Datepicker from "../../../components/common/Datepicker";
import { getFormatDate } from "../../../function/common";
import { usePopupAdd } from "../../../hooks/popup/usePopupAdd";
import {
    initPopupModalState,
    popupModalReducer,
} from "../../../reducers/popupAdd/popupModalReducer";
import { Button, Modal } from "react-bootstrap";
import LoadingPortal from "../../../portals/LoadingPortal";
import LoadingModal from "../../../components/loadingmodal/LoadingModal";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

function resize({ target }) {
    target.style.height = "1px";

    if (target.name === "common") {
        if (window.innerWidth >= 599) {
            target.style.height =
                target.scrollHeight <= 134
                    ? 134 + "px"
                    : target.scrollHeight + "px";
        } else {
            target.style.height =
                target.scrollHeight <= 106
                    ? 106 + "px"
                    : target.scrollHeight + "px";
        }
        return;
    }

    if (target.name === "additionalDesc") {
        if (window.innerWidth >= 599) {
            target.style.height =
                target.scrollHeight <= 134
                    ? 134 + "px"
                    : target.scrollHeight + "px";
        } else {
            target.style.height =
                target.scrollHeight <= 106
                    ? 106 + "px"
                    : target.scrollHeight + "px";
        }
        return;
    }

    if (window.innerWidth >= 599) {
        target.style.height =
            target.scrollHeight <= 168
                ? 168 + "px"
                : target.scrollHeight + "px";
    } else {
        target.style.height =
            target.scrollHeight <= 134
                ? 134 + "px"
                : target.scrollHeight + "px";
    }
}

export default function PopupAdd() {
    const navigate = useNavigate();
    const [image, setImage] = useState({
        name: "",
        formData: "",
        isFlag: false,
    });
    const [popupState, popupDispatch] = useReducer(popupReducer, initialState);
    const [eventDate, setEventDate] = useState({
        startDate: getFormatDate(),
        endDate: getFormatDate(),
    });
    const { mutate, isLoading } = usePopupAdd();
    const [popupModalState, popupModalStateDispatch] = useReducer(
        popupModalReducer,
        initPopupModalState
    );

    const validatePopupInfo = (data) => {
        const { storeTitle, popupImageType, title, useButton, buttonText } =
            data;

        if (!storeTitle) {
            popupModalStateDispatch({ type: "warning_title" });
            return true;
        }

        if (popupImageType === "NONE") {
            popupModalStateDispatch({ type: "warning_image" });
            return true;
        }

        if (popupImageType === "MANUAL" && !image.isFlag) {
            popupModalStateDispatch({ type: "warning_image_detail" });
            return true;
        }

        if (!title) {
            popupModalStateDispatch({ type: "warning_popup_title" });
            return true;
        }

        if (useButton && !buttonText) {
            popupModalStateDispatch({ type: "warning_buttonName" });
            return true;
        }

        return false;
    };

    const handleImageUpload = ({ target }) => {
        const popupImg = target.files[0];
        const name = popupImg.name;
        const formData = new FormData();
        formData.append("images", popupImg);

        setImage((prev) => ({
            ...prev,
            formData,
            name,
            isFlag: true,
        }));

        target.value = "";
    };

    const handleImageRemoveClick = () => {
        setImage((prev) => ({
            ...prev,
            name: "",
            isFlag: false,
        }));
    };

    const handleRegisterClick = () => {
        if (isLoading) {
            return;
        }

        const {
            storeTitle,
            popupImageType,
            title,
            subTitle,
            buttonStatus,
            buttonText,
            buttonLink,
        } = popupState;
        const { startDate, endDate } = eventDate;

        const data = {
            startDate,
            endDate,
            storeTitle,
            popupImageType,
            title,
            subTitle,
            useButton: buttonStatus === "TRUE",
            buttonText,
            buttonLink,
            imageName: image.isFlag ? image.name : "",
        };

        if (validatePopupInfo(data)) {
            return;
        }

        mutate({ data, image });
    };

    return (
        <>
            <div className="demo">
                <div className="TPopupAddPage">
                    <div className="container-fluid g-0">
                        <nav className="sub-navbar">
                            <p className="navbar-title">팝업 생성</p>
                            <button
                                type="button"
                                className="btn-clear btn-back"
                                onClick={() => navigate("/storemain")}
                            >
                                <img
                                    className="img-fluid"
                                    alt="뒤로가기"
                                    src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                                />
                            </button>
                            <button
                                className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasNavbar"
                                aria-controls="offcanvasNavbar"
                            >
                                <img
                                    className="img-fluid"
                                    alt="메뉴"
                                    src="https://www.flexdaycdn.net/public/images/ticket/menu3.png"
                                />
                            </button>
                            <AdminHeader />
                        </nav>

                        <div className="PopupGuide">
                            <div className="Guide-wrap">
                                <div className="Guide-Box">
                                    <div className="txt">
                                        <span className="small">①</span>번<br />
                                        영역
                                    </div>
                                    <div className="space1">
                                        강조하실 문구를
                                        <br />
                                        작성해주세요!
                                    </div>
                                </div>
                                <div className="Guide-Box">
                                    <div className="txt">
                                        <span className="small">②</span>번<br />
                                        영역
                                    </div>
                                    <div className="space2">
                                        상세 설명을 작성해주세요.
                                        <br />
                                        날짜 기입도 가능합니다.
                                        <br />
                                        팝업 문구는 최대 3줄까지 노출됩니다.
                                    </div>
                                </div>
                            </div>
                            <Swiper
                                slidesPerView={1.2}
                                spaceBetween={25}
                                centeredSlides={true}
                                pagination={{
                                    el: ".Guide-pag",
                                    type: "fraction",
                                }}
                                modules={[Pagination, Navigation]}
                                className="Guide-Slider"
                            >
                                {
                                    <>
                                        <SwiperSlide className="slide1">
                                            <div className="tit">
                                                <span className="color">
                                                    강조하실 문구
                                                </span>
                                                를
                                                <br />
                                                작성해주세요!
                                            </div>
                                            <div className="sub">
                                                상세 설명을 작성해주세요.
                                                <br />
                                                날짜 기입도 가능합니다.
                                                <br />
                                                팝업 문구는 최대 3줄까지
                                                노출됩니다.
                                            </div>
                                            <div className="button">버튼명</div>
                                        </SwiperSlide>
                                        <SwiperSlide className="slide2">
                                            <div className="tit">
                                                <span className="color">
                                                    강조하실 문구
                                                </span>
                                                를
                                                <br />
                                                작성해주세요!
                                            </div>
                                            <div className="sub">
                                                상세 설명을 작성해주세요.
                                                <br />
                                                날짜 기입도 가능합니다.
                                                <br />
                                                팝업 문구는 최대 3줄까지
                                                노출됩니다.
                                            </div>
                                            <div className="button">버튼명</div>
                                        </SwiperSlide>
                                    </>
                                }
                            </Swiper>
                            <div className="swiper-pagination Guide-pag"></div>
                        </div>

                        <div className="PopupAddBox py-4-5 px-4-5">
                            <div className="PopupAdd-Form">
                                <div className="PopupAdd-Formitem pb-5">
                                    <p className="PopupAdd-tit mb-0">
                                        1. 관리자용 팝업 제목<span>*</span>
                                    </p>
                                    <p className="PopupAdd-sub">
                                        사용자에게는 표시되지 않습니다.
                                    </p>
                                    <div className="PopupAdd-inputbox">
                                        <input
                                            type="text"
                                            className="form-control InputType"
                                            placeholder="예) 인제페이 출시 안내"
                                            onChange={({ target }) =>
                                                popupDispatch({
                                                    type: "changed_storeTitle",
                                                    payload: target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="PopupAdd-Formitem pb-5">
                                    <p className="PopupAdd-tit">
                                        2. 팝업 노출 기간<span>*</span>
                                    </p>
                                    <Datepicker
                                        type="popupAdd"
                                        eventDate={eventDate}
                                        setEventDate={setEventDate}
                                    />
                                </div>

                                <div className="PopupAdd-Formitem pb-5">
                                    <p className="PopupAdd-tit">
                                        3. 팝업 배경 이미지<span>*</span>
                                    </p>
                                    <div className="PopupAdd-radiobox d-flex ver2">
                                        <div className="radioBox">
                                            <input
                                                className="form-check-input"
                                                id="PrdOption1-1"
                                                type="radio"
                                                value=""
                                                name="PrdOption1"
                                                onChange={() =>
                                                    popupDispatch({
                                                        type: "changed_popupImageType",
                                                        payload: "DEFAULT1",
                                                    })
                                                }
                                                defaultChecked={
                                                    popupState.popupImageType ===
                                                    "DEFAULT1"
                                                }
                                            />
                                            <label
                                                className="RadioLabel"
                                                htmlFor="PrdOption1-1"
                                            >
                                                <span>기본 이미지 1</span>
                                            </label>
                                        </div>
                                        <div className="radioBox">
                                            <input
                                                className="form-check-input"
                                                id="PrdOption1-2"
                                                type="radio"
                                                value=""
                                                name="PrdOption1"
                                                onChange={() =>
                                                    popupDispatch({
                                                        type: "changed_popupImageType",
                                                        payload: "DEFAULT2",
                                                    })
                                                }
                                                defaultChecked={
                                                    popupState.popupImageType ===
                                                    "DEFAULT2"
                                                }
                                            />
                                            <label
                                                className="RadioLabel"
                                                htmlFor="PrdOption1-2"
                                            >
                                                <span>기본 이미지 2</span>
                                            </label>
                                        </div>
                                        <div className="radioBox">
                                            <input
                                                className="form-check-input"
                                                id="PrdOption1-3"
                                                type="radio"
                                                value=""
                                                name="PrdOption1"
                                                onChange={() =>
                                                    popupDispatch({
                                                        type: "changed_popupImageType",
                                                        payload: "MANUAL",
                                                    })
                                                }
                                                defaultChecked={
                                                    popupState.popupImageType ===
                                                    "MANUAL"
                                                }
                                            />
                                            <label
                                                className="RadioLabel"
                                                htmlFor="PrdOption1-3"
                                            >
                                                <span>직접 등록</span>
                                            </label>
                                        </div>
                                        {popupState.popupImageType ===
                                            "MANUAL" && (
                                            <div className="Pop-filebox show">
                                                <input
                                                    type="file"
                                                    id="pop_file"
                                                    className="Pop-File d-none"
                                                    onChange={handleImageUpload}
                                                    accept=".jpg, .jpeg, .png"
                                                />
                                                <label
                                                    htmlFor="pop_file"
                                                    className={`form-control InputType Pop-File-Label ${
                                                        image.isFlag ? "on" : ""
                                                    }`}
                                                >
                                                    <img
                                                        className="img-fluid file_icon"
                                                        alt=""
                                                        src="https://www.flexdaycdn.net/public/images/ticket_ver2/file_icon.png"
                                                    />
                                                    <span className="file_text">
                                                        {image.name ||
                                                            "첨부파일을 등록해주세요. (.jpg, .jpeg, .png 파일 가능)"}
                                                    </span>
                                                    <button
                                                        className="btn btn-clear file_delete"
                                                        onClick={() =>
                                                            handleImageRemoveClick()
                                                        }
                                                    >
                                                        <img
                                                            className="img-fluid"
                                                            alt=""
                                                            src="https://www.flexdaycdn.net/public/images/ticket_ver2/file_delete.png"
                                                        />
                                                    </button>
                                                </label>
                                                <p className="PopupAdd-sub mt-2 mb-0">
                                                    이미지 업로드 최대 용량은
                                                    5mb입니다.
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="PopupAdd-Formitem pb-5">
                                    <p className="PopupAdd-tit">
                                        4. 팝업 타이틀<span>*</span>
                                    </p>
                                    <div className="PopupAdd-inputbox Input-placeholderbox">
                                        {!popupState.title && (
                                            <div className="Input-placeholder">
                                                <span className="small">①</span>
                                                번 영역에 들어갈 내용을 입력한
                                                후,
                                                <br />
                                                컬러가 필요한 문구는 {} 괄호안에
                                                작성해주세요.
                                                <br />
                                                (줄바꿈 필요 시, Enter입력)
                                            </div>
                                        )}
                                        <textarea
                                            className="form-control ph InputType"
                                            rows="3"
                                            onChange={({ target }) =>
                                                popupDispatch({
                                                    type: "changed_title",
                                                    payload: target.value,
                                                })
                                            }
                                            onKeyDown={resize}
                                            onKeyUp={resize}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="PopupAdd-Formitem pb-5">
                                    <p className="PopupAdd-tit">
                                        5. 팝업 서브 타이틀
                                    </p>
                                    <div className="PopupAdd-inputbox Input-placeholderbox">
                                        {!popupState.subTitle && (
                                            <div className="Input-placeholder">
                                                <span className="small">②</span>
                                                번 영역에 들어갈 내용을
                                                입력해주세요.
                                                <br />
                                                (줄바꿈 필요 시, Enter입력)
                                            </div>
                                        )}
                                        <textarea
                                            className="form-control ph InputType ver2"
                                            rows="2"
                                            onChange={({ target }) =>
                                                popupDispatch({
                                                    type: "changed_subTitle",
                                                    payload: target.value,
                                                })
                                            }
                                            onKeyDown={resize}
                                            onKeyUp={resize}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="PopupAdd-Formitem pb-5">
                                    <p className="PopupAdd-tit mb-0">
                                        6. 팝업 버튼
                                    </p>
                                    <p className="PopupAdd-sub">
                                        링크 이동이 필요한 경우, 버튼에 url을
                                        삽입할 수 있습니다.
                                    </p>
                                    <div className="PopupAdd-radiobox d-flex">
                                        <div className="radioBox">
                                            <input
                                                className="form-check-input 
                              prdLimited"
                                                onChange={() =>
                                                    popupDispatch({
                                                        type: "changed_buttonStatus",
                                                        payload: "TRUE",
                                                    })
                                                }
                                                id="PrdOption2-1"
                                                type="radio"
                                                value=""
                                                name="PrdOption2"
                                                defaultChecked={
                                                    popupState.buttonStatus ===
                                                    "TRUE"
                                                }
                                            />
                                            <label
                                                className="RadioLabel"
                                                htmlFor="PrdOption2-1"
                                            >
                                                <span>사용하기</span>
                                            </label>
                                        </div>
                                        <div className="radioBox">
                                            <input
                                                className="form-check-input"
                                                onChange={() =>
                                                    popupDispatch({
                                                        type: "changed_buttonStatus",
                                                        payload: "FALSE",
                                                    })
                                                }
                                                id="PrdOption2-2"
                                                type="radio"
                                                value=""
                                                name="PrdOption2"
                                                defaultChecked={
                                                    popupState.buttonStatus ===
                                                    "FALSE"
                                                }
                                            />
                                            <label
                                                className="RadioLabel"
                                                htmlFor="PrdOption2-2"
                                            >
                                                <span>사용안함</span>
                                            </label>
                                        </div>
                                    </div>
                                    {popupState.buttonStatus === "TRUE" && (
                                        <div className="PopupAdd-subbox prdLimited-box active">
                                            <div className="subBox">
                                                <p className="tit">
                                                    버튼명<span>*</span>
                                                </p>
                                                <div className="PopupAdd-inputbox g-0 position-relative">
                                                    <input
                                                        type="text"
                                                        pattern="\d*"
                                                        className="form-control InputType"
                                                        placeholder="예) 애플 스토어 바로가기"
                                                        onChange={({
                                                            target,
                                                        }) =>
                                                            popupDispatch({
                                                                type: "changed_buttonText",
                                                                payload:
                                                                    target.value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="subBox">
                                                <p className="tit">링크 연결</p>
                                                <div className="PopupAdd-inputbox g-0 position-relative">
                                                    <input
                                                        type="text"
                                                        pattern="\d*"
                                                        className="form-control InputType"
                                                        placeholder="연결할 url 링크를 입력해주세요."
                                                        onChange={({
                                                            target,
                                                        }) =>
                                                            popupDispatch({
                                                                type: "changed_buttonLink",
                                                                payload:
                                                                    target.value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="PopupAdd-Btnbox">
                                <button
                                    type="button"
                                    className="btn btn-default"
                                    onClick={handleRegisterClick}
                                >
                                    등록
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && (
                <LoadingPortal>
                    <LoadingModal type="black" />
                </LoadingPortal>
            )}
            <Modal
                show={popupModalState.isShowing}
                onHide={() => popupModalStateDispatch({ type: "hide_modal" })}
                keyboard={false}
                size="sm"
                centered
                className="demoModal modal-prdAdd text-center"
            >
                <Modal.Header>
                    <Modal.Title>{popupModalState.text}</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button
                        variant="modal-primary"
                        className="btn-clear w-100"
                        onClick={() =>
                            popupModalStateDispatch({ type: "hide_modal" })
                        }
                    >
                        확인
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
