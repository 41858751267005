import { apiClient } from "../../api/apiClient";
import { useFetch } from "../common/useFetch";

export default function usePopupDetail({ popupId, popupStateDispatch }) {
    useFetch(
        [],
        async () => {
            const { data } = await apiClient({
                method: "get",
                url: `/popups/${popupId}`,
            });

            return data;
        },
        {
            onSuccess: (data) => {
                popupStateDispatch({ type: "inited_popupInfo", payload: data });
            },
        }
    );
}
