import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSaleMenuDetail from "../../../hooks/useSaleMenuDetail";
import "./SaleMenuModify.css";
import SaleMenuModifyQuantity from "../../../components/salemenu/salemenumodifyquantity/SaleMenuModifyQuantity";
import useSaleMenuModify from "../../../hooks/useSaleMenuModify";
import {
  getDeepCopy,
  getFormatDate,
  getFormatTimeByHour,
  validateSaleMenu,
} from "../../../function/common";
import { Button, Form, Modal } from "react-bootstrap";
import AdminHeader from "../../UserMain/components/AdminHeader";
import useRemoveBodyStyle from "../../../hooks/common/useRemoveBodyStyle";
import LoadingPortal from "../../../portals/LoadingPortal";
import LoadingModal from "../../../components/loadingmodal/LoadingModal";
import { useReducer } from "react";
import {
  getThisWeekDate,
  initialState,
  saleMenuModifyReducer,
  nextInitialState,
  saleMenuModifyNextReducer,
} from "../../../reducers/saleMenuModify/saleMenuModfiyReducer";
import {
  getNextWeekDateISO,
  getThisWeekDateISO,
  getThisWeekDateMenuText,
} from "../../../reducers/saleMenuAdd/saleMenuAddReducer";
import { useRef } from "react";
import { useAppSelector } from "../../../store";
import { STORE_DEFAULT } from "../../../components/constants/CommonConstants";
import { MENU_STATUS } from "../../../components/constants/CommonConstants";
import { DESC_STATUS } from "../../../components/constants/CommonConstants";
import { isDefaultStore } from "../../../function/common";
function resize({ target }) {
  target.style.height = "1px";

  if (target.name === "common") {
    if (window.innerWidth >= 599) {
      target.style.height =
        target.scrollHeight <= 134 ? 134 + "px" : target.scrollHeight + "px";
    } else {
      target.style.height =
        target.scrollHeight <= 106 ? 106 + "px" : target.scrollHeight + "px";
    }
    return;
  }

  if (target.name === "additionalDesc") {
    if (window.innerWidth >= 599) {
      target.style.height =
        target.scrollHeight <= 134 ? 134 + "px" : target.scrollHeight + "px";
    } else {
      target.style.height =
        target.scrollHeight <= 106 ? 106 + "px" : target.scrollHeight + "px";
    }
    return;
  }

  if (window.innerWidth >= 599) {
    target.style.height =
      target.scrollHeight <= 168 ? 168 + "px" : target.scrollHeight + "px";
  } else {
    target.style.height =
      target.scrollHeight <= 134 ? 134 + "px" : target.scrollHeight + "px";
  }
}

function getDescStatusByCode(code) {
  if (code == 1) {
    return DESC_STATUS.COMMON;
  } else if (code == 2) {
    return DESC_STATUS.DATE;
  } else {
    return DESC_STATUS.NONE;
  }
}

const MIN_PRICE = 300;

export default function SaleMenuModify() {
  useRemoveBodyStyle();
  const navigate = useNavigate();

  const storeType = useAppSelector((state) => {
    return state.storeReducer.storeType;
  });

  useEffect(() => {
    // do stuff
  }, [storeType]);

  const { productId } = useParams();
  const [eventDate, setEventDate] = useState({
    startDate: getFormatDate(),
    endDate: getFormatDate(),
  });
  const [menuDetail, setMenuDetail] = useState();
  const { data, isSaleMenuLoading } = useSaleMenuDetail(productId);
  const [errorText, setErrorText] = useState("");
  const [isRemoveModalShowing, setIsRemoveModalShowing] = useState(false);
  const { mutate, isLoading } = useSaleMenuModify(
    productId,
    setErrorText,
    setIsRemoveModalShowing
  );
  const [eventTime, setEventTime] = useState({
    startTime: "00:00",
    endTime: "23:30",
  });

  const [smartOrderTime, setSmartOrderTime] = useState({
    startTime: "00:00",
    endTime: "23:30",
  });
  const [isTime, setIsTime] = useState();
  const [descStatus, setDescStatus] = useState(DESC_STATUS.NONE);
  const [menuDesc, setMenuDesc] = useState();
  const [dayMenuDescState, dayMenuDescStateDispatch] = useReducer(
    saleMenuModifyReducer,
    initialState
  );

  const [nextDayMenuDescState, nextDayMenuDescStateDispatch] = useReducer(
    saleMenuModifyNextReducer,
    nextInitialState
  );
  const commonInputRef = useRef();
  const additionalInputRef = useRef();

  useEffect(() => {
    if (
      commonInputRef.current !== undefined &&
      commonInputRef.current !== null
    ) {
      commonInputRef.current.style.height = `${
        commonInputRef.current.scrollHeight || 134
      }px`;
    }

    if (
      additionalInputRef.current !== undefined &&
      additionalInputRef.current !== null
    ) {
      additionalInputRef.current.style.height = `${
        additionalInputRef.current.scrollHeight || 134
      }px`;
    }
  }, [
    commonInputRef,
    additionalInputRef,
    dayMenuDescState,
    nextDayMenuDescState,
  ]);

  useEffect(() => {
    if (
      new Date(eventDate.startDate).getTime() ===
      new Date(eventDate.endDate).getTime()
    ) {
      setIsTime(true);
      return;
    }

    setIsTime(false);
  }, [eventDate]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data.canUseLine === "ALL_LINE") {
      setMenuDetail(
        getDeepCopy({
          ...data,
          canUseLine: { name: data.canUseLine, value: "전체" },
          additionalDesc: data.productDescription,
        })
      );
    } else if (data.canUseLine === "A_LINE") {
      setMenuDetail(
        getDeepCopy({
          ...data,
          canUseLine: { name: data.canUseLine, value: "태블릿A" },
          additionalDesc: data.productDescription,
        })
      );
    } else if (data.canUseLine === "B_LINE") {
      setMenuDetail(
        getDeepCopy({
          ...data,
          canUseLine: { name: data.canUseLine, value: "태블릿B" },
          additionalDesc: data.productDescription,
        })
      );
    } else if (data.canUseLine === "C_LINE") {
      setMenuDetail(
        getDeepCopy({
          ...data,
          canUseLine: { name: data.canUseLine, value: "태블릿C" },
          additionalDesc: data.productDescription,
        })
      );
    }

    setDescStatus(getDescStatusByCode(data.descriptionType.code));
    if (
      (data.descriptionType && data.descriptionType.code === 1) ||
      data.descriptionType.code === 3
    ) {
      setMenuDesc(data.toDayDescription);
      dayMenuDescStateDispatch({
        type: "inited_menuDesc",
        payload: [
          {
            date: getThisWeekDateISO(1),
            dateOfTheWeek: "월",
            detailDescription: "",
          },
          {
            date: getThisWeekDateISO(2),
            dateOfTheWeek: "화",
            detailDescription: "",
          },
          {
            date: getThisWeekDateISO(3),
            dateOfTheWeek: "수",
            detailDescription: "",
          },
          {
            date: getThisWeekDateISO(4),
            dateOfTheWeek: "목",
            detailDescription: "",
          },
          {
            date: getThisWeekDateISO(5),
            dateOfTheWeek: "금",
            detailDescription: "",
          },
        ],
      });
      nextDayMenuDescStateDispatch({
        type: "inited_menuDesc",
        payload: [
          {
            date: getNextWeekDateISO(1),
            dateOfTheWeek: "월",
            detailDescription: "",
          },
          {
            date: getNextWeekDateISO(2),
            dateOfTheWeek: "화",
            detailDescription: "",
          },
          {
            date: getNextWeekDateISO(3),
            dateOfTheWeek: "수",
            detailDescription: "",
          },
          {
            date: getNextWeekDateISO(4),
            dateOfTheWeek: "목",
            detailDescription: "",
          },
          {
            date: getNextWeekDateISO(5),
            dateOfTheWeek: "금",
            detailDescription: "",
          },
        ],
      });
    } else if (data.descriptionType && data.descriptionType.code === 2) {
      if (data.productDayDescriptions.length > 0) {
        dayMenuDescStateDispatch({
          type: "inited_menuDesc",
          payload: data.productDayDescriptions,
        });
      } else {
        dayMenuDescStateDispatch({
          type: "inited_menuDesc",
          payload: [
            {
              date: getThisWeekDateISO(1),
              dateOfTheWeek: "월",
              detailDescription: "",
            },
            {
              date: getThisWeekDateISO(2),
              dateOfTheWeek: "화",
              detailDescription: "",
            },
            {
              date: getThisWeekDateISO(3),
              dateOfTheWeek: "수",
              detailDescription: "",
            },
            {
              date: getThisWeekDateISO(4),
              dateOfTheWeek: "목",
              detailDescription: "",
            },
            {
              date: getThisWeekDateISO(5),
              dateOfTheWeek: "금",
              detailDescription: "",
            },
          ],
        });
      }

      if (data.nextProductDayDescriptions.length > 0) {
        nextDayMenuDescStateDispatch({
          type: "inited_menuDesc",
          payload: data.nextProductDayDescriptions,
        });
      } else {
        nextDayMenuDescStateDispatch({
          type: "inited_menuDesc",
          payload: [
            {
              date: getNextWeekDateISO(1),
              dateOfTheWeek: "월",
              detailDescription: "",
            },
            {
              date: getNextWeekDateISO(2),
              dateOfTheWeek: "화",
              detailDescription: "",
            },
            {
              date: getNextWeekDateISO(3),
              dateOfTheWeek: "수",
              detailDescription: "",
            },
            {
              date: getNextWeekDateISO(4),
              dateOfTheWeek: "목",
              detailDescription: "",
            },
            {
              date: getNextWeekDateISO(5),
              dateOfTheWeek: "금",
              detailDescription: "",
            },
          ],
        });
      }
    }
    let savedTime = false;
    if (data.quantityLimit.useQuantityLimit) {
      savedTime = true;
      // setIsTime(true);
      setEventDate({
        startDate: data.quantityLimit.startDateOfQuantityLimit,
        endDate: data.quantityLimit.endDateOfQuantityLimit,
      });
      setEventTime({
        startTime: getFormatTimeByHour(
          data.quantityLimit.startTimeOfQuantityLimit
        ),
        endTime: getFormatTimeByHour(data.quantityLimit.endTimeOfQuantityLimit),
      });
      // return;
    }

    if (data.quantityLimit.smartOrderTimeLimit) {
      savedTime = true;
      // setIsTime(true);
      setEventDate({
        startDate: data.quantityLimit.startDateOfQuantityLimit,
        endDate: data.quantityLimit.endDateOfQuantityLimit,
      });
      setSmartOrderTime({
        startTime: getFormatTimeByHour(
          data.quantityLimit.startTimeOfSmartOrder
        ),
        endTime: getFormatTimeByHour(data.quantityLimit.endTimeOfSmartOrder),
      });
    }

    setIsTime(savedTime);
  }, [data]);

  if (isSaleMenuLoading) {
    return (
      <LoadingPortal>
        <LoadingModal />
      </LoadingPortal>
    );
  }

  if (!data || !menuDetail) {
    return null;
  }

  const handleRemoveModalShow = () => {
    setIsRemoveModalShowing(true);
  };

  const handleRemoveModalClose = () => {
    setIsRemoveModalShowing(false);
  };

  // 메뉴 종류 메인 변경
  const handleMainMenuChange = () => {
    setMenuDetail((prev) => {
      const copyed = getDeepCopy(prev);

      return { ...copyed, menuType: MENU_STATUS.MAIN };
    });
  };

  // 메뉴 종류 사이드 변경
  const handleSideMenuChange = () => {
    setMenuDetail((prev) => {
      const copyed = getDeepCopy(prev);

      return { ...copyed, menuType: MENU_STATUS.SIDE };
    });
  };

  // 메뉴 가격 변경
  const handleMenuPriceChange = ({ target }) => {
    if (parseInt(target.value) > 1000000) {
      return;
    }

    setMenuDetail((prev) => {
      const copyed = getDeepCopy(prev);

      return { ...copyed, price: parseInt(target.value) };
    });
  };

  // 메뉴 설명 변경
  const handleMenuDescChange = ({ target }) => {
    setMenuDetail((prev) => {
      const copyed = getDeepCopy(prev);

      return { ...copyed, productDescription: target.value };
    });
  };

  // 판매 상태 상품 변경
  const handleSaleStatusSaleChange = () => {
    setMenuDetail((prev) => {
      const copyed = getDeepCopy(prev);

      return {
        ...copyed,
        productStatus: {
          code: 1,
          name: "판매중",
          description: "판매중",
        },
      };
    });
  };

  // 판매 상태 중지 변경
  const handleSaleStatusSaleStopChange = () => {
    setMenuDetail((prev) => {
      const copyed = getDeepCopy(prev);

      return {
        ...copyed,
        productStatus: {
          code: 2,
          name: "판매중지",
          description: "판매중지",
        },
      };
    });
  };

  // 수량 제한 메뉴 보임
  const handleQuanityMenuChange = () => {
    setIsTime(true);

    setMenuDetail((prev) => {
      const copyed = getDeepCopy(prev);

      return {
        ...copyed,
        quantityLimit: {
          ...copyed.quantityLimit,
          countOfMaxSold: 0,
          endDateOfQuantityLimit: getFormatDate(),
          endTimeOfQuantityLimit: "00:00",
          startDateOfQuantityLimit: getFormatDate(),
          startTimeOfQuantityLimit: "00:00",
          useQuantityLimit: true,
        },
      };
    });
  };

  // 수량 제한 메뉴 안보임
  const handleNotQuanityMenuChange = () => {
    setIsTime(false);
    setMenuDetail((prev) => {
      const copyed = getDeepCopy(prev);

      return {
        ...copyed,
        quantityLimit: {
          countOfMaxSold: null,
          endDateOfQuantityLimit: null,
          endTimeOfQuantityLimit: null,
          startDateOfQuantityLimit: null,
          startTimeOfQuantityLimit: null,
          useQuantityLimit: false,
        },
      };
    });
  };

  // 메뉴 이름 변경
  const handleProductNameChange = ({ target }) => {
    setMenuDetail((prev) => {
      const copyed = getDeepCopy(prev);

      return {
        ...copyed,
        productName: target.value,
      };
    });
  };

  const getProductStatus = ({ code }) => {
    if (code === 1) {
      return "SALE";
    }

    return "SALE_STOPPED";
  };

  // 판매 메뉴 변경 api 요청
  const handleNewSaleMenuModifyClick = () => {
    if (isLoading) {
      return;
    }

    const {
      menuType,
      productName,
      price,
      productDescription,
      productStatus,
      canUseLine,
      toDayDescription,
      additionalDescription,
    } = menuDetail;
    const {
      countOfMaxSold,
      useQuantityLimit,
      useTimeLimit,
      dayPeriodLimit,
      userDayBuyLimit,
      daySellLimit,
      smartOrderTimeLimit,
      countOfUserDayBuy,
    } = menuDetail.quantityLimit;

    const modifiedSaleMenu = {
      menuType,
      productName,
      price,
      productDescription: productDescription,
      productStatus: getProductStatus(productStatus),
      toDayDescription: "",
      additionalDescription: additionalDescription,
      quantityLimit: {
        useQuantityLimit,
        countOfMaxSold,
        startDateOfQuantityLimit: eventDate.startDate,
        startTimeOfQuantityLimit: eventTime.startTime,
        endDateOfQuantityLimit: eventDate.endDate,
        endTimeOfQuantityLimit: eventTime.endTime,
        useTimeLimit: useTimeLimit,
        smartOrderTimeLimit: smartOrderTimeLimit,
        startTimeOfSmartOrder: smartOrderTime.startTime,
        endTimeOfSmartOrder: smartOrderTime.endTime,
        daySellLimit,
        dayPeriodLimit,
        userDayBuyLimit,
        countOfUserDayBuy,
      },
      descriptionType: descStatus,
      descriptionByDates:
        descStatus === DESC_STATUS.DATE ? dayMenuDescState : [],
      nextDescriptionByDates:
        descStatus === DESC_STATUS.DATE ? nextDayMenuDescState : [],
      canUseLine: canUseLine.name,
    };

    if (
      !validateSaleMenu({
        menuDetail,
        modifiedSaleMenu,
        setErrorText,
        setIsRemoveModalShowing,
      })
    ) {
      return;
    }

    mutate(modifiedSaleMenu);
  };

  // 메뉴 수량 제한
  const handleCountOfMaxSoldChange = ({ target }) => {
    if (parseInt(target.value) > 1000) {
      return;
    }

    setMenuDetail((prev) => {
      const copyed = getDeepCopy(prev);

      return {
        ...copyed,
        quantityLimit: {
          ...copyed.quantityLimit,
          countOfMaxSold: parseInt(target.value),
        },
      };
    });
  };

  // 1인 일일 구매 한도
  const handleCountOfUserDayBuyChange = ({ target }) => {
    if (parseInt(target.value) > 1000) {
      return;
    }

    setMenuDetail((prev) => {
      const copyed = getDeepCopy(prev);

      return {
        ...copyed,
        quantityLimit: {
          ...copyed.quantityLimit,
          countOfUserDayBuy: parseInt(target.value),
        },
      };
    });
  };

  const handleLineChange = ({ target }) => {
    setMenuDetail((prev) => {
      const copyed = getDeepCopy(prev);

      return { ...copyed, canUseLine: target.value };
    });
  };

  const handleLineClick = ({ target }) => {
    setMenuDetail((prev) => ({
      ...prev,
      canUseLine: {
        value: target.dataset.value,
        name: target.dataset.name,
      },
    }));
  };

  return (
    <>
      <div className="demo">
        <div className="TSalePrdAddPage">
          <div className="container-fluid g-0">
            <nav className="sub-navbar">
              <p className="navbar-title">판매 메뉴 수정</p>
              <button
                type="button"
                className="btn-clear btn-back"
                onClick={() => navigate("/salemenu")}
              >
                <img
                  className="img-fluid"
                  alt="뒤로가기"
                  src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                />
              </button>
              <button
                className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
              >
                <img
                  className="img-fluid"
                  alt="메뉴"
                  src="https://www.flexdaycdn.net/public/images/ticket/menu3.png"
                />
              </button>
              <AdminHeader />
            </nav>

            <div className="SalePrdAddBox py-4-5 px-4-5">
              <div className="SalePrdAdd-Form">
                <div className="SalePrdAdd-Formitem pb-5">
                  <p className="SalePrdAdd-tit">
                    1. 메뉴 종류<span>*</span>
                  </p>
                  <div className="SalePrdAdd-radiobox d-flex">
                    <div className="radioBox">
                      <input
                        className="form-check-input"
                        id="PrdOption1-1"
                        type="radio"
                        value=""
                        name="PrdOption1"
                        checked={menuDetail.menuType === MENU_STATUS.MAIN}
                        onChange={handleMainMenuChange}
                      />
                      <label className="RadioLabel" htmlFor="PrdOption1-1">
                        <span>메인</span>
                      </label>
                    </div>
                    <div className="radioBox">
                      <input
                        className="form-check-input"
                        id="PrdOption1-2"
                        type="radio"
                        value=""
                        name="PrdOption1"
                        checked={menuDetail.menuType === MENU_STATUS.SIDE}
                        onChange={handleSideMenuChange}
                      />
                      <label className="RadioLabel" htmlFor="PrdOption1-2">
                        <span>사이드</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="SalePrdAdd-Formitem pb-5">
                  <p className="SalePrdAdd-tit">
                    2. 메뉴 이름<span>*</span>
                  </p>
                  <div className="SalePrdAdd-inputbox">
                    <input
                      type="text"
                      className="form-control InputType"
                      placeholder="메뉴 이름을 입력해 주세요."
                      defaultValue={menuDetail.productName}
                      onChange={handleProductNameChange}
                    />
                  </div>
                </div>
                <div className="SalePrdAdd-Formitem pb-5">
                  <p className="SalePrdAdd-tit">
                    3. 메뉴 가격<span>*</span>
                  </p>
                  <div className="SalePrdAdd-inputbox g-0 position-relative">
                    <input
                      type="number"
                      pattern="\d*"
                      className={`form-control InputType PayPrice ${
                        menuDetail.price < MIN_PRICE && "active"
                      }`}
                      placeholder="0"
                      value={menuDetail.price}
                      defaultValue={menuDetail.price ? menuDetail.price : ""}
                      onChange={handleMenuPriceChange}
                      onWheel={(e) => e.target.blur()}
                    />
                    <div className="end-0 position-absolute price-txt top-0">
                      원
                    </div>
                    {/* <!-- 확인 : 1,000원 미만일 때 / className "active" 추가 --> */}
                    <div className="form-text info-txt">
                      최소 {MIN_PRICE.toLocaleString()}
                      원부터 메뉴 등록이 가능합니다.
                    </div>
                  </div>
                </div>
                <div className="SalePrdAdd-Formitem pb-5">
                  <p className="SalePrdAdd-tit">4. 메뉴 설명</p>
                  <div className="SalePrdAdd-radiobox d-flex mb-3">
                    <div className="radioBox">
                      <input
                        className="form-check-input prdDes1"
                        onChange={(val) => {
                          if (val.target.checked) {
                            setDescStatus(DESC_STATUS.COMMON);
                          } else {
                            setDescStatus(DESC_STATUS.NONE);
                          }
                        }}
                        id="PrdOption4-1"
                        type="checkbox"
                        checked={descStatus === DESC_STATUS.COMMON}
                        name="PrdOption4"
                      />
                      <label className="RadioLabel" htmlFor="PrdOption4-1">
                        <span>공통 입력</span>
                      </label>
                    </div>
                    <div className="radioBox">
                      <input
                        className="form-check-input prdDes2"
                        onChange={(val) => {
                          if (val.target.checked) {
                            setDescStatus(DESC_STATUS.DATE);
                          } else {
                            setDescStatus(DESC_STATUS.NONE);
                          }
                        }}
                        id="PrdOption4-2"
                        type="checkbox"
                        checked={descStatus === DESC_STATUS.DATE}
                        name="PrdOption4"
                      />
                      <label className="RadioLabel" htmlFor="PrdOption4-2">
                        <span>요일 별 입력</span>
                      </label>
                    </div>
                  </div>
                  {descStatus === DESC_STATUS.COMMON && (
                    <div className="SalePrdDes prdDes-box1 active">
                      <div className="SalePrdAdd-inputbox Input-placeholderbox">
                        {!menuDetail.productDescription && (
                          <div className="Input-placeholder">
                            메뉴에 대한 설명을 해주세요!
                            <br />
                            예) 뚝배기 갈비탕, 무우 잔파 생채, 양파 마늘쫑
                            장아찌
                          </div>
                        )}
                        <textarea
                          className="form-control ph InputType"
                          rows="2"
                          name="common"
                          style={{ height: "auto" }}
                          // defaultValue={menuDesc}
                          value={menuDetail.productDescription}
                          ref={commonInputRef}
                          onKeyDown={resize}
                          onKeyUp={resize}
                          onChange={handleMenuDescChange}
                        ></textarea>
                      </div>
                    </div>
                  )}
                  {descStatus === DESC_STATUS.DATE && (
                    <div className="SalePrdDes prdDes-box2 active">
                      <div
                        className="nav nav-tabs prdDes-tabs"
                        id="prdDes-tab"
                        role="tablist"
                      >
                        <button
                          className="nav-link active"
                          id="nav-home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-prdDes1"
                          type="button"
                          role="tab"
                        >
                          {getThisWeekDate(dayMenuDescState[0]?.date)}
                        </button>
                        <button
                          className="nav-link"
                          id="nav-profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-prdDes2"
                          type="button"
                          role="tab"
                        >
                          {getThisWeekDate(dayMenuDescState[1]?.date)}
                        </button>
                        <button
                          className="nav-link"
                          id="nav-contact-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-prdDes3"
                          type="button"
                          role="tab"
                        >
                          {getThisWeekDate(dayMenuDescState[2]?.date)}
                        </button>
                        <button
                          className="nav-link"
                          id="nav-profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-prdDes4"
                          type="button"
                          role="tab"
                        >
                          {getThisWeekDate(dayMenuDescState[3]?.date)}
                        </button>
                        <button
                          className="nav-link"
                          id="nav-contact-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-prdDes5"
                          type="button"
                          role="tab"
                        >
                          {getThisWeekDate(dayMenuDescState[4]?.date)}
                        </button>
                        <button
                          className="nav-link"
                          id="nav-home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-prdDes6"
                          type="button"
                          role="tab"
                        >
                          {getThisWeekDate(nextDayMenuDescState[0]?.date)}
                        </button>
                        <button
                          className="nav-link"
                          id="nav-profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-prdDes7"
                          type="button"
                          role="tab"
                        >
                          {getThisWeekDate(nextDayMenuDescState[1]?.date)}
                        </button>
                        <button
                          className="nav-link"
                          id="nav-contact-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-prdDes8"
                          type="button"
                          role="tab"
                        >
                          {getThisWeekDate(nextDayMenuDescState[2]?.date)}
                        </button>
                        <button
                          className="nav-link"
                          id="nav-profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-prdDes9"
                          type="button"
                          role="tab"
                        >
                          {getThisWeekDate(nextDayMenuDescState[3]?.date)}
                        </button>
                        <button
                          className="nav-link"
                          id="nav-contact-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-prdDes10"
                          type="button"
                          role="tab"
                        >
                          {getThisWeekDate(nextDayMenuDescState[4]?.date)}
                        </button>
                      </div>
                      <div
                        className="tab-content prdDes-content"
                        id="prdDes-tabContent"
                      >
                        <div
                          className="tab-pane show active"
                          id="nav-prdDes1"
                          role="tabpanel"
                        >
                          <div className="SalePrdAdd-inputbox Input-placeholderbox">
                            {!dayMenuDescState[0].detailDescription && (
                              <div className="Input-placeholder">
                                월요일 메뉴에 대한 설명을 입력해주세요.
                                <br />
                                작성된 내용은 모바일 앱 이번주 식단에서도 확인
                                됩니다.
                                <br />
                                예)뚝배기 갈비탕, 무우 잔파 생채, 양파 마늘종
                                장아찌
                              </div>
                            )}
                            <textarea
                              className="form-control ph InputType"
                              rows="3"
                              value={dayMenuDescState[0].detailDescription}
                              onChange={({ target }) =>
                                dayMenuDescStateDispatch({
                                  type: "changed_menuDesc",
                                  payload: {
                                    day: 0,
                                    value: target.value,
                                  },
                                })
                              }
                              onKeyDown={resize}
                              onKeyUp={resize}
                            ></textarea>
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="nav-prdDes2"
                          role="tabpanel"
                        >
                          <div className="SalePrdAdd-inputbox Input-placeholderbox">
                            {!dayMenuDescState[1].detailDescription && (
                              <div className="Input-placeholder">
                                화요일 메뉴에 대한 설명을 입력해주세요.
                                <br />
                                작성된 내용은 모바일 앱 이번주 식단에서도 확인
                                됩니다.
                                <br />
                                예)뚝배기 갈비탕, 무우 잔파 생채, 양파 마늘종
                                장아찌
                              </div>
                            )}
                            <textarea
                              className="form-control ph InputType"
                              rows="3"
                              value={dayMenuDescState[1].detailDescription}
                              onChange={({ target }) =>
                                dayMenuDescStateDispatch({
                                  type: "changed_menuDesc",
                                  payload: {
                                    day: 1,
                                    value: target.value,
                                  },
                                })
                              }
                              onKeyDown={resize}
                              onKeyUp={resize}
                            ></textarea>
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="nav-prdDes3"
                          role="tabpanel"
                        >
                          <div className="SalePrdAdd-inputbox Input-placeholderbox">
                            {!dayMenuDescState[2].detailDescription && (
                              <div className="Input-placeholder">
                                수요일 메뉴에 대한 설명을 입력해주세요.
                                <br />
                                작성된 내용은 모바일 앱 이번주 식단에서도 확인
                                됩니다.
                                <br />
                                예)뚝배기 갈비탕, 무우 잔파 생채, 양파 마늘종
                                장아찌
                              </div>
                            )}
                            <textarea
                              className="form-control ph InputType"
                              rows="3"
                              value={dayMenuDescState[2].detailDescription}
                              onChange={({ target }) =>
                                dayMenuDescStateDispatch({
                                  type: "changed_menuDesc",
                                  payload: {
                                    day: 2,
                                    value: target.value,
                                  },
                                })
                              }
                              onKeyDown={resize}
                              onKeyUp={resize}
                            ></textarea>
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="nav-prdDes4"
                          role="tabpanel"
                        >
                          <div className="SalePrdAdd-inputbox Input-placeholderbox">
                            {!dayMenuDescState[3].detailDescription && (
                              <div className="Input-placeholder">
                                목요일 메뉴에 대한 설명을 입력해주세요.
                                <br />
                                작성된 내용은 모바일 앱 이번주 식단에서도 확인
                                됩니다.
                                <br />
                                예)뚝배기 갈비탕, 무우 잔파 생채, 양파 마늘종
                                장아찌
                              </div>
                            )}
                            <textarea
                              className="form-control ph InputType"
                              rows="3"
                              value={dayMenuDescState[3].detailDescription}
                              onChange={({ target }) =>
                                dayMenuDescStateDispatch({
                                  type: "changed_menuDesc",
                                  payload: {
                                    day: 3,
                                    value: target.value,
                                  },
                                })
                              }
                              onKeyDown={resize}
                              onKeyUp={resize}
                            ></textarea>
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="nav-prdDes5"
                          role="tabpanel"
                        >
                          <div className="SalePrdAdd-inputbox Input-placeholderbox">
                            {!dayMenuDescState[4].detailDescription && (
                              <div className="Input-placeholder">
                                금요일 메뉴에 대한 설명을 입력해주세요.
                                <br />
                                작성된 내용은 모바일 앱 이번주 식단에서도 확인
                                됩니다.
                                <br />
                                예)뚝배기 갈비탕, 무우 잔파 생채, 양파 마늘종
                                장아찌
                              </div>
                            )}
                            <textarea
                              className="form-control ph InputType"
                              rows="3"
                              value={dayMenuDescState[4].detailDescription}
                              onChange={({ target }) =>
                                dayMenuDescStateDispatch({
                                  type: "changed_menuDesc",
                                  payload: {
                                    day: 4,
                                    value: target.value,
                                  },
                                })
                              }
                              onKeyDown={resize}
                              onKeyUp={resize}
                            ></textarea>
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="nav-prdDes6"
                          role="tabpanel"
                        >
                          <div className="SalePrdAdd-inputbox Input-placeholderbox">
                            {!nextDayMenuDescState[0].detailDescription && (
                              <div className="Input-placeholder">
                                월요일 메뉴에 대한 설명을 입력해주세요.
                                <br />
                                작성된 내용은 모바일 앱 이번주 식단에서도 확인
                                됩니다.
                                <br />
                                예)뚝배기 갈비탕, 무우 잔파 생채, 양파 마늘종
                                장아찌
                              </div>
                            )}
                            <textarea
                              className="form-control ph InputType"
                              rows="3"
                              value={nextDayMenuDescState[0].detailDescription}
                              onChange={({ target }) =>
                                nextDayMenuDescStateDispatch({
                                  type: "changed_menuDesc",
                                  payload: {
                                    day: 0,
                                    value: target.value,
                                  },
                                })
                              }
                              onKeyDown={resize}
                              onKeyUp={resize}
                            ></textarea>
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="nav-prdDes7"
                          role="tabpanel"
                        >
                          <div className="SalePrdAdd-inputbox Input-placeholderbox">
                            {!nextDayMenuDescState[1].detailDescription && (
                              <div className="Input-placeholder">
                                화요일 메뉴에 대한 설명을 입력해주세요.
                                <br />
                                작성된 내용은 모바일 앱 이번주 식단에서도 확인
                                됩니다.
                                <br />
                                예)뚝배기 갈비탕, 무우 잔파 생채, 양파 마늘종
                                장아찌
                              </div>
                            )}
                            <textarea
                              className="form-control ph InputType"
                              rows="3"
                              value={nextDayMenuDescState[1].detailDescription}
                              onChange={({ target }) =>
                                nextDayMenuDescStateDispatch({
                                  type: "changed_menuDesc",
                                  payload: {
                                    day: 1,
                                    value: target.value,
                                  },
                                })
                              }
                              onKeyDown={resize}
                              onKeyUp={resize}
                            ></textarea>
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="nav-prdDes8"
                          role="tabpanel"
                        >
                          <div className="SalePrdAdd-inputbox Input-placeholderbox">
                            {!nextDayMenuDescState[2].detailDescription && (
                              <div className="Input-placeholder">
                                수요일 메뉴에 대한 설명을 입력해주세요.
                                <br />
                                작성된 내용은 모바일 앱 이번주 식단에서도 확인
                                됩니다.
                                <br />
                                예)뚝배기 갈비탕, 무우 잔파 생채, 양파 마늘종
                                장아찌
                              </div>
                            )}
                            <textarea
                              className="form-control ph InputType"
                              rows="3"
                              value={nextDayMenuDescState[2].detailDescription}
                              onChange={({ target }) =>
                                nextDayMenuDescStateDispatch({
                                  type: "changed_menuDesc",
                                  payload: {
                                    day: 2,
                                    value: target.value,
                                  },
                                })
                              }
                              onKeyDown={resize}
                              onKeyUp={resize}
                            ></textarea>
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="nav-prdDes9"
                          role="tabpanel"
                        >
                          <div className="SalePrdAdd-inputbox Input-placeholderbox">
                            {!nextDayMenuDescState[3].detailDescription && (
                              <div className="Input-placeholder">
                                목요일 메뉴에 대한 설명을 입력해주세요.
                                <br />
                                작성된 내용은 모바일 앱 이번주 식단에서도 확인
                                됩니다.
                                <br />
                                예)뚝배기 갈비탕, 무우 잔파 생채, 양파 마늘종
                                장아찌
                              </div>
                            )}
                            <textarea
                              className="form-control ph InputType"
                              rows="3"
                              value={nextDayMenuDescState[3].detailDescription}
                              onChange={({ target }) =>
                                nextDayMenuDescStateDispatch({
                                  type: "changed_menuDesc",
                                  payload: {
                                    day: 3,
                                    value: target.value,
                                  },
                                })
                              }
                              onKeyDown={resize}
                              onKeyUp={resize}
                            ></textarea>
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="nav-prdDes10"
                          role="tabpanel"
                        >
                          <div className="SalePrdAdd-inputbox Input-placeholderbox">
                            {!nextDayMenuDescState[4].detailDescription && (
                              <div className="Input-placeholder">
                                금요일 메뉴에 대한 설명을 입력해주세요.
                                <br />
                                작성된 내용은 모바일 앱 이번주 식단에서도 확인
                                됩니다.
                                <br />
                                예)뚝배기 갈비탕, 무우 잔파 생채, 양파 마늘종
                                장아찌
                              </div>
                            )}
                            <textarea
                              className="form-control ph InputType"
                              rows="3"
                              value={nextDayMenuDescState[4].detailDescription}
                              onChange={({ target }) =>
                                nextDayMenuDescStateDispatch({
                                  type: "changed_menuDesc",
                                  payload: {
                                    day: 4,
                                    value: target.value,
                                  },
                                })
                              }
                              onKeyDown={resize}
                              onKeyUp={resize}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="SalePrdAdd-Formitem pb-5">
                  <p className="SalePrdAdd-tit">5. 부가 설명</p>
                  <div className="SalePrdAdd-inputbox Input-placeholderbox">
                    {!menuDetail.additionalDescription && (
                      <div className="Input-placeholder">
                        부가 설명이 있는 경우 입력해주세요.
                        <br />
                        예) 운영시간 11:00 ~ 14:00
                      </div>
                    )}
                    <textarea
                      className="form-control ph InputType"
                      rows="2"
                      name="productDescription"
                      defaultValue={menuDetail.additionalDescription}
                      onChange={({ target }) =>
                        setMenuDetail((prev) => ({
                          ...prev,
                          additionalDescription: target.value,
                        }))
                      }
                      onKeyDown={resize}
                      onKeyUp={resize}
                    ></textarea>
                  </div>
                </div>
                <div className="SalePrdAdd-Formitem pb-5">
                  <p className="SalePrdAdd-tit mb-0">
                    6. 메뉴 판매제한 설정<span>*</span>
                  </p>
                  {isDefaultStore(storeType) ? (
                    <p className="SalePrdAdd-sub">
                      1일 판매 수량, 판매 일자, 판매 시간, 1인 일일 구매 한도를
                      설정할 수 있습니다.
                    </p>
                  ) : (
                    <p className="SalePrdAdd-sub">
                      1일 판매 수량, 판매 일자, 판매 시간, 태블릿 주문 시간, 1인
                      일일 구매 한도를 설정할 수 있습니다.
                    </p>
                  )}
                  <div className="SalePrdAdd-radiobox d-flex">
                    <div className="radioBox">
                      <input
                        className="form-check-input prdLimited"
                        id="PrdOption2-1"
                        type="radio"
                        value=""
                        name="PrdOption2"
                        checked={menuDetail.quantityLimit.useQuantityLimit}
                        onChange={handleQuanityMenuChange}
                      />
                      <label className="RadioLabel" htmlFor="PrdOption2-1">
                        <span>사용하기</span>
                      </label>
                    </div>
                    <div className="radioBox">
                      <input
                        className="form-check-input"
                        id="PrdOption2-2"
                        type="radio"
                        value=""
                        name="PrdOption2"
                        checked={!menuDetail.quantityLimit.useQuantityLimit}
                        onChange={handleNotQuanityMenuChange}
                      />
                      <label className="RadioLabel" htmlFor="PrdOption2-2">
                        <span>사용안함</span>
                      </label>
                    </div>
                  </div>
                  {menuDetail.quantityLimit.useQuantityLimit && (
                    <SaleMenuModifyQuantity
                      menuDetail={menuDetail}
                      setMenuDetail={setMenuDetail}
                      onCountOfMaxSoldChange={handleCountOfMaxSoldChange}
                      onCountOfUserDayBuyChange={handleCountOfUserDayBuyChange}
                      eventDate={eventDate}
                      setEventDate={setEventDate}
                      eventTime={eventTime}
                      setEventTime={setEventTime}
                      setIsTime={setIsTime}
                      smartOrderTime={smartOrderTime}
                      setSmartOrderTime={setSmartOrderTime}
                    />
                  )}
                </div>
                <div className="SalePrdAdd-Formitem pb-5">
                  <p className="SalePrdAdd-tit">
                    7. 판매 상태<span>*</span>
                  </p>
                  <div className="SalePrdAdd-radiobox d-flex">
                    <div className="radioBox">
                      <input
                        className="form-check-input"
                        id="PrdOption3-1"
                        type="radio"
                        value=""
                        name="PrdOption3"
                        checked={menuDetail.productStatus.code === 1}
                        onChange={handleSaleStatusSaleChange}
                      />
                      <label className="RadioLabel" htmlFor="PrdOption3-1">
                        <span>판매 상품</span>
                      </label>
                    </div>
                    <div className="radioBox">
                      <input
                        className="form-check-input"
                        id="PrdOption3-2"
                        type="radio"
                        value=""
                        name="PrdOption3"
                        checked={menuDetail.productStatus.code === 2}
                        onChange={handleSaleStatusSaleStopChange}
                      />
                      <label className="RadioLabel" htmlFor="PrdOption3-2">
                        <span>판매 중지</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="SalePrdAdd-Formitem">
                  <p className="SalePrdAdd-tit">
                    8. 결제 가능 라인<span>*</span>
                  </p>
                  <div className="SalePrdAdd-Select">
                    <button
                      className="btn btn-clear selectBtn"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#SelectBox"
                    >
                      {menuDetail.canUseLine.value}
                    </button>
                    <div className="collapse" id="SelectBox">
                      <div className="selectInputbox">
                        <input
                          className="form-check-input"
                          id="SelectPay1"
                          type="radio"
                          checked={menuDetail.canUseLine.name === "ALL_LINE"}
                          readOnly
                        />
                        <label
                          className="RadioLabel"
                          htmlFor="SelectPay1"
                          data-bs-toggle="collapse"
                          data-bs-target="#SelectBox"
                          data-name="ALL_LINE"
                          data-value="전체"
                          onClick={handleLineClick}
                        >
                          전체
                        </label>
                      </div>
                      <div className="selectInputbox">
                        <input
                          className="form-check-input"
                          id="SelectPay2"
                          type="radio"
                          checked={menuDetail.canUseLine.name === "A_LINE"}
                          readOnly
                        />
                        <label
                          className="RadioLabel"
                          htmlFor="SelectPay2"
                          data-bs-toggle="collapse"
                          data-bs-target="#SelectBox"
                          data-name="A_LINE"
                          data-value="태블릿A"
                          onClick={handleLineClick}
                        >
                          태블릿A
                        </label>
                      </div>
                      <div className="selectInputbox">
                        <input
                          className="form-check-input"
                          id="SelectPay3"
                          type="radio"
                          name="SelectPay"
                          checked={menuDetail.canUseLine.name === "B_LINE"}
                          readOnly
                        />
                        <label
                          className="RadioLabel"
                          htmlFor="SelectPay3"
                          data-bs-toggle="collapse"
                          data-bs-target="#SelectBox"
                          data-name="B_LINE"
                          data-value="태블릿B"
                          onClick={handleLineClick}
                        >
                          태블릿B
                        </label>
                      </div>
                      <div className="selectInputbox">
                        <input
                          className="form-check-input"
                          id="SelectPay4"
                          type="radio"
                          name="SelectPay"
                          checked={menuDetail.canUseLine.name === "C_LINE"}
                          readOnly
                        />
                        <label
                          className="RadioLabel"
                          htmlFor="SelectPay4"
                          data-bs-toggle="collapse"
                          data-bs-target="#SelectBox"
                          data-name="C_LINE"
                          data-value="태블릿C"
                          onClick={handleLineClick}
                        >
                          태블릿C
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="SalePrdAdd-Btnbox">
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={handleNewSaleMenuModifyClick}
                >
                  등록
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={isRemoveModalShowing}
        onHide={handleRemoveModalShow}
        keyboard={false}
        size="sm"
        centered
        className="demoModal modal-prdAdd text-center"
      >
        <Modal.Header>
          <Modal.Title>{errorText}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="modal-primary"
            className="btn-clear w-100"
            onClick={handleRemoveModalClose}
          >
            확인
          </Button>
        </Modal.Footer>
      </Modal>
      {isLoading && (
        <LoadingPortal>
          <LoadingModal type="black" />
        </LoadingPortal>
      )}
    </>
  );
}
