import Datepicker from "../../common/Datepicker";

export default function SaleMenuModifyQuantity({
    menuDetail,
    setMenuDetail,
    onCountOfMaxSoldChange,
    onCountOfUserDayBuyChange,
    eventTime,
    setEventTime,
    eventDate,
    setEventDate,
    setIsTime,
    smartOrderTime,
    setSmartOrderTime,
    isDefaultStore
}) {
    return (
        <>
            <div
                className={`SalePrdAdd-subbox prdLimited-box ${
                    menuDetail?.quantityLimit?.useQuantityLimit && "active"
                }`}
            >
                <div className="subBox">
                    <input
                        className="form-check-input check"
                        type="checkbox"
                        checked={menuDetail?.quantityLimit?.daySellLimit}
                        onChange={() => {
                            setMenuDetail((prev) => ({
                                ...prev,
                                quantityLimit: {
                                    ...prev.quantityLimit,
                                    daySellLimit:
                                        !prev.quantityLimit.daySellLimit,
                                },
                            }));
                        }}
                    />
                    <div className="checkContent">
                        <p className="tit">1일 판매 수량</p>
                        <div className="SalePrdAdd-inputbox g-0 position-relative">
                            <input
                                type="number"
                                pattern="\d*"
                                className="form-control InputType PrdCount"
                                placeholder="0"
                                value={
                                    menuDetail?.quantityLimit?.countOfMaxSold
                                }
                                defaultValue={
                                    menuDetail?.quantityLimit?.countOfMaxSold
                                        ? menuDetail?.quantityLimit
                                              ?.countOfMaxSold
                                        : 0
                                }
                                onChange={onCountOfMaxSoldChange}
                                onWheel={(e) => e.target.blur()}
                            />
                            <div className="end-0 position-absolute price-txt top-0">
                                개
                            </div>
                        </div>
                    </div>
                </div>
                <div className="subBox date">
                    <input
                        className="form-check-input check"
                        type="checkbox"
                        checked={menuDetail?.quantityLimit?.dayPeriodLimit}
                        onChange={() => {
                            setMenuDetail((prev) => ({
                                ...prev,
                                quantityLimit: {
                                    ...prev.quantityLimit,
                                    dayPeriodLimit:
                                        !prev.quantityLimit.dayPeriodLimit,
                                },
                            }));
                        }}
                    />
                    <div className="checkContent">
                        <p className="tit">판매 일자 설정</p>
                        {menuDetail?.quantityLimit?.dayPeriodLimit && (
                            <div className="inputbox">
                                <div id="range" className="range d-flex">
                                    <Datepicker
                                        type="sale"
                                        eventDate={eventDate}
                                        setEventDate={setEventDate}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="subBox time">
                    <input
                        className="form-check-input check"
                        type="checkbox"
                        checked={menuDetail?.quantityLimit?.useTimeLimit}
                        onChange={() => {
                            setMenuDetail((prev) => {
                                return {
                                    ...prev,
                                    quantityLimit: {
                                        ...prev.quantityLimit,
                                        useTimeLimit:
                                            !prev.quantityLimit.useTimeLimit,
                                    },
                                };
                            });
                        }}
                    />
                    <div className="checkContent">
                        <p className="tit">판매 시간 설정</p>
                        <Datepicker
                            type="saleTime"
                            eventDate={eventDate}
                            setEventDate={setEventDate}
                            eventTime={eventTime}
                            setEventTime={setEventTime}
                        />
                    </div>
                </div>
                { isDefaultStore 
                ?
                    null 
                :
                <div className="subBox time">
                    <input
                        className="form-check-input check"
                        type="checkbox"
                        checked={menuDetail?.quantityLimit?.smartOrderTimeLimit}
                        onChange={() => {
                            setMenuDetail((prev) => {
                                return {
                                    ...prev,
                                    quantityLimit: {
                                        ...prev.quantityLimit,
                                        smartOrderTimeLimit: !prev.quantityLimit.smartOrderTimeLimit,
                                    },
                                };
                            });
                        }}
                    />
                    <div className="checkContent">
                        <p className="tit">(태블릿) 주문 가능 시간 설정</p>
                        <Datepicker
                            type="saleTime"
                            eventDate={eventDate}
                            setEventDate={setEventDate}
                            eventTime={smartOrderTime}
                            setEventTime={setSmartOrderTime}
                        />
                    </div>
                </div>
                }

                <div className="subBox mb-0">
                    <input
                        className="form-check-input check"
                        type="checkbox"
                        checked={menuDetail?.quantityLimit?.userDayBuyLimit}
                        onChange={() => {
                            setMenuDetail((prev) => ({
                                ...prev,
                                quantityLimit: {
                                    ...prev.quantityLimit,
                                    userDayBuyLimit:
                                        !prev.quantityLimit.userDayBuyLimit,
                                },
                            }));
                        }}
                    />
                    <div className="checkContent">
                        <p className="tit mb-2">1인 일일 구매 한도</p>
                        <div className="SalePrdAdd-inputbox g-0 position-relative">
                            <input
                                type="number"
                                pattern="\d*"
                                className="form-control InputType"
                                placeholder="0"
                                value={
                                    menuDetail?.quantityLimit?.countOfUserDayBuy
                                }
                                defaultValue={
                                    menuDetail?.quantityLimit?.countOfUserDayBuy
                                        ? menuDetail?.quantityLimit
                                              ?.countOfUserDayBuy
                                        : 0
                                }
                                onChange={onCountOfUserDayBuyChange}
                                onWheel={(e) => e.target.blur()}
                            />
                            <div className="end-0 position-absolute price-txt top-0">
                                개
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
