import { useEffect, useMemo } from "react";
import { Pagination } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { INIT_PAGENATION_SIZE } from "../constants/CommonConstants";
import { useState } from "react";
import { getPageParamsToURL } from "../../function/common";

const PageNation = (props)=> {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const queryPage = searchParams.get('page');
    const [page, setPage] = useState(queryPage != null ? queryPage : 0);
    

    const [totalPage, setTotalPage] = useState(props.totalPage || 1 );
    
    useEffect(() => {
        setPage(queryPage != null ? queryPage : 0);
      }, [queryPage]);

    useEffect(() => {
    setTotalPage(props.totalPage || 1);
    }, [props.totalPage]);


    useEffect(() => {
        // const searchParams = new URLSearchParams(location.search);
        console.log(page, searchParams.get('page'));
        
        
        // searchParams.set('page', page);
    
        if(props.callBack){

            const paramsObject = Object.fromEntries(searchParams.entries());
            paramsObject.page = Number(page);
            
            props.callBack(paramsObject);
        }
    }, [page]);



    const currentPageIndexList = useMemo(() => {
        
        const pageIndexList = Array.from(
            {
                length: totalPage,
            },
            (_, index) => index
        );
        const firstPage = page - (page % INIT_PAGENATION_SIZE);
        const lastPage = page - (page % INIT_PAGENATION_SIZE) + INIT_PAGENATION_SIZE;

        return pageIndexList.slice(firstPage, lastPage);
    }, [page, totalPage]);

    const mapedTotalPageArray = currentPageIndexList.map((pageIndex) => {
        return (
            <Pagination.Item
                key={pageIndex}
                className={pageIndex === parseInt(page) ? "page on" : "page"}
                onClick={() => {
                    sessionStorage.setItem("page", pageIndex);
                    setPage(pageIndex);
                }}
            >
                {pageIndex + 1}
            </Pagination.Item>
        );
    });

    return (
        <Pagination className="align-items-center">
            <Pagination.First
                className="PaginationFirst"
                onClick={() => {
                    // if (0 === getPageParamsToURL(location)) {
                    //     return;
                    // }
                    setPage(0);

                    
                }}
            />
            <Pagination.Prev
                className="PaginationPrev"
                onClick={() => {
                    const movePage = page - 1;

                    if (movePage === -1) {
                        return;
                    }
                    setPage(movePage - 1);

                }}
            />
            {mapedTotalPageArray}
            <Pagination.Next
                className="PaginationNext"
                onClick={() => {
                    const movePage = parseInt(page) + 1;

                    if (movePage === totalPage) {
                        return;
                    }
                    setPage(movePage);
                }}
            />
            <Pagination.Last
                className="PaginationLast"
                onClick={() => {
                    // if (totalPage - 1 === getPageParamsToURL(location)) {
                    //     return;
                    // }

                    setPage(totalPage - 1);                    
                }}
            />
        </Pagination>
    );
}


export default PageNation;