import { useNavigate } from "react-router-dom";

export default function PopupList({ popups, setPopupRemoveId }) {
    const navigate = useNavigate();

    const mapedPopups = popups.map((popup) => {
        const { popupId, startDate, endDate, storeTitle, title, subTitle } =
            popup;

        const getPopupStatusText = (startDate, endDate) => {
            if (
                new Date(`${startDate} 00:00:00`) <= new Date() &&
                new Date() <= new Date(`${endDate} 23:59:59`)
            ) {
                return "진행중";
            } else if (new Date(`${startDate} 00:00:00`) > new Date()) {
                const today = new Date();
                const diffDate =
                    new Date(`${startDate} 00:00:00`).getTime() -
                    new Date(
                        `${today.getFullYear()}-${
                            today.getMonth() + 1
                        }-${today.getDate()}`
                    ).getTime();

                return `D-${Math.abs(diffDate / (1000 * 60 * 60 * 24))}`;
            } else if (new Date(`${endDate} 23:59:59`) < new Date()) {
                return "완료";
            }
        };

        const getPopupStatusClassName = (startDate, endDate) => {
            if (
                new Date(`${startDate} 00:00:00`) <= new Date() &&
                new Date() <= new Date(`${endDate} 23:59:59`)
            ) {
                return "";
            } else if (new Date(`${startDate} 00:00:00`) > new Date()) {
                return "";
            } else if (new Date(`${endDate} 23:59:59`) < new Date()) {
                return "done";
            }
        };

        return (
            <div
                className={`PopupManage-Item d-flex ${getPopupStatusClassName(
                    startDate,
                    endDate
                )}`}
                key={popupId}
            >
                <div className="prd-infobox">
                    <p className="prd-tit">
                        {storeTitle}
                        <span className="state">
                            {getPopupStatusText(startDate, endDate)}
                        </span>
                    </p>
                    <p className="prd-desc">
                        {startDate} ~ {endDate}
                    </p>
                </div>
                <div className="prd-btnbox d-flex">
                    <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => navigate(`/popup/${popupId}`)}
                    >
                        변경
                    </button>
                    <button
                        type="button"
                        className="btn btn-default"
                        data-bs-toggle="modal"
                        data-bs-target="#Modal-01"
                        onClick={() => setPopupRemoveId(popupId)}
                    >
                        삭제
                    </button>
                </div>
            </div>
        );
    });

    return mapedPopups;
}
