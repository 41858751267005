import { Fragment, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SurveyDatePicker from "../../components/survey/SurveyDatePicker";
import useSurvey from "../../hooks/survey/useSurvey";
import AdminHeader from "../../pages/UserMain/components/AdminHeader";
import useRemoveBodyStyle from "../../hooks/common/useRemoveBodyStyle";

import "./Survey.css";
import { getKoreanDayByDayNumber } from "../../function/common";
import useSurveyExcelDownload from "../../hooks/survey/useSurveyExcelDownload";
import LoadingPortal from "../../portals/LoadingPortal";
import LoadingModal from "../../components/loadingmodal/LoadingModal";

const getSurveyDesc = (code) => {
  if (code === 1) {
    return "만족";
  } else if (code === 2) {
    return "보통";
  }

  return "부족";
};

export default function Survey() {
  useRemoveBodyStyle();
  const navigate = useNavigate();
  const [showingDate, setShowingDate] = useState({
    startDate: sessionStorage.getItem("surveyStartDate")
      ? new Date(sessionStorage.getItem("surveyStartDate"))
      : new Date(),
    endDate: sessionStorage.getItem("surveyEndDate")
      ? new Date(sessionStorage.getItem("surveyEndDate"))
      : new Date(),
  });
  const [eventDate, setEventDate] = useState({
    startDate: sessionStorage.getItem("surveyStartDate")
      ? new Date(sessionStorage.getItem("surveyStartDate"))
      : new Date(),
    endDate: sessionStorage.getItem("surveyEndDate")
      ? new Date(sessionStorage.getItem("surveyEndDate"))
      : new Date(),
  });
  const { data, refetch, isLoading } = useSurvey(showingDate);
  const { excelMutate, isExcelLoading } = useSurveyExcelDownload({ eventDate });
  const tableRef = useRef();

  useEffect(() => {
    return () => {
      sessionStorage.clear();
    };
  }, []);

  if (isLoading) {
    return (
      <LoadingPortal>
        <LoadingModal />
      </LoadingPortal>
    );
  }

  if (!data) {
    return null;
  }

  const handleExcelDownloadClick = () => {
    if (isLoading) {
      return;
    }

    excelMutate({ eventDate });
  };

  const handleBackMoveClick = () => {
    sessionStorage.clear();
    navigate("/storemain");
  };

  const mapedSurveyInfos = data.dateGroupList.map((info, index) => {
    const { date, productGroupsList } = info;

    const mapedProductGroupsList = productGroupsList.map(
      (productGroupItem, productsGroupindex) => {
        const { productName, ratings, totalCount } = productGroupItem;
        const [good, soso, bad] = ratings;
        const { count: goodCount, grade: goodGrade } = good;
        const goodPercentage = Math.round((goodCount / totalCount) * 100);

        const { count: sosoCount, grade: sosoGrade } = soso;
        const sosoPercentage = Math.round((sosoCount / totalCount) * 100);
        const { count: badCount, grade: badGrade } = bad;

        const badPercentage = Math.round((badCount / totalCount) * 100);

        // 가장 많은 값
        const maxCount = Math.max(goodCount, sosoCount, badCount);

        if (productsGroupindex === 0) {
          return (
            <Fragment key={`${index}${productsGroupindex}`}>
              <tr>
                <td
                  data-txt="일자"
                  rowSpan={productGroupsList.length * ratings.length}
                >
                  {date} <br />
                  {getKoreanDayByDayNumber(new Date(date).getDay())}
                </td>
                <td data-txt="사용 현황" rowSpan="3">
                  {productName}
                </td>
                <td
                  data-txt="평가 내용"
                  style={{
                    backgroundColor:
                      maxCount && maxCount === goodCount && "#F5F7FC",
                  }}
                >
                  {getSurveyDesc(goodGrade.code)}
                </td>
                <td
                  data-txt="평가(비율%)"
                  style={{
                    backgroundColor:
                      maxCount && maxCount === goodCount && "#F5F7FC",
                  }}
                >
                  {goodCount} ({goodPercentage}%)
                </td>
              </tr>
              <tr>
                <td
                  data-txt="평가 내용"
                  style={{
                    backgroundColor:
                      maxCount && maxCount === sosoCount && "#F5F7FC",
                  }}
                >
                  {getSurveyDesc(sosoGrade.code)}
                </td>
                <td
                  data-txt="평가(비율%)"
                  style={{
                    backgroundColor:
                      maxCount && maxCount === sosoCount && "#F5F7FC",
                  }}
                >
                  {sosoCount} ({sosoPercentage}%)
                </td>
              </tr>
              <tr>
                <td
                  data-txt="평가 내용"
                  style={{
                    backgroundColor:
                      maxCount && maxCount === badCount && "#F5F7FC",
                  }}
                >
                  {getSurveyDesc(badGrade.code)}
                </td>
                <td
                  data-txt="평가(비율%)"
                  style={{
                    backgroundColor:
                      maxCount && maxCount === badCount && "#F5F7FC",
                  }}
                >
                  {badCount} ({badPercentage}%)
                </td>
              </tr>
            </Fragment>
          );
        }

        return (
          <Fragment key={`${index}${productsGroupindex}`}>
            <tr>
              <td data-txt="사용 현황" rowSpan="3">
                {productName}
              </td>
              <td
                data-txt="평가 내용"
                style={{
                  backgroundColor:
                    maxCount && maxCount === goodCount && "#F5F7FC",
                }}
              >
                {getSurveyDesc(goodGrade.code)}
              </td>
              <td
                data-txt="평가(비율%)"
                style={{
                  backgroundColor:
                    maxCount && maxCount === goodCount && "#F5F7FC",
                }}
              >
                {goodCount} ({goodPercentage}%)
              </td>
            </tr>
            <tr>
              <td
                data-txt="평가 내용"
                style={{
                  backgroundColor:
                    maxCount && maxCount === sosoCount && "#F5F7FC",
                }}
              >
                {getSurveyDesc(sosoGrade.code)}
              </td>
              <td
                data-txt="평가(비율%)"
                style={{
                  backgroundColor:
                    maxCount && maxCount === sosoCount && "#F5F7FC",
                }}
              >
                {sosoCount} ({sosoPercentage}%)
              </td>
            </tr>
            <tr>
              <td
                data-txt="평가 내용"
                style={{
                  backgroundColor:
                    maxCount && maxCount === badCount && "#F5F7FC",
                }}
              >
                {getSurveyDesc(badGrade.code)}
              </td>
              <td
                data-txt="평가(비율%)"
                style={{
                  backgroundColor:
                    maxCount && maxCount === badCount && "#F5F7FC",
                }}
              >
                {badCount} ({badPercentage}%)
              </td>
            </tr>
          </Fragment>
        );
      }
    );

    return mapedProductGroupsList;
  });

  const handleSearchClick = () => {
    if (isLoading) {
      return;
    }

    setShowingDate({ ...eventDate });
    refetch();
  };

  return (
    <>
      <div className="demo">
        <div className="TSurveyResultPage">
          <div className="container-fluid g-0">
            <nav className="sub-navbar">
              <p className="navbar-title">설문조사 집계표</p>
              <button
                type="button"
                className="btn-clear btn-back"
                onClick={handleBackMoveClick}
              >
                <img
                  className="img-fluid"
                  alt="뒤로가기"
                  src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                />
              </button>
              <button
                className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
              >
                <img
                  className="img-fluid"
                  alt="메뉴"
                  src="https://www.flexdaycdn.net/public/images/ticket/menu3.png"
                />
              </button>
              <AdminHeader />
            </nav>

            <div className="SurveyResult-Box py-4-5">
              <div className="SurveyResult-Top pb-4-5 px-4-5 d-flex">
                <SurveyDatePicker
                  eventDate={eventDate}
                  setEventDate={setEventDate}
                />
                <button
                  type="button"
                  className="btn btn-default"
                  style={{ whiteSpace: "nowrap" }}
                  onClick={handleSearchClick}
                >
                  조회
                </button>
              </div>
              <div className="SurveyResult-Bottom px-4-5">
                {/* <!-- <div className="PrdTbl-downbox">
                        <button type="button" className="btn-clear PrdTbl-down">
                        집계표 다운로드
                            <img className="img-fluid" alt=""
                            src="https://www.flexdaycdn.net/public/images/ticket/down_icon.png" />
                        </button>
                    </div> --> */}
                <div className="PrdTbl-excelbox pc">
                  <button
                    type="button"
                    className="btn-clear PrdTbl-down"
                    onClick={handleExcelDownloadClick}
                    style={{ width: "auto" }}
                  >
                    <span>엑셀 다운로드</span>
                    <img
                      className="img-fluid"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/down_icon.png"
                      style={{ paddingBottom: 0 }}
                    />
                  </button>
                </div>
                <div className="PrdTbl-excelbox tablet text-center">
                  PC에서 엑셀 파일 다운로드가 가능합니다.
                </div>
                <div className="PrdTbl-wrap" id="survey-table">
                  <table className="PrdTbl table" ref={tableRef}>
                    <thead>
                      <tr>
                        <th>일자</th>
                        <th>사용 현황</th>
                        <th>평가 내용</th>
                        <th>평가(비율%)</th>
                      </tr>
                    </thead>
                    <tbody>{mapedSurveyInfos}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isExcelLoading && (
        <LoadingPortal>
          <LoadingModal type="black" />
        </LoadingPortal>
      )}
    </>
  );
}
