import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";

const getDayName = (date) => {
  return date
    .toLocaleDateString("ko-KR", {
      weekday: "long",
    })
    .substr(0, 1);
};

const createDate = (date) => {
  return new Date(
    new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
  );
};

export default function SurveyDatePicker({ eventDate, setEventDate }) {
  return (
    <div className="DatepickerBox ver1 inputbox">
      <div className="align-items-center d-flex">
        <DatePicker
          className="form-control InputType input-datepicker prd-admin w-100"
          locale={ko}
          dateFormat="yyyy-MM-dd"
          selected={eventDate.startDate}
          onChange={(date) => {
            if (date > eventDate.endDate) {
              setEventDate({
                startDate: eventDate.endDate,
                endDate: date,
              });
              return;
            }

            setEventDate((prev) => ({ ...prev, startDate: date }));
          }}
          onFocus={(e) => e.target.blur()}
          selectsStart
          startDate={eventDate.startDate}
          endDate={eventDate.endDate}
          placeholderText="YYYY-MM-DD"
          dayClassName={(date) =>
            getDayName(createDate(date)) === "토"
              ? "Sat"
              : getDayName(createDate(date)) === "일"
              ? "Sun"
              : undefined
          }
          renderCustomHeader={({
            monthDate,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
            decreaseMonth,
            increaseMonth,
          }) => (
            <div
              className="header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="month-day">
                {monthDate.toLocaleString("ko-KO", {
                  month: "long",
                  year: "numeric",
                })}
              </div>

              <div className="btn-group">
                <div
                  className="btn_month btn_month-prev"
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  <img
                    className="img-fluid"
                    src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png"
                  />
                </div>
                <div
                  className="btn_month btn_month-next"
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  <img
                    className="img-fluid"
                    src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png"
                  />
                </div>
              </div>
            </div>
          )}
        />
        <span className="tilde">~</span>
        <DatePicker
          className="form-control InputType input-datepicker prd-admin w-100"
          locale={ko}
          dateFormat="yyyy-MM-dd"
          selected={eventDate.endDate}
          onChange={(date) =>
            setEventDate((prev) => ({ ...prev, endDate: date }))
          }
          onFocus={(e) => e.target.blur()}
          selectsEnd
          startDate={eventDate.startDate}
          endDate={eventDate.endDate}
          minDate={eventDate.startDate}
          placeholderText="YYYY-MM-DD"
          dayClassName={(date) =>
            getDayName(createDate(date)) === "토"
              ? "Sat"
              : getDayName(createDate(date)) === "일"
              ? "Sun"
              : undefined
          }
          renderCustomHeader={({
            monthDate,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
            decreaseMonth,
            increaseMonth,
          }) => (
            <div
              className="header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="month-day">
                {monthDate.toLocaleString("ko-KO", {
                  month: "long",
                  year: "numeric",
                })}
              </div>

              <div className="btn-group">
                <div
                  className="btn_month btn_month-prev"
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  <img
                    className="img-fluid"
                    src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png"
                  />
                </div>
                <div
                  className="btn_month btn_month-next"
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  <img
                    className="img-fluid"
                    src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png"
                  />
                </div>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
}
