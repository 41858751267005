import {
  Row,
  Col,
  Container,
  Form,
  Table,
  Image,
  ProgressBar,
  Button,
} from "react-bootstrap";

import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
// import CustomAlert from './../../../components/common/CustomAlert';
import StoreItem from "../../components/common/StoreItem";
// import CustomModal from './../../../components/common/CustomModal';
import {
  AlertError,
  getApiUrl,
  getRedirctUrl,
  includeStr,
  setCommas,
} from "../../function/common";
// import QRFooter from './../../user/QRFooter';
// import LineChart from '../../../components/chart/LineChart';
import QrCode from "../../components/common/QrCode";
// import { getImgUrl, PrintConsole } from './../../../function/common';
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Carousel from "react-bootstrap/Carousel";
// import Footer from '../../components/common/Footer';
import Gradient from "../../components/common/Gradient";
import StoreInfo from "../../components/common/StoreInfo";
import { useParams } from "react-router-dom";
import { GetUserMe } from "../../api/ApiGetUser";
// import { deviceType, setAccessToken, setNav1 } from '../../../api/ApiWebView';
import { getStoreAuth } from "../../api/ApiGetStoreUser";
// import Footer_V2 from '../../components/common/Footer_V2';

import Modal from "react-bootstrap/Modal";
import Header from "./components/Header";
import "./StoreMain.css";

import CustomAlert from "../../components/common/CustomAlert";
import { getImgUrl, PrintConsole } from "../../function/common";
import Footer_V2 from "./../../components/common/Footer_V2";
import QRFooter from "../user/QRFooter";
import { openQRRead, setAccessToken, setNav1 } from "../../api/ApiWebView";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function UserStore() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const {} =

  // 모달
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { storeDomain } = useParams();
  // console.log(storeDomain);
  // if(storeDomain === 'null' || storeDomain === undefined){
  //     console.log(storeDomain);
  //     navigate('/404');
  // }

  const [Params] = useSearchParams();
  var Parameters = [];
  for (const entry of Params.entries()) {
    Parameters.push({ [entry[0]]: entry[1] });
  }

  console.log(localStorage.getItem("domainType"));
  // console.log(localStorage.getItem('storeDomain'));

  const [store, setStore] = useState();
  function getStore() {
    if (localStorage.getItem("domainType") === "STORE") {
      return new Promise(async function (resolve, reject) {
        const response = await axios
          .get(
            getApiUrl("stores/" + storeDomain),
            (axios.defaults.headers.common["Authorization"] =
              "Bearer " + localStorage.getItem("accessToken")),
            (axios.defaults.headers.common["ContentType"] =
              "application/json; charset=utf-8")
          )
          .then(function (response) {
            console.log("----------------------------------");
            console.log(response);
            if (response.status === 200) {
              setStore(response.data);
            }
          })
          .catch(function (error) {
            navigate("/404");
            console.log("error");
          });
        // if (response) {
        //     console.log(response);
        //     return resolve(response);
        // }
      });
    } else {
      return new Promise(async function (resolve, reject) {
        const response = await axios
          .get(getApiUrl("stores/" + storeDomain))
          .then(function (response) {
            console.log("----------------------------------");
            console.log(response);
            if (response.status === 200) {
              setStore(response.data);
            }
          })
          .catch(function (error) {
            navigate("/404");
            console.log("error");
          });
        // if (response) {
        //     console.log(response);
        //     return resolve(response);
        // }
      });
    }
  }

  const [storeAuth, setStoreAuth] = useState();

  const [saleItem, setSaleItem] = useState();

  function getSeleItem() {
    return new Promise(async function (resolve, reject) {
      const response = await axios
        .get(getApiUrl("stores/" + storeDomain + "/products?status=SALE"))
        .then(function (response) {
          setSaleItem(response.data);
        })
        .catch(function (e) {
          console.log(e);
        });
      if (response) {
        return resolve(response);
      }
    });
  }
  const [stopItem, setStopItem] = useState();
  function getStopItem() {
    return new Promise(async function (resolve, reject) {
      const response = await axios
        .get(
          getApiUrl("stores/" + storeDomain + "/products?status=SALE_STOPPED")
        )
        .then(function (response) {
          setStopItem(response.data);
        })
        .catch(function (e) {
          console.log(e);
        });
      if (response) {
        return resolve(response);
      }
    });
  }

  const [storeAdmin, setStoreAdmin] = useState();
  function getStoreAdmin(sort) {
    return new Promise(async function (resolve, reject) {
      const response = await (getApiUrl("stores/" + storeDomain + "/products"),
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }).then(function (response) {
        // console.log('*********************************');
        // console.log(response.data);
        setStoreAdmin(response.data);
      });
      if (response) {
        return resolve(response);
      }
    });
  }

  const [storeReview, setStoreReview] = useState();
  function getStoreReview(sort) {
    // console.log(sort);
    let query = "";

    switch (sort) {
      case "NEW":
        query = "?reviewOrder=NEW";
        break;
      case "HIGH":
        query = "?reviewOrder=HIGH";
        break;
      case "LOW":
        query = "?reviewOrder=LOW";
        break;
      default:
        query = "?reviewOrder=NEW";
        break;
    }

    return new Promise(async function (resolve, reject) {
      const response = await axios
        .get(getApiUrl("stores/" + storeDomain + "/reviews" + query))
        .then(function (response) {
          PrintConsole(response);
          setStoreReview(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
      if (response) {
        console.log(response);
        return resolve(response);
      }
    });
  }

  const titleFileUpload = useRef();
  const [file, setFile] = useState([]); // eslint-disable-line no-unused-vars

  const onClickUpload = () => {
    titleFileUpload.current.click();
  };

  const onChangeImg = (e) => {
    if (e.target.files) {
      const uploadFile = e.target.files[0];
      setFile(uploadFile);

      let tmpData = [{ priority: 1 }];

      let obj = new Blob([JSON.stringify(tmpData)], {
        type: "application/json",
      });

      var formData = new FormData();
      formData.append("request", obj);
      formData.append("images", uploadFile);

      axios
        .put(
          getApiUrl("stores/" + storeDomain + "/images"),
          formData,
          (axios.defaults.headers.common["ContentType"] =
            "multipart/form-data"),
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken"))
        )
        .then(function (response) {
          if (response.status === 200) {
            window.location.reload();
          } else {
            alert("매장 사진이 변경되었습니다.");
          }
        })
        .catch(function (error) {
          AlertError(error);
        });
    }
  };

  useEffect(() => {
    //데이터 로딩
    setLoading(true);

    //상점 정보
    // getStore();
    //아이템 정보
    // getSeleItem();
    // getStopItem();
    //리뷰
    // getStoreReview();

    try {
      setAccessToken();
    } catch (e) {}

    // try{
    //     setNav1();
    // }catch(e){}

    setStoreAuth(getStoreAuth());

    // console.log('+++++++'+localStorage.getItem('domainType'));
    //데이터 로딩 완료
    setLoading(false);
  }, []);

  if (loading) return <></>;

  // if(localStorage.getItem('domainType') === 'USER' || localStorage.getItem('domainType') === null){
  //     // 승인전 승인 심사 페이지로 이동
  //     if(store && store.status.code === 4){
  //         navigate('/user/underreview');
  //     }
  // }

  // console.log(storeAuth);

  return (
    <>
      <div className="demo">
        <div className="TicketPage">
          <div className="container-fluid pt-70px g-0">
            <nav className="navbar bg-none fixed-top">
              <div className="container-fluid">
                <a className="navbar-brand hide" href="#">
                  기본 텍스트
                </a>
                <Header />

                {/* <button className="Navbar-Toggler" type="button" data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                                <img className="img-fluid" alt="메뉴"
                                src="https://www.flexdaycdn.net/public/images/ticket/menu3.png" />
                            </button>
                            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar"
                                aria-labelledby="offcanvasNavbarLabel">
                                <div className="offcanvas-btnbox pt-4 px-4 text-end">
                                    <button type="button" className="btn-close opacity-100 shadow-none" data-bs-dismiss="offcanvas"
                                        aria-label="Close">
                                        <img className="img-fluid close" alt=""
                                        src="https://www.flexdaycdn.net/public/images/ticket/close4.png" />
                                    </button>
                                </div>
                                <div className="offcanvas-header p-4">
                                    <img className="img-fluid logo" alt=""
                                        src="https://www.flexdaycdn.net/public/images/ticket/logo.png" />
                                    <div className="offcanvas-title" id="offcanvasNavbarLabel">
                                        <span className="small">
                                            반가워요 :)
                                        </span>
                                        김인제 님
                                    </div>
                                </div>
                                <div className="offcanvas-body position-relative py-4-5">
                                    <div className="navbar-nav justify-content-end">
                                        <div className="nav-item">
                                            <a href="#none"  className="nav-link active d-flex align-items-center pb-4" aria-current="page">
                                                <span>메인</span>
                                            </a>
                                        </div>
                                        <div className="nav-item">
                                            <a href={"/demo/paymentlist"} className="nav-link d-flex align-items-center pb-4">
                                                <span>QR 지갑</span>
                                            </a>
                                        </div>
                                        <div className="nav-item">
                                            <a href={"/demo/paymentlist"} className="nav-link d-flex align-items-center pb-4">
                                                <span>결제 내역</span>
                                            </a>
                                        </div>
                                        <div className="nav-item">
                                            <a href={"/demo/paymentlist"} className="nav-link d-flex align-items-center pb-4">
                                                <span>스탬프 리워드</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="btnbox">
                                        <a href="tel:0516210203" type="button" className="btn btn-light mb-2 shadow-none">고객센터 051-621-0203</a>
                                        <button type="button" className="btn btn-default" onClick={ ()=> { localStorage.clear(); navigate("/demo/userlogin") }}>로그아웃</button>
                                    </div>
                                </div>
                            </div> */}
              </div>
            </nav>

            <div className="TicketPhoto">
              <div className="TicketPhoto-Slider">
                <Swiper
                  loop={true}
                  pagination={{ el: ".TS-pag", type: "fraction" }}
                  modules={[Pagination, Navigation]}
                  className="mySwiper d-flex"
                >
                  <SwiperSlide>
                    <Image
                      fluid
                      className="w-100 StoreBg"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/store_main.png"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Image
                      fluid
                      className="w-100 StoreBg"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/store_main02.png"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Image
                      fluid
                      className="w-100 StoreBg"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/store_main03.png"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Image
                      fluid
                      className="w-100 StoreBg"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/store_main04.png"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Image
                      fluid
                      className="w-100StoreBg"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/store_main05.png"
                    />
                  </SwiperSlide>
                </Swiper>
              </div>

              <div className="TicketPhoto-Content d-flex">
                <button
                  type="button"
                  className="btn btn-clear btn-camera opacity-0 pe-none"
                >
                  <img
                    className="img-fluid"
                    alt=""
                    src="https://www.flexdaycdn.net/public/images/static/store-main/store_camera.png"
                  />
                </button>

                <div className="PhotoCount swiper-pagination TS-pag"></div>
              </div>
            </div>

            <div className="StoreDetail pt-4-5">
              <div className="StoreTitle align-items-center d-flex px-4-5">
                <img
                  className="img-fluid logo"
                  alt=""
                  src="https://www.flexdaycdn.net/public/images/ticket/logo.png"
                />
                <p className="mb-0 tit">인제대학교 늘빛관(다인)</p>
              </div>
              <div className="StoreInfo  px-4-5">
                <div className="g-0 row mb-2">
                  <div className="col-2 tit">운영시간</div>
                  <div className="col-10 desc">
                    주중 - 11:00~18:00
                    <br />
                    (Breack time 14:30~15:30)
                    <br />
                    주말 - 휴무
                  </div>
                </div>
                <div className="g-0 row">
                  <div className="col-2 tit">위치안내</div>
                  <div className="col-10 desc">늘빛관 1층</div>
                </div>
              </div>

              <div className="StoreTab-wrap b-top">
                <div
                  className="StoreTab-content tab-content px-4-5"
                  id="nav-tabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id="nav-01"
                    role="tabpanel"
                    aria-labelledby="nav-01-tab"
                    tabIndex="0"
                  >
                    <div className="ProductList">
                      <div
                        className="prd-item"
                        onClick={() => {
                          navigate(
                            "/demo/payment?storeurl=" +
                              storeDomain +
                              "&productid=43"
                          );
                        }}
                      >
                        <p className="prd-tit">A코너</p>
                        <p className="prd-sub">
                          뚝배기 갈비탕 + 당면사리 / 무우 잔파 생채 / 양파
                          마늘쫑 장아찌
                        </p>
                        <p className="prd-price">
                          <span className="price">4,000</span>원
                        </p>
                        <div className="quantity-txt">
                          <img
                            className="img-fluid icon"
                            alt=""
                            src="https://www.flexdaycdn.net/public/images/ticket/cart-icon.png"
                          />
                          100개 남음
                        </div>
                      </div>
                      <div className="prd-item">
                        <p className="prd-tit">B코너</p>
                        <p className="prd-sub">
                          훈제오리 마늘 볶음밥 + 계란후라이 / 샐러드 /
                          해쉬브라운 + 케찹 / 우동장국
                        </p>
                        <p className="prd-price">
                          <span className="price">6,000</span>원
                        </p>
                        <div className="quantity-txt">
                          <img
                            className="img-fluid icon"
                            alt=""
                            src="https://www.flexdaycdn.net/public/images/ticket/cart-icon.png"
                          />
                          90개 남음
                        </div>
                      </div>
                      <div className="prd-item">
                        <p className="prd-tit">C코너</p>
                        <p className="prd-sub">
                          수제 등심돈까스 / 샐러드 + 드레싱 / 크림스프
                        </p>
                        <p className="prd-price">
                          <span className="price">6,000</span>원
                        </p>
                        <div className="quantity-txt">
                          <img
                            className="img-fluid icon"
                            alt=""
                            src="https://www.flexdaycdn.net/public/images/ticket/cart-icon.png"
                          />
                          15개 남음
                        </div>
                      </div>
                      <div className="prd-item">
                        <p className="prd-tit">공기밥</p>
                        <p className="prd-sub">면요리에 추가해서 드세요.</p>
                        <p className="prd-price">
                          <span className="price">5000</span>원
                        </p>
                      </div>
                      <div className="prd-item">
                        <p className="prd-tit">도시락 김</p>
                        <p className="prd-sub">추가 반찬</p>
                        <p className="prd-price">
                          <span className="price">300</span>원
                        </p>
                      </div>
                      <div className="prd-item">
                        <p className="prd-tit">스파클링 탄산음료</p>
                        <p className="prd-sub">사과, 포도, 오렌지 중 택1</p>
                        <p className="prd-price">
                          <span className="price">900</span>원
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="StorePay">
            {/* 확인 : 메뉴 추가시 노출 (임시로 숨김)*/}
            <div className="d-none AddPrdBox px-4-5">
              <div className="mx-auto prd-slidedown py-2">
                <img
                  className="img-fluid logo"
                  alt=""
                  src="https://www.flexdaycdn.net/public/images/ticket/prd-down.png"
                />
              </div>
              <div className="prd-item">
                <div className="prd-tit">C코너</div>
                <div className="prd-sub">
                  수제 등심돈까스 / 샐러드 + 드레싱 / 크림스프
                </div>
                <div className="align-items-center d-flex justify-content-between prd-option">
                  <div className="inputbox d-flex">
                    <button type="button" className="btn btn-clear minus">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/prd-minus.png"
                        alt=""
                        className="img-fluid"
                      />
                    </button>
                    <input
                      type="number"
                      className="form-control InputType"
                      readOnly
                      value="1"
                    />
                    <button type="button" className="btn btn-clear plus">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/prd-plus.png"
                        alt=""
                        className="img-fluid"
                      />
                    </button>
                  </div>
                  <p className="prd-price">
                    <span className="price">4,000원</span>
                    <button type="button" className="btn btn-clear closeBtn">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/prd-close.png"
                        alt=""
                        className="img-fluid"
                      />
                    </button>
                  </p>
                </div>
              </div>
              <div className="prd-item">
                <div className="prd-tit">스파클링 탄산음료</div>
                <div className="prd-sub">사과, 포도, 오렌지 중 택1</div>
                <div className="align-items-center d-flex justify-content-between prd-option">
                  <div className="inputbox d-flex">
                    <button type="button" className="btn btn-clear minus">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/prd-minus.png"
                        alt=""
                        className="img-fluid"
                      />
                    </button>
                    <input
                      type="number"
                      className="form-control InputType"
                      readOnly
                      value="2"
                    />
                    <button type="button" className="btn btn-clear plus">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/prd-plus.png"
                        alt=""
                        className="img-fluid"
                      />
                    </button>
                  </div>
                  <p className="prd-price">
                    <span className="price">1,800원</span>
                    <button type="button" className="btn btn-clear closeBtn">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/prd-close.png"
                        alt=""
                        className="img-fluid"
                      />
                    </button>
                  </p>
                </div>
              </div>
              <div className="prd-item">
                <div className="prd-tit">C코너</div>
                <div className="prd-sub">
                  수제 등심돈까스 / 샐러드 + 드레싱 / 크림스프
                </div>
                <div className="align-items-center d-flex justify-content-between prd-option">
                  <div className="inputbox d-flex">
                    <button type="button" className="btn btn-clear minus">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/prd-minus.png"
                        alt=""
                        className="img-fluid"
                      />
                    </button>
                    <input
                      type="number"
                      className="form-control InputType"
                      readOnly
                      value="1"
                    />
                    <button type="button" className="btn btn-clear plus">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/prd-plus.png"
                        alt=""
                        className="img-fluid"
                      />
                    </button>
                  </div>
                  <p className="prd-price">
                    <span className="price">4,000원</span>
                    <button type="button" className="btn btn-clear closeBtn">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/prd-close.png"
                        alt=""
                        className="img-fluid"
                      />
                    </button>
                  </p>
                </div>
              </div>
              <div className="prd-item">
                <div className="prd-tit">스파클링 탄산음료</div>
                <div className="prd-sub">사과, 포도, 오렌지 중 택1</div>
                <div className="align-items-center d-flex justify-content-between prd-option">
                  <div className="inputbox d-flex">
                    <button type="button" className="btn btn-clear minus">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/prd-minus.png"
                        alt=""
                        className="img-fluid"
                      />
                    </button>
                    <input
                      type="number"
                      className="form-control InputType"
                      readOnly
                      value="2"
                    />
                    <button
                      type="button"
                      className="btn btn-clear plus"
                      data-bs-toggle="modal"
                      data-bs-target="#Modal-01"
                    >
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/prd-plus.png"
                        alt=""
                        className="img-fluid"
                      />
                    </button>
                  </div>
                  <p className="prd-price">
                    <span className="price">1,800원</span>
                    <button type="button" className="btn btn-clear closeBtn">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/prd-close.png"
                        alt=""
                        className="img-fluid"
                      />
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="align-items-center btn btn-primary d-flex h-auto justify-content-center py-4 shadow-none"
              onClick={handleShow}
            >
              <span>5,800</span>원 결제하기
            </button>
          </div>

          {/* 대량구매 Modal */}
          <Modal
            show={show}
            onHide={handleClose}
            keyboard={false}
            size="sm"
            centered
            className="demoModal modal-prdAdd text-center"
          >
            <Modal.Header>
              <Modal.Title>
                대량 구매를 원하시는 분은 <br />
                관리자에게 문의 바랍니다.
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="modal-title" style={{ color: "#666666" }}>
                055-320-3054
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="modal-secondary"
                className="btn-clear"
                onClick={handleClose}
              >
                닫기
              </Button>
              <a
                href="tel:0516210203"
                className="btn btn-clear btn-modal-primary"
              >
                담당자 연결
              </a>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
}
