import { useState } from "react";
import { useFetch } from "../common/useFetch";
import { apiClient } from "../../api/apiClient";

export default function useProducts() {
    const [saleProducts, setSaleProducts] = useState();
    const [saleStopedProducts, setSaleStopedProducts] = useState();

    const { refetch } = useFetch(
        ["mainProducts"],
        async () => {
            const { data } = await apiClient({
                url: "/products",
                method: "get",
            });

            return data;
        },
        {
            onSuccess: (products) => {
                const saleStopedProducts = [];
                const mapedSaleProdcuts = products
                    .map((product) => {
                        const { productStatus } = product;

                        if (productStatus.code === 2) {
                            saleStopedProducts.push(product);
                            return;
                        }

                        if (productStatus.code === 3) {
                            return;
                        }

                        return product;
                    })
                    .filter((product) => product);

                setSaleProducts(mapedSaleProdcuts);
                setSaleStopedProducts(saleStopedProducts);
            },
            onError: ({ response }) => {
                alert(response.data.message);
            },
        }
    );

    return {
        saleProducts,
        saleStopedProducts,
        refetch,
    };
}
