import { useNavigate } from "react-router-dom";
import AdminHeader from "../../UserMain/components/AdminHeader";
import "./PopupManager.css";
import useRemoveBodyStyle from "../../../hooks/common/useRemoveBodyStyle";
import { usePopup } from "../../../hooks/popup/userPopup";
import PopupList from "../../../components/popup/PopupList";
import usePopupRemove from "../../../hooks/popup/usePopupRemove";
import { useState } from "react";
import LoadingPortal from "../../../portals/LoadingPortal";
import LoadingModal from "../../../components/loadingmodal/LoadingModal";

export default function PopupManager() {
    useRemoveBodyStyle();
    const navigate = useNavigate();
    const { data, refetch } = usePopup();
    const [popupRemoveId, setPopupRemoveId] = useState();
    const { mutate, isLoading } = usePopupRemove({ refetch });

    if (!data) {
        return;
    }

    if (!isLoading) {
        <LoadingPortal>
            <LoadingModal type="black" />
        </LoadingPortal>;
    }

    const handlePopupRemoveBtnClick = () => {
        if (isLoading) {
            return;
        }

        mutate({ popupId: popupRemoveId });
    };

    return (
        <div className="demo">
            <div className="TPopupManagePage">
                <div className="container-fluid g-0">
                    <nav className="sub-navbar">
                        <p className="navbar-title">팝업 관리</p>
                        <button
                            type="button"
                            className="btn-clear btn-back"
                            onClick={() => navigate("/storemain")}
                        >
                            <img
                                className="img-fluid"
                                alt="뒤로가기"
                                src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                            />
                        </button>
                        <button
                            className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasNavbar"
                            aria-controls="offcanvasNavbar"
                        >
                            <img
                                className="img-fluid"
                                alt="메뉴"
                                src="https://www.flexdaycdn.net/public/images/ticket/menu3.png"
                            />
                        </button>
                        <AdminHeader />
                    </nav>

                    <div className="PopupManageBox py-4-5 px-4-5">
                        <div className="PopupManage-Btnbox d-flex">
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={() => navigate("/popup/sequence")}
                            >
                                순서 편집
                            </button>
                            <button
                                type="button"
                                className="btn btn-default"
                                onClick={() => navigate("/popup/add")}
                            >
                                + 팝업 생성
                            </button>
                        </div>

                        <div className="PopupManage-Itembox">
                            {/* <!-- 팝업이 없는 경우 노출--> */}
                            {!data.length && (
                                <div className="prd-blank">
                                    등록된 팝업이 없습니다.
                                </div>
                            )}
                            <PopupList
                                popups={data}
                                setPopupRemoveId={setPopupRemoveId}
                            />
                        </div>
                    </div>
                </div>

                {/* <!-- 팝업 삭제 Modal --> */}
                <div
                    className="modal demoModal fade modal-prdAdd text-center"
                    id="Modal-01"
                    tabIndex="-1"
                >
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <p className="modal-title">
                                    해당 팝업을 <br />
                                    삭제 하시겠습니까?
                                </p>
                            </div>
                            <div className="modal-body">
                                팝업 목록에서 삭제됩니다.
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-clear btn-modal-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    취소
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-clear btn-modal-primary"
                                    data-bs-dismiss="modal"
                                    onClick={handlePopupRemoveBtnClick}
                                >
                                    확인
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
