import { useNavigate } from "react-router-dom";

export default function SaleMenuNav() {
  const navigate = useNavigate();

  return (
    <div className="SalePrd-Btnbox d-flex">
      <button
        type="button"
        className="btn btn-light"
        onClick={() => navigate("/salemenu/sequence")}
      >
        순서 편집
      </button>
      <button
        type="button"
        className="btn btn-default"
        onClick={() => navigate("/salemenu/add")}
      >
        + 메뉴 추가
      </button>
    </div>
  );
}
