import useStoreDetail from "../../../hooks/main/useStoreDetail";
import { fetchStoreInfo, init } from "../../../store/modules/storeSlice";
import { RootState, useAppSelector } from "../../../store";
import { useAppDispatch } from "../../../store";
import { useEffect } from "react";

export default function StoreDetail(props) {
  // const { storeInfo } = useStoreDetail();

  const dispatch = useAppDispatch();
  const tempStoreType = RootState().storeReducer.storeType;
  useEffect(() => {
    dispatch(fetchStoreInfo());
    if (tempStoreType) {
      localStorage.setItem("storeType", tempStoreType);
    }
  }, [tempStoreType]);

  const storeName = useAppSelector((state) => {
    return state.storeReducer.storeName;
  });
  const businessHours = useAppSelector((state) => {
    return state.storeReducer.businessHours;
  });
  const location = useAppSelector((state) => {
    return state.storeReducer.location;
  });
  const useNotice = useAppSelector((state) => {
    return state.storeReducer.useNotice;
  });
  const notice = useAppSelector((state) => {
    return state.storeReducer.notice;
  });
  const logoUrl = useAppSelector((state) => {
    return state.storeReducer.logoUrl;
  });
  const storeType = useAppSelector((state) => {
    return state.storeReducer.storeType;
  });
  // TODO : 데이터가 없을 경우는 ?
  if (storeName == null) return null;
  // if (!storeInfo) {
  //     return null;
  // }

  // const { storeName, businessHours, location, useNotice, notice, logoUrl } =
  //     storeInfo;

  return (
    <>
      <div className="StoreTitle align-items-center d-flex px-4-5">
        <img className="img-fluid logo" alt="" src={logoUrl} />
        {/* <p className="mb-0 tit">인제대학교 백병원(다인)</p> */}
        <p className="mb-0 tit">{storeName}</p>
      </div>
      <div className="StoreInfo  px-4-5">
        {/* <div className="g-0 row mb-2">
                    <div className="col-2 tit">운영시간</div>
                    <div className="col-10 desc">
                        주중 - 09:00~16:30
                        <br />
                        주말 - 휴무
                    </div>
                </div> */}
        <div className="g-0 row mb-2">
          <div className="col-2 tit">운영시간</div>
          <div className="col-10 desc" style={{ whiteSpace: "pre-line" }}>
            {props.isNotOpen ? "현재 운영 기간이 아닙니다." : businessHours}
          </div>
        </div>
        {/* <div className="g-0 row">
                    <div className="col-2 tit">위치안내</div>
                    <div className="col-10 desc">인제대학교 의과대학</div>
                </div> */}
        <div className="g-0 row">
          <div className="col-2 tit">위치안내</div>
          <div className="col-10 desc" style={{ whiteSpace: "pre-line" }}>
            {location}
          </div>
        </div>
      </div>
      {useNotice && (
        <div className="StoreAppInfo">
          <div className="title">
            <img
              className="img-fluid speaker"
              alt=""
              src="https://www.flexdaycdn.net/public/images/ticket_ver2/speaker2.png"
            />
            <span>공지사항</span>
          </div>
          <div className="txtbox" style={{ whiteSpace: "pre-line" }}>
            {notice}
          </div>
        </div>
      )}
    </>
  );
}
