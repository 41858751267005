import { useFetch } from "./common/useFetch";
import { apiClient } from "../api/apiClient";

export default function useEventDetailModal(
  eventId,
  setIsEventDetailModalShowing
) {
  const { data, refetch } = useFetch(
    [eventId],
    async () => {
      if (!eventId) {
        return;
      }

      const { data } = await apiClient({
        url: `/events/${eventId}`,
        method: "get",
      });

      return data;
    },
    {
      onSuccess: () => {
        if (!eventId) {
          return;
        }

        setIsEventDetailModalShowing(true);
      },
      onError: ({ response }) => {
        alert(response.data.message);
      },
      enabled: false,
    }
  );

  return { data, refetch };
}
