import { useSearchParams } from "react-router-dom";
import { useState, useRef, Suspense } from "react";

import AdminHeader from "./components/AdminHeader";
import "./StoreMain.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Image } from "react-bootstrap";
import SaleProductList from "../../components/main/saleproductlist/SaleProductList";
import StopProductList from "../../components/main/stopproductlist/StopProductList";
import StoreDetail from "../../components/main/storedetail/StoreDetail";
import useRemoveBodyStyle from "../../hooks/common/useRemoveBodyStyle";
import useSlide from "../../hooks/main/useSlide";

export default function DemoStoreMain() {
    useRemoveBodyStyle();
    const [isShowingTab, setIsShowingTab] = useState(true);
    const navSaledRef = useRef();
    const navStopedRef = useRef();
    const { slides } = useSlide();

    const [Params] = useSearchParams();

    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]]: entry[1] });
    }

    const mapedSlides = slides?.files?.map(({ id, url }) => {
        return (
            <SwiperSlide key={id}>
                <Image fluid className="w-100 StoreBg" alt="" src={url} />
            </SwiperSlide>
        );
    });

    const checkNonOperationTime = () => {
        return slides?.periods?.some(({ startDate, endDate }) => {
            if (
                new Date(`${startDate} 00:00:00`) <=
                    new Date(new Date().setHours(0, 0, 0, 0)) &&
                new Date(new Date().setHours(0, 0, 0, 0)) <=
                    new Date(`${endDate} 00:00:00`)
            ) {
                return true;
            }

            return false;
        });
    };

    return (
        <>
            <div className="demo">
                <div className="TicketPage admin">
                    <div className="container-fluid pt-70px g-0">
                        <nav className="navbar bg-none fixed-top">
                            <div className="container-fluid">
                                <a className="navbar-brand hide" href="#">
                                    기본 텍스트
                                </a>

                                <AdminHeader />
                            </div>
                        </nav>

                        <div className="TicketPhoto">
                            <div className="TicketPhoto-Slider">
                                <Swiper
                                    loop={true}
                                    pagination={{
                                        el: ".TS-pag",
                                        type: "fraction",
                                    }}
                                    initialSlide={1}
                                    modules={[Pagination, Navigation]}
                                    className="mySwiper d-flex"
                                >
                                    {mapedSlides}
                                </Swiper>
                            </div>
                            <div className="TicketPhoto-Content d-flex">
                                <div className="PhotoCount swiper-pagination TS-pag"></div>
                            </div>
                        </div>
                        <div className="StoreDetail pt-4-5">
                            <StoreDetail isNotOpen={checkNonOperationTime()} />

                            <div className="StoreTab-wrap b-top">
                                {!checkNonOperationTime() && (
                                    <nav className="StoreTab">
                                        <div
                                            className="nav nav-tabs"
                                            id="nav-tab"
                                            role="tablist"
                                        >
                                            <button
                                                className="nav-link active"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-01"
                                                ref={navSaledRef}
                                                role="tab"
                                                type="button"
                                                onClick={() =>
                                                    setIsShowingTab(true)
                                                }
                                            >
                                                <span>판매 상품</span>
                                            </button>
                                            <button
                                                className="nav-link"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-02"
                                                ref={navStopedRef}
                                                role="tab"
                                                type="button"
                                                onClick={() =>
                                                    setIsShowingTab(false)
                                                }
                                            >
                                                <span>판매 중지</span>
                                            </button>
                                        </div>
                                    </nav>
                                )}

                                <div
                                    className="StoreTab-content tab-content px-4-5"
                                    id="nav-tabContent"
                                >
                                    {checkNonOperationTime() ? (
                                        <div className="no-item">
                                            <img
                                                className="img-fluid no_itemImg"
                                                src="https://www.flexdaycdn.net/public/images/ticket_ver2/no_item.png"
                                                alt=""
                                            />
                                            <p className="no_txt mb-0">
                                                지금은 매장 미운영 기간입니다.
                                            </p>
                                        </div>
                                    ) : (
                                        <>
                                            <div
                                                className="tab-pane fade show active"
                                                id="nav-01"
                                                role="tabpanel"
                                                aria-labelledby="nav-01-tab"
                                                tabIndex="0"
                                            >
                                                {isShowingTab && (
                                                    <Suspense
                                                        fallback={
                                                            <div>
                                                                Loading...
                                                            </div>
                                                        }
                                                    >
                                                        <SaleProductList
                                                            ref={navStopedRef}
                                                        />
                                                    </Suspense>
                                                )}
                                            </div>

                                            <div
                                                className="tab-pane fade"
                                                id="nav-02"
                                                role="tabpanel"
                                                aria-labelledby="nav-02-tab"
                                                tabIndex="0"
                                            >
                                                {!isShowingTab && (
                                                    <Suspense
                                                        fallback={
                                                            <div>
                                                                Loading...
                                                            </div>
                                                        }
                                                    >
                                                        <StopProductList
                                                            ref={navSaledRef}
                                                        />
                                                    </Suspense>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
