import React from "react";
import { useNavigate } from "react-router-dom";
import { STORE_DEFAULT } from "../components/constants/CommonConstants";

export function getRedirctUrl(url = "") {
  return process.env.REACT_APP_BASEURL + url;
}

export function getApiUrl(api = "") {
  return process.env.REACT_APP_API + api;
  // return "https://api.flexday.kr/" + api;
  // return 'https://dev-api.flexday.kr/' + api;
}

export function getImgUrl(url) {
  return process.env.REACT_APP_IMGURL + url;
  // return "https://dev.flexdaycdn.net/public/" + url;
  // return "https://www.flexdaycdn.net/public/" + url;
}
export function LocationUrl(url) {
  const navigate = useNavigate();
  navigate(url);
}

//await timeout(1000);
export function LoginCheck() {
  if (
    localStorage.getItem("accessToken") !== undefined &&
    localStorage.getItem("accessToken") !== null
  ) {
    return "LOGIN";
  } else {
    return "VIEWER";
  }
}

export function PrintConsole(log) {
  if (process.env.NODE_ENV !== "production") {
    console.log(log);
  }
}

export function getSubDomain() {
  if (window.location.host.split(".")[0] === "admin") {
    return "admin";
  } else if (window.location.host.split(".")[0] === "store") {
    return "store";
  } else if (window.location.host.split(".")[0] === "user") {
    return "user";
  } else {
    return "localhost";
  }
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function setCommas(x) {
  if (isNaN(x)) {
    return "";
  } else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

export function getNewlines(html) {
  if (!html) return null;

  const lines = html.split("\\n");

  if (lines.length === 0) {
    return html;
  }

  return lines.map((line, index) =>
    React.createElement(
      "span",
      {
        key: index,
      },
      line,
      React.createElement("br")
    )
  );
}

export function includeStr(str, check) {
  if (str.includes(check)) {
    return true;
  } else {
    return false;
  }
}

export function makeAddress(item) {
  if (!item) {
    return;
  }

  var name = item.name,
    region = item.region,
    land = item.land,
    isRoadAddress = name === "roadaddr";

  var sido = "",
    sigugun = "",
    dongmyun = "",
    ri = "",
    rest = "";

  if (hasArea(region.area1)) {
    sido = region.area1.name;
  }

  if (hasArea(region.area2)) {
    sigugun = region.area2.name;
  }

  if (hasArea(region.area3)) {
    dongmyun = region.area3.name;
  }

  if (hasArea(region.area4)) {
    ri = region.area4.name;
  }

  if (land) {
    if (hasData(land.number1)) {
      if (hasData(land.type) && land.type === "2") {
        rest += "산";
      }

      rest += land.number1;

      if (hasData(land.number2)) {
        rest += "-" + land.number2;
      }
    }

    if (isRoadAddress === true) {
      if (checkLastString(dongmyun, "면")) {
        ri = land.name;
      } else {
        dongmyun = land.name;
        ri = "";
      }

      if (hasAddition(land.addition0)) {
        rest += " " + land.addition0.value;
      }
    }
  }

  return [sido, sigugun, dongmyun, ri, rest].join(" ");
}

export function hasArea(area) {
  return !!(area && area.name && area.name !== "");
}

export function hasData(data) {
  return !!(data && data !== "");
}

export function checkLastString(word, lastString) {
  return new RegExp(lastString + "$").test(word);
}

export function hasAddition(addition) {
  return !!(addition && addition.value);
}

export function maxLengthCheck(object) {
  if (object.value.Length > object.max.Length)
    object.value = object.value.slice(0, object.max.Length);
}

export function EmailCheck(text) {
  var regExp =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

  if (text.match(regExp) != null) {
    return true;
  } else {
    return false;
  }
}
export function EngNumCheck(text) {
  console.log(text);
  var regExp = /^[A-Za-z0-9]+$/;
  if (text.match(regExp) != null) {
    return false;
  } else {
    return true;
  }
}

export function encodeFileToBase64(fileBlob) {
  const reader = new FileReader();
  reader.readAsDataURL(fileBlob);
  return new Promise((resolve) => {
    reader.onload = () => {
      console.log(reader.result);
      return reader.result;
    };
  });
}

export function timer(id, time) {
  var timer = time;
  var min = "";
  var sec = "";

  var x = setInterval(function () {
    min = parseInt(timer / 60);
    sec = timer % 60;

    document.getElementById(id).innerHTML = min + "분" + sec + "초";
    timer--;

    if (timer < 0) {
      clearInterval(x);
    }
  }, 1000);
}

export function AlertError(error) {
  let detailMessage;
  let errorDetails = error.response.data.errorDetails;
  if (errorDetails && errorDetails.length > 0) {
    detailMessage = errorDetails[0].reason;
  } else {
    detailMessage = error.response.data.message;
  }
  alert(detailMessage);
}

export function getTextLength(str) {
  var len = 0;
  for (var i = 0; i < str.length; i++) {
    if (escape(str.charAt(i)).length == 6) {
      len++;
    }
    len++;
  }
  return len;
}

export function getFormatYearMonth(date) {
  const baseDate = date ? new Date(date) : new Date();

  const year = baseDate.getFullYear();
  const month = ("0" + (baseDate.getMonth() + 1)).slice(-2);

  return `${year}-${month}`;
}

export function getFormatDate(date) {
  const baseDate = date ? new Date(date) : new Date();

  const year = baseDate.getFullYear();
  const month = ("0" + (baseDate.getMonth() + 1)).slice(-2);
  const day = ("0" + baseDate.getDate()).slice(-2);

  return `${year}-${month}-${day}`;
}

export function getFormatDateAndTime(dateOfQuantityLimit, timeOfQuantityLimit) {
  const year = new Date(dateOfQuantityLimit).getFullYear();
  const month = new Date(dateOfQuantityLimit).getMonth() + 1;
  const day = new Date(dateOfQuantityLimit).getDate();

  const [hour, minute] = timeOfQuantityLimit.split(":");

  return new Date(year, month, day, hour, minute);
}

export function getFormatTime(Time) {
  const baseTime = Time ? new Date(Time) : new Date();

  if (!Time) {
    const hour = ("0" + baseTime.getHours()).slice(-2);
    const minute = ("0" + baseTime.getHours()).slice(-2);

    return `${hour}:${minute}`;
  }

  const hour = ("0" + baseTime.getHours()).slice(-2);
  const minute = ("0" + baseTime.getMinutes()).slice(-2);

  return `${hour}:${minute}`;
}

export function getFormatTimeByHour(hour) {
  const time = hour < 10 ? `0${hour}00` : `${hour}00`;
  const baseTime = hour.split(":");
  const formatTime = `${time.slice(0, 2)}:${baseTime[1]}`;

  return formatTime;
}

export function getTodayYearAndMonth() {
  return (
    new Date().getFullYear() +
    "-" +
    ("0" + (new Date().getMonth() + 1)).slice(-2)
  );
}

export function getMaxTime(startTime, endTime) {
  const [startHour] = getFormatTime(startTime).split(":").map(Number);
  const [endHour] = endTime.split(":").map(Number);

  if (startHour > endHour) {
    return true;
  }

  return false;
}

export function getMaxDate(startDate, endDate) {
  const [startYear, startMonth, startDay] = getFormatDate(startDate)
    .split("-")
    .map(Number);
  const [endYear, endMonth, endDay] = endDate.split("-").map(Number);

  if (startYear > endYear) {
    return true;
  }

  if (startYear >= endYear && startMonth > endMonth) {
    return true;
  }

  if (startYear >= endYear && startMonth >= endMonth && startDay > endDay) {
    return true;
  }

  return false;
}

export function getKoreanYearMonthDay(date) {
  if (!date) {
    return;
  }

  const [year, month, day] = date.split("-");

  return `${year}년 ${month}월 ${day}일`;
}

export function getKoreanDayByDayNumber(dayNumber) {
  const days = ["일", "월", "화", "수", "목", "금", "토"];

  return days[dayNumber];
}

export function vaildateEvent(
  eventInfo,
  setErrorText,
  setIsRemoveModalShowing
) {
  if (eventInfo.eventName === "") {
    setErrorText("이벤트 명을 입력해주세요.");
    setIsRemoveModalShowing(true);
    return false;
  }

  if (
    eventInfo.stampEvent.stamp.find(
      (rewardItem) => rewardItem.stampCount === null
    )
  ) {
    setErrorText("스탬프를 입력해주세요.");
    setIsRemoveModalShowing(true);
    return false;
  }

  if (
    eventInfo.stampEvent.stamp.find((rewardItem) => rewardItem.stampCount === 0)
  ) {
    setErrorText("스탬프는 1개 이상 입력 가능합니다.");
    setIsRemoveModalShowing(true);
    return false;
  }

  if (
    eventInfo.stampEvent.stamp.find(
      (rewardItem) =>
        rewardItem.rewardType === "INNER_PRODUCT" &&
        rewardItem.rewardProductId === "default"
    )
  ) {
    setErrorText("상품을 입력해주세요.");
    setIsRemoveModalShowing(true);
    return false;
  }

  if (
    eventInfo.stampEvent.stamp.find(
      (rewardItem) =>
        rewardItem.rewardType === "ETC" && rewardItem.reward === null
    )
  ) {
    setErrorText("상품을 입력해주세요.");
    setIsRemoveModalShowing(true);
    return false;
  }

  return true;
}

export function validateSaleMenu({
  menuDetail,
  saleMenuStatus,
  modifiedSaleMenu: saleMenuInfo,
  setErrorText,
  setIsRemoveModalShowing,
}) {
  const MIN_PRICE = 300;
  const {
    menuType,
    price,
    productName,
    productStatus,
    quantityLimit,
    canUseLine,
  } = saleMenuInfo;

  if (!menuType) {
    setErrorText("메뉴 종류를 입력해주세요.");
    setIsRemoveModalShowing(true);
    return false;
  }

  if (!productName) {
    setErrorText("메뉴 이름을 입력해주세요.");
    setIsRemoveModalShowing(true);
    return false;
  }

  if (!price) {
    setErrorText("가격을 입력해주세요.");
    setIsRemoveModalShowing(true);
    return false;
  }

  if (price < MIN_PRICE) {
    setErrorText(`메뉴 가격의 최소 값은 ${MIN_PRICE.toLocaleString()}입니다.`);
    setIsRemoveModalShowing(true);
    return false;
  }

  if (typeof quantityLimit.useQuantityLimit === "undefined") {
    setErrorText("메뉴 수량 제한을 입력해주세요.");
    setIsRemoveModalShowing(true);
    return false;
  }

  if (
    quantityLimit.useQuantityLimit &&
    (saleMenuStatus
      ? saleMenuStatus.saleTime
      : menuDetail.quantityLimit.useTimeLimit) &&
    getFormatDateAndTime(
      quantityLimit.startDateOfQuantityLimit,
      quantityLimit.startTimeOfQuantityLimit
    ).getTime() >
      getFormatDateAndTime(
        quantityLimit.endDateOfQuantityLimit,
        quantityLimit.endTimeOfQuantityLimit
      ).getTime()
  ) {
    setErrorText("이벤트 종료 시간이 시작 시간보다 빠릅니다.");
    setIsRemoveModalShowing(true);
    return false;
  }

  if (
    quantityLimit.useQuantityLimit &&
    (saleMenuStatus
      ? saleMenuStatus.saleTime
      : menuDetail.quantityLimit.useTimeLimit) &&
    getFormatDateAndTime(
      quantityLimit.startDateOfQuantityLimit,
      quantityLimit.startTimeOfQuantityLimit
    ).getTime() ===
      getFormatDateAndTime(
        quantityLimit.endDateOfQuantityLimit,
        quantityLimit.endTimeOfQuantityLimit
      ).getTime()
  ) {
    setErrorText("이벤트 시간이 같습니다.");
    setIsRemoveModalShowing(true);
    return false;
  }

  if (
    (quantityLimit.useQuantityLimit &&
      (saleMenuStatus
        ? saleMenuStatus.saleCount
        : menuDetail.quantityLimit.daySellLimit) &&
      quantityLimit.countOfMaxSold === 0) ||
    isNaN(quantityLimit.countOfMaxSold)
  ) {
    setErrorText("1일 판매 수량을 입력해주세요.");
    setIsRemoveModalShowing(true);
    return false;
  }

  if (
    quantityLimit.useQuantityLimit &&
    (saleMenuStatus
      ? saleMenuStatus.saleLimit
      : menuDetail.quantityLimit.userDayBuyLimit) &&
    (quantityLimit.countOfUserDayBuy === 0 ||
      isNaN(quantityLimit.countOfUserDayBuy))
  ) {
    setErrorText("1인 일일 구매 한도를 입력해주세요.");
    setIsRemoveModalShowing(true);
    return false;
  }

  if (!productStatus) {
    setErrorText("판매 상태를 입력해주세요.");
    setIsRemoveModalShowing(true);
    return false;
  }

  if (!canUseLine) {
    setErrorText("결제 가능 라인을 입력해주세요.");
    setIsRemoveModalShowing(true);
    return false;
  }

  return true;
}

export function getDeepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function isAuthedUser() {
  if (
    localStorage.getItem("accessToken") !== undefined &&
    localStorage.getItem("accessToken") !== null
  ) {
    return true;
  } else {
    return false;
  }
}

export function getSessionValue(key) {
  return sessionStorage.getItem(key);
}

export const getPageParamsToURL = ({ search }) => {
  const pageRegex = /\?page=(\d+)/;

  if (!search.match(pageRegex)) {
    return;
  }

  const [_, matchedPage] = search.match(pageRegex);

  return parseInt(matchedPage);
};

export function isDefaultStore(storeType) {
  const item = localStorage.getItem("storeType");

  let isDefault = item !== "SMART_ORDER";
  // isDefault = false;
  return isDefault;
}
