import { apiClient } from "../api/apiClient";
import { useFetch } from "./common/useFetch";

export default function useSaleMenuDetail(productId) {
  const { data, isLoading } = useFetch(
    [productId],
    async () => {
      const { data } = await apiClient({
        url: `/products/${productId}`,
        method: "get",
      });

      return data;
    },
    {
      onError: ({ response }) => {
        alert(response.data.message);
      },
    }
  );

  return { data, isSaleMenuLoading: isLoading };
}
