import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiClient } from "../../api/apiClient";



const initialState = {
    
    content: []
    ,isLoading : false
}

const paymentOrderSlice = createSlice({
    name: "paymentOrder",
    initialState,
    reducers:{
        init: (state, action) => {
            state.storeName = "테스트";
            console.log("action : " + action);
        }
    },
    extraReducers: (builder)=> {
        // builder는 Case Reducer로 액션별로 나눠서 액션을 처리할 수 있음.
        // extraReducer를 사용한 이유는 맵핑된 내부 액션 타입이 아니라 외부 액션을 참조하려는 것임.

        builder.addCase(fetchPaymentOrderInfo.fulfilled, (state, action) => {          
            return { ...state, ...action.payload , isLoading: true};
        })
    }

});

export const fetchPaymentOrderInfo = createAsyncThunk(
    'paymentOrder/Info',
    async( params, {rejectWithValue}) => {
        if(params.buyerPhoneNumber != undefined && params.buyerPhoneNumber.length == 0){
            
            delete params.buyerPhoneNumber;
        }
        try{
            const response = await apiClient({
                url : "/payments-sorder",
                params: params,
                method : "get",
            })
            return { ...response.data};
        }catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
)

export default paymentOrderSlice;
export const {init} = paymentOrderSlice.actions;
