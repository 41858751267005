import { apiClient } from "../../api/apiClient";
import { useMutation } from "../common/useMutation";

export default function usePopupRemove({ refetch }) {
    const { mutate, isLoading } = useMutation(
        async ({ popupId }) =>
            await apiClient({
                method: "delete",
                url: `/popups/${popupId}`,
            }),
        {
            onSuccess: () => {
                refetch();
            },
        }
    );

    return { mutate, isLoading };
}
