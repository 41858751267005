import { apiClient } from "../api/apiClient";
import { useMutation } from "./common/useMutation";

export default function useSaleMenuRemove(setIsRemoveModalShowing, refetch) {
    const { mutate, isLoading } = useMutation(
        async (productId) =>
            await apiClient({
                url: `/products/${productId}`,
                method: "delete",
            }),
        {
            onSuccess: () => {
                setIsRemoveModalShowing(false);
                refetch();
            },
            onError: ({ response }) => {
                alert(response.data.message);
            },
        }
    );

    return { mutate, isLoading };
}
