import { useInfinityScroll } from "./common/useInfiniteScroll";
import { apiClient } from "../api/apiClient";

const EVENT_SIZE = 5;

export default function useEventInfiniteScroll() {
    const { data, hasNextPage, setTarget, fetchNextPage, refetch } =
        useInfinityScroll(
            [],
            async (pageParam = "") => {
                const { data } = await apiClient({
                    url: `/events?${pageParam ? `cursorId=${pageParam}` : ""}`,
                    method: "get",
                    params: {
                        size: EVENT_SIZE,
                    },
                });

                return data;
            },
            {
                onError: (response) => {
                    alert(response.data.message);
                },
            }
        );

    return {
        data,
        hasNextPage,
        setTarget,
        fetchNextPage,
        refetchInfinite: refetch,
    };
}
