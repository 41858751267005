import { useNavigate } from "react-router-dom";
import { apiClient } from "../../api/apiClient";
import { useMutation } from "../common/useMutation";

export default function useSequence() {
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(
    async (params) =>
      await apiClient({
        url: "/products/change-ordering",
        method: "post",
        data: { orderings: params },
      }),
    {
      onSuccess: () => {
        navigate("/salemenu");
      },
      onError: ({ response }) => {
        alert(response.data.message);
      },
    }
  );

  return { mutate, isLoading };
}
