import { useState, useEffect } from "react";
import { apiClient, wrapPromise } from "../../api/apiClient";

const initOptions = {
  onSuccess: (data) => {},
  onError: (error) => {},
};

export function useFetch(keys, fetcher, options) {
  const [data, setData] = useState(null);
  const [refetcher, setRefetcher] = useState();

  const newOptions = { ...initOptions, ...options };
  const { onSuccess, onError, select } = newOptions;

  const refetch = () => {
    setRefetcher(new Date());
  };

  const setupSelect = () => {
    if (!select) {
      return;
    }

    return apiClient.interceptors.response.use((response) => ({
      ...response,
      data: select(response.data),
    }));
  };

  const removeSelect = (interceptor) => {
    apiClient.interceptors.response.eject(interceptor);
  };

  const fetchData = async () => {
    try {
      const response = await fetcher();
      onSuccess(response);

      return response;
    } catch (error) {
      onError(error);
    }
  };

  useEffect(() => {
    const interceptor = setupSelect();
    const response = wrapPromise(fetchData());

    setData(response);
    removeSelect(interceptor);
  }, [...keys, refetcher]);

  return { data: data?.read(), refetch };
}
