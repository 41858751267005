import { useState, useMemo, useCallback } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useSaleMenuRemove from "../../../hooks/useSaleMenuRemove";

export default function SaleMenuList({ saleMenuList, refetch }) {
    const navigate = useNavigate();
    const [isRemoveModalShowing, setIsRemoveModalShowing] = useState(false);
    const { mutate, isLoading } = useSaleMenuRemove(
        setIsRemoveModalShowing,
        refetch
    );
    const [productId, setProductId] = useState();

    const handleRemoveModalShow = () => {
        setIsRemoveModalShowing(true);
    };

    const handleRemoveModalClose = () => {
        setIsRemoveModalShowing(false);
    };

    const handleRemoveBtnClick = (productId) => {
        setProductId(productId);
        handleRemoveModalShow();
    };

    const handleRemoveConfirmBtnClick = (productId) => {
        if (isLoading) {
            return;
        }

        mutate(productId);
    };

    const getSaleStatus = useCallback(({ code }) => {
        if (code === 1) {
            return "판매 중";
        }

        return "판매 중지";
    }, []);

    const mapedSaleMenuList = useMemo(() => {
        return saleMenuList.map(
            ({
                productId,
                productName,
                productDescription,
                descriptionType,
                toDayDescription,
                productStatus,
            }) => {
                return (
                    <div
                        key={productId}
                        className={`SalePrd-Item d-flex ${
                            productStatus.code === 2 && "stop"
                        }`}
                    >
                        <div className="prd-infobox">
                            <p className="prd-tit">
                                {productName}
                                <span className="state">
                                    {getSaleStatus(productStatus)}
                                </span>
                            </p>
                            <p
                                className="prd-desc"
                                style={{ whiteSpace: "pre-line" }}
                            >
                                {descriptionType.code === 1
                                    ? productDescription
                                    : toDayDescription}
                            </p>
                        </div>
                        <div className="prd-btnbox d-flex">
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={() =>
                                    navigate(`/salemenu/${productId}`)
                                }
                            >
                                변경
                            </button>
                            <button
                                type="button"
                                className="btn btn-default"
                                onClick={() => handleRemoveBtnClick(productId)}
                            >
                                삭제
                            </button>
                        </div>
                    </div>
                );
            }
        );
    }, [saleMenuList]);

    return (
        <>
            {mapedSaleMenuList}
            {/* 상품 삭제 확인 모달 */}
            <Modal
                show={isRemoveModalShowing}
                onHide={handleRemoveModalClose}
                keyboard={false}
                size="sm"
                centered
                className="demoModal modal-prdAdd text-center"
            >
                <Modal.Header>
                    <Modal.Title>
                         해당 메뉴를 <br/>
                         삭제 하시겠습니까?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   판매 메뉴 관리 목록에서 삭제됩니다.
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    {isLoading ? (
                        <div>
                            <Spinner animation="border" />
                        </div>
                    ) : (
                        <>
                            <Button
                                variant="modal-secondary"
                                className="btn-clear"
                                onClick={handleRemoveModalClose}
                            >
                                취소
                            </Button>
                            <Button
                                variant="modal-primary"
                                className="btn-clear"
                                onClick={() =>
                                    handleRemoveConfirmBtnClick(productId)
                                }
                            >
                                확인
                            </Button>
                        </>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
}
