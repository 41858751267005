import React from "react";
import { useEffect } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { getApiUrl } from "../../function/common";
import AppleLogin from "react-apple-login";

export default function Apple(){

    const { name } = useParams();

    const AppleLogin = async () => {
        let obj = {
            "oauthAccessToken": window.location.href.split('?id_token=')[1],
            "oauthType": "APPLE"
        }
        console.log(obj);
        const response = await axios.post(
            getApiUrl('v1/oauth/login' ),
            obj,
            axios.defaults.headers.common['ContentType'] = "application/json;",
        ).then(
            function(response){
                console.log('----------------------------------');
                console.log(response);
                if(response.status === 200){
                    console.log(response)
                }
            }
        ).catch(function(error){
            console.log('error');
        })
    }
    useEffect (() => {
        AppleLogin();
        
    }, []);

    return (
        <>
        </>
    )
}