import { useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getDeepCopy, setCommas } from "../../../function/common";
import useRemoveBodyStyle from "../../../hooks/common/useRemoveBodyStyle";

import useCalculateInfiniteScroll from "../../../hooks/paymentinfo/useCalculateInfiniteScroll";
import AdminHeader from "../../../pages/UserMain/components/AdminHeader";
import LoadingPortal from "../../../portals/LoadingPortal";
import LoadingModal from "../../loadingmodal/LoadingModal";
import CalculateDetailInfo from "../calculatedetailinfo/CalculateDetailInfo";
import AccountingGNB from "../../../pages/accounting/header/AccountingGNB";

export default function CalculateInfo() {
  useRemoveBodyStyle();
  const navigate = useNavigate();
  const { calculateInfos, hasNextPage, setTarget } =
    useCalculateInfiniteScroll();
  const [isShowingPopup, setIsShowingPopup] = useState(false);
  const [settlementId, setSettlementId] = useState();

  if (!calculateInfos) {
    return (
      <LoadingPortal>
        <LoadingModal />
      </LoadingPortal>
    );
  }

  const handleItemClick = (settlementId) => {
    setSettlementId(settlementId);
    setIsShowingPopup(true);
  };

  const reversedCalculateInfos = getDeepCopy(calculateInfos);

  const mapedCalculateInfos = reversedCalculateInfos.map(
    (calculateInfo, index) => {
      const {
        settlementId,
        startDate,
        endDate,
        settlePrice,
        settleStatus,
        year,
      } = calculateInfo;
      const [strStartYear, strStartMonth, strStartDate] = startDate.split("-");
      const [strEndYear, strEndMonth, strEndDate] = endDate.split("-");

      if (!index) {
        return (
          <Fragment key={settlementId}>
            <div className="Listyear">{year}</div>
            <div
              className={`Listitem px-4-5 py-4 ${
                settleStatus.code === 2 ? "done" : ""
              }`}
              onClick={() => handleItemClick(settlementId)}
            >
              <div className="align-items-center d-flex justify-content-between">
                <div className="txtbox">
                  <p className="date mb-0">
                    {strStartMonth}월 {strStartDate}일 ~ {strEndMonth}월{" "}
                    {strEndDate}일
                  </p>
                  <p className="price">
                    <span className="num">{setCommas(settlePrice)}</span> 원
                  </p>
                </div>
                <div className="align-items-center btnbox d-flex">
                  <span>{settleStatus.description}</span>
                  <div className="arrow"></div>
                </div>
              </div>
            </div>
          </Fragment>
        );
      }

      if (year !== reversedCalculateInfos[index - 1].year) {
        return (
          <Fragment key={settlementId}>
            <div className="Listyear">{year}</div>
            <div
              className={`Listitem px-4-5 py-4 ${
                settleStatus.code === 2 ? "done" : ""
              }`}
              onClick={() => handleItemClick(settlementId)}
            >
              <div className="align-items-center d-flex justify-content-between">
                <div className="txtbox">
                  <p className="date mb-0">
                    {strStartMonth}월 {strStartDate}일 ~ {strEndMonth}월{" "}
                    {strEndDate}일
                  </p>
                  <p className="price">
                    <span className="num">{setCommas(settlePrice)}</span> 원
                  </p>
                </div>
                <div className="align-items-center btnbox d-flex">
                  <span>{settleStatus.description}</span>
                  <div className="arrow"></div>
                </div>
              </div>
            </div>
          </Fragment>
        );
      }

      return (
        <div
          className={`Listitem px-4-5 py-4 ${
            settleStatus.code === 2 ? "done" : ""
          }`}
          key={settlementId}
          onClick={() => handleItemClick(settlementId)}
        >
          <div className="align-items-center d-flex justify-content-between">
            <div className="txtbox">
              <p className="date mb-0">
                {strStartMonth}월 {strStartDate}일 ~ {strEndMonth}월{" "}
                {strEndDate}일
              </p>
              <p className="price">
                <span className="num">{setCommas(settlePrice)}</span> 원
              </p>
            </div>
            <div className="align-items-center btnbox d-flex">
              <span>{settleStatus.description}</span>
              <div className="arrow"></div>
            </div>
          </div>
        </div>
      );
    }
  );

  return (
    <>
      {isShowingPopup == false ? <AccountingGNB /> : ""}
      <div className="demo mw-100">
        <div className="TPrdAdminPage">
          <div className="container-fluid g-0">
            {/* <nav className="sub-navbar">
                            <p className="navbar-title">결제 / 매출 / 정산</p>
                            <button
                                type="button"
                                className="btn-clear btn-back"
                                onClick={() => {
                                    sessionStorage.clear();
                                    navigate("/storemain");
                                }}
                            >
                                <img
                                    className="img-fluid"
                                    alt="뒤로가기"
                                    src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                                />
                            </button>
                            <button
                                className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasNavbar"
                                aria-controls="offcanvasNavbar"
                            >
                                <img
                                    className="img-fluid"
                                    alt="메뉴"
                                    src="https://www.flexdaycdn.net/public/images/ticket/menu3.png"
                                />
                            </button>
                            <AdminHeader />
                        </nav> */}

            <div className="PayCateTab">
              {/* <div
                                className="nav nav-pills justify-content-around mb-4-5 px-4-5"
                                id="pills-tab"
                                role="tablist"
                            >
                                <div className="nav-item" role="presentation">
                                    <Link to="/paymentInfo">
                                        <button
                                            className="nav-link PayCateBtn"
                                            id="pills-content1-tab"
                                            type="button"
                                            role="tab"
                                        >
                                            결제/사용 현황
                                        </button>
                                    </Link>
                                </div>
                                <div className="nav-item" role="presentation">
                                    <Link to="/salesInfo">
                                        <button
                                            className="nav-link PayCateBtn"
                                            id="pills-content2-tab"
                                            type="button"
                                            role="tab"
                                        >
                                            매출 내역
                                        </button>
                                    </Link>
                                </div>
                                <div className="nav-item" role="presentation">
                                    <Link to="/settlementinfo">
                                        <button
                                            className="nav-link PayCateBtn active"
                                            id="pills-content3-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-content3"
                                            type="button"
                                            role="tab"
                                        >
                                            정산 내역
                                        </button>
                                    </Link>
                                </div>
                            </div> */}
              <div className="tab-content" id="pills-tabContent">
                <div id="pills-content3" role="tabpanel">
                  <div className="SettleList">
                    <div className="SettleTop2 pb-4-5 px-4-5 text-center">
                      <p className="PageTit mb-2">
                        이번 주(월요일~일요일) 결제 금액은
                        <br />
                        <span className="color">다다음주 월요일</span>에
                        지급됩니다.
                        <br />
                        <span
                          style={{
                            fontSize: "1.2rem",
                            color: "#6d6d6d",
                          }}
                        >
                          (매출 마지막일 기준 6영업일)
                        </span>
                      </p>
                      <div className="accordion ver3">
                        <div className="accordion-item bg-transparent border-0">
                          <p className="accordion-headerd mb-0">
                            <button
                              className="accordion-button justify-content-center mt-3 px-2 py-0 bg-transparent collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                            >
                              정산일 한눈에 확인하기
                            </button>
                          </p>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body px-0">
                              <div className="Calendar p-4 mt-4">
                                <div className="Week-Day row g-0 mb-4">
                                  <div className="col">월</div>
                                  <div className="col">화</div>
                                  <div className="col">수</div>
                                  <div className="col">목</div>
                                  <div className="col">금</div>
                                  <div className="col ColorF">토</div>
                                  <div className="col ColorA">일</div>
                                </div>
                                <div className="Week-NowDate row g-0 mb-2 py-2">
                                  <div className="col">1</div>
                                  <div className="col">2</div>
                                  <div className="col">3</div>
                                  <div className="col">4</div>
                                  <div className="col">5</div>
                                  <div className="col ColorF">6</div>
                                  <div className="col ColorA">7</div>
                                </div>
                                <div className="Week-Txt ColorA mb-4">
                                  일주일 매출
                                </div>
                                <div className="Week-NextDate row g-0">
                                  <div className="col">8</div>
                                  <div className="col">9</div>
                                  <div className="col">10</div>
                                  <div className="col">11</div>
                                  <div className="col">
                                    <span>12</span>
                                    <p
                                      className="mb-0 mt-3 ColorA"
                                      style={{ opacity: 0 }}
                                    >
                                      정산일
                                    </p>
                                  </div>
                                  <div className="col ColorF">13</div>
                                  <div className="col ColorA">14</div>
                                </div>
                                <div className="Week-NextDate row g-0">
                                  <div className="col ColorA active">
                                    <span>15</span>
                                    <p className="mb-0 mt-3 ColorA">정산일</p>
                                  </div>
                                  <div className="col">16</div>
                                  <div className="col">17</div>
                                  <div className="col">18</div>
                                  <div className="col">19</div>
                                  <div className="col ColorF">20</div>
                                  <div className="col ColorA">21</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="SettleBottom2">
                      <div className="Listbox">{mapedCalculateInfos}</div>
                      {hasNextPage && <div ref={setTarget} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isShowingPopup && (
        <CalculateDetailInfo
          settlementId={settlementId}
          setIsShowingPopup={setIsShowingPopup}
        />
      )}
    </>
  );
}
