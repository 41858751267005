import { apiClient } from "../../api/apiClient";
import { useMutation } from "../common/useMutation";

export default function useProductRemove({ setIsRemoveModal, refetch }) {
  const { mutate, isLoading } = useMutation(
    async (productId) =>
      await apiClient({
        url: `/products/${productId}`,
        method: "delete",
      }),
    {
      onSuccess: () => {
        setIsRemoveModal({
          text: "",
          showing: false,
        });
        refetch();
      },
      onError: ({ response }) => {
        alert(response.data.message);
      },
    }
  );

  return { removeMutate: mutate, isRemoveLoading: isLoading };
}
