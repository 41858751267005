import { apiClient } from "../../api/apiClient";
import { useFetch } from "../common/useFetch";

export function usePopup() {
    const { data, refetch } = useFetch([], async () => {
        const { data } = await apiClient({
            method: "get",
            url: "/popups",
        });

        return data;
    });

    return { data, refetch };
}
