import { useNavigate } from "react-router-dom";
import { useMutation } from "../common/useMutation";
import { apiClient } from "../../api/apiClient";

export default function useImageUpload() {
    const navigate = useNavigate();

    const { mutate: mutateImgUpload, isLoading } = useMutation(
        async (params) =>
            await apiClient({
                method: "put",
                url: "/stores/me/images",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                data: params,
            }),
        {
            onSuccess: () => {
                navigate("/storemain");
            },
            onError: ({ response }) => {
                alert(response.data.message);
            },
        }
    );

    return { mutateImgUpload, isLoading };
}
