import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AdminHeader from "../../UserMain/components/AdminHeader";
import "../popupAdd/PopupAdd.css";
import { useReducer, useState } from "react";
import Datepicker from "../../../components/common/Datepicker";
import { getFormatDate } from "../../../function/common";
import { usePopupAdd } from "../../../hooks/popup/usePopupAdd";
import usePopupDetail from "../../../hooks/popup/usePopupDetail";
import {
    initialState,
    popupModifyReducer,
} from "../../../reducers/popupModify/popupModifyReducer";
import usePopupRemove from "../../../hooks/popup/usePopupRemove";
import usePopupModify from "../../../hooks/popup/usePopupModify";
import { popupModalReducer } from "../../../reducers/popupAdd/popupModalReducer";
import { initPopupModalState } from "../../../reducers/popupModify/popupModifyModalReducer";
import { Button, Form, Modal } from "react-bootstrap";
import LoadingPortal from "../../../portals/LoadingPortal";
import LoadingModal from "../../../components/loadingmodal/LoadingModal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";

function resize({ target }) {
    target.style.height = "1px";

    if (target.name === "common") {
        if (window.innerWidth >= 599) {
            target.style.height =
                target.scrollHeight <= 134
                    ? 134 + "px"
                    : target.scrollHeight + "px";
        } else {
            target.style.height =
                target.scrollHeight <= 106
                    ? 106 + "px"
                    : target.scrollHeight + "px";
        }
        return;
    }

    if (target.name === "additionalDesc") {
        if (window.innerWidth >= 599) {
            target.style.height =
                target.scrollHeight <= 134
                    ? 134 + "px"
                    : target.scrollHeight + "px";
        } else {
            target.style.height =
                target.scrollHeight <= 106
                    ? 106 + "px"
                    : target.scrollHeight + "px";
        }
        return;
    }

    if (window.innerWidth >= 599) {
        target.style.height =
            target.scrollHeight <= 168
                ? 168 + "px"
                : target.scrollHeight + "px";
    } else {
        target.style.height =
            target.scrollHeight <= 134
                ? 134 + "px"
                : target.scrollHeight + "px";
    }
}

export default function PopupModify() {
    const navigate = useNavigate();
    const { popupId } = useParams();
    const [popupState, popupStateDispatch] = useReducer(
        popupModifyReducer,
        initialState
    );
    const { mutate, isLoading } = usePopupModify();
    usePopupDetail({ popupId, popupStateDispatch });
    const [popupModalState, popupModalStateDispatch] = useReducer(
        popupModalReducer,
        initPopupModalState
    );

    if (!Object.entries(popupState).length) {
        return;
    }

    const validatePopupInfo = () => {
        const { storeTitle, popupImageType, title, useButton, buttonText } =
            popupState;

        if (!storeTitle) {
            popupModalStateDispatch({ type: "warning_title" });
            return true;
        }

        if (popupImageType === "NONE") {
            popupModalStateDispatch({ type: "warning_image" });
            return true;
        }

        if (popupImageType === "MANUAL" && !popupState.imageName) {
            popupModalStateDispatch({ type: "warning_image_detail" });
            return true;
        }

        if (!title) {
            popupModalStateDispatch({ type: "warning_popup_title" });
            return true;
        }

        if (useButton && !buttonText) {
            popupModalStateDispatch({ type: "warning_buttonName" });
            return true;
        }

        return false;
    };

    const handleImageUpload = ({ target }) => {
        const image = target.files[0];
        const name = image.name;

        popupStateDispatch({
            type: "added_image",
            payload: { name, image },
        });

        target.value = "";
    };

    const handleImageRemoveClick = () => {
        popupStateDispatch({ type: "reseted_imageName" });
    };

    const handleRegisterClick = () => {
        if (isLoading) {
            return;
        }

        if (validatePopupInfo()) {
            return;
        }

        mutate({ popupId, popupInfo: popupState });
    };

    return (
        <>
            <div className="demo">
                <div className="TPopupAddPage">
                    <div className="container-fluid g-0">
                        <nav className="sub-navbar">
                            <p className="navbar-title">팝업 변경</p>
                            <button
                                type="button"
                                className="btn-clear btn-back"
                                onClick={() => navigate("/storemain")}
                            >
                                <img
                                    className="img-fluid"
                                    alt="뒤로가기"
                                    src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                                />
                            </button>
                            <button
                                className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasNavbar"
                                aria-controls="offcanvasNavbar"
                            >
                                <img
                                    className="img-fluid"
                                    alt="메뉴"
                                    src="https://www.flexdaycdn.net/public/images/ticket/menu3.png"
                                />
                            </button>
                            <AdminHeader />
                        </nav>

                        <div className="PopupGuide">
                            <div className="Guide-wrap">
                                <div className="Guide-Box">
                                    <div className="txt">
                                        <span className="small">①</span>번<br />
                                        영역
                                    </div>
                                    <div className="space1">
                                        강조하실 문구를
                                        <br />
                                        작성해주세요!
                                    </div>
                                </div>
                                <div className="Guide-Box">
                                    <div className="txt">
                                        <span className="small">②</span>번<br />
                                        영역
                                    </div>
                                    <div className="space2">
                                        상세 설명을 작성해주세요.
                                        <br />
                                        날짜 기입도 가능합니다.
                                        <br />
                                        팝업 문구는 최대 3줄까지 노출됩니다.
                                    </div>
                                </div>
                            </div>
                            <Swiper
                                loop={true}
                                pagination={{
                                    el: ".Guide-pag",
                                    type: "fraction",
                                }}
                                initialSlide={0}
                                modules={[Pagination, Navigation]}
                                className="Guide-Slider"
                            >
                                {
                                    <>
                                        <SwiperSlide className="slide1">
                                            <div className="tit">
                                                <span className="color">
                                                    강조하실 문구
                                                </span>
                                                를
                                                <br />
                                                작성해주세요!
                                            </div>
                                            <div className="sub">
                                                상세 설명을 작성해주세요.
                                                <br />
                                                날짜 기입도 가능합니다.
                                                <br />
                                                팝업 문구는 최대 3줄까지
                                                노출됩니다.
                                            </div>
                                            <div className="button">버튼명</div>
                                        </SwiperSlide>
                                        <SwiperSlide className="slide2">
                                            <div className="tit">
                                                <span className="color">
                                                    강조하실 문구
                                                </span>
                                                를
                                                <br />
                                                작성해주세요!
                                            </div>
                                            <div className="sub">
                                                상세 설명을 작성해주세요.
                                                <br />
                                                날짜 기입도 가능합니다.
                                                <br />
                                                팝업 문구는 최대 3줄까지
                                                노출됩니다.
                                            </div>
                                            <div className="button">버튼명</div>
                                        </SwiperSlide>
                                    </>
                                }
                            </Swiper>
                            <div className="swiper-pagination Guide-pag"></div>
                        </div>

                        <div className="PopupAddBox py-4-5 px-4-5">
                            <div className="PopupAdd-Form">
                                <div className="PopupAdd-Formitem pb-5">
                                    <p className="PopupAdd-tit mb-0">
                                        1. 관리자용 팝업 제목<span>*</span>
                                    </p>
                                    <p className="PopupAdd-sub">
                                        사용자에게는 표시되지 않습니다.
                                    </p>
                                    <div className="PopupAdd-inputbox">
                                        <input
                                            type="text"
                                            className="form-control InputType"
                                            placeholder="예) 인제페이 출시 안내"
                                            value={popupState.storeTitle || ""}
                                            onChange={({ target }) =>
                                                popupStateDispatch({
                                                    type: "changed_storeTitle",
                                                    payload: target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="PopupAdd-Formitem pb-5">
                                    <p className="PopupAdd-tit">
                                        2. 팝업 노출 기간<span>*</span>
                                    </p>
                                    <Datepicker
                                        type="popup"
                                        eventDate={{
                                            startDate: popupState.startDate,
                                            endDate: popupState.endDate,
                                        }}
                                        popupStateDispatch={popupStateDispatch}
                                    />
                                </div>

                                <div className="PopupAdd-Formitem pb-5">
                                    <p className="PopupAdd-tit">
                                        3. 팝업 배경 이미지<span>*</span>
                                    </p>
                                    <div className="PopupAdd-radiobox d-flex ver2">
                                        <div className="radioBox">
                                            <input
                                                className="form-check-input"
                                                id="PrdOption1-1"
                                                type="radio"
                                                value=""
                                                name="PrdOption1"
                                                onChange={() => {
                                                    popupStateDispatch({
                                                        type: "changed_popupImageType",
                                                        payload: {
                                                            code: 1,
                                                            description:
                                                                "기본 이미지 1",
                                                        },
                                                    });
                                                }}
                                                checked={
                                                    popupState.popupImageType
                                                        .code === 1 || ""
                                                }
                                            />
                                            <label
                                                className="RadioLabel"
                                                htmlFor="PrdOption1-1"
                                            >
                                                <span>기본 이미지 1</span>
                                            </label>
                                        </div>
                                        <div className="radioBox">
                                            <input
                                                className="form-check-input"
                                                id="PrdOption1-2"
                                                type="radio"
                                                value=""
                                                name="PrdOption1"
                                                onChange={() => {
                                                    popupStateDispatch({
                                                        type: "changed_popupImageType",
                                                        payload: {
                                                            code: 2,
                                                            description:
                                                                "기본 이미지 2",
                                                        },
                                                    });
                                                }}
                                                checked={
                                                    popupState.popupImageType
                                                        .code === 2 || ""
                                                }
                                            />
                                            <label
                                                className="RadioLabel"
                                                htmlFor="PrdOption1-2"
                                            >
                                                <span>기본 이미지 2</span>
                                            </label>
                                        </div>
                                        <div className="radioBox">
                                            <input
                                                className="form-check-input"
                                                id="PrdOption1-3"
                                                type="radio"
                                                value=""
                                                name="PrdOption1"
                                                onChange={() =>
                                                    popupStateDispatch({
                                                        type: "changed_popupImageType",
                                                        payload: {
                                                            code: 3,
                                                            description:
                                                                "직접 등록",
                                                        },
                                                    })
                                                }
                                                checked={
                                                    popupState.popupImageType
                                                        .code === 3 || ""
                                                }
                                            />
                                            <label
                                                className="RadioLabel"
                                                htmlFor="PrdOption1-3"
                                            >
                                                <span>직접 등록</span>
                                            </label>
                                        </div>
                                        {popupState.popupImageType.code ===
                                            3 && (
                                            <div className="Pop-filebox show">
                                                <input
                                                    type="file"
                                                    id="pop_file"
                                                    className="Pop-File d-none"
                                                    onChange={handleImageUpload}
                                                    accept=".jpg, .jpeg, .png"
                                                />
                                                <label
                                                    htmlFor="pop_file"
                                                    className={`form-control InputType Pop-File-Label ${
                                                        popupState.imageName
                                                            ? "on"
                                                            : ""
                                                    }`}
                                                >
                                                    <img
                                                        className="img-fluid file_icon"
                                                        alt=""
                                                        src="https://www.flexdaycdn.net/public/images/ticket_ver2/file_icon.png"
                                                    />
                                                    <span className="file_text">
                                                        {popupState.imageName ||
                                                            "첨부파일을 등록해주세요. (.jpg, .jpeg, .png 파일 가능)"}
                                                    </span>
                                                    <button
                                                        className="btn btn-clear file_delete"
                                                        onClick={() =>
                                                            handleImageRemoveClick()
                                                        }
                                                    >
                                                        <img
                                                            className="img-fluid"
                                                            alt=""
                                                            src="https://www.flexdaycdn.net/public/images/ticket_ver2/file_delete.png"
                                                        />
                                                    </button>
                                                </label>
                                                <p className="PopupAdd-sub mt-2 mb-0">
                                                    이미지 업로드 최대 용량은
                                                    5mb입니다.
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="PopupAdd-Formitem pb-5">
                                    <p className="PopupAdd-tit">
                                        4. 팝업 타이틀<span>*</span>
                                    </p>
                                    <div className="PopupAdd-inputbox Input-placeholderbox">
                                        {!popupState.title && (
                                            <div className="Input-placeholder">
                                                <span className="small">①</span>
                                                번 영역에 들어갈 내용을 입력한
                                                후,
                                                <br />
                                                컬러가 필요한 문구는 {} 괄호안에
                                                작성해주세요.
                                                <br />
                                                (줄바꿈 필요 시, Enter입력)
                                            </div>
                                        )}
                                        <textarea
                                            className="form-control ph InputType"
                                            rows="3"
                                            onChange={({ target }) =>
                                                popupStateDispatch({
                                                    type: "changed_title",
                                                    payload: target.value,
                                                })
                                            }
                                            value={popupState.title}
                                            onKeyDown={resize}
                                            onKeyUp={resize}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="PopupAdd-Formitem pb-5">
                                    <p className="PopupAdd-tit">
                                        5. 팝업 서브 타이틀
                                    </p>
                                    <div className="PopupAdd-inputbox Input-placeholderbox">
                                        {!popupState.subTitle && (
                                            <div className="Input-placeholder">
                                                <span className="small">②</span>
                                                번 영역에 들어갈 내용을
                                                입력해주세요.
                                                <br />
                                                (줄바꿈 필요 시, Enter입력)
                                            </div>
                                        )}
                                        <textarea
                                            className="form-control ph InputType ver2"
                                            rows="2"
                                            onChange={({ target }) =>
                                                popupStateDispatch({
                                                    type: "changed_subTitle",
                                                    payload: target.value,
                                                })
                                            }
                                            value={popupState.subTitle}
                                            onKeyDown={resize}
                                            onKeyUp={resize}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="PopupAdd-Formitem pb-5">
                                    <p className="PopupAdd-tit mb-0">
                                        6. 팝업 버튼
                                    </p>
                                    <p className="PopupAdd-sub">
                                        링크 이동이 필요한 경우, 버튼에 url을
                                        삽입할 수 있습니다.
                                    </p>
                                    <div className="PopupAdd-radiobox d-flex">
                                        <div className="radioBox">
                                            <input
                                                className="form-check-input 
                              prdLimited"
                                                onChange={() =>
                                                    popupStateDispatch({
                                                        type: "changed_buttonStatus",
                                                        payload: true,
                                                    })
                                                }
                                                id="PrdOption2-1"
                                                type="radio"
                                                value=""
                                                name="PrdOption2"
                                                checked={popupState.useButton}
                                            />
                                            <label
                                                className="RadioLabel"
                                                htmlFor="PrdOption2-1"
                                            >
                                                <span>사용하기</span>
                                            </label>
                                        </div>
                                        <div className="radioBox">
                                            <input
                                                className="form-check-input"
                                                onChange={() =>
                                                    popupStateDispatch({
                                                        type: "changed_buttonStatus",
                                                        payload: false,
                                                    })
                                                }
                                                id="PrdOption2-2"
                                                type="radio"
                                                value=""
                                                name="PrdOption2"
                                                checked={!popupState.useButton}
                                            />
                                            <label
                                                className="RadioLabel"
                                                htmlFor="PrdOption2-2"
                                            >
                                                <span>사용안함</span>
                                            </label>
                                        </div>
                                    </div>
                                    {popupState.useButton && (
                                        <div className="PopupAdd-subbox prdLimited-box active">
                                            <div className="subBox">
                                                <p className="tit">
                                                    버튼명<span>*</span>
                                                </p>
                                                <div className="PopupAdd-inputbox g-0 position-relative">
                                                    <input
                                                        type="text"
                                                        pattern="\d*"
                                                        className="form-control InputType"
                                                        placeholder="예) 애플 스토어 바로가기"
                                                        value={
                                                            popupState.buttonText
                                                        }
                                                        onChange={({
                                                            target,
                                                        }) =>
                                                            popupStateDispatch({
                                                                type: "changed_buttonText",
                                                                payload:
                                                                    target.value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="subBox">
                                                <p className="tit">링크 연결</p>
                                                <div className="PopupAdd-inputbox g-0 position-relative">
                                                    <input
                                                        type="text"
                                                        pattern="\d*"
                                                        className="form-control InputType"
                                                        placeholder="연결할 url 링크를 입력해주세요."
                                                        value={
                                                            popupState.buttonLink
                                                        }
                                                        onChange={({
                                                            target,
                                                        }) =>
                                                            popupStateDispatch({
                                                                type: "changed_buttonLink",
                                                                payload:
                                                                    target.value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="PopupAdd-Btnbox">
                                <button
                                    type="button"
                                    className="btn btn-default"
                                    onClick={handleRegisterClick}
                                >
                                    등록
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={popupModalState.isShowing}
                onHide={() => popupModalStateDispatch({ type: "hide_modal" })}
                keyboard={false}
                size="sm"
                centered
                className="demoModal modal-prdAdd text-center"
            >
                <Modal.Header>
                    <Modal.Title>{popupModalState.text}</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button
                        variant="modal-primary"
                        className="btn-clear w-100"
                        onClick={() =>
                            popupModalStateDispatch({ type: "hide_modal" })
                        }
                    >
                        확인
                    </Button>
                </Modal.Footer>
            </Modal>
            {isLoading && (
                <LoadingPortal>
                    <LoadingModal type="black" />
                </LoadingPortal>
            )}
        </>
    );
}
