import SaleMenuNav from "../../../components/salemenu/salemenunav/SaleMenuNav";
import SaleMenuList from "../../../components/salemenu/salemenulist/SaleMenuList";
import useSaleMenu from "../../../hooks/useSaleMenu";
import "./SaleMenu.css";
import { useNavigate } from "react-router-dom";
import AdminHeader from "../../UserMain/components/AdminHeader";
import useRemoveBodyStyle from "../../../hooks/common/useRemoveBodyStyle";
import LoadingPortal from "../../../portals/LoadingPortal";
import LoadingModal from "../../../components/loadingmodal/LoadingModal";

export default function SaleMenu() {
  const navigate = useNavigate();
  useRemoveBodyStyle();
  const { data: saleMenuList, isLoading, refetch } = useSaleMenu();

  if (isLoading) {
    return (
      <LoadingPortal>
        <LoadingModal />
      </LoadingPortal>
    );
  }

  if (!saleMenuList) {
    return null;
  }

  return (
    <div className="demo">
      <div className="TSalePrdPage">
        <div className="container-fluid g-0">
          <nav className="sub-navbar">
            <p className="navbar-title">판매 메뉴 관리</p>
            <button
              type="button"
              className="btn-clear btn-back"
              onClick={() => navigate("/storemain")}
            >
              <img
                className="img-fluid"
                alt="뒤로가기"
                src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
              />
            </button>
            <button
              className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              <img
                className="img-fluid"
                alt="메뉴"
                src="https://www.flexdaycdn.net/public/images/ticket/menu3.png"
              />
            </button>
            <AdminHeader />
          </nav>

          <div className="SalePrdBox py-4-5 px-4-5">
            <SaleMenuNav />

            <div className="SalePrd-Itembox">
              <SaleMenuList saleMenuList={saleMenuList} refetch={refetch} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
