import { useNavigate } from "react-router-dom";
import LoadingModal from "../../../components/loadingmodal/LoadingModal";
import useRemoveBodyStyle from "../../../hooks/common/useRemoveBodyStyle";
import useParticipation from "../../../hooks/event/useParticipation";
import useParticipationExcel from "../../../hooks/event/useParticipationExcel";
import LoadingPortal from "../../../portals/LoadingPortal";
import AdminHeader from "../../UserMain/components/AdminHeader";
import "./EventParticipation.css";

export default function EventParticipation() {
  const navigate = useNavigate();
  const { participationInfo } = useParticipation();
  const { excelMutate, isExcelLoading } = useParticipationExcel();
  useRemoveBodyStyle();

  if (!participationInfo) {
    return;
  }

  const {
    eventName,
    startDate,
    endDate,
    stampList,
    countOfParticipation,
    rewardInfo,
  } = participationInfo;

  const mapedStampList = stampList.map((stamp, index) => {
    const { id, stampCount, rewardType, productName, reward } = stamp;

    if (rewardType === "INNER_PRODUCT") {
      return (
        <div className="detail-item d-flex" key={id}>
          <div className="tit">리워드 {index + 1}</div>
          <div className="contents">
            스탬프 {stampCount}개, {productName}
          </div>
        </div>
      );
    }

    return (
      <div className="detail-item d-flex" key={id}>
        <div className="tit">리워드 {index + 1}</div>
        <div className="contents">
          스탬프 {stampCount}개, {reward} 응모권
        </div>
      </div>
    );
  });

  const mapedRewardInfo = rewardInfo.map((reward, index) => {
    const { rewardTargetName, rewardTargetCount, rewardTargetPercent } = reward;

    const [prefix, suffix] = rewardTargetName.split(/\d/);
    const rewardTargetNum = rewardTargetName.replace(/[^0-9]/g, "");

    return (
      <div className="detail-item d-flex" key={index}>
        <div className="tit">{`${prefix} ${rewardTargetNum} ${suffix}`}</div>
        <div className="contents">
          {rewardTargetCount.toLocaleString()}명 ({rewardTargetPercent}%)
        </div>
      </div>
    );
  });

  return (
    <>
      <div className="demo">
        <div className="TEventStatusPage">
          <div className="container-fluid g-0">
            <nav className="sub-navbar">
              <p className="navbar-title">이벤트 참여 현황</p>
              <button
                type="button"
                className="btn-clear btn-back"
                onClick={() => navigate("/event")}
              >
                <img
                  className="img-fluid"
                  alt="뒤로가기"
                  src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                />
              </button>
              <button
                className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
              >
                <img
                  className="img-fluid"
                  alt="메뉴"
                  src="https://www.flexdaycdn.net/public/images/ticket/menu3.png"
                />
              </button>
              <AdminHeader />
            </nav>
            <div className="EventStatusBox py-4-5">
              <div className="EventStatus-Top mb-4 px-4-5">
                <div className="PrdTbl-excelbox pc">
                  <button
                    type="button"
                    className="btn-clear PrdTbl-down"
                    onClick={() => excelMutate()}
                    style={{ width: "auto" }}
                  >
                    <span>엑셀 다운로드</span>
                    <img
                      className="img-fluid"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/down_icon.png"
                    />
                  </button>
                </div>
                <div className="PrdTbl-excelbox tablet text-center">
                  상세 현황은 PC에서 엑셀 파일을 <br />
                  다운로드 받으신 후 확인 가능합니다.
                </div>
                <div className="detail-box">
                  <div className="detail-item d-flex">
                    <div className="tit">이벤트명</div>
                    <div className="contents">{eventName}</div>
                  </div>
                  <div className="detail-item d-flex">
                    <div className="tit">운영기간</div>
                    <div className="contents">
                      {startDate} ~ {endDate}
                    </div>
                  </div>
                  {mapedStampList}
                </div>
                <div className="detail-box ver2">
                  <div className="detail-item d-flex">
                    <div className="tit">총 참여자</div>
                    <div className="contents">
                      {countOfParticipation.toLocaleString()}명
                    </div>
                  </div>
                  {mapedRewardInfo}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isExcelLoading && (
        <LoadingPortal>
          <LoadingModal type="black" />
        </LoadingPortal>
      )}
    </>
  );
}
