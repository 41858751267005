import { useFetch } from "../common/useFetch";
import { apiClient } from "../../api/apiClient";

export default function useCalculateDetail(settlementId, setIsShowingPopup) {
  const { data, isLoading } = useFetch(
    [settlementId],
    async () => {
      if (!settlementId) {
        return;
      }

      const { data } = await apiClient({
        url: `/settlements/${settlementId}`,
        method: "get",
      });

      return data;
    },
    {
      onSuccess: () => {
        setIsShowingPopup(true);
      },
      onError: ({ response }) => {
        alert(response.data.message);
      },
    }
  );

  return { data, isLoading };
}
