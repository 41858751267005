import { useState } from "react";
import { useFetch } from "../common/useFetch";
import { apiClient } from "../../api/apiClient";

export default function useGNB() {
    const { data: storeInfo } = useFetch(
        ["GNB"],
        async () => {
            const { data } = await apiClient({
                url: "/store/me",
                method: "get",
            });

            return data;
        },
        {
            onError: ({ response }) => {
                alert(response.data.message);
            },
        }
    );

    return {
        storeInfo,
    };
}
