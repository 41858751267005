

import { Container, Row, Col, Form, Image, Button } from 'react-bootstrap';
// import CheckBox from '../../../components/common/CheckBox';

// import ListGroupH from '../../../components/ListGroupH';
// import { GetAuthorize, GetUserMe } from './../../../api/ApiGetUser';
import { useState } from 'react';
// import { getImgUrl, LocationUrl } from '../../../function/common';
// import { getVersion, setAccessToken, deviceType, setNav3 } from './../../../api/ApiWebView';
// import { sleep, includeStr, getSubDomain, PrintConsole } from './../../../function/common';
import { useNavigate, Link, useSearchParams, useParams } from 'react-router-dom';
import { useEffect } from 'react';
// import SideNav from './../../../components/common/SideNav';

import { getApiUrl, PrintConsole, setCommas, sleep } from '../../function/common';
import { GetUserMe } from '../../api/ApiGetStoreUser';
import { GetAuthorize } from '../../api/ApiGetUser';
import { setAccessToken, setNav3 } from '../../api/ApiWebView';

import axios from 'axios';

import Header from './components/Header';
import "./QRDetail.css";

export default function QRDetail(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const { number } = useParams();
    
    const [item, setItem] = useState();

    function getOrderItem() {
        return new Promise( async function(resolve, reject) {
            var obj = {
                isUsed : true
            }
            const response = await axios.get( 
                getApiUrl('users/me/food-tickets/'+number),
                
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8"
            ).then(
                function(response){
                    if(response.status === 200){
                        setItem(response.data);
                        
                    }
                    
                }
            ).catch(function(error){
                console.log('error');
            })
            if (response) {
                return resolve(response);
            }
        });
    };

    

    useEffect( () => {
        setLoading(true);

        // getOrderItem();
        // console.log(item); 

        setLoading(false);
    },[])

    if (loading) return <></>

    
    

    //개발자 콘솔에서 반응형또는 기기 선택시 해당 os 반환
    // getVersion();
    // setAccessToken();
    
    return (
        <>

        <div className='demo'>

            <div className="TPayDetail2Page">
                <div className="container-fluid g-0">

                    <nav className="sub-navbar">
                        <p className="navbar-title">결제 상세</p>
                        <button type="button" className="btn-clear btn-back" onClick={ () => {navigate(-1)}}>
                            <img className="img-fluid" alt="뒤로가기" src="https://www.flexdaycdn.net/public/images/ticket/back-b.png" />
                        </button>
                        <Header/>

                    </nav>
                    <div className="PayDetailBox px-4-5 py-4-5 ">
                    {/* 확인 : 결제취소 / class 'cancel' 추가 */}
                        <div className="PayDetail mt-4">
                            <img className="img-fluid clip_icon" alt="" src="https://www.flexdaycdn.net/public/images/ticket/clip_icon.png" />

                            <div className="PayDetail-Tit">
                                식권 상품
                            </div>
                            <div className="PayDetail-Item d-flex mb-2">
                                <div className="prd">
                                    C코너 <span className="small">X</span> 1개
                                    {/* {item && item.productName} */}
                                </div>
                                <div className="used">
                                    미사용
                                    {/* 결제 취소일때 문구 수정 "결제취소"*/}
                                </div>
                            </div>
                            <div className="PayDetail-Item d-flex mb-2">
                                <div className="prd">
                                    스파클링 탄산음료 <span className="small">X</span> 2개
                                    {/* {item && item.productName} */}
                                </div>
                                <div className="used">
                                    미사용
                                </div>
                            </div>
                            <div className="PayDetail-bar"></div>
                            <div className="PayDetail-Tit">
                                결제 정보
                            </div>
                            <div className="PayDetail-Item d-flex mb-2">
                                <div className="tit">
                                    결제 방법
                                </div>
                                <div className="price text-end">
                                    카드
                                    {/* {item && item.payType['description']} */}
                                </div>
                            </div>
                            <div className="PayDetail-Item d-flex mb-2">
                                <div className="tit">
                                    결제 금액
                                </div>
                                <div className="price text-end">
                                    <span>5,800</span>원
                                    {/* <span>{item && setCommas(item.price)}</span>원 */}
                                </div>
                            </div>
                            <div className="PayDetail-Item d-flex mb-2">
                                <div className="tit">
                                    결제 일자
                                </div>
                                <div className="price text-end">
                                    2022-12-27 11:50
                                    {/* {item && item.paidAt} */}
                                </div>
                            </div>
                            <div className="PayDetail-Item d-flex">
                                <div className="tit">
                                    결제 상태
                                </div>
                                <div className="price state text-end">
                                    결제 완료
                                     {/* 결제 취소일때 문구 수정 "결제 취소"*/}
                                </div>
                            </div>

                        </div>

                        <p className="PayInfotxt mb-4">
                            • 구매한 날짜에 사용하지 못하셨다면 <br />
                            유효기간 내 같은 금액의 식단으로 사용이 가능합니다.
                        </p>

                        <p className="PayInfotxt">
                            • 구매일 당일(~23:59)까지 취소가 가능합니다. <br />
                            (단, 구매 건에 이미 사용된 교환권이 있는 경우
                            취소가 불가능합니다.)
                        </p>

                        <div className="PayDetail-Confirm py-4">
                            <button type="button" className="btn btn-default">
                                결제 취소
                            </button>
                        </div>

                        {/* <Link to="/demo/paymentcancel" className="btn btn-default mb-4">
                            결제 취소
                        </Link> */}

                    </div>
                </div>
            </div>

            </div>
        </>
    )
}