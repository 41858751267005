import { useParams } from "react-router-dom";
import { apiClient } from "../../api/apiClient";
import { useMutation } from "../common/useMutation";

const getFileName = (response) => {
  const disposition = response.headers["content-disposition"];

  const fileName = decodeURI(
    disposition
      .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
      .replace(/['"]/g, "")
  );
  return fileName;
};

export default function useParticipationExcel() {
  const { eventId } = useParams();
  const { mutate: excelMutate, isLoading: isExcelLoading } = useMutation(
    async () =>
      await apiClient({
        url: `/events/${eventId}/excel`,
        method: "get",
        responseType: "arraybuffer",
      }),
    {
      onSuccess: (response) => {
        const blob = new Blob([response.data]);
        const fileUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = fileUrl;
        link.style.display = "none";
        link.download = getFileName(response);
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
      onError: ({ response }) => {
        alert(response.data.message);
      },
    }
  );

  return { excelMutate, isExcelLoading };
}
