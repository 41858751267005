import DatePicker from "react-datepicker";

import "./Datepicker.css";
import { getFormatYearMonth } from "../../function/common";

const START_YEAR = 2023;

export default function YearDatepicker({ paymentMonth, setPaymentMonth }) {
  const ver2_months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const ver2_locale = {
    localize: {
      month: (n) => ver2_months[n],
    },
    formatLong: {
      date: () => "yyyy-MM",
    },
  };

  return (
    <>
      <div className="demo" style={{ margin: "0", maxWidth: "100%" }}>
        <div className="TDatepicker">
          <div className="container-fluid g-0">
            <div
              className="DatepickerWrap DatepickerWrapWidth"
              style={{ width: "100%" }}
            >
              <div className="DatepickerBox ver2 py-3 year">
                <DatePicker
                  className="form-control InputType input-datepicker prd-admin2"
                  locale={ver2_locale}
                  dateFormat="yyyy-MM"
                  minDate={new Date("2023-02-01")}
                  maxDate={new Date()}
                  selected={new Date(paymentMonth)}
                  onChange={(date) => setPaymentMonth(getFormatYearMonth(date))}
                  onFocus={(e) => e.target.blur()}
                  dateFormatCalendar="yyyy-MM"
                  showMonthYearPicker
                  showFourColumnMonthYearPicker
                  renderCustomHeader={({
                    monthDate,
                    prevYearButtonDisabled,
                    nextYearButtonDisabled,
                    decreaseYear,
                    increaseYear,
                  }) => (
                    <div
                      className="header"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="btn_month btn_month-prev"
                        onClick={() => {
                          if (monthDate.getFullYear() === START_YEAR) {
                            return;
                          }

                          decreaseYear();
                        }}
                        disabled={prevYearButtonDisabled}
                      >
                        <img
                          className="img-fluid"
                          src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                        />
                      </div>

                      <div className="month-day">
                        {monthDate.toLocaleString("en-US", {
                          year: "numeric",
                        })}
                      </div>

                      <div
                        className="btn_month btn_month-next"
                        onClick={() => {
                          if (
                            monthDate.getFullYear() === new Date().getFullYear()
                          ) {
                            return;
                          }

                          increaseYear();
                        }}
                        disabled={nextYearButtonDisabled}
                      >
                        <img
                          className="img-fluid"
                          src="https://www.flexdaycdn.net/public/images/ticket/back-b-right.png"
                        />
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
