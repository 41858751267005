import { useNavigate } from "react-router-dom";
import { apiClient } from "../../api/apiClient";
import { useMutation } from "../common/useMutation";

export default function usePopupSequence() {
    const navigate = useNavigate();

    const { mutate, isLoading } = useMutation(
        async ({ orderings }) =>
            await apiClient({
                url: "/popups",
                method: "put",
                data: { orderings },
            }),
        {
            onSuccess: () => {
                navigate("/popup");
            },
            onError: ({ response }) => {
                alert(response.data.message);
            },
        }
    );

    return { mutate, isLoading };
}
