import { useState, useEffect } from "react";

const initOptions = {
    onSuccess: (data) => {},
    onError: (error) => {},
};

export function useInfinityScroll(keys, fetcher, options) {
    const [start, setStart] = useState(false);
    const [data, setData] = useState();
    const [nextCursorId, setNextCursorId] = useState();
    const [hasNextPage, setNextPage] = useState(true);
    const [refetcher, setRefetcher] = useState();
    const [target, setTarget] = useState();

    useEffect(() => {
        let observer;

        if (!target) {
            return;
        }

        observer = new IntersectionObserver(onIntersect);
        observer.observe(target);

        return () => {
            observer.unobserve(target);
        };
    }, [target]);

    const newOptions = { ...initOptions, ...options };
    const { onSuccess, onError } = newOptions;

    const fetchNextPage = (params) => {
        if (params) {
            setData((prev) => {
                return prev.filter((data) => data.eventId !== [params]);
            });
        }
        setRefetcher(new Date());
    };

    const refetch = () => {
        setStart(false);
        setNextCursorId();
        setRefetcher(new Date());
    };

    const fetchData = async () => {
        try {
            setStart(true);
            const response = await fetcher(nextCursorId);
            const { nextCursorId: cursorId, hasNext, data } = response;

            onSuccess(response);
            setNextPage(hasNext);
            setNextCursorId(cursorId);

            setData((prev) => {
                if (!prev || !start) {
                    return data;
                }

                return prev.concat(data);
            });
        } catch (error) {
            onError(error);
        }
    };

    const onIntersect = (entries) => {
        entries.forEach((entry) => {
            if (entry.intersectionRatio > 0 && entry.isIntersecting) {
                fetchNextPage();
            }
        });
    };

    useEffect(() => {
        fetchData();
    }, [...keys, refetcher]);

    return {
        data,
        nextCursorId,
        hasNextPage,
        setTarget,
        fetchNextPage,
        refetch,
    };
}
