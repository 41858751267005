import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { openQRRead, setAccessToken, setNav1 } from "../../../api/ApiWebView";
import useGNB from "../../../hooks/main/useGNB";
import { useAppSelector } from "../../../store";
import { isDefaultStore } from "../../../function/common";

export default function AdminHeader(props) {
  const navigate = useNavigate();
  const { storeInfo } = useGNB();
  const location = useLocation();
  const storeType = useAppSelector((state) => {
    return state.storeReducer.storeType;
  });

  useEffect(() => {
    // do stuff
  }, [storeType]);
  if (!storeInfo) {
    return;
  }

  const activeClass = (...route) => {
    return route.includes(location.pathname)
      ? "active nav-link d-flex align-items-center pb-4"
      : "nav-link d-flex align-items-center pb-4";
  };

  return (
    <>
      <button
        className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar"
      >
        <img
          className="img-fluid"
          alt="메뉴"
          src="https://www.flexdaycdn.net/public/images/ticket/menu3.png"
        />
      </button>

      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div className="offcanvas-btnbox pt-4 px-4 text-end">
          <button
            type="button"
            className="btn-close opacity-100 shadow-none"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <img
              className="img-fluid close"
              alt=""
              src="https://www.flexdaycdn.net/public/images/ticket/close4.png"
            />
          </button>
        </div>
        <div className="offcanvas-header p-4">
          <img className="img-fluid logo" alt="" src={storeInfo.logoUrl} />
          <div className="offcanvas-title" id="offcanvasNavbarLabel">
            {storeInfo.storeName}
          </div>
        </div>
        <div className="offcanvas-body position-relative py-4-5">
          <div className="navbar-nav justify-content-end">
            <div className="nav-item">
              <Link
                to="/storemain"
                className={activeClass("/storemain")}
                // aria-current="page"
              >
                <span>관리자 메인</span>
              </Link>
            </div>
            <div className="nav-item">
              <Link
                to="/edit"
                onClick={() => sessionStorage.clear()}
                className={activeClass("/edit")}
              >
                <span>기본 정보 관리</span>
              </Link>
            </div>
            <div className="nav-item">
              <Link
                to="/salemenu"
                onClick={() => sessionStorage.clear()}
                className={activeClass("/salemenu")}
              >
                <span>판매 메뉴 관리</span>
              </Link>
            </div>
            <div className="nav-item">
              <Link
                to={
                  isDefaultStore(storeType)
                    ? "/paymentInfo"
                    : "/paymentOrderInfo"
                }
                onClick={() => sessionStorage.clear()}
                className={activeClass(
                  "/paymentInfo",
                  "/paymentOrderInfo",
                  "/salesInfo",
                  "/settlementInfo",
                  "/taxbillInfo"
                )}
              >
                <span>결제/매출/정산</span>
              </Link>
            </div>
            <div className="nav-item">
              <Link
                to="/survey"
                onClick={() => sessionStorage.clear()}
                className={activeClass("/survey")}
              >
                <span>설문조사 집계표</span>
              </Link>
            </div>
            <div className="nav-item">
              <Link
                to="/event"
                onClick={() => sessionStorage.clear()}
                className={activeClass("/event")}
              >
                <span>이벤트 관리</span>
              </Link>
            </div>
            <div className="nav-item">
              <Link
                to="/popup"
                onClick={() => sessionStorage.clear()}
                className={activeClass("/popup")}
              >
                <span>팝업 관리</span>
              </Link>
            </div>
          </div>
          <div className="btnbox">
            <p style={{ color: "#d3d3d3", textAlign: "right" }}>
              {process.env.REACT_APP_VERSION}
            </p>
            <button
              type="button"
              className="btn btn-default"
              onClick={() => {
                localStorage.clear();
                sessionStorage.clear();
                navigate("/admin/login");
              }}
            >
              로그아웃
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
