import { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useEventRemove from "../../../hooks/useEventRemove";
import "./Event.css";
import useEventInfiniteScroll from "../../../hooks/useEventInfiniteScroll";
import useEventDetailModal from "../../../hooks/useEventDetailModal";
import { Button, Modal, Spinner } from "react-bootstrap";
import AdminHeader from "../../UserMain/components/AdminHeader";
import useRemoveBodyStyle from "../../../hooks/common/useRemoveBodyStyle";
import LoadingPortal from "../../../portals/LoadingPortal";
import LoadingModal from "../../../components/loadingmodal/LoadingModal";

export default function Event() {
    useRemoveBodyStyle();
    const navigate = useNavigate();
    const [eventRemoveList, setEventRemoveList] = useState([]);
    const [status, setStatus] = useState("DEFAULT");
    const { data, hasNextPage, setTarget, fetchNextPage, refetchInfinite } =
        useEventInfiniteScroll();
    const [isRemoveModalShowing, setIsRemoveModalShowing] = useState(false);
    const [isRemoveConfirmModalShowing, setIsRemoveConfirmModalShowing] =
        useState(false);
    const { mutate, isLoading } = useEventRemove(
        setStatus,
        setIsRemoveModalShowing,
        setIsRemoveConfirmModalShowing,
        refetchInfinite
    );
    const [isEventDetailModalShowing, setIsEventDetailModalShowing] =
        useState(false);
    const [eventDetailModal, setEventDetailModal] = useState({
        name: "",
        date: "",
    });
    const [eventId, setEventId] = useState();
    const { data: eventDetail, refetch } = useEventDetailModal(
        eventId,
        setIsEventDetailModalShowing
    );

    const handleEventDetailModalClose = () => {
        setIsEventDetailModalShowing(false);
    };

    const handleRemoveModalClose = () => {
        setIsRemoveModalShowing(false);
    };

    const handleRemoveConfirmModalClose = () => {
        setIsRemoveConfirmModalShowing(false);
    };

    const getDDay = (description, startDate) => {
        if (description === "대기") {
            const distance =
                new Date(startDate).setHours(0) -
                new Date(Date.now()).setHours(0, 0, 0, 0);
            const day = Math.floor(distance / (1000 * 60 * 60 * 24));

            return `D-${day}`;
        }

        return description;
    };

    const getEventStatus = useCallback((status) => {
        if (status === 1) {
            return "d-day";
        } else if (status === 2) {
            return "in-progress";
        } else {
            return "done";
        }
    }, []);

    const getParticipationStatus = useCallback((status) => {
        if (status !== 1) {
            return;
        }

        return "disabled";
    }, []);

    const getModifyStatus = useCallback((status) => {
        if (status === 1) {
            return;
        }

        return "disabled";
    }, []);

    if (!data) {
        return null;
    }

    const handleEventItemClick = (eventItem) => {
        if (status === "DELETE") {
            return;
        }

        const { eventName, startDate, endDate, eventId } = eventItem;

        refetch();
        setEventId(eventId);
        setEventDetailModal({
            name: eventName,
            date: `${startDate} ~ ${endDate}`,
        });
    };

    const handleEventRemoveChange = (eventId) => {
        if (
            eventRemoveList.find(
                (eventRemoveItem) => eventRemoveItem === eventId
            )
        ) {
            setEventRemoveList((prev) =>
                prev.filter((eventRemoveItem) => eventRemoveItem !== eventId)
            );
            return;
        }

        setEventRemoveList((prev) => [...prev, eventId]);
    };

    const handleEventModifyBtnClick = (eventId) => {
        if (status === "DELETE") {
            return;
        }

        navigate(`/event/${eventId}`);
    };

    const handleEventRemoveBtnClick = (eventRemoveList) => {
        if (isLoading) {
            return;
        }

        mutate(eventRemoveList);
    };

    const mapedEventList = data.map((eventItem, index) => {
        const { eventId, eventName, startDate, endDate, eventStatus } =
            eventItem;

        return (
            <div
                className={`EventItem ${getEventStatus(
                    eventStatus.code
                )} d-flex`}
                key={`${eventId}-${index}`}
                onClick={() => handleEventItemClick(eventItem)}
                // onMouseEnter={() => preFetchEventDetail(eventId, eventStatus)}
            >
                <div className="line"></div>
                <div className="info-box">
                    {/* <!-- 첫번째 이벤트만 모달 임시로 적용--> */}
                    <div className="txtbox">
                        <p className="event-name">{eventName}</p>
                        <p className="event-period">
                            운영 기간 <br className="mob-320" />
                            {`${startDate} ~ ${endDate}`}
                        </p>
                        <div className="btn-box d-flex">
                            <div>
                                <button
                                    type="button"
                                    className={`btn h-auto btn-light ${getModifyStatus(
                                        eventStatus.code
                                    )}`}
                                    onClick={() =>
                                        handleEventModifyBtnClick(eventId)
                                    }
                                >
                                    {status === "DEFAULT" ? (
                                        <Link to={`/event/${eventId}`}>
                                            {" "}
                                            내용 수정
                                        </Link>
                                    ) : (
                                        "내용 수정"
                                    )}
                                </button>
                            </div>

                            {status === "DEFAULT" && eventStatus.code !== 1 ? (
                                <Link to={`/event/${eventId}/participation`}>
                                    <button
                                        type="button"
                                        className={`btn h-auto btn-light ${getParticipationStatus(
                                            eventStatus.code
                                        )}`}
                                    >
                                        참여 현황
                                    </button>
                                </Link>
                            ) : (
                                <button
                                    type="button"
                                    className={`btn h-auto btn-light ${getParticipationStatus(
                                        eventStatus.code
                                    )}`}
                                >
                                    참여 현황
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="state-box">
                        {` ${getDDay(eventStatus.description, startDate)} `}
                    </div>
                    <div className="delete-box">
                        <input
                            className="form-check-input"
                            name="EventDelete"
                            type="checkbox"
                            onChange={() => handleEventRemoveChange(eventId)}
                        />
                    </div>
                </div>
            </div>
        );
    });

    const getMapedEventDetail = () => {
        if (!eventDetail) {
            return;
        }

        const mapedEventDetail = eventDetail.stampList.map((stamp, index) => {
            const { id, productName, reward, rewardType, stampCount } = stamp;
            const product =
                rewardType === "INNER_PRODUCT" ? productName : reward;

            return (
                <div className="detail-item d-flex" key={id}>
                    <div className="tit">{`리워드 ${index + 1}`}</div>
                    <div className="contents">{`스탬프 ${stampCount}개, ${product} ${
                        rewardType === "ETC" ? "응모권 " : ""
                    }지급`}</div>
                </div>
            );
        });

        return mapedEventDetail;
    };

    return (
        <>
            <div className="demo">
                <div
                    className={`TEventManagementPage ${
                        status === "DELETE" && "deleting"
                    }`}
                >
                    {/* <!-- 이벤트 삭제 모드일때 className "deleting" 추가 --> */}
                    <div className="container-fluid g-0">
                        <nav className="sub-navbar">
                            <p className="navbar-title">이벤트 관리</p>
                            <button
                                type="button"
                                className="btn-clear btn-back"
                                onClick={() => navigate("/storemain")}
                            >
                                <img
                                    className="img-fluid"
                                    alt="뒤로가기"
                                    src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                                />
                            </button>
                            <button
                                className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasNavbar"
                                aria-controls="offcanvasNavbar"
                            >
                                <img
                                    className="img-fluid"
                                    alt="메뉴"
                                    src="https://www.flexdaycdn.net/public/images/ticket/menu3.png"
                                />
                            </button>
                            <AdminHeader />
                        </nav>

                        <div className="EventManagementBox py-4-5 px-4-5">
                            <div className="Event-Top d-flex mb-4">
                                <div className="tit">Event list</div>
                                <div className="btnbox default">
                                    <Link to="/event/add">
                                        <button
                                            type="button"
                                            className="btn h-auto btn-default"
                                        >
                                            + 이벤트 추가
                                        </button>
                                    </Link>
                                    <button
                                        type="button"
                                        className="btn h-auto btn-light"
                                        onClick={() => setStatus("DELETE")}
                                    >
                                        - 선택 삭제
                                    </button>
                                </div>

                                <div className="btnbox delete">
                                    <button
                                        type="button"
                                        className="btn h-auto btn-light"
                                        onClick={() => setStatus("DEFAULT")}
                                    >
                                        취소
                                    </button>
                                    <button
                                        type="button"
                                        className="btn h-auto btn-default"
                                        onClick={() => {
                                            if (!eventRemoveList.length) {
                                                return;
                                            }

                                            setIsRemoveModalShowing(true);
                                        }}
                                    >
                                        삭제
                                    </button>
                                </div>
                            </div>

                            <div className="EventBox">
                                {mapedEventList}
                                {hasNextPage && <div ref={setTarget} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* 이벤트 삭제 확인 모달 */}
            <Modal
                show={isRemoveModalShowing}
                onHide={handleRemoveModalClose}
                keyboard={false}
                size="sm"
                centered
                className="demoModal modal-prdAdd text-center"
            >
                <Modal.Header>
                    <Modal.Title>
                        선택한 이벤트를 <br/>
                        삭제하시겠습니까?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   이벤트 목록에서 삭제됩니다.
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    {isLoading ? (
                        <div>
                            <Spinner animation="border" />
                        </div>
                    ) : (
                        <>
                            <Button
                                variant="modal-secondary"
                                className="btn-clear"
                                onClick={handleRemoveModalClose}
                            >
                                취소
                            </Button>
                            <Button
                                variant="modal-primary"
                                className="btn-clear"
                                onClick={() =>
                                    handleEventRemoveBtnClick(eventRemoveList)
                                }
                            >
                                확인
                            </Button>
                        </>
                    )}
                </Modal.Footer>
            </Modal>
            {/* 이벤트 삭제 성공 모달 */}
            <Modal
                show={isRemoveConfirmModalShowing}
                onHide={handleRemoveConfirmModalClose}
                keyboard={false}
                size="sm"
                centered
                className="demoModal modal-prdAdd text-center"
            >
                <Modal.Header>
                    <Modal.Title>삭제 성공!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    이벤트 목록에서 삭제되었습니다.
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="modal-primary"
                        className="btn-clear w-100"
                        onClick={handleRemoveConfirmModalClose}
                    >
                        확인
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={isEventDetailModalShowing}
                onHide={handleEventDetailModalClose}
                keyboard={false}
                size="sm"
                centered
                className="demoModal modal"
                dialogClassName="modal-sm centered modal-event-detail"
            >
                <Modal.Header className="px-4-5 pb-4">
                    <Modal.Title>이벤트 상세 정보</Modal.Title>
                    <button type="button" className="btn btn-clear">
                        <img
                            className="img-fluid"
                            alt=""
                            src="https://www.flexdaycdn.net/public/images/ticket/close.png"
                            onClick={handleEventDetailModalClose}
                        />
                    </button>
                </Modal.Header>
                <Modal.Body className="px-4-5 pt-0">
                    <div className="detail-box">
                        <div className="detail-item d-flex">
                            <div className="tit">이벤트명</div>
                            <div className="contents">
                                {eventDetailModal.name}
                            </div>
                        </div>
                        <div className="detail-item d-flex">
                            <div className="tit">운영기간</div>
                            <div className="contents">
                                {eventDetailModal.date}
                            </div>
                        </div>
                        {getMapedEventDetail()}
                    </div>
                </Modal.Body>
            </Modal>
            {isLoading && (
                <LoadingPortal>
                    <LoadingModal />
                </LoadingPortal>
            )}
        </>
    );
}
