import { Container, Row, Col, Form, Image, Button } from 'react-bootstrap';

import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useEffect } from 'react';

import DatePicker, { registerLocale } from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { getYear } from "date-fns";


import './Datepicker.css';


export default function Datepicker(){
    const navigate = useNavigate();

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    // 요일 반환
    const getDayName = (date) => {
      return date.toLocaleDateString('ko-KR', {
        weekday: 'long',
      }).substr(0, 1);
    }

    // 날짜 비교시 년 월 일까지만 비교하게끔
    const createDate = (date) => {
      return new Date(new Date(date.getFullYear()
        , date.getMonth()
        , date.getDate()
        , 0
        , 0
        , 0));
    }


    // 월별 캘린더 표시
    // const ver2_year = getYear(startDate);
    const ver2_year = "0000"
    const ver2_months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']

    const ver2_locale = {
      localize: {
        month: n => ver2_year + '-' + ver2_months[n]
      },
      formatLong: {
        date: () => 'yyyy-MM'
      }
    }


    return (
        <>
            <div className='demo'>
                <div className="TDatepicker">
                    <div className="container-fluid g-0">

                        <nav className="sub-navbar">
                            <p className="navbar-title">캘린더 샘플</p>
                            <button type="button" className="btn-clear btn-close" onClick={() => navigate(-1)}>
                                <img className="img-fluid" alt="뒤로가기"
                                    src="https://www.flexdaycdn.net/public/images/ticket/close.png" />
                            </button>
                        </nav>
                        
                        <div className="DatepickerWrap px-4-5">
                          
                          {/* 판매 메뉴 추가/변경 */}
                          <div className='DatepickerBox ver1 pt-4-5 pb-3'>
                              <div className='align-items-center d-flex'>

                              <DatePicker className='form-control InputType input-datepicker sale-prd-add'
                                  locale={ko}
                                  dateFormat="yyyy-MM-dd"
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                  onFocus={e => e.target.blur()}
                                  selectsStart
                                  minDate={new Date()}
                                  startDate={startDate}
                                  endDate={endDate}
                                  placeholderText="YYYY-MM-DD"
                                  dayClassName={date =>
                                    getDayName(createDate(date)) === '토' ? "Sat" :
                                    getDayName(createDate(date)) === '일' ? "Sun" : undefined
                                  }
                                  renderCustomHeader={({
                                      monthDate,
                                      prevMonthButtonDisabled,
                                      nextMonthButtonDisabled,
                                      decreaseMonth,
                                      increaseMonth,
                                    }) => (
                                      <div className="header"
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems : "center"
                                        }}
                                      >
                                        
                                      <div className="month-day">
                                        {monthDate.toLocaleString("ko-KO", {
                                            month: "long",
                                            year: "numeric",
                                          })}
                                        </div> 

                                        <div className="btn-group">
                                          <div
                                            className="btn_month btn_month-prev"
                                            onClick={decreaseMonth}
                                            disabled={prevMonthButtonDisabled}
                                          >
                                            <img className='img-fluid' src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png" />
                                          </div>
                                          <div
                                            className="btn_month btn_month-next"
                                            onClick={increaseMonth}
                                            disabled={nextMonthButtonDisabled}
                                          >
                                            <img className='img-fluid' src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png" />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                              />

                              <span className="tilde">~</span>

                              <DatePicker className='form-control InputType input-datepicker sale-prd-add'
                                  locale={ko}
                                  dateFormat="yyyy-MM-dd"
                                  selected={endDate}
                                  onChange={(date) => setEndDate(date)}
                                  onFocus={e => e.target.blur()}
                                  selectsEnd
                                  startDate={startDate}
                                  endDate={endDate}
                                  minDate={startDate}
                                  placeholderText="YYYY-MM-DD"
                                  dayClassName={date =>
                                    getDayName(createDate(date)) === '토' ? "Sat" :
                                    getDayName(createDate(date)) === '일' ? "Sun" : undefined
                                }
                                renderCustomHeader={({
                                  monthDate,
                                  prevMonthButtonDisabled,
                                  nextMonthButtonDisabled,
                                  decreaseMonth,
                                  increaseMonth,
                                }) => (
                                  <div className="header"
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems : "center"
                                        }}
                                      >
                                        
                                      <div className="month-day">
                                        {monthDate.toLocaleString("ko-KO", {
                                            month: "long",
                                            year: "numeric",
                                          })}
                                        </div> 

                                        <div className="btn-group">
                                          <div
                                            className="btn_month btn_month-prev"
                                            onClick={decreaseMonth}
                                            disabled={prevMonthButtonDisabled}
                                          >
                                            <img className='img-fluid' src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png" />
                                          </div>
                                          <div
                                            className="btn_month btn_month-next"
                                            onClick={increaseMonth}
                                            disabled={nextMonthButtonDisabled}
                                          >
                                            <img className='img-fluid' src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png" />
                                          </div>
                                        </div>
                                      </div>
                                )}
                              />
                              </div>
                          </div>

                          {/* 결제/매출/정산 설정 모달 (일자별) */}
                          <div className='DatepickerBox ver1 py-3'>
                              <div className='align-items-center d-flex'>

                              <DatePicker className='form-control InputType input-datepicker prd-admin'
                                  locale={ko}
                                  dateFormat="yyyy-MM-dd"
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                  onFocus={e => e.target.blur()}
                                  selectsStart
                                  minDate={new Date()}
                                  startDate={startDate}
                                  endDate={endDate}
                                  placeholderText="YYYY-MM-DD"
                                  dayClassName={date =>
                                    getDayName(createDate(date)) === '토' ? "Sat" :
                                    getDayName(createDate(date)) === '일' ? "Sun" : undefined
                                  }
                                  renderCustomHeader={({
                                      monthDate,
                                      prevMonthButtonDisabled,
                                      nextMonthButtonDisabled,
                                      decreaseMonth,
                                      increaseMonth,
                                    }) => (
                                      <div className="header"
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems : "center"
                                        }}
                                      >
                                        
                                      <div className="month-day">
                                        {monthDate.toLocaleString("ko-KO", {
                                            month: "long",
                                            year: "numeric",
                                          })}
                                        </div> 

                                        <div className="btn-group">
                                          <div
                                            className="btn_month btn_month-prev"
                                            onClick={decreaseMonth}
                                            disabled={prevMonthButtonDisabled}
                                          >
                                            <img className='img-fluid' src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png" />
                                          </div>
                                          <div
                                            className="btn_month btn_month-next"
                                            onClick={increaseMonth}
                                            disabled={nextMonthButtonDisabled}
                                          >
                                            <img className='img-fluid' src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png" />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                              />

                              <span className="tilde">~</span>

                              <DatePicker className='form-control InputType input-datepicker prd-admin'
                                  locale={ko}
                                  dateFormat="yyyy-MM-dd"
                                  selected={endDate}
                                  onChange={(date) => setEndDate(date)}
                                  onFocus={e => e.target.blur()}
                                  selectsEnd
                                  startDate={startDate}
                                  endDate={endDate}
                                  minDate={startDate}
                                  placeholderText="YYYY-MM-DD"
                                  dayClassName={date =>
                                    getDayName(createDate(date)) === '토' ? "Sat" :
                                    getDayName(createDate(date)) === '일' ? "Sun" : undefined
                                }
                                renderCustomHeader={({
                                  monthDate,
                                  prevMonthButtonDisabled,
                                  nextMonthButtonDisabled,
                                  decreaseMonth,
                                  increaseMonth,
                                }) => (
                                  <div className="header"
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems : "center"
                                        }}
                                      >
                                        
                                      <div className="month-day">
                                        {monthDate.toLocaleString("ko-KO", {
                                            month: "long",
                                            year: "numeric",
                                          })}
                                        </div> 

                                        <div className="btn-group">
                                          <div
                                            className="btn_month btn_month-prev"
                                            onClick={decreaseMonth}
                                            disabled={prevMonthButtonDisabled}
                                          >
                                            <img className='img-fluid' src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png" />
                                          </div>
                                          <div
                                            className="btn_month btn_month-next"
                                            onClick={increaseMonth}
                                            disabled={nextMonthButtonDisabled}
                                          >
                                            <img className='img-fluid' src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png" />
                                          </div>
                                        </div>
                                      </div>
                                )}
                              />
                              </div>
                          </div>

                          {/* 결제/매출/정산 설정 모달 (월별) */} 
                          <div className='DatepickerBox ver2 py-3'>
                            <DatePicker className='form-control InputType input-datepicker prd-admin2'
                              locale={ver2_locale}
                              dateFormat="yyyy-MM"
                              smaxDate={new Date()}
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              onFocus={e => e.target.blur()}
                              dateFormatCalendar="yyyy-MM"
                              showMonthYearPicker
                              showFourColumnMonthYearPicker
                              renderCustomHeader={({
                                monthDate,
                                prevYearButtonDisabled,
                                nextYearButtonDisabled,
                                decreaseYear,
                                increaseYear,
                              }) => (
                              <div className="header"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems : "center"
                                  }}
                                >
                              <div className="btn_month btn_month-prev"
                                onClick={decreaseYear}
                                disabled={prevYearButtonDisabled} >
                                <img className='img-fluid' src="https://www.flexdaycdn.net/public/images/ticket/back-b.png" />
                              </div>
                                      
                              <div className="month-day">
                                {monthDate.toLocaleString("en-US", {
                                  year: "numeric"
                                })}
                              </div> 

                              <div className="btn_month btn_month-next"
                                onClick={increaseYear}
                                disabled={nextYearButtonDisabled}
                              >
                                <img className='img-fluid' src="https://www.flexdaycdn.net/public/images/ticket/back-b-right.png" />
                              </div>
                            </div>
                              )}
                            />                      
                          </div>   

                          {/* 설문조사 집계표 */}
                          <div className='DatepickerBox ver1 py-3'>
                              <div className='align-items-center d-flex'>
                                  <DatePicker className='form-control InputType input-datepicker prd-admin w-100'
                                      locale={ko}
                                      dateFormat="yyyy-MM-dd"
                                      selected={startDate}
                                      onChange={(date) => setStartDate(date)}
                                      onFocus={e => e.target.blur()}
                                      selectsStart
                                      minDate={new Date()}
                                      startDate={startDate}
                                      endDate={endDate}
                                      placeholderText="YYYY-MM-DD"
                                      dayClassName={date =>
                                        getDayName(createDate(date)) === '토' ? "Sat" :
                                        getDayName(createDate(date)) === '일' ? "Sun" : undefined
                                      }
                                      renderCustomHeader={({
                                          monthDate,
                                          prevMonthButtonDisabled,
                                          nextMonthButtonDisabled,
                                          decreaseMonth,
                                          increaseMonth,
                                        }) => (
                                          <div className="header"
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems : "center"
                                            }}
                                          >
                                            
                                          <div className="month-day">
                                            {monthDate.toLocaleString("ko-KO", {
                                                month: "long",
                                                year: "numeric",
                                              })}
                                            </div> 

                                            <div className="btn-group">
                                              <div
                                                className="btn_month btn_month-prev"
                                                onClick={decreaseMonth}
                                                disabled={prevMonthButtonDisabled}
                                              >
                                                <img className='img-fluid' src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png" />
                                              </div>
                                              <div
                                                className="btn_month btn_month-next"
                                                onClick={increaseMonth}
                                                disabled={nextMonthButtonDisabled}
                                              >
                                                <img className='img-fluid' src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png" />
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                  />
                                  <span className="tilde">~</span>
                                  <DatePicker className='form-control InputType input-datepicker prd-admin w-100'
                                      locale={ko}
                                      dateFormat="yyyy-MM-dd"
                                      selected={endDate}
                                      onChange={(date) => setEndDate(date)}
                                      onFocus={e => e.target.blur()}
                                      selectsEnd
                                      startDate={startDate}
                                      endDate={endDate}
                                      minDate={startDate}
                                      placeholderText="YYYY-MM-DD"
                                      dayClassName={date =>
                                        getDayName(createDate(date)) === '토' ? "Sat" :
                                        getDayName(createDate(date)) === '일' ? "Sun" : undefined
                                    }
                                    renderCustomHeader={({
                                      monthDate,
                                      prevMonthButtonDisabled,
                                      nextMonthButtonDisabled,
                                      decreaseMonth,
                                      increaseMonth,
                                    }) => (
                                      <div className="header"
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems : "center"
                                            }}
                                          >
                                            
                                          <div className="month-day">
                                            {monthDate.toLocaleString("ko-KO", {
                                                month: "long",
                                                year: "numeric",
                                              })}
                                            </div> 

                                            <div className="btn-group">
                                              <div
                                                className="btn_month btn_month-prev"
                                                onClick={decreaseMonth}
                                                disabled={prevMonthButtonDisabled}
                                              >
                                                <img className='img-fluid' src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png" />
                                              </div>
                                              <div
                                                className="btn_month btn_month-next"
                                                onClick={increaseMonth}
                                                disabled={nextMonthButtonDisabled}
                                              >
                                                <img className='img-fluid' src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png" />
                                              </div>
                                            </div>
                                          </div>
                                    )}
                                  />                              
                              </div>
                          </div>    

                          {/* 이벤트 추가/수정 */}
                          <div className='DatepickerBox ver1 py-3'>
                              <div className='align-items-center d-flex ver2'>
                                  <DatePicker className='form-control InputType input-datepicker event-add'
                                      locale={ko}
                                      dateFormat="yyyy-MM-dd"
                                      selected={startDate}
                                      onChange={(date) => setStartDate(date)}
                                      onFocus={e => e.target.blur()}
                                      selectsStart
                                      minDate={new Date()}
                                      startDate={startDate}
                                      endDate={endDate}
                                      placeholderText="YYYY-MM-DD"
                                      dayClassName={date =>
                                        getDayName(createDate(date)) === '토' ? "Sat" :
                                        getDayName(createDate(date)) === '일' ? "Sun" : undefined
                                      }
                                      renderCustomHeader={({
                                          monthDate,
                                          prevMonthButtonDisabled,
                                          nextMonthButtonDisabled,
                                          decreaseMonth,
                                          increaseMonth,
                                        }) => (
                                          <div className="header"
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems : "center"
                                            }}
                                          >
                                            
                                          <div className="month-day">
                                            {monthDate.toLocaleString("ko-KO", {
                                                month: "long",
                                                year: "numeric",
                                              })}
                                            </div> 

                                            <div className="btn-group">
                                              <div
                                                className="btn_month btn_month-prev"
                                                onClick={decreaseMonth}
                                                disabled={prevMonthButtonDisabled}
                                              >
                                                <img className='img-fluid' src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png" />
                                              </div>
                                              <div
                                                className="btn_month btn_month-next"
                                                onClick={increaseMonth}
                                                disabled={nextMonthButtonDisabled}
                                              >
                                                <img className='img-fluid' src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png" />
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                  />
                                  <span className="tilde">~</span>
                                  <DatePicker className='form-control InputType input-datepicker event-add'
                                      locale={ko}
                                      dateFormat="yyyy-MM-dd"
                                      selected={endDate}
                                      onChange={(date) => setEndDate(date)}
                                      onFocus={e => e.target.blur()}
                                      selectsEnd
                                      startDate={startDate}
                                      endDate={endDate}
                                      minDate={startDate}
                                      placeholderText="YYYY-MM-DD"
                                      dayClassName={date =>
                                        getDayName(createDate(date)) === '토' ? "Sat" :
                                        getDayName(createDate(date)) === '일' ? "Sun" : undefined
                                    }
                                    renderCustomHeader={({
                                      monthDate,
                                      prevMonthButtonDisabled,
                                      nextMonthButtonDisabled,
                                      decreaseMonth,
                                      increaseMonth,
                                    }) => (
                                      <div className="header"
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems : "center"
                                            }}
                                          >
                                            
                                          <div className="month-day">
                                            {monthDate.toLocaleString("ko-KO", {
                                                month: "long",
                                                year: "numeric",
                                              })}
                                            </div> 

                                            <div className="btn-group">
                                              <div
                                                className="btn_month btn_month-prev"
                                                onClick={decreaseMonth}
                                                disabled={prevMonthButtonDisabled}
                                              >
                                                <img className='img-fluid' src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png" />
                                              </div>
                                              <div
                                                className="btn_month btn_month-next"
                                                onClick={increaseMonth}
                                                disabled={nextMonthButtonDisabled}
                                              >
                                                <img className='img-fluid' src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png" />
                                              </div>
                                            </div>
                                          </div>
                                    )}
                                  />                              
                              </div>
                          </div>  

                      </div>
                    </div>
                </div>
            </div>
        </>

    )
}