import { useFetch } from "./common/useFetch";
import { apiClient } from "../api/apiClient";

export default function useSaleMenu() {
  const { data, isLoading, refetch } = useFetch(
    [],
    async () => {
      const { data } = await apiClient({
        url: "/products",
      });
      return data;
    },
    {
      onError: ({ response }) => {
        alert(response.data.message);
      },
    }
  );

  return { data, isProductsLoading: isLoading, refetch };
}
