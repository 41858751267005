import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";

import "./Datepicker.css";
import {
  getFormatDate,
  getFormatTime,
  getMaxDate,
} from "../../function/common";

export default function Datepicker({
  type,
  eventDate,
  setEventDate,
  eventTime,
  setEventTime,
  popupStateDispatch,
}) {
  // 요일 반환
  const getDayName = (date) => {
    return date
      .toLocaleDateString("ko-KR", {
        weekday: "long",
      })
      .substr(0, 1);
  };

  // 날짜 비교시 년 월 일까지만 비교하게끔
  const createDate = (date) => {
    return new Date(
      new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
    );
  };

  // 월별 캘린더 !! 수정해야함 !!
  const ver2_year = "0000";
  const ver2_months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const ver2_locale = {
    localize: {
      month: (n) => ver2_year + "-" + ver2_months[n],
    },
    formatLong: {
      date: () => "yyyy-MM",
    },
  };

  if (type === "sale") {
    {
      /* 판매 메뉴 추가/변경 */
    }
    return (
      <div className="demo">
        <div className="TDatepicker">
          <div className="container-fluid g-0">
            <div className="DatepickerWrap">
              <div className="DatepickerBox ver1">
                <div className="align-items-center d-flex">
                  <div className="datepicker-box">
                    <img
                      className="img-fluid calendar"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/calendar3.png"
                    />
                    <DatePicker
                      className="form-control InputType input-datepicker input-sale-datepicker sale-prd-add"
                      locale={ko}
                      dateFormat="yyyy-MM-dd"
                      selected={new Date(eventDate.startDate)}
                      onChange={(date) => {
                        if (getMaxDate(date, eventDate.endDate)) {
                          setEventDate({
                            startDate: getFormatDate(eventDate.endDate),
                            endDate: getFormatDate(date),
                          });
                          return;
                        }

                        setEventDate((prev) => ({
                          ...prev,
                          startDate: getFormatDate(date),
                        }));
                      }}
                      onFocus={(e) => e.target.blur()}
                      selectsStart
                      minDate={new Date()}
                      startDate={new Date(eventDate.startDate)}
                      endDate={new Date(eventDate.endDate)}
                      placeholderText="YYYY-MM-DD"
                      dayClassName={(date) =>
                        getDayName(createDate(date)) === "토"
                          ? "Sat"
                          : getDayName(createDate(date)) === "일"
                          ? "Sun"
                          : undefined
                      }
                      renderCustomHeader={({
                        monthDate,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                        decreaseMonth,
                        increaseMonth,
                      }) => (
                        <div
                          className="header"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div className="month-day">
                            {monthDate.toLocaleString("ko-KO", {
                              month: "long",
                              year: "numeric",
                            })}
                          </div>

                          <div className="btn-group">
                            <div
                              className="btn_month btn_month-prev"
                              onClick={decreaseMonth}
                              disabled={prevMonthButtonDisabled}
                            >
                              <img
                                className="img-fluid"
                                src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png"
                              />
                            </div>
                            <div
                              className="btn_month btn_month-next"
                              onClick={increaseMonth}
                              disabled={nextMonthButtonDisabled}
                            >
                              <img
                                className="img-fluid"
                                src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </div>

                  <span className="tilde">~</span>
                  <div className="datepicker-box">
                    <img
                      className="img-fluid calendar"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/calendar3.png"
                    />
                    <DatePicker
                      className="form-control InputType input-datepicker input-sale-datepicker sale-prd-add"
                      locale={ko}
                      dateFormat="yyyy-MM-dd"
                      selected={new Date(eventDate.endDate)}
                      onChange={(date) =>
                        setEventDate((prev) => ({
                          ...prev,
                          endDate: getFormatDate(date),
                        }))
                      }
                      onFocus={(e) => e.target.blur()}
                      selectsEnd
                      startDate={new Date(eventDate.startDate)}
                      endDate={new Date(eventDate.endDate)}
                      minDate={new Date(eventDate.startDate)}
                      placeholderText="YYYY-MM-DD"
                      dayClassName={(date) =>
                        getDayName(createDate(date)) === "토"
                          ? "Sat"
                          : getDayName(createDate(date)) === "일"
                          ? "Sun"
                          : undefined
                      }
                      renderCustomHeader={({
                        monthDate,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                        decreaseMonth,
                        increaseMonth,
                      }) => (
                        <div
                          className="header"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div className="month-day">
                            {monthDate.toLocaleString("ko-KO", {
                              month: "long",
                              year: "numeric",
                            })}
                          </div>

                          <div className="btn-group">
                            <div
                              className="btn_month btn_month-prev"
                              onClick={decreaseMonth}
                              disabled={prevMonthButtonDisabled}
                            >
                              <img
                                className="img-fluid"
                                src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png"
                              />
                            </div>
                            <div
                              className="btn_month btn_month-next"
                              onClick={increaseMonth}
                              disabled={nextMonthButtonDisabled}
                            >
                              <img
                                className="img-fluid"
                                src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (type === "saleTime") {
    const [startYear, startMonth, startDate] = eventDate.startDate.split("-");
    const [endYear, endMonth, endDate] = eventDate.endDate.split("-");

    const startTime = new Date(
      `${startYear}, ${startMonth}, ${startDate}, ${eventTime.startTime}`
    );
    const endTime = new Date(
      `${endYear}, ${endMonth}, ${endDate}, ${eventTime.endTime}`
    );

    return (
      <div className="demo">
        <div className="TDatepicker">
          <div className="container-fluid g-0">
            <div className="DatepickerWrap">
              <div className="DatepickerBox ver1">
                <div className="align-items-center d-flex">
                  <div className="inputbox DatepickerBox ver1 time PrdTime-box">
                    <div className="align-items-center d-flex">
                      <DatePicker
                        className="form-control InputType input-datepicker sale-prd-add"
                        locale={ko}
                        selected={startTime}
                        onChange={(date) => {
                          setEventTime((prev) => ({
                            ...prev,
                            startTime: getFormatTime(date),
                          }));
                        }}
                        onFocus={(e) => e.target.blur()}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="HH:mm"
                        // disabled={
                        //     eventDate.startDate !==
                        //     eventDate.endDate
                        // }
                      />
                      <span className="tilde">~</span>
                      <DatePicker
                        className="form-control InputType input-datepicker sale-prd-add"
                        locale={ko}
                        selected={endTime}
                        onChange={(date) => {
                          setEventTime((prev) => ({
                            ...prev,
                            endTime: getFormatTime(date),
                          }));
                        }}
                        onFocus={(e) => e.target.blur()}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="HH:mm"
                        // disabled={
                        //     eventDate.startDate !==
                        //     eventDate.endDate
                        // }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (type === "paymentDay") {
    {
      /* 결제/매출/정산 설정 모달 (일자별) */
    }
    return (
      <div className="demo">
        <div className="TDatepicker">
          <div className="container-fluid g-0">
            <div className="DatepickerWrap px-4-5">
              <div className="DatepickerBox ver1 py-3">
                <div className="align-items-center d-flex">
                  <DatePicker
                    className="form-control InputType input-datepicker prd-admin"
                    locale={ko}
                    dateFormat="yyyy-MM-dd"
                    selected={new Date(eventDate.startDate)}
                    onChange={(date) => {
                      if (getMaxDate(date, eventDate.endDate)) {
                        setEventDate({
                          startDate: getFormatDate(eventDate.endDate),
                          endDate: getFormatDate(date),
                        });
                        return;
                      }

                      setEventDate((prev) => ({
                        ...prev,
                        startDate: getFormatDate(date),
                      }));
                    }}
                    onFocus={(e) => e.target.blur()}
                    selectsStart
                    minDate={new Date()}
                    startDate={new Date(eventDate.startDate)}
                    endDate={new Date(eventDate.endDate)}
                    placeholderText="YYYY-MM-DD"
                    dayClassName={(date) =>
                      getDayName(createDate(date)) === "토"
                        ? "Sat"
                        : getDayName(createDate(date)) === "일"
                        ? "Sun"
                        : undefined
                    }
                    renderCustomHeader={({
                      monthDate,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                      decreaseMonth,
                      increaseMonth,
                    }) => (
                      <div
                        className="header"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className="month-day">
                          {monthDate.toLocaleString("ko-KO", {
                            month: "long",
                            year: "numeric",
                          })}
                        </div>

                        <div className="btn-group">
                          <div
                            className="btn_month btn_month-prev"
                            onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}
                          >
                            <img
                              className="img-fluid"
                              src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png"
                            />
                          </div>
                          <div
                            className="btn_month btn_month-next"
                            onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}
                          >
                            <img
                              className="img-fluid"
                              src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  />

                  <span className="tilde">~</span>

                  <DatePicker
                    className="form-control InputType input-datepicker prd-admin"
                    locale={ko}
                    dateFormat="yyyy-MM-dd"
                    selected={new Date(eventDate.endDate)}
                    onChange={(date) =>
                      setEventDate((prev) => ({
                        ...prev,
                        endDate: getFormatDate(date),
                      }))
                    }
                    onFocus={(e) => e.target.blur()}
                    selectsEnd
                    startDate={new Date(eventDate.startDate)}
                    endDate={new Date(eventDate.endDate)}
                    minDate={new Date(eventDate.startDate)}
                    placeholderText="YYYY-MM-DD"
                    dayClassName={(date) =>
                      getDayName(createDate(date)) === "토"
                        ? "Sat"
                        : getDayName(createDate(date)) === "일"
                        ? "Sun"
                        : undefined
                    }
                    renderCustomHeader={({
                      monthDate,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                      decreaseMonth,
                      increaseMonth,
                    }) => (
                      <div
                        className="header"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className="month-day">
                          {monthDate.toLocaleString("ko-KO", {
                            month: "long",
                            year: "numeric",
                          })}
                        </div>

                        <div className="btn-group">
                          <div
                            className="btn_month btn_month-prev"
                            onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}
                          >
                            <img
                              className="img-fluid"
                              src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png"
                            />
                          </div>
                          <div
                            className="btn_month btn_month-next"
                            onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}
                          >
                            <img
                              className="img-fluid"
                              src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (type === "paymentMonth") {
    {
      /* 결제/매출/정산 설정 모달 (월별) */
    }
    <div className="demo">
      <div className="TDatepicker">
        <div className="container-fluid g-0">
          <div className="DatepickerWrap px-4-5">
            <div className="DatepickerBox ver2 py-3">
              <DatePicker
                className="form-control InputType input-datepicker prd-admin2"
                locale={ver2_locale}
                dateFormat="yyyy-MM"
                smaxDate={new Date()}
                selected={eventDate.startDate}
                onChange={(date) =>
                  setEventDate((prev) => ({
                    ...prev,
                    startDate: date,
                  }))
                }
                onFocus={(e) => e.target.blur()}
                dateFormatCalendar="yyyy-MM"
                showMonthYearPicker
                showFourColumnMonthYearPicker
                renderCustomHeader={({
                  monthDate,
                  prevYearButtonDisabled,
                  nextYearButtonDisabled,
                  decreaseYear,
                  increaseYear,
                }) => (
                  <div
                    className="header"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="btn_month btn_month-prev"
                      onClick={decreaseYear}
                      disabled={prevYearButtonDisabled}
                    >
                      <img
                        className="img-fluid"
                        src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                      />
                    </div>

                    <div className="month-day">
                      {monthDate.toLocaleString("en-US", {
                        year: "numeric",
                      })}
                    </div>

                    <div
                      className="btn_month btn_month-next"
                      onClick={increaseYear}
                      disabled={nextYearButtonDisabled}
                    >
                      <img
                        className="img-fluid"
                        src="https://www.flexdaycdn.net/public/images/ticket/back-b-right.png"
                      />
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>;
  } else if (type === "survey") {
    {
      /* 설문조사 집계표 */
    }
    return (
      <div className="demo">
        <div className="TDatepicker">
          <div className="container-fluid g-0">
            <div className="DatepickerWrap px-4-5">
              <div className="DatepickerBox ver1 py-3">
                <div className="align-items-center d-flex">
                  <DatePicker
                    className="form-control InputType input-datepicker prd-admin w-100"
                    locale={ko}
                    dateFormat="yyyy-MM-dd"
                    selected={eventDate.startDate}
                    onChange={(date) =>
                      setEventDate((prev) => ({
                        ...prev,
                        startDate: date,
                      }))
                    }
                    onFocus={(e) => e.target.blur()}
                    selectsStart
                    minDate={new Date()}
                    startDate={eventDate.startDate}
                    endDate={eventDate.endDate}
                    placeholderText="YYYY-MM-DD"
                    dayClassName={(date) =>
                      getDayName(createDate(date)) === "토"
                        ? "Sat"
                        : getDayName(createDate(date)) === "일"
                        ? "Sun"
                        : undefined
                    }
                    renderCustomHeader={({
                      monthDate,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                      decreaseMonth,
                      increaseMonth,
                    }) => (
                      <div
                        className="header"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className="month-day">
                          {monthDate.toLocaleString("ko-KO", {
                            month: "long",
                            year: "numeric",
                          })}
                        </div>

                        <div className="btn-group">
                          <div
                            className="btn_month btn_month-prev"
                            onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}
                          >
                            <img
                              className="img-fluid"
                              src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png"
                            />
                          </div>
                          <div
                            className="btn_month btn_month-next"
                            onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}
                          >
                            <img
                              className="img-fluid"
                              src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  />
                  <span className="tilde">~</span>
                  <DatePicker
                    className="form-control InputType input-datepicker prd-admin w-100"
                    locale={ko}
                    dateFormat="yyyy-MM-dd"
                    selected={eventDate.endDate}
                    onChange={(date) =>
                      setEventDate((prev) => ({
                        ...prev,
                        endDate: date,
                      }))
                    }
                    onFocus={(e) => e.target.blur()}
                    selectsEnd
                    startDate={eventDate.startDate}
                    endDate={eventDate.endDate}
                    minDate={eventDate.startDate}
                    placeholderText="YYYY-MM-DD"
                    dayClassName={(date) =>
                      getDayName(createDate(date)) === "토"
                        ? "Sat"
                        : getDayName(createDate(date)) === "일"
                        ? "Sun"
                        : undefined
                    }
                    renderCustomHeader={({
                      monthDate,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                      decreaseMonth,
                      increaseMonth,
                    }) => (
                      <div
                        className="header"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className="month-day">
                          {monthDate.toLocaleString("ko-KO", {
                            month: "long",
                            year: "numeric",
                          })}
                        </div>

                        <div className="btn-group">
                          <div
                            className="btn_month btn_month-prev"
                            onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}
                          >
                            <img
                              className="img-fluid"
                              src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png"
                            />
                          </div>
                          <div
                            className="btn_month btn_month-next"
                            onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}
                          >
                            <img
                              className="img-fluid"
                              src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (type === "popup") {
    return (
      <div className="DatepickerBox ver1">
        <div className="align-items-center d-flex ver2">
          <DatePicker
            className="form-control InputType input-datepicker event-add"
            locale={ko}
            dateFormat="yyyy-MM-dd"
            selected={new Date(eventDate.startDate)}
            onChange={(date) => {
              if (getMaxDate(date, eventDate.endDate)) {
                popupStateDispatch({
                  type: "changed_date",
                  payload: {
                    startDate: getFormatDate(eventDate.endDate),
                    endDate: getFormatDate(date),
                  },
                });
                return;
              }

              popupStateDispatch({
                type: "changed_startDate",
                payload: {
                  startDate: getFormatDate(date),
                },
              });
            }}
            onFocus={(e) => e.target.blur()}
            selectsStart
            minDate={new Date()}
            startDate={new Date(eventDate.startDate)}
            endDate={new Date(eventDate.endDate)}
            placeholderText="YYYY-MM-DD"
            dayClassName={(date) =>
              getDayName(createDate(date)) === "토"
                ? "Sat"
                : getDayName(createDate(date)) === "일"
                ? "Sun"
                : undefined
            }
            renderCustomHeader={({
              monthDate,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
              decreaseMonth,
              increaseMonth,
            }) => (
              <div
                className="header"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="month-day">
                  {monthDate.toLocaleString("ko-KO", {
                    month: "long",
                    year: "numeric",
                  })}
                </div>

                <div className="btn-group">
                  <div
                    className="btn_month btn_month-prev"
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    <img
                      className="img-fluid"
                      src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png"
                    />
                  </div>
                  <div
                    className="btn_month btn_month-next"
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    <img
                      className="img-fluid"
                      src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png"
                    />
                  </div>
                </div>
              </div>
            )}
          />
          <span className="tilde">~</span>
          <DatePicker
            className="form-control InputType input-datepicker event-add"
            locale={ko}
            dateFormat="yyyy-MM-dd"
            selected={new Date(eventDate.endDate)}
            onChange={(date) =>
              popupStateDispatch({
                type: "changed_endDate",
                payload: {
                  endDate: getFormatDate(date),
                },
              })
            }
            onFocus={(e) => e.target.blur()}
            selectsEnd
            startDate={new Date(eventDate.startDate)}
            endDate={new Date(eventDate.endDate)}
            minDate={new Date(eventDate.startDate)}
            placeholderText="YYYY-MM-DD"
            dayClassName={(date) =>
              getDayName(createDate(date)) === "토"
                ? "Sat"
                : getDayName(createDate(date)) === "일"
                ? "Sun"
                : undefined
            }
            renderCustomHeader={({
              monthDate,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
              decreaseMonth,
              increaseMonth,
            }) => (
              <div
                className="header"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="month-day">
                  {monthDate.toLocaleString("ko-KO", {
                    month: "long",
                    year: "numeric",
                  })}
                </div>

                <div className="btn-group">
                  <div
                    className="btn_month btn_month-prev"
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    <img
                      className="img-fluid"
                      src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png"
                    />
                  </div>
                  <div
                    className="btn_month btn_month-next"
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    <img
                      className="img-fluid"
                      src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png"
                    />
                  </div>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    );
  } else if (type === "popupAdd") {
    <div className="DatepickerBox ver1">
      <div className="align-items-center d-flex ver2">
        <DatePicker
          className="form-control InputType input-datepicker event-add"
          locale={ko}
          dateFormat="yyyy-MM-dd"
          selected={new Date(eventDate.startDate)}
          onChange={(date) => {
            if (getMaxDate(date, eventDate.endDate)) {
              setEventDate({
                startDate: getFormatDate(eventDate.endDate),
                endDate: getFormatDate(date),
              });
              return;
            }

            setEventDate((prev) => ({
              ...prev,
              startDate: getFormatDate(date),
            }));
          }}
          onFocus={(e) => e.target.blur()}
          selectsStart
          minDate={new Date()}
          startDate={new Date(eventDate.startDate)}
          endDate={new Date(eventDate.endDate)}
          placeholderText="YYYY-MM-DD"
          dayClassName={(date) =>
            getDayName(createDate(date)) === "토"
              ? "Sat"
              : getDayName(createDate(date)) === "일"
              ? "Sun"
              : undefined
          }
          renderCustomHeader={({
            monthDate,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
            decreaseMonth,
            increaseMonth,
          }) => (
            <div
              className="header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="month-day">
                {monthDate.toLocaleString("ko-KO", {
                  month: "long",
                  year: "numeric",
                })}
              </div>

              <div className="btn-group">
                <div
                  className="btn_month btn_month-prev"
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  <img
                    className="img-fluid"
                    src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png"
                  />
                </div>
                <div
                  className="btn_month btn_month-next"
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  <img
                    className="img-fluid"
                    src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png"
                  />
                </div>
              </div>
            </div>
          )}
        />
        <span className="tilde">~</span>
        <DatePicker
          className="form-control InputType input-datepicker event-add"
          locale={ko}
          dateFormat="yyyy-MM-dd"
          selected={new Date(eventDate.endDate)}
          onChange={(date) =>
            setEventDate((prev) => ({
              ...prev,
              endDate: getFormatDate(date),
            }))
          }
          onFocus={(e) => e.target.blur()}
          selectsEnd
          startDate={new Date(eventDate.startDate)}
          endDate={new Date(eventDate.endDate)}
          minDate={new Date(eventDate.startDate)}
          placeholderText="YYYY-MM-DD"
          dayClassName={(date) =>
            getDayName(createDate(date)) === "토"
              ? "Sat"
              : getDayName(createDate(date)) === "일"
              ? "Sun"
              : undefined
          }
          renderCustomHeader={({
            monthDate,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
            decreaseMonth,
            increaseMonth,
          }) => (
            <div
              className="header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="month-day">
                {monthDate.toLocaleString("ko-KO", {
                  month: "long",
                  year: "numeric",
                })}
              </div>

              <div className="btn-group">
                <div
                  className="btn_month btn_month-prev"
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  <img
                    className="img-fluid"
                    src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png"
                  />
                </div>
                <div
                  className="btn_month btn_month-next"
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  <img
                    className="img-fluid"
                    src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png"
                  />
                </div>
              </div>
            </div>
          )}
        />
      </div>
    </div>;
  }

  return (
    <>
      {/* 이벤트 추가/수정 */}
      <div className="DatepickerBox ver1">
        <div className="align-items-center d-flex ver2">
          <DatePicker
            className="form-control InputType input-datepicker event-add"
            locale={ko}
            dateFormat="yyyy-MM-dd"
            selected={new Date(eventDate.startDate)}
            onChange={(date) => {
              if (getMaxDate(date, eventDate.endDate)) {
                setEventDate({
                  startDate: getFormatDate(eventDate.endDate),
                  endDate: getFormatDate(date),
                });
                return;
              }

              setEventDate((prev) => ({
                ...prev,
                startDate: getFormatDate(date),
              }));
            }}
            onFocus={(e) => e.target.blur()}
            selectsStart
            minDate={new Date()}
            startDate={new Date(eventDate.startDate)}
            endDate={new Date(eventDate.endDate)}
            placeholderText="YYYY-MM-DD"
            dayClassName={(date) =>
              getDayName(createDate(date)) === "토"
                ? "Sat"
                : getDayName(createDate(date)) === "일"
                ? "Sun"
                : undefined
            }
            renderCustomHeader={({
              monthDate,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
              decreaseMonth,
              increaseMonth,
            }) => (
              <div
                className="header"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="month-day">
                  {monthDate.toLocaleString("ko-KO", {
                    month: "long",
                    year: "numeric",
                  })}
                </div>

                <div className="btn-group">
                  <div
                    className="btn_month btn_month-prev"
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    <img
                      className="img-fluid"
                      src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png"
                    />
                  </div>
                  <div
                    className="btn_month btn_month-next"
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    <img
                      className="img-fluid"
                      src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png"
                    />
                  </div>
                </div>
              </div>
            )}
          />
          <span className="tilde">~</span>
          <DatePicker
            className="form-control InputType input-datepicker event-add"
            locale={ko}
            dateFormat="yyyy-MM-dd"
            selected={new Date(eventDate.endDate)}
            onChange={(date) =>
              setEventDate((prev) => ({
                ...prev,
                endDate: getFormatDate(date),
              }))
            }
            onFocus={(e) => e.target.blur()}
            selectsEnd
            startDate={new Date(eventDate.startDate)}
            endDate={new Date(eventDate.endDate)}
            minDate={new Date(eventDate.startDate)}
            placeholderText="YYYY-MM-DD"
            dayClassName={(date) =>
              getDayName(createDate(date)) === "토"
                ? "Sat"
                : getDayName(createDate(date)) === "일"
                ? "Sun"
                : undefined
            }
            renderCustomHeader={({
              monthDate,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
              decreaseMonth,
              increaseMonth,
            }) => (
              <div
                className="header"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="month-day">
                  {monthDate.toLocaleString("ko-KO", {
                    month: "long",
                    year: "numeric",
                  })}
                </div>

                <div className="btn-group">
                  <div
                    className="btn_month btn_month-prev"
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    <img
                      className="img-fluid"
                      src="https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png"
                    />
                  </div>
                  <div
                    className="btn_month btn_month-next"
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    <img
                      className="img-fluid"
                      src="https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png"
                    />
                  </div>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </>
  );
}
