import Datepicker from "../../common/Datepicker";

export default function SaleMenuAddQuantity({
    saleMenuStatus,
    setSaleMenuStatus,
    isShowingQuantityMenu,
    setIsTime,
    eventDate,
    setEventDate,
    quantity,
    setQuantity,
    saleLimit,
    setSaleLimit,
    eventTime,
    setEventTime,
    smartOrderTime,
    setSmartOrderTime,
    isDefaultStore
}) {
    const handleQuantityChange = ({ target }) => {
        if (parseInt(target.value) > 1000) {
            return;
        }

        setQuantity(parseInt(target.value));
    };

    const handleSaleLimitChange = ({ target }) => {
        if (parseInt(target.value) > 100) {
            return;
        }

        setSaleLimit(parseInt(target.value));
    };

    return (
        <>
            <div
                className={`SalePrdAdd-subbox prdLimited-box ${
                    isShowingQuantityMenu ? "active" : ""
                }`}
            >
                <div className="subBox">
                    <input
                        className="form-check-input check"
                        type="checkbox"
                        checked={saleMenuStatus.saleCount}
                        onChange={() =>
                            setSaleMenuStatus((prev) => ({
                                ...prev,
                                saleCount: !prev.saleCount,
                            }))
                        }
                    />
                    <div className="checkContent">
                        <p className="tit">1일 판매 수량</p>
                        <div className="SalePrdAdd-inputbox g-0 position-relative">
                            <input
                                type="number"
                                pattern="\d*"
                                className="form-control InputType PrdCount"
                                placeholder="0"
                                value={quantity || ""}
                                // defaultValue={quantity}
                                onChange={handleQuantityChange}
                                onWheel={(e) => e.target.blur()}
                            />
                            <div className="end-0 position-absolute price-txt top-0">
                                개
                            </div>
                        </div>
                    </div>
                </div>
                <div className="subBox date">
                    <input
                        className="form-check-input check"
                        type="checkbox"
                        checked={saleMenuStatus.saleDate}
                        onChange={() => {
                            setSaleMenuStatus((prev) => ({
                                ...prev,
                                saleDate: !prev.saleDate,
                            }));
                        }}
                    />
                    <div className="checkContent">
                        <p className="tit">판매 일자 설정</p>
                        {saleMenuStatus.saleDate && (
                            <div className="inputbox">
                                <div id="range" className="range d-flex">
                                    <Datepicker
                                        type="sale"
                                        eventDate={eventDate}
                                        setEventDate={setEventDate}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="subBox time">
                    <input
                        className="form-check-input check"
                        type="checkbox"
                        checked={saleMenuStatus.saleTime}
                        onChange={() => {
                            setSaleMenuStatus((prev) => ({
                                ...prev,
                                saleTime: !prev.saleTime,
                            }));
                        }}
                    />
                    <div className="checkContent">
                        <p className="tit">판매 시간 설정</p>
                        <Datepicker
                            type="saleTime"
                            eventDate={eventDate}
                            setEventDate={setEventDate}
                            eventTime={eventTime}
                            setEventTime={setEventTime}
                        />
                    </div>
                </div>
                
                { isDefaultStore 
                ?
                    null 
                :
                <div className="subBox time">
                    <input
                        className="form-check-input check"
                        type="checkbox"
                        checked={saleMenuStatus.saleSmartOrder}
                        onChange={() => {
                            setSaleMenuStatus((prev) => ({
                                ...prev,
                                saleSmartOrder: !prev.saleSmartOrder,
                            }));
                        }}
                    />
                    <div className="checkContent">
                        <p className="tit">(태블릿) 주문 가능 시간 설정</p>
                        <Datepicker
                            type="saleTime"
                            eventDate={eventDate}
                            setEventDate={setEventDate}
                            eventTime={smartOrderTime}
                            setEventTime={setSmartOrderTime}
                        />
                    </div>
                </div>
                }
                    
                
                <div className="subBox mb-0">
                    <input
                        className="form-check-input check"
                        type="checkbox"
                        checked={saleMenuStatus.saleLimit}
                        onChange={() =>
                            setSaleMenuStatus((prev) => ({
                                ...prev,
                                saleLimit: !prev.saleLimit,
                            }))
                        }
                    />
                    <div className="checkContent">
                        <p className="tit mb-2">1인 일일 구매 한도</p>
                        <div className="SalePrdAdd-inputbox g-0 position-relative">
                            <input
                                type="number"
                                pattern="\d*"
                                className="form-control InputType"
                                placeholder="0"
                                value={saleLimit || ""}
                                onChange={handleSaleLimitChange}
                                onWheel={(e) => e.target.blur()}
                            />
                            <div className="end-0 position-absolute price-txt top-0">
                                개
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
