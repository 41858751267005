import { forwardRef, useEffect } from "react";
import { useRef } from "react";

function resize({ target }) {
    target.style.height = "1px";
    target.style.height = target.scrollHeight + "px";
}

function Notice({ noticeRegister, errors, isNotice, setIsNotice }, ref) {
    const noticeRef = useRef();

    useEffect(() => {
        if (noticeRef.current !== null) {
            noticeRef.current.style.height = `${
                noticeRef.current.scrollHeight || 65
            }px`;
        }
    }, [noticeRef]);

    return (
        <div className="BasicInfo-Formitem">
            <div className="BasicInfo_Switch">
                <p className="BasicInfo-tit">4. 안내사항</p>
                <div className="form-check form-switch">
                    <input
                        className="form-check-input input-switch"
                        type="checkbox"
                        role="switch"
                        id="notice_toggle"
                        checked={isNotice}
                        onChange={() => setIsNotice((prev) => !prev)}
                    />
                    <label
                        className="form-check-label on"
                        htmlFor="notice_toggle"
                    >
                        사용중
                    </label>
                    <label
                        className="form-check-label off"
                        htmlFor="notice_toggle"
                    >
                        사용안함
                    </label>
                </div>
            </div>
            <div
                className={`BasicInfo-inputbox inputbox-switch ${
                    isNotice && "checked"
                }`}
            >
                <textarea
                    className={`form-control InputType ${
                        errors.notice && "active"
                    }`}
                    placeholder="(필수) 안내사항 또는 공지사항을 입력해주세요."
                    onKeyDown={resize}
                    onKeyUp={resize}
                    {...noticeRegister}
                    ref={(e) => {
                        ref(e);
                        noticeRef.current = e;
                    }}
                />
                {errors.notice && (
                    <div className="form-text info-txt">
                        {errors.notice.message}
                    </div>
                )}
            </div>
        </div>
    );
}

export default forwardRef(Notice);
