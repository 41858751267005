import Datepicker from "./DatePicker";

export default function CloseTimeList({
    nonOperationTimes,
    nonOperationTimeDispatch,
}) {
    const handleNonOperationRemoveBtnClick = (id) => {
        nonOperationTimeDispatch({
            type: "removed_nonOperationTime",
            payload: id,
        });
    };

    const handleNonOperationAddBtnClick = () => {
        nonOperationTimeDispatch({ type: "added_nonOperationTime" });
    };

    const mapedNonOperationTimes = nonOperationTimes.map((nonOperationTime) => {
        return (
            <div
                className="non_openDate"
                key={nonOperationTime.id || nonOperationTime.tempId}
            >
                <div id="range" className="range d-flex">
                    <Datepicker
                        id={nonOperationTime.tempId}
                        eventDate={{
                            startDate: nonOperationTime.startDate,
                            endDate: nonOperationTime.endDate,
                        }}
                        nonOperationTimeDispatch={nonOperationTimeDispatch}
                    />
                </div>
                <button
                    type="button"
                    className="button btn-default"
                    onClick={() =>
                        handleNonOperationRemoveBtnClick(
                            nonOperationTime.tempId
                        )
                    }
                >
                    삭제
                </button>
                <button
                    type="button"
                    className="button btn-light"
                    onClick={handleNonOperationAddBtnClick}
                >
                    추가
                </button>
            </div>
        );
    });

    return <div className="non_openDatebox">{mapedNonOperationTimes}</div>;
}
