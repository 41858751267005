export const initialState = {
    storeTitle: "",
    popupImageType: "NONE",
    title: "",
    subTitle: "",
    buttonStatus: "NONE",
    buttonText: "",
    buttonLink: "",
};

export const popupReducer = (state, action) => {
    switch (action.type) {
        case "changed_storeTitle":
            return { ...state, storeTitle: action.payload };
        case "changed_popupImageType":
            return { ...state, popupImageType: action.payload };
        case "changed_title":
            return { ...state, title: action.payload };
        case "changed_subTitle":
            return { ...state, subTitle: action.payload };
        case "changed_buttonStatus":
            return { ...state, buttonStatus: action.payload };
        case "changed_buttonText":
            return { ...state, buttonText: action.payload };
        case "changed_buttonLink":
            return { ...state, buttonLink: action.payload };
        default:
            throw Error(`Unknown action type ${action.type}`);
    }
};
