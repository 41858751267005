import { apiClient } from "../api/apiClient";
import { useMutation } from "./common/useMutation";

export default function useEventRemove(
    setStatus,
    setIsRemoveModalShowing,
    setIsRemoveConfirmModalShowing,
    refetchInfinite
) {
    const { mutate, isLoading } = useMutation(
        async (params) => {
            await apiClient({
                url: "/events",
                method: "delete",
                data: { eventIds: params },
            });
            return params;
        },
        {
            onSuccess: (params) => {
                refetchInfinite();
                setStatus("DEFAULT");
                setIsRemoveModalShowing(false);
                setIsRemoveConfirmModalShowing(true);
            },
            onError: (data) => {
                console.log(data);
                // alert(response.data.message);
            },
        }
    );

    return { mutate, isLoading };
}
