import { useNavigate } from "react-router-dom";
import { apiClient } from "../../api/apiClient";
import { useMutation } from "../common/useMutation";

export function usePopupAdd() {
    const navigate = useNavigate();

    const getFormData = (data, image) => {
        if (!image.isFlag) {
            const newFormData = new FormData();
            newFormData.append("request", JSON.stringify(data));
            newFormData.append("images", "");
            return newFormData;
        }

        image.formData.append("request", JSON.stringify(data));

        return image.formData;
    };

    const { mutate, isLoading } = useMutation(
        async ({ data, image }) => {
            await apiClient({
                method: "post",
                url: "/popups",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                data: getFormData(data, image),
            });
        },
        {
            onSuccess: () => {
                navigate("/popup");
            },
            onError: (error) => {
                console.log(error);
                // alert(response.data.code);
            },
        }
    );

    return { mutate, isLoading };
}
