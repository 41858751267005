import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { PrintConsole, getImgUrl, setCommas, ReturnYear, getApiUrl } from './../../function/common';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Image, Table, Tabs } from 'react-bootstrap';

import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ko } from 'date-fns/esm/locale';

import { Tab } from 'react-bootstrap';
import Btn from './../../components/common/Btn';
import { setAccessToken, setNav5 } from '../../api/ApiWebView';


import './SettleHistory.css';

export default function SettleHistory(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const [printCoupon, setPrintCoupon] = useState({});
    const onChangeData2 = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setPrintCoupon({
          ...printCoupon,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };
    
    // console.log('==================='+localStorage.getItem('storeDomain'))

    // if(localStorage.getItem('storeDomain') === undefined || localStorage.getItem('storeDomain') === null){
    //     alert('로그인이 필요한 서비스 입니다.');
    //     navigate('/store/login');
    // }

        
    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    const tableCss = {verticalAlign: 'top'};

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    
    function getSearch() {
        
        let query = '';

        // console.log(startDate.getMonth());

        let start = new Date(startDate);
        let end = new Date(endDate);

        let TmpStart = start.getFullYear().toString().substring(2,4) + '-' + ('0' + (start.getMonth()+1)).slice(-2)+'-'+('0' + start.getDate()).slice(-2);
        let TmpEnd = end.getFullYear().toString().substring(2,4) + '-' + ('0' + (end.getMonth()+1)).slice(-2)+'-'+('0' + end.getDate()).slice(-2);
        query = '?startDate='+TmpStart+'&endDate='+TmpEnd;
        
        return new Promise( async function(resolve, reject) {
            
            const response = await axios.get( 
                getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/settlements'+query),
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
            ).then(
                function(response){
                    PrintConsole(response);
                    if(response.status === 200){
                        setData(response.data);
                    }
                }
            ).catch( function(error){
                console.log(error);
            })
            // if (response) {
            //     console.log(response);
            //     return resolve(response);
            // }
        });
    };

    function getPrintList() {
        
        let query = '';

        // console.log(startDate.getMonth());

        let start = new Date(startDate);
        let end = new Date(endDate);

        let TmpStart = start.getFullYear().toString().substring(2,4) + '-' + ('0' + (start.getMonth()+1)).slice(-2)+'-'+('0' + start.getDate()).slice(-2);
        let TmpEnd = end.getFullYear().toString().substring(2,4) + '-' + ('0' + (end.getMonth()+1)).slice(-2)+'-'+('0' + end.getDate()).slice(-2);
        query = '?startDateOfIssuedDate='+TmpStart+'&endDateOfIssuedDate='+TmpEnd;
        
        return new Promise( async function(resolve, reject) {
            
            const response = await axios.get( 
                getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/coupons'+query),
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
            ).then(
                function(response){
                    PrintConsole(response);
                    if(response.status === 200){
                        setPrintCoupon(response.data);

                        getSumPrintList();
                    }
                }
            ).catch( function(error){
                console.log(error);
            })
            // if (response) {
            //     console.log(response);
            //     return resolve(response);
            // }
        });
    };

    const [sumPrintCoupon, setSumPrintCoupon] = useState({});

    function getSumPrintList() {
        let query = '';

        let start = new Date(startDate);
        let end = new Date(endDate);

        let TmpStart = start.getFullYear().toString().substring(2,4) + '-' + ('0' + (start.getMonth()+1)).slice(-2)+'-'+('0' + start.getDate()).slice(-2);
        let TmpEnd = end.getFullYear().toString().substring(2,4) + '-' + ('0' + (end.getMonth()+1)).slice(-2)+'-'+('0' + end.getDate()).slice(-2);
        query = '?startDateOfIssuedDate='+TmpStart+'&endDateOfIssuedDate='+TmpEnd;
        
        return new Promise( async function(resolve, reject) {
            
            const response = await axios.get( 
                getApiUrl('stores/'+localStorage.getItem('storeDomain')+'/coupons-summary'+query),
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
            ).then(
                function(response){
                    PrintConsole(response);
                    if(response.status === 200){
                        setSumPrintCoupon(response.data);
                    }
                }
            ).catch( function(error){
                console.log(error);
            })
            // if (response) {
            //     console.log(response);
            //     return resolve(response);
            // }
        });
    };

    function AddEvent(){
        let CateItem = document.querySelectorAll(".HistoryYear .yearbox .btn")
        let FixedYear = document.querySelector(".HistoryFixed")

        for (var i = 0; i < CateItem.length; i++) {
            CateItem[i].addEventListener("click", function () {
                let currentIndex = i;

                CateItem.forEach(function (targets, index) {
                    if (index != currentIndex) {
                        targets.classList.remove("btn-default");
                        targets.classList.add("btn-light");
                    }
                });

                var Text = this.innerText;
                FixedYear.innerText = Text;

                this.classList.remove("btn-light");
                this.classList.add("btn-default");

                let HCClass = this.getAttribute('data-content');
                let HContent = document.querySelectorAll(".HContent")

                for (var i = 0; i < HContent.length; i++) {
                    HContent[i].style.display = "none";

                    if (!HCClass) {
                        HContent[i].style.display = "block";
                    } else {
                        document.querySelector(HCClass).style.display = "block";
                    }
                }

            });
        }

        let CateTarget = document.querySelector(".HistoryFixed");
        let TopValue = document.querySelector(".Point").clientHeight + 50;

        document.addEventListener('scroll', function () {
            console.log(TopValue)

            if (window.pageYOffset >= TopValue) {
                CateTarget.classList.add('fixed')
                //CateTarget.style.top = TopValue + "px";
                CateTarget.style.top = "0px";

            } else {
                CateTarget.classList.remove('fixed')
            }

        });
    }

    useEffect( () => {
        //데이터 로딩
        setLoading(true);

        // getSearch();
        // getPrintList();

        try{
            //webview에 토큰값, 도메인 전달
            setAccessToken();
        }catch(e){}

        // AddEvent();
        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    console.log(printCoupon);

    if (loading) return <></>

    return (
        <>
        <div className='demo'>

            <div className="TSettleHistoryPage">
        <div className="container-fluid g-0">

            <nav className="sub-navbar">
                <p className="navbar-title">지난 매출 내역</p>
                <button type="button" className="btn-clear btn-close" onClick={()=>{navigate(-1)}}>
                    <img className="img-fluid" alt="뒤로가기"
                        src="https://www.flexdaycdn.net/public/images/ticket/close2.png" />
                </button>
            </nav>

            <div className="HistoryBox">
                <div className="HistoryYear pb-3 pt-4-5 px-4-5">
                    <div className="d-flex yearbox">
                        <div className="btn btn-default w-auto h-auto" data-content=".Y2022">2022</div>
                        <div className="btn btn-light w-auto h-auto" data-content=".Y2021">2021</div>
                        <div className="btn btn-light w-auto h-auto" data-content=".Y2020">2020</div>
                    </div>
                    <div className="fixedbox">
                        <div className="HistoryFixed text-center">
                            2022
                        </div>
                    </div>
                </div>
                <div className="HContentWrap">
                    <div className="HContent Y2022">
                        <div className="Point Listitem px-4-5 py-4">
                            <Link to={"/demo/settlehistorysub"}                                 className="align-items-center d-flex justify-content-between">
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        12월12일 ~ 12월18일
                                    </p>
                                    <p className="price mb-0">
                                        <span className="num">
                                            7,555,275
                                        </span> 원
                                    </p>
                                </div>
                                <div className="align-items-center btnbox text-end">
                                    <img className="img-fluid arrow"
                                        src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                </div>
                            </Link>
                        </div>
                        <div className="Listitem px-4-5 py-4">
                            <Link to={"/demo/settlehistorysub"} className="align-items-center d-flex justify-content-between">
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        12월5일 ~ 12월11일
                                    </p>
                                    <p className="price mb-0">
                                        <span className="num">
                                            5,599,255
                                        </span> 원
                                    </p>
                                </div>
                                <div className="align-items-center btnbox text-end">
                                    <img className="img-fluid arrow"
                                        src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                </div>
                            </Link>
                        </div>
                        {/* <div className="Listitem px-4-5 py-4">
                            <Link to={"/demo/settlehistorysub"} className="align-items-center d-flex justify-content-between">
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        10월3일 ~ 10월9일
                                    </p>
                                    <p className="price mb-0">
                                        <span className="num">
                                            5,396,900
                                        </span> 원
                                    </p>
                                </div>
                                <div className="align-items-center btnbox text-end">
                                    <img className="img-fluid arrow"
                                        src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                </div>
                            </Link>
                        </div>
                        <div className="Listitem px-4-5 py-4">
                            <Link to={"/demo/settlehistorysub"} className="align-items-center d-flex justify-content-between">
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        9월26일 ~ 10월2일
                                    </p>
                                    <p className="price mb-0">
                                        <span className="num">
                                            9,228,350
                                        </span> 원
                                    </p>
                                </div>
                                <div className="align-items-center btnbox text-end">
                                    <img className="img-fluid arrow"
                                        src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                </div>
                            </Link>
                        </div>
                        <div className="Listitem px-4-5 py-4">
                            <Link to={"/demo/settlehistorysub"} className="align-items-center d-flex justify-content-between">
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        9월19일 ~ 9월25일
                                    </p>
                                    <p className="price mb-0">
                                        <span className="num">
                                            5,599,000
                                        </span> 원
                                    </p>
                                </div>
                                <div className="align-items-center btnbox text-end">
                                    <img className="img-fluid arrow"
                                        src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                </div>
                            </Link>
                        </div>
                        <div className="Listitem px-4-5 py-4">
                            <Link to={"/demo/settlehistorysub"} className="align-items-center d-flex justify-content-between">
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        9월12일 ~ 9월18일
                                    </p>
                                    <p className="price mb-0">
                                        <span className="num">
                                            5,215,000
                                        </span> 원
                                    </p>
                                </div>
                                <div className="align-items-center btnbox text-end">
                                    <img className="img-fluid arrow"
                                        src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                </div>
                            </Link>
                        </div>
                        <div className="Listitem px-4-5 py-4">
                            <Link to={"/demo/settlehistorysub"} className="align-items-center d-flex justify-content-between">
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        9월5일 ~ 9월11일
                                    </p>
                                    <p className="price mb-0">
                                        <span className="num">
                                            4,538,000
                                        </span> 원
                                    </p>
                                </div>
                                <div className="align-items-center btnbox text-end">
                                    <img className="img-fluid arrow"
                                        src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="HContent Y2021">
                        <div className="Point Listitem none px-4-5 py-4">
                            <Link to={"/demo/settlehistorysub"} className="align-items-center d-flex justify-content-between">
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        12월27일 ~ 12월31일
                                    </p>
                                    <p className="price mb-0">
                                        <span className="num">
                                            0
                                        </span> 원
                                    </p>
                                </div>
                                <div className="align-items-center btnbox text-end">
                                    <img className="img-fluid arrow"
                                        src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                </div>
                            </Link>
                        </div>
                        <div className="Listitem none px-4-5 py-4">
                            <Link to={"/demo/settlehistorysub"} className="align-items-center d-flex justify-content-between">
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        12월20일 ~ 12월26일
                                    </p>
                                    <p className="price mb-0">
                                        <span className="num">
                                            0
                                        </span> 원
                                    </p>
                                </div>
                                <div className="align-items-center btnbox text-end">
                                    <img className="img-fluid arrow"
                                        src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                </div>
                            </Link>
                        </div>
                        <div className="Listitem none px-4-5 py-4">
                            <Link to={"/demo/settlehistorysub"} className="align-items-center d-flex justify-content-between">
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        12월13일 ~ 12월19일
                                    </p>
                                    <p className="price mb-0">
                                        <span className="num">
                                            0
                                        </span> 원
                                    </p>
                                </div>
                                <div className="align-items-center btnbox text-end">
                                    <img className="img-fluid arrow"
                                        src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                </div>
                            </Link>
                        </div>
                        <div className="Listitem none px-4-5 py-4">
                            <Link to={"/demo/settlehistorysub"} className="align-items-center d-flex justify-content-between">
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        12월6일 ~ 12월12일
                                    </p>
                                    <p className="price mb-0">
                                        <span className="num">
                                            0
                                        </span> 원
                                    </p>
                                </div>
                                <div className="align-items-center btnbox text-end">
                                    <img className="img-fluid arrow"
                                        src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                </div>
                            </Link>
                        </div>
                        <div className="Listitem none px-4-5 py-4">
                            <Link to={"/demo/settlehistorysub"} className="align-items-center d-flex justify-content-between">
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        11월29일 ~ 12월5일
                                    </p>
                                    <p className="price mb-0">
                                        <span className="num">
                                            0
                                        </span> 원
                                    </p>
                                </div>
                                <div className="align-items-center btnbox text-end">
                                    <img className="img-fluid arrow"
                                        src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                </div>
                            </Link>
                        </div>
                        <div className="Listitem none px-4-5 py-4">
                            <Link to={"/demo/settlehistorysub"} className="align-items-center d-flex justify-content-between">
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        11월22일 ~ 11월28일
                                    </p>
                                    <p className="price mb-0">
                                        <span className="num">
                                            0
                                        </span> 원
                                    </p>
                                </div>
                                <div className="align-items-center btnbox text-end">
                                    <img className="img-fluid arrow"
                                        src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                </div>
                            </Link>
                        </div>
                        <div className="Listitem none px-4-5 py-4">
                            <Link to={"/demo/settlehistorysub"} className="align-items-center d-flex justify-content-between">
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        11월15일 ~ 11월21일
                                    </p>
                                    <p className="price mb-0">
                                        <span className="num">
                                            0
                                        </span> 원
                                    </p>
                                </div>
                                <div className="align-items-center btnbox text-end">
                                    <img className="img-fluid arrow"
                                        src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="HContent Y2020">
                        <div className="Point Listitem none px-4-5 py-4">
                            <Link to={"/demo/settlehistorysub"} className="align-items-center d-flex justify-content-between">
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        12월28일 ~ 12월31일
                                    </p>
                                    <p className="price mb-0">
                                        <span className="num">
                                            0
                                        </span> 원
                                    </p>
                                </div>
                                <div className="align-items-center btnbox text-end">
                                    <img className="img-fluid arrow"
                                        src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                </div>
                            </Link>
                        </div>
                        <div className="Listitem none px-4-5 py-4">
                            <Link to={"/demo/settlehistorysub"} className="align-items-center d-flex justify-content-between">
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        12월21일 ~ 12월27일
                                    </p>
                                    <p className="price mb-0">
                                        <span className="num">
                                            0
                                        </span> 원
                                    </p>
                                </div>
                                <div className="align-items-center btnbox text-end">
                                    <img className="img-fluid arrow"
                                        src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                </div>
                            </Link>
                        </div>
                        <div className="Listitem none px-4-5 py-4">
                            <Link to={"/demo/settlehistorysub"} className="align-items-center d-flex justify-content-between">
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        12월14일 ~ 12월20일
                                    </p>
                                    <p className="price mb-0">
                                        <span className="num">
                                            0
                                        </span> 원
                                    </p>
                                </div>
                                <div className="align-items-center btnbox text-end">
                                    <img className="img-fluid arrow"
                                        src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                </div>
                            </Link>
                        </div>
                        <div className="Listitem none px-4-5 py-4">
                            <Link to={"/demo/settlehistorysub"} className="align-items-center d-flex justify-content-between">
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        12월7일 ~ 12월13일
                                    </p>
                                    <p className="price mb-0">
                                        <span className="num">
                                            0
                                        </span> 원
                                    </p>
                                </div>
                                <div className="align-items-center btnbox text-end">
                                    <img className="img-fluid arrow"
                                        src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                </div>
                            </Link>
                        </div>
                        <div className="Listitem none px-4-5 py-4">
                            <Link to={"/demo/settlehistorysub"} className="align-items-center d-flex justify-content-between">
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        11월30일 ~ 11월6일
                                    </p>
                                    <p className="price mb-0">
                                        <span className="num">
                                            0
                                        </span> 원
                                    </p>
                                </div>
                                <div className="align-items-center btnbox text-end">
                                    <img className="img-fluid arrow"
                                        src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                </div>
                            </Link>
                        </div>
                        <div className="Listitem none px-4-5 py-4">
                            <Link to={"/demo/settlehistorysub"} className="align-items-center d-flex justify-content-between">
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        11월23일 ~ 11월29일
                                    </p>
                                    <p className="price mb-0">
                                        <span className="num">
                                            0
                                        </span> 원
                                    </p>
                                </div>
                                <div className="align-items-center btnbox text-end">
                                    <img className="img-fluid arrow"
                                        src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                </div>
                            </Link>
                        </div>
                        <div className="Listitem none px-4-5 py-4">
                            <Link to={"/demo/settlehistorysub"} className="align-items-center d-flex justify-content-between">
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        11월16일 ~ 11월22일
                                    </p>
                                    <p className="price mb-0">
                                        <span className="num">
                                            0
                                        </span> 원
                                    </p>
                                </div>
                                <div className="align-items-center btnbox text-end">
                                    <img className="img-fluid arrow"
                                        src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                </div>
                            </Link>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    </div>

    </div>
        </>
    )
}