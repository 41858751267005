import { useEffect, useRef, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LoadingModal from "../../../components/loadingmodal/LoadingModal";
import SaleMenuAddQuantity from "../../../components/salemenu/salemenuaddquantity/SaleMenuAddQuantity";
import { getFormatDate, validateSaleMenu } from "../../../function/common";
import useRemoveBodyStyle from "../../../hooks/common/useRemoveBodyStyle";
import useSaleMenuAdd from "../../../hooks/useSaleMenuAdd";
import LoadingPortal from "../../../portals/LoadingPortal";
import AdminHeader from "../../UserMain/components/AdminHeader";
import { STORE_DEFAULT } from "../../../components/constants/CommonConstants";
import "./SaleMenuAdd.css";
import {
    getThisWeekDate,
    getThisWeekDateMenuText,
    initialState,
    saleMenuAddReducer,
} from "../../../reducers/saleMenuAdd/saleMenuAddReducer";
import { useReducer } from "react";
import { useAppSelector } from "../../../store";
import { useAppDispatch } from "../../../store";
import { fetchStoreInfo } from "../../../store/modules/storeSlice";
import { MENU_STATUS } from "../../../components/constants/CommonConstants";
import { DESC_STATUS } from "../../../components/constants/CommonConstants";
import { isDefaultStore } from "../../../function/common";

function resize({ target }) {
    target.style.height = "1px";

    if (target.name === "common") {
        if (window.innerWidth >= 599) {
            target.style.height =
                target.scrollHeight <= 134
                    ? 134 + "px"
                    : target.scrollHeight + "px";
        } else {
            target.style.height =
                target.scrollHeight <= 106
                    ? 106 + "px"
                    : target.scrollHeight + "px";
        }
        return;
    }

    if (target.name === "additionalDesc") {
        if (window.innerWidth >= 599) {
            target.style.height =
                target.scrollHeight <= 134
                    ? 134 + "px"
                    : target.scrollHeight + "px";
        } else {
            target.style.height =
                target.scrollHeight <= 106
                    ? 106 + "px"
                    : target.scrollHeight + "px";
        }
        return;
    }

    if (window.innerWidth >= 599) {
        target.style.height =
            target.scrollHeight <= 168
                ? 168 + "px"
                : target.scrollHeight + "px";
    } else {
        target.style.height =
            target.scrollHeight <= 134
                ? 134 + "px"
                : target.scrollHeight + "px";
    }
}


const MENU_SALE_STATUS = {
    SALE: "SALE",
    SALE_STOPPED: "SALE_STOPPED",
};

const MIN_PRICE = 300;


export default function SaleMenuAdd() {
    useRemoveBodyStyle();
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    
    useEffect(() => {
        dispatch(fetchStoreInfo());
    }, [dispatch]);
    
    const storeType = useAppSelector((state) => {return state.storeReducer.storeType});
    
    useEffect(() => { 
        // do stuff   
        
     }, [storeType]);
    


    const [menuStatus, setMenuStatus] = useState("");
    const menuNameRef = useRef();
    const [menuPrice, setMenuPrice] = useState("");
    const [menuDesc, setMenuDesc] = useState("");
    const [isPriceInput, setIsPriceInput] = useState(false);
    const [isShowingMenuPriceWarning, setIsShowingMenuPriceWarning] =
        useState(false);
    const [saleStatus, setSaleStatus] = useState("");
    const [isShowingQuantityMenu, setIsShowingQuantityMenu] = useState();
    const [quantity, setQuantity] = useState(0);
    const [eventDate, setEventDate] = useState({
        startDate: getFormatDate(),
        endDate: getFormatDate(),
    });
    const [isTime, setIsTime] = useState();
    const [eventTime, setEventTime] = useState({
        startTime: "00:00",
        endTime: "23:30",
    });
    const [smartOrderTime, setSmartOrderTime] = useState({
        startTime: "00:00",
        endTime: "23:30",
    });
    const [errorText, setErrorText] = useState("");
    const [isRemoveModalShowing, setIsRemoveModalShowing] = useState(false);
    const [usingLine, setUsingLine] = useState({
        line: "ALL_LINE",
        value: "전체",
    });
    const [descStatus, setDescStatus] = useState("NONE");
    const [saleLimit, setSaleLimit] = useState(0);
    const [additionalDesc, setAdditionalDesc] = useState("");
    const [saleMenuStatus, setSaleMenuStatus] = useState({
        saleCount: false,
        saleDate: false,
        saleTime: false,
        saleLimit: false,
        saleSmartOrder: false,
    });
    const [dayMenuDescState, dayMenuDescStateDispatch] = useReducer(
        saleMenuAddReducer,
        initialState
    );

    const { mutate, isLoading } = useSaleMenuAdd(
        setErrorText,
        setIsRemoveModalShowing
    );

    useEffect(() => {
        dayMenuDescStateDispatch({ type: "reset" });
    }, []);

    useEffect(() => {
        if (
            new Date(eventDate.startTime).getTime() ===
            new Date(eventDate.endTime).getTime()
        ) {
            setIsTime(true);
            return;
        }

        setIsTime(false);
    }, [eventDate]);

    // useEffect(() => {
    //     if (eventDate.startDate === eventDate.endDate) {
    //         setSaleMenuStatus((prev) => ({ ...prev, saleTime: true }));
    //         return;
    //     }

    //     setSaleMenuStatus((prev) => ({ ...prev, saleTime: false }));
    // }, [eventDate]);

    useEffect(() => {
        if (menuPrice >= MIN_PRICE || Number.isNaN(menuPrice)) {
            setIsShowingMenuPriceWarning(false);
            return;
        }

        setIsShowingMenuPriceWarning(true);
    }, [menuPrice]);

    const handleRemoveModalShow = () => {
        setIsRemoveModalShowing(true);
    };

    const handleRemoveModalClose = () => {
        setIsRemoveModalShowing(false);
    };

    // 메뉴 종류 메인 변경
    const handleMainMenuChange = () => {
        setMenuStatus(MENU_STATUS.MAIN);
    };

    // 메뉴 종류 사이드 변경
    const handleSideMenuChange = () => {
        setMenuStatus(MENU_STATUS.SIDE);
    };

    // 메뉴 가격 변경
    const handleMenuPriceChange = ({ target }) => {
        if (parseInt(target.value) > 1000000) {
            return;
        }

        if (target.value < MIN_PRICE) {
            setIsPriceInput(true);
        }
        setMenuPrice(parseInt(target.value));
    };

    // 메뉴 설명 변경
    const handleMenuDescChange = ({ target }) => {
        setMenuDesc(target.value);
    };

    // 판매 상태 상품 변경
    const handleSaleStatusSaleChange = () => {
        setSaleStatus(MENU_SALE_STATUS.SALE);
    };

    // 판매 상태 중지 변경
    const handleSaleStatusSaleStopChange = () => {
        setSaleStatus(MENU_SALE_STATUS.SALE_STOPPED);
    };

    // 수량 제한 메뉴 보임
    const handleQuanityMenuChange = () => {
        setIsShowingQuantityMenu(true);
        setIsTime(true);
    };

    // 수량 제한 메뉴 안보임
    const handleNotQuanityMenuChange = () => {
        setIsShowingQuantityMenu(false);
        setIsTime(false);
    };

    // 판매 메뉴 추가 api 요청
    const handleNewSaleMenuRegisterClick = () => {
        if (isLoading) {
            return;
        }

        const newSaleMenu = {
            menuType: menuStatus,
            productName: menuNameRef.current.value,
            price: menuPrice,
            descriptionType: descStatus,
            productDescription:
                descStatus === DESC_STATUS.COMMON ? menuDesc : "",
            descriptionByDates:
                descStatus === DESC_STATUS.COMMON ? [] : dayMenuDescState,
            quantityLimit: {
                useQuantityLimit: isShowingQuantityMenu,
                startDateOfQuantityLimit: eventDate.startDate,
                endDateOfQuantityLimit: eventDate.endDate,
                startTimeOfQuantityLimit: eventTime.startTime,
                endTimeOfQuantityLimit: eventTime.endTime,
                startTimeOfSmartOrder: smartOrderTime.startTime,
                endTimeOfSmartOrder: smartOrderTime.endTime,
                countOfMaxSold: quantity || 0,
                countOfUserDayBuy: saleLimit || 0,
                daySellLimit: !isShowingQuantityMenu
                    ? false
                    : saleMenuStatus.saleCount,
                dayPeriodLimit: !isShowingQuantityMenu
                    ? false
                    : saleMenuStatus.saleDate,
                useTimeLimit: !isShowingQuantityMenu
                    ? false
                    : saleMenuStatus.saleTime,
                userDayBuyLimit: !isShowingQuantityMenu
                    ? false
                    : saleMenuStatus.saleLimit,
                smartOrderTimeLimit: !isShowingQuantityMenu
                    ? false
                    : saleMenuStatus.saleSmartOrder
            },
            additionalDescription: additionalDesc,
            toDayDescription: "",
            productStatus: saleStatus,
            canUseLine: usingLine.line,
        };

        if (
            !validateSaleMenu({
                saleMenuStatus,
                modifiedSaleMenu: newSaleMenu,
                setErrorText,
                setIsRemoveModalShowing,
            })
        ) {
            return;
        }
        
        mutate(newSaleMenu);
    };

    const handleLineChange = ({ target }) => {
        setUsingLine(target.value);
    };

    const handleLineClick = ({ target }) => {
        setUsingLine({
            line: target.dataset.name,
            value: target.dataset.value,
        });
    };

    return (
        <>
            <div className="demo">
                <div className="TSalePrdAddPage">
                    <div className="container-fluid g-0">
                        <nav className="sub-navbar">
                            <p className="navbar-title">판매 메뉴 추가</p>
                            <button
                                type="button"
                                className="btn-clear btn-back"
                                onClick={() => navigate("/salemenu")}
                            >
                                <img
                                    className="img-fluid"
                                    alt="뒤로가기"
                                    src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                                />
                            </button>
                            <button
                                className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasNavbar"
                                aria-controls="offcanvasNavbar"
                            >
                                <img
                                    className="img-fluid"
                                    alt="메뉴"
                                    src="https://www.flexdaycdn.net/public/images/ticket/menu3.png"
                                />
                            </button>
                            <AdminHeader />
                        </nav>

                        <div className="SalePrdAddBox py-4-5 px-4-5">
                            <div className="SalePrdAdd-Form">
                                <div className="SalePrdAdd-Formitem pb-5">
                                    <p className="SalePrdAdd-tit">
                                        1. 메뉴 종류<span>*</span>
                                    </p>
                                    <div className="SalePrdAdd-radiobox d-flex">
                                        <div className="radioBox">
                                            <input
                                                className="form-check-input"
                                                id="PrdOption1-1"
                                                type="radio"
                                                value={menuStatus}
                                                name="PrdOption1"
                                                onChange={handleMainMenuChange}
                                            />
                                            <label
                                                className="RadioLabel"
                                                htmlFor="PrdOption1-1"
                                            >
                                                <span>메인</span>
                                            </label>
                                        </div>
                                        <div className="radioBox">
                                            <input
                                                className="form-check-input"
                                                id="PrdOption1-2"
                                                type="radio"
                                                value={menuStatus}
                                                name="PrdOption1"
                                                onChange={handleSideMenuChange}
                                            />
                                            <label
                                                className="RadioLabel"
                                                htmlFor="PrdOption1-2"
                                            >
                                                <span>사이드</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="SalePrdAdd-Formitem pb-5">
                                    <p className="SalePrdAdd-tit">
                                        2. 메뉴 이름<span>*</span>
                                    </p>
                                    <div className="SalePrdAdd-inputbox">
                                        <input
                                            type="text"
                                            className="form-control InputType"
                                            placeholder="메뉴 이름을 입력해 주세요."
                                            ref={menuNameRef}
                                        />
                                    </div>
                                </div>
                                <div className="SalePrdAdd-Formitem pb-5">
                                    <p className="SalePrdAdd-tit">
                                        3. 메뉴 가격<span>*</span>
                                    </p>
                                    <div className="SalePrdAdd-inputbox g-0 position-relative">
                                        <input
                                            type="number"
                                            pattern="\d*"
                                            className={`form-control InputType PayPrice ${
                                                isPriceInput &&
                                                isShowingMenuPriceWarning
                                                    ? "active"
                                                    : "false"
                                            }`}
                                            value={menuPrice}
                                            placeholder="0"
                                            onWheel={(e) => e.target.blur()}
                                            onChange={handleMenuPriceChange}
                                        />
                                        <div className="end-0 position-absolute price-txt top-0">
                                            원
                                        </div>
                                        {/* <!-- 확인 : 1,000원 미만일 때 / className "active" 추가 --> */}
                                        {isShowingMenuPriceWarning && (
                                            <div className="form-text info-txt">
                                                최소{" "}
                                                {MIN_PRICE.toLocaleString()}
                                                원부터 메뉴 등록이 가능합니다.
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="SalePrdAdd-Formitem pb-5">
                                    <p className="SalePrdAdd-tit">
                                        4. 메뉴 설명
                                    </p>
                                    <div className="SalePrdAdd-radiobox d-flex mb-3">
                                        <div className="radioBox">
                                            <input
                                                className="form-check-input prdDes1"
                                                onChange={(val) => {
                                                    if(val.target.checked){
                                                        setDescStatus(DESC_STATUS.COMMON);
                                                    }else{
                                                        setDescStatus(DESC_STATUS.NONE);
                                                    }
                                                }}
                                                id="PrdOption4-1"
                                                type="checkbox"
                                                checked={
                                                    descStatus ===
                                                    DESC_STATUS.COMMON
                                                }
                                                name="PrdOption4"
                                            />
                                            <label
                                                className="RadioLabel"
                                                htmlFor="PrdOption4-1"
                                            >
                                                <span>공통 입력</span>
                                            </label>
                                        </div>
                                        <div className="radioBox">
                                            <input
                                                className="form-check-input prdDes2"
                                                onChange={(val) => {
                                                    if(val.target.checked){
                                                        setDescStatus(DESC_STATUS.DATE);
                                                    }else{
                                                        setDescStatus(DESC_STATUS.NONE);
                                                    }
                                                }}
                                                id="PrdOption4-2"
                                                type="checkbox"
                                                checked={
                                                    descStatus ===
                                                    DESC_STATUS.DATE
                                                }
                                                name="PrdOption4"
                                            />
                                            <label
                                                className="RadioLabel"
                                                htmlFor="PrdOption4-2"
                                            >
                                                <span>요일 별 입력</span>
                                            </label>
                                        </div>
                                    </div>
                                    {descStatus === DESC_STATUS.COMMON && (
                                        <div className="SalePrdDes prdDes-box1 active">
                                            <div className="SalePrdAdd-inputbox Input-placeholderbox">
                                                {!menuDesc && (
                                                    <div className="Input-placeholder">
                                                        메뉴에 대한 설명을
                                                        해주세요!
                                                        <br />
                                                        예) 뚝배기 갈비탕, 무우
                                                        잔파 생채, 양파 마늘쫑
                                                        장아찌
                                                    </div>
                                                )}
                                                <textarea
                                                    className="form-control ph InputType"
                                                    rows="2"
                                                    name="common"
                                                    defaultValue={menuDesc}
                                                    onKeyDown={resize}
                                                    onKeyUp={resize}
                                                    onChange={
                                                        handleMenuDescChange
                                                    }
                                                ></textarea>
                                            </div>
                                        </div>
                                    )}
                                    {descStatus === DESC_STATUS.DATE && (
                                        <div className="SalePrdDes prdDes-box2 active">
                                            <div
                                                className="nav nav-tabs prdDes-tabs"
                                                id="prdDes-tab"
                                                role="tablist"
                                            >
                                                <button
                                                    className="nav-link active"
                                                    id="nav-home-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#nav-prdDes1"
                                                    type="button"
                                                    role="tab"
                                                >
                                                    {getThisWeekDateMenuText(1)}
                                                </button>
                                                <button
                                                    className="nav-link"
                                                    id="nav-profile-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#nav-prdDes2"
                                                    type="button"
                                                    role="tab"
                                                >
                                                    {getThisWeekDateMenuText(2)}
                                                </button>
                                                <button
                                                    className="nav-link"
                                                    id="nav-contact-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#nav-prdDes3"
                                                    type="button"
                                                    role="tab"
                                                >
                                                    {getThisWeekDateMenuText(3)}
                                                </button>
                                                <button
                                                    className="nav-link"
                                                    id="nav-profile-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#nav-prdDes4"
                                                    type="button"
                                                    role="tab"
                                                >
                                                    {getThisWeekDateMenuText(4)}
                                                </button>
                                                <button
                                                    className="nav-link"
                                                    id="nav-contact-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#nav-prdDes5"
                                                    type="button"
                                                    role="tab"
                                                >
                                                    {getThisWeekDateMenuText(5)}
                                                </button>
                                            </div>
                                            <div
                                                className="tab-content prdDes-content"
                                                id="prdDes-tabContent"
                                            >
                                                <div
                                                    className="tab-pane show active"
                                                    id="nav-prdDes1"
                                                    role="tabpanel"
                                                >
                                                    <div className="SalePrdAdd-inputbox Input-placeholderbox">
                                                        {!dayMenuDescState[0]
                                                            .detailDescription && (
                                                            <div className="Input-placeholder">
                                                                월요일 메뉴에
                                                                대한 설명을
                                                                입력해주세요.
                                                                <br />
                                                                작성된 내용은
                                                                모바일 앱 이번주
                                                                식단에서도 확인
                                                                됩니다.
                                                                <br />
                                                                예)뚝배기
                                                                갈비탕, 무우
                                                                잔파 생채, 양파
                                                                마늘종 장아찌
                                                            </div>
                                                        )}
                                                        <textarea
                                                            className="form-control ph InputType"
                                                            rows="3"
                                                            value={
                                                                dayMenuDescState[0]
                                                                    .detailDescription
                                                            }
                                                            onChange={({
                                                                target,
                                                            }) => {
                                                                dayMenuDescStateDispatch(
                                                                    {
                                                                        type: "added_menuDesc",
                                                                        payload:
                                                                            {
                                                                                day: 0,
                                                                                value: target.value,
                                                                            },
                                                                    }
                                                                );
                                                            }}
                                                            onKeyDown={resize}
                                                            onKeyUp={resize}
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane"
                                                    id="nav-prdDes2"
                                                    role="tabpanel"
                                                >
                                                    <div className="SalePrdAdd-inputbox Input-placeholderbox">
                                                        {!dayMenuDescState[1]
                                                            .detailDescription && (
                                                            <div className="Input-placeholder">
                                                                화요일 메뉴에
                                                                대한 설명을
                                                                입력해주세요.
                                                                <br />
                                                                작성된 내용은
                                                                모바일 앱 이번주
                                                                식단에서도 확인
                                                                됩니다.
                                                                <br />
                                                                예)뚝배기
                                                                갈비탕, 무우
                                                                잔파 생채, 양파
                                                                마늘종 장아찌
                                                            </div>
                                                        )}
                                                        <textarea
                                                            className="form-control ph InputType"
                                                            rows="3"
                                                            value={
                                                                dayMenuDescState[1]
                                                                    .detailDescription
                                                            }
                                                            onChange={({
                                                                target,
                                                            }) => {
                                                                dayMenuDescStateDispatch(
                                                                    {
                                                                        type: "added_menuDesc",
                                                                        payload:
                                                                            {
                                                                                day: 1,
                                                                                value: target.value,
                                                                            },
                                                                    }
                                                                );
                                                            }}
                                                            onKeyDown={resize}
                                                            onKeyUp={resize}
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane"
                                                    id="nav-prdDes3"
                                                    role="tabpanel"
                                                >
                                                    <div className="SalePrdAdd-inputbox Input-placeholderbox">
                                                        {!dayMenuDescState[2]
                                                            .detailDescription && (
                                                            <div className="Input-placeholder">
                                                                수요일 메뉴에
                                                                대한 설명을
                                                                입력해주세요.
                                                                <br />
                                                                작성된 내용은
                                                                모바일 앱 이번주
                                                                식단에서도 확인
                                                                됩니다.
                                                                <br />
                                                                예)뚝배기
                                                                갈비탕, 무우
                                                                잔파 생채, 양파
                                                                마늘종 장아찌
                                                            </div>
                                                        )}
                                                        <textarea
                                                            className="form-control ph InputType"
                                                            rows="3"
                                                            value={
                                                                dayMenuDescState[2]
                                                                    .detailDescription
                                                            }
                                                            onChange={({
                                                                target,
                                                            }) => {
                                                                dayMenuDescStateDispatch(
                                                                    {
                                                                        type: "added_menuDesc",
                                                                        payload:
                                                                            {
                                                                                day: 2,
                                                                                value: target.value,
                                                                            },
                                                                    }
                                                                );
                                                            }}
                                                            onKeyDown={resize}
                                                            onKeyUp={resize}
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane"
                                                    id="nav-prdDes4"
                                                    role="tabpanel"
                                                >
                                                    <div className="SalePrdAdd-inputbox Input-placeholderbox">
                                                        {!dayMenuDescState[3]
                                                            .detailDescription && (
                                                            <div className="Input-placeholder">
                                                                목요일 메뉴에
                                                                대한 설명을
                                                                입력해주세요.
                                                                <br />
                                                                작성된 내용은
                                                                모바일 앱 이번주
                                                                식단에서도 확인
                                                                됩니다.
                                                                <br />
                                                                예)뚝배기
                                                                갈비탕, 무우
                                                                잔파 생채, 양파
                                                                마늘종 장아찌
                                                            </div>
                                                        )}
                                                        <textarea
                                                            className="form-control ph InputType"
                                                            rows="3"
                                                            value={
                                                                dayMenuDescState[3]
                                                                    .detailDescription
                                                            }
                                                            onChange={({
                                                                target,
                                                            }) => {
                                                                dayMenuDescStateDispatch(
                                                                    {
                                                                        type: "added_menuDesc",
                                                                        payload:
                                                                            {
                                                                                day: 3,
                                                                                value: target.value,
                                                                            },
                                                                    }
                                                                );
                                                            }}
                                                            onKeyDown={resize}
                                                            onKeyUp={resize}
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane"
                                                    id="nav-prdDes5"
                                                    role="tabpanel"
                                                >
                                                    <div className="SalePrdAdd-inputbox Input-placeholderbox">
                                                        {!dayMenuDescState[4]
                                                            .detailDescription && (
                                                            <div className="Input-placeholder">
                                                                금요일 메뉴에
                                                                대한 설명을
                                                                입력해주세요.
                                                                <br />
                                                                작성된 내용은
                                                                모바일 앱 이번주
                                                                식단에서도 확인
                                                                됩니다.
                                                                <br />
                                                                예)뚝배기
                                                                갈비탕, 무우
                                                                잔파 생채, 양파
                                                                마늘종 장아찌
                                                            </div>
                                                        )}
                                                        <textarea
                                                            className="form-control ph InputType"
                                                            rows="3"
                                                            value={
                                                                dayMenuDescState[4]
                                                                    .detailDescription
                                                            }
                                                            onChange={({
                                                                target,
                                                            }) => {
                                                                dayMenuDescStateDispatch(
                                                                    {
                                                                        type: "added_menuDesc",
                                                                        payload:
                                                                            {
                                                                                day: 4,
                                                                                value: target.value,
                                                                            },
                                                                    }
                                                                );
                                                            }}
                                                            onKeyDown={resize}
                                                            onKeyUp={resize}
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="SalePrdAdd-Formitem pb-5">
                                    <p className="SalePrdAdd-tit">
                                        5. 부가 설명
                                    </p>
                                    <div className="SalePrdAdd-inputbox Input-placeholderbox">
                                        {!additionalDesc && (
                                            <div className="Input-placeholder">
                                                부가 설명이 있는 경우
                                                입력해주세요.
                                                <br />
                                                예) 운영시간 11:00 ~ 14:00
                                            </div>
                                        )}
                                        <textarea
                                            className="form-control ph InputType"
                                            rows="2"
                                            name="additionalDesc"
                                            onChange={({ target }) =>
                                                setAdditionalDesc(target.value)
                                            }
                                            onKeyDown={resize}
                                            onKeyUp={resize}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="SalePrdAdd-Formitem pb-5">
                                    <p className="SalePrdAdd-tit mb-0">
                                        6. 메뉴 판매제한 설정<span>*</span>
                                    </p>
                                    
                                    {
                                        isDefaultStore(storeType) 
                                        ?
                                        <p className="SalePrdAdd-sub">
                                            1일 판매 수량, 판매 일자, 판매 시간, 1인
                                            일일 구매 한도를 설정할 수 있습니다.
                                        </p>
                                        :
                                        <p className="SalePrdAdd-sub">
                                            1일 판매 수량, 판매 일자, 판매 시간, 태블릿 주문 시간,
                                            1인 일일 구매 한도를 설정할 수 있습니다.
                                        </p>
                                     }
                                        
                                    
                                    <div className="SalePrdAdd-radiobox d-flex">
                                        <div className="radioBox">
                                            <input
                                                className="form-check-input prdLimited"
                                                id="PrdOption2-1"
                                                type="radio"
                                                value=""
                                                name="PrdOption2"
                                                onChange={
                                                    handleQuanityMenuChange
                                                }
                                            />
                                            <label
                                                className="RadioLabel"
                                                htmlFor="PrdOption2-1"
                                            >
                                                <span>사용하기</span>
                                            </label>
                                        </div>
                                        <div className="radioBox">
                                            <input
                                                className="form-check-input"
                                                id="PrdOption2-2"
                                                type="radio"
                                                value=""
                                                name="PrdOption2"
                                                onChange={
                                                    handleNotQuanityMenuChange
                                                }
                                            />
                                            <label
                                                className="RadioLabel"
                                                htmlFor="PrdOption2-2"
                                            >
                                                <span>사용안함</span>
                                            </label>
                                        </div>
                                    </div>
                                    <SaleMenuAddQuantity
                                        saleMenuStatus={saleMenuStatus}
                                        setSaleMenuStatus={setSaleMenuStatus}
                                        isShowingQuantityMenu={
                                            isShowingQuantityMenu
                                        }
                                        setIsTime={setIsTime}
                                        eventDate={eventDate}
                                        setEventDate={setEventDate}
                                        quantity={quantity}
                                        setQuantity={setQuantity}
                                        saleLimit={saleLimit}
                                        setSaleLimit={setSaleLimit}
                                        eventTime={eventTime}
                                        setEventTime={setEventTime}
                                        smartOrderTime={smartOrderTime}
                                        setSmartOrderTime={setSmartOrderTime}
                                        isDefaultStore = {isDefaultStore(storeType)}
                                    />
                                </div>
                                <div className="SalePrdAdd-Formitem pb-5">
                                    <p className="SalePrdAdd-tit">
                                        7. 판매 상태<span>*</span>
                                    </p>
                                    <div className="SalePrdAdd-radiobox d-flex">
                                        <div className="radioBox">
                                            <input
                                                className="form-check-input"
                                                id="PrdOption3-1"
                                                type="radio"
                                                value=""
                                                name="PrdOption3"
                                                onChange={
                                                    handleSaleStatusSaleChange
                                                }
                                            />
                                            <label
                                                className="RadioLabel"
                                                htmlFor="PrdOption3-1"
                                            >
                                                <span>판매 상품</span>
                                            </label>
                                        </div>
                                        <div className="radioBox">
                                            <input
                                                className="form-check-input"
                                                id="PrdOption3-2"
                                                type="radio"
                                                value=""
                                                name="PrdOption3"
                                                onChange={
                                                    handleSaleStatusSaleStopChange
                                                }
                                            />
                                            <label
                                                className="RadioLabel"
                                                htmlFor="PrdOption3-2"
                                            >
                                                <span>판매 중지</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="SalePrdAdd-Formitem">
                  <p className="SalePrdAdd-tit">
                    7. 결제 가능 라인<span>*</span>
                  </p>
                  <div className="SalePrdAdd-radiobox d-flex">
                    <Form.Select
                      size="lg"
                      selected="ALL_LINE"
                      onChange={handleLineChange}
                    >
                      <option value="ALL_LINE">전체</option>
                      <option value="A_LINE">A 라인</option>
                      <option value="B_LINE">B 라인</option>
                      <option value="C_LINE">C 라인</option>
                    </Form.Select>
                  </div>
                </div> */}
                                <div className="SalePrdAdd-Formitem">
                                    <p className="SalePrdAdd-tit">
                                        8. 결제 가능 라인<span>*</span>
                                    </p>
                                    <div className="SalePrdAdd-Select">
                                        <button
                                            className="btn btn-clear selectBtn"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#SelectBox"
                                        >
                                            {usingLine.value}
                                        </button>
                                        <div
                                            className="collapse"
                                            id="SelectBox"
                                        >
                                            <div className="selectInputbox">
                                                <input
                                                    className="form-check-input"
                                                    id="SelectPay1"
                                                    type="radio"
                                                    checked={
                                                        usingLine.line ===
                                                        "ALL_LINE"
                                                    }
                                                    readOnly
                                                />
                                                <label
                                                    className="RadioLabel"
                                                    htmlFor="SelectPay1"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#SelectBox"
                                                    data-name="ALL_LINE"
                                                    data-value="전체"
                                                    onClick={handleLineClick}
                                                >
                                                    전체
                                                </label>
                                            </div>
                                            <div className="selectInputbox">
                                                <input
                                                    className="form-check-input"
                                                    id="SelectPay2"
                                                    type="radio"
                                                    checked={
                                                        usingLine.line ===
                                                        "A_LINE"
                                                    }
                                                    readOnly
                                                />
                                                <label
                                                    className="RadioLabel"
                                                    htmlFor="SelectPay2"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#SelectBox"
                                                    data-name="A_LINE"
                                                    data-value="태블릿A"
                                                    onClick={handleLineClick}
                                                >
                                                    태블릿A
                                                </label>
                                            </div>
                                            <div className="selectInputbox">
                                                <input
                                                    className="form-check-input"
                                                    id="SelectPay3"
                                                    type="radio"
                                                    name="SelectPay"
                                                    checked={
                                                        usingLine.line ===
                                                        "B_LINE"
                                                    }
                                                    readOnly
                                                />
                                                <label
                                                    className="RadioLabel"
                                                    htmlFor="SelectPay3"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#SelectBox"
                                                    data-name="B_LINE"
                                                    data-value="태블릿B"
                                                    onClick={handleLineClick}
                                                >
                                                    태블릿B
                                                </label>
                                            </div>
                                            <div className="selectInputbox">
                                                <input
                                                    className="form-check-input"
                                                    id="SelectPay4"
                                                    type="radio"
                                                    name="SelectPay"
                                                    checked={
                                                        usingLine.line ===
                                                        "C_LINE"
                                                    }
                                                    readOnly
                                                />
                                                <label
                                                    className="RadioLabel"
                                                    htmlFor="SelectPay4"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#SelectBox"
                                                    data-name="C_LINE"
                                                    data-value="태블릿C"
                                                    onClick={handleLineClick}
                                                >
                                                    태블릿C
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="SalePrdAdd-Btnbox">
                                <button
                                    type="button"
                                    className="btn btn-default"
                                    onClick={handleNewSaleMenuRegisterClick}
                                >
                                    등록
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={isRemoveModalShowing}
                onHide={handleRemoveModalShow}
                keyboard={false}
                size="sm"
                centered
                className="demoModal modal-prdAdd text-center"
            >
                <Modal.Header>
                    <Modal.Title>필수 정보 입력</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   {errorText}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="modal-primary"
                        className="btn-clear w-100"
                        onClick={handleRemoveModalClose}
                    >
                        확인
                    </Button>
                </Modal.Footer>
            </Modal>
            {isLoading && (
                <LoadingPortal>
                    <LoadingModal type="black" />
                </LoadingPortal>
            )}
        </>
    );
}
