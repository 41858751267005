import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

export default function Header(props) {
  const navigate = useNavigate();

  const activeClass = (route) => {
    return window.location.pathname === route
      ? "active nav-link d-flex align-items-center pb-4"
      : "nav-link d-flex align-items-center pb-4";
  };

  return (
    <>
      <button
        className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar"
      >
        <img
          className="img-fluid"
          alt="메뉴"
          src="https://www.flexdaycdn.net/public/images/ticket/menu3.png"
        />
      </button>

      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div className="offcanvas-btnbox pt-4 px-4 text-end">
          <button
            type="button"
            className="btn-close opacity-100 shadow-none"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <img
              className="img-fluid close"
              alt=""
              src="https://www.flexdaycdn.net/public/images/ticket/close4.png"
            />
          </button>
        </div>
        <div className="offcanvas-header p-4">
          <img
            className="img-fluid logo"
            alt=""
            src="https://www.flexdaycdn.net/public/images/ticket/logo.png"
          />
          <div className="offcanvas-title" id="offcanvasNavbarLabel">
            <span className="small">반가워요 :) </span>
            김인제 님
          </div>
        </div>
        <div className="offcanvas-body position-relative py-4-5">
          <div className="navbar-nav justify-content-end">
            <div className="nav-item">
              <a
                href={"/userstore"}
                className={activeClass("/userstore")}
                aria-current="page"
              >
                <span>메인</span>
              </a>
            </div>
            <div className="nav-item">
              <a href={"/qrwallet"} className={activeClass("/qrwallet")}>
                <span>QR 지갑</span>
              </a>
            </div>
            <div className="nav-item">
              <a href={"/paymentlist"} className={activeClass("/paymentlist")}>
                <span>결제 내역</span>
              </a>
            </div>
            <div className="nav-item">
              <a href={"/stampreward"} className={activeClass("/stampreward")}>
                <span>스탬프 리워드</span>
              </a>
            </div>
          </div>
          <div className="btnbox">
            <a
              href="tel:0516210203"
              type="button"
              className="btn btn-light mb-2 shadow-none"
            >
              고객센터 051-621-0203
            </a>
            <button
              type="button"
              className="btn btn-default"
              onClick={() => {
                localStorage.clear();
                sessionStorage.clear();
                navigate("/login");
              }}
            >
              로그아웃
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
