import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiClient } from "../../api/apiClient";
import { INIT_PAGE_SIZE } from "../../components/constants/CommonConstants";
const initialState = {
  content: [],
  isLoading: false,
};

const taxBillSlice = createSlice({
  name: "taxBill",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTaxBillInfo.fulfilled, (state, action) => {
      // action.payload.content.push(  {
      //     id : 1,
      //     issuedDate : 1,
      //     startOfReferenceDate : 1,
      //     endOfReferenceDate : 1,
      //     supplyOfFee : 1, vatOfFee : 1, fee : 1
      //   });
      return { ...state, ...action.payload, isLoading: true };
    });
  },
});

export const fetchTaxBillInfo = createAsyncThunk(
  "taxBill/Info",
  async (_params, { rejectWithValue }) => {
    try {
      const queryString = new URLSearchParams(_params).toString();

      const currentPath = window.location.pathname;

      const newURL = `${window.location.origin}${currentPath}?${queryString}`;

      if (_params) {
        _params.size = INIT_PAGE_SIZE;
      }

      window.history.pushState({ path: newURL }, null, newURL);

      const response = await apiClient({
        url: "/tax-invoices",
        params: _params,
        method: "get",
      });
      return { ...response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export default taxBillSlice;
export const { init } = taxBillSlice.actions;
