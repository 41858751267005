import { getFormatDate } from "../../function/common";
import { apiClient } from "../../api/apiClient";
import { useFetch } from "../common/useFetch";

const PAGE_SIZE = 10;

const getAPIUrl = (status, paymentDate, paymentTime, paymentMonth, page, usedType) => {
    console.log(status);
    if (status === "TODAY") {
        return `${
            process.env.REACT_APP_STORE_API
        }/v1/payments?startTime=${getFormatDate(new Date())} ${
            paymentTime.startTime
        }&endTime=${getFormatDate(new Date())} ${
            paymentTime.endTime
        }&dateRangeType=${status}&page=${page}&size=${PAGE_SIZE}&usedType=${usedType}`;
    } else if (status === "BY_MONTH") {
        return `${process.env.REACT_APP_STORE_API}/v1/payments?startTime=${paymentMonth}-01 00:00&endTime=${paymentMonth}-01 00:00&dateRangeType=${status}&page=${page}&size=${PAGE_SIZE}&usedType=${usedType}`;
    }

    return `${process.env.REACT_APP_STORE_API}/v1/payments?startTime=${paymentDate.startDate} ${paymentTime.startTime}&endTime=${paymentDate.endDate} ${paymentTime.endTime}&dateRangeType=${status}&page=${page}&size=${PAGE_SIZE}&usedType=${usedType}`;
};

export default function useDetailPaymentInfo(
    showingName,
    setShowingName,
    showingPhone,
    setShowingPhone,
    name,
    phone,
    status,
    paymentDate,
    paymentTime,
    paymentMonth,
    showingDate,
    showingTime,
    showingMonth,
    setShowingTime,
    setShowingDate,
    setShowingMonth,
    setShowingStatus,
    modalRef,
    page,
    payType,
    payModalRef,
    cancelType,
    cancelModalRef,
    usedType,
    usedModalRef,
    searchType
) {
    const { data, refetch, isLoading } = useFetch(
        [
            String(page),
            showingDate.startDate,
            showingDate.endDate,
            showingTime.start,
            showingTime.end,
            showingMonth,
            showingName,
            showingPhone,
            payType,
            cancelType,
            usedType,
        ],
        async () => {
            const { data } = await apiClient({
                url:
                    getAPIUrl(
                        status,
                        paymentDate,
                        paymentTime,
                        paymentMonth,
                        page,
                        usedType
                    ) +
                    `&payType=${payType}&${
                        cancelType ? `isCancelled=${cancelType}` : ""
                    }&${usedType ? `isUsed=${usedType}` : ""}${
                        searchType === "NAME" && name
                            ? `&buyerName=${name}`
                            : ""
                    }${
                        searchType === "PHONE" && phone
                            ? `&buyerPhoneNumber=${phone}`
                            : ""
                    }`,
                method: "get",
            });

            return data;
        },
        {
            onSuccess: () => {
                if (!modalRef.current) {
                    return;
                }

                modalRef.current.click();
                payModalRef.current.click();
                cancelModalRef.current.click();
                usedModalRef.current.click();

                if (status === "TODAY") {
                    setShowingDate({
                        startDate: getFormatDate(),
                        endDate: getFormatDate(),
                    });
                    setShowingTime({
                        start: paymentTime.startTime,
                        end: paymentTime.endTime,
                    });
                    sessionStorage.setItem("status", "TODAY");
                    sessionStorage.setItem("startTime", paymentTime.startTime);
                    sessionStorage.setItem("endTime", paymentTime.endTime);
                    setShowingStatus("TODAY");
                    setShowingName(name);
                    setShowingPhone(phone);
                    return;
                } else if (status === "BY_DAY") {
                    setShowingDate({
                        startDate: paymentDate.startDate,
                        endDate: paymentDate.endDate,
                    });
                    sessionStorage.setItem("status", "BY_DAY");
                    sessionStorage.setItem("startDate", paymentDate.startDate);
                    sessionStorage.setItem("endDate", paymentDate.endDate);
                    setShowingStatus("BY_DAY");
                    setShowingName(name);
                    setShowingPhone(phone);
                    return;
                }

                sessionStorage.setItem("status", "BY_MONTH");
                sessionStorage.setItem("month", paymentMonth);
                setShowingMonth(paymentMonth);
                setShowingStatus("BY_MONTH");
                setShowingName(name);
                setShowingPhone(phone);
            },
            onError: ({ response }) => {
                alert(response.data.message);
            },
        }
    );

    return { paymentInfos: data, refetch, isLoading };
}
