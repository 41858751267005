export const getThisWeekDate = (date) => {
    const days = ["일", "월", "화", "수", "목", "금", "토"];
    const day = new Date(date);

    return `${day.getMonth() + 1}/${day.getDate()}(${days[day.getDay()]})`;
};

export const initialState = [];

export const saleMenuModifyReducer = (state, action) => {
    switch (action.type) {
        case "inited_menuDesc":
            return [...action.payload];
        case "changed_menuDesc":
            const copyState = [...state];

            copyState[action.payload.day].detailDescription =
                action.payload.value;

            return copyState;
        default:
            throw Error(`Unknown action ${action.type}`);
    }
};


export const nextInitialState = [];

export const saleMenuModifyNextReducer = (state, action) => {
    switch (action.type) {
        case "inited_menuDesc":
            return [...action.payload];
        case "changed_menuDesc":
            const copyState = [...state];

            copyState[action.payload.day].detailDescription =
                action.payload.value;

            return copyState;
        default:
            throw Error(`Unknown action ${action.type}`);
    }
};