import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";

import "./Datepicker.css";
import {
  getFormatDate,
  getFormatTime,
  getMaxDate,
  getMaxTime,
} from "../../function/common";

export default function PaymentTimes({
  eventDate,
  setEventDate,
  eventTime,
  setEventTime,
}) {
  const [startYear, startMonth, startDate] = eventDate.startDate.split("-");
  const [endYear, endMonth, endDate] = eventDate.endDate.split("-");

  const startTime = new Date(
    `${startYear}, ${startMonth}, ${startDate}, ${eventTime.startTime}`
  );
  const endTime = new Date(
    `${endYear}, ${endMonth}, ${endDate}, ${eventTime.endTime}`
  );

  return (
    <div className="demo" style={{ margin: 0, maxWidth: "100%" }}>
      <div className="TDatepicker">
        <div className="container-fluid g-0">
          <div className="DatepickerWrap">
            <div className="DatepickerBox ver1">
              <div className="align-items-center d-flex">
                <div className="inputbox DatepickerBox ver1 time PrdTime-box">
                  <div
                    className="align-items-center d-flex"
                    // style={{ width: "100%" }}
                  >
                    <DatePicker
                      className="form-control InputType input-datepicker sale-prd-add"
                      locale={ko}
                      selected={startTime}
                      onChange={(date) => {
                        setEventDate({
                          startDate: getFormatDate(),
                          endDate: getFormatDate(),
                        });

                        if (getMaxTime(date, eventTime.endTime)) {
                          setEventTime({
                            startTime: eventTime.endTime,
                            endTime: getFormatTime(date),
                          });
                          return;
                        }

                        setEventTime((prev) => ({
                          ...prev,
                          startTime: getFormatTime(date),
                        }));
                      }}
                      onFocus={(e) => e.target.blur()}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                    />
                    <span className="tilde">~</span>
                    <DatePicker
                      className="form-control InputType input-datepicker sale-prd-add"
                      locale={ko}
                      selected={endTime}
                      onChange={(date) => {
                        setEventDate({
                          startDate: getFormatDate(),
                          endDate: getFormatDate(),
                        });

                        if (!getMaxTime(date, eventTime.startTime)) {
                          setEventTime({
                            startTime: getFormatTime(date),
                            endTime: eventTime.startTime,
                          });
                          return;
                        }

                        setEventTime((prev) => ({
                          ...prev,
                          endTime: getFormatTime(date),
                        }));
                      }}
                      onFocus={(e) => e.target.blur()}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
