import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PaymentDetailInfo from "../../components/paymentinfo/paymentdetailinfo/PaymentDetailInfo";
import useRemoveBodyStyle from "../../hooks/common/useRemoveBodyStyle";
import AdminHeader from "../UserMain/components/AdminHeader";
import { isDefaultStore } from "../../function/common";
import "./PaymentInfo.css";
import { useAppSelector } from "../../store";
export default function PaymentInfo() {
  const navigate = useNavigate();
  useRemoveBodyStyle();

  const storeType = useAppSelector((state) => {
    return state.storeReducer.storeType;
  });

  useEffect(() => {
    // do stuff
  }, [storeType]);

  return (
    <div className="demo mw-100">
      <div className="TPrdAdminPage">
        <div className="container-fluid g-0">
          <nav className="sub-navbar">
            <p className="navbar-title">결제 / 매출 / 정산</p>
            <button
              type="button"
              className="btn-clear btn-back"
              onClick={() => {
                sessionStorage.clear();
                navigate("/storemain");
              }}
            >
              <img
                className="img-fluid"
                alt="뒤로가기"
                src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
              />
            </button>
            <button
              className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              <img
                className="img-fluid"
                alt="메뉴"
                src="https://www.flexdaycdn.net/public/images/ticket/menu3.png"
              />
            </button>
            <AdminHeader />
          </nav>

          <div className="PayCateTab">
            <div
              className="nav nav-pills justify-content-around mb-4-5 px-4-5"
              id="pills-tab"
              role="tablist"
            >
              <div className="nav-item2" role="presentation">
                {isDefaultStore(storeType) ? (
                  <Link to="/paymentInfo">
                    <button
                      className="nav-link active PayCateBtn"
                      id="pills-content1-tab"
                      type="button"
                      role="tab"
                    >
                      결제/사용 현황
                    </button>
                  </Link>
                ) : (
                  <Link to="/paymentInfo">
                    <button
                      className="nav-link active PayCateBtn"
                      id="pills-content1-tab"
                      type="button"
                      role="tab"
                    >
                      결제/주문 현황
                    </button>
                  </Link>
                )}
              </div>
              <div className="nav-item2" role="presentation">
                <Link to="/salesInfo">
                  <button
                    className="nav-link PayCateBtn"
                    id="pills-content2-tab"
                    type="button"
                    role="tab"
                  >
                    매출 내역
                  </button>
                </Link>
              </div>
              <div className="nav-item2" role="presentation">
                <Link to="/settlementInfo">
                  <button
                    className="nav-link PayCateBtn"
                    id="pills-content3-tab"
                    type="button"
                    role="tab"
                  >
                    정산 내역
                  </button>
                </Link>
              </div>
              <div className="nav-item2" role="presentation">
                <Link to="/taxbillInfo">
                  <button
                    className={"nav-link PayCateBtn"}
                    id="pills-content4-tab"
                    type="button"
                    role="tab"
                  >
                    세금계산서 조회
                  </button>
                </Link>
              </div>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane show active"
                id="pills-content1"
                role="tabpanel"
              >
                <PaymentDetailInfo />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
