import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import './Login.css';
import axios from 'axios';
import { getApiUrl } from "../../function/common";

import { PrintConsole, sleep } from '../../function/common';
import { GetUserMe } from '../../api/ApiGetStoreUser';
import { GetAuthorize } from '../../api/ApiGetUser';
import AppleLogin from "react-apple-login";

export default function Login(){
    const navigate = useNavigate();

    const [account, setAccount] = useState({
        Id: "",
        Password: "",
        clientId: '10000'
    });

    const onChangeAccount = (e) => {
        //console.log(e.target.value);
        setAccount({
          ...account,
          [e.target.id]: e.target.value,
        });
    };

    const [searchParams, setSearchParams] = useSearchParams(window.location.href.split('/login#')[1]);
    const NaverLogin = () => {
        const { naver } = window
      
        const naverLogin = new naver.LoginWithNaverId(
            {
                clientId: process.env.REACT_APP_NAVER_ID,
                callbackUrl: process.env.REACT_APP_NAVER_CALLBACK,
                isPopup: false, /* 팝업을 통한 연동처리 여부, true 면 팝업 */
                loginButton: { color: "green", type: 1, height: 47 }, /* 로그인 버튼의 타입을 지정 */
                callbackHandle : true
            }
        );
      
        naverLogin.init();

        naverLogin.getLoginStatus( async function (status) {
            if (status) {
                const id = naverLogin.user.getId();
                const name = naverLogin.user.getName();
                const mobile = naverLogin.user.getMobile();

                // console.log(access_token);
                console.log(id);
                console.log(name);
                console.log(mobile);

                console.log(searchParams.get("access_token"));

                let obj = {
                    "oauthAccessToken": searchParams.get("access_token"),
                    "oauthType": "NAVER"
                }
                const response = await axios.post(
                    getApiUrl('v1/oauth/login' ),
                    obj,
                    // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                    axios.defaults.headers.common['ContentType'] = "application/json;",
                ).then(
                    function(response){
                        console.log('----------------------------------');
                        console.log(response);
                        if(response.status === 200){
                            console.log(response)
                        }
                    }
                ).catch(function(error){
                    console.log('error');
                    // AlertError(error);
                    // document.getElementById('submitBtn').removeAttribute("disabled");
                })
            }
        });
    }

    const KakaoLogin = () => {
        const REST_API_KEY = '55c49dcd60e7316f131543bf9696059f';
        const REDIRECT_URI = 'http://localhost:3000/sns/kakao';
        const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;
        window.location.href = KAKAO_AUTH_URL;
    }

    return (
        <>
            <div className="demo">        
                <div className="TLoginPage">
                    <div className="container position-absolute start-50 top-50 translate-middle w-100">
                        <div className="Inner m-auto p-2">
                            <div className="LogoImg text-center">
                                <img src="https://www.flexdaycdn.net/public/images/ticket/inje_logo2.png" alt="FLEX" />
                            </div>
                            
                            <div className="LoginBox">
                                <div className="row">
                                    <button type="button" className="btn btn-kakao" onClick={ () => {KakaoLogin()}}>
                                        <img src="https://www.flexdaycdn.net/public/images/ticket/kakao_icon.png" alt="kakao"
                                            className="img-fluid" />
                                        카카오 로그인
                                    </button>
                                </div>
                                <div className="row">
                                    <button type="button" className="btn btn-naver" onClick={() => {NaverLogin();}}>
                                        <img src="https://www.flexdaycdn.net/public/images/ticket/naver_icon.png" alt="naver"
                                            className="img-fluid" />
                                        네이버 로그인
                                    </button>
                                </div>
                                <div className="row">
                                    <div style={{display : 'none'}}>
                                        <AppleLogin  
                                            clientId={"com.flexday.loginservice"} 
                                            redirectURI={"https://dev-user-api.ivoucher.kr/v1/oauth/apple"}
                                            scope={"email name"}
                                            responseType={"code id_token"}
                                            responseMode={"form_post"}
                                            usePopup={false}
                                            className="btn btn-default"
                                            designProp={
                                                {
                                                height: 30,
                                                width: 140,
                                                color: "black",
                                                border: false,
                                                type: "sign-in",
                                                border_radius: 15,
                                                scale: 1,
                                                locale: "en_US", 
                                                }
                                            }
                                        />
                                    </div>
                                    <button type="button" className="btn btn-default" onClick={() => {document.getElementById("appleid-signin").click();}}>
                                        <img src="https://www.flexdaycdn.net/public/images/ticket/apple_icon3.png" alt="apple"
                                            className="img-fluid" />
                                        애플 로그인
                                    </button>
                                </div>
                                <div className="row">
                                    <button onClick={()=> {navigate('/nonuserlogin')}} type="button" className="btn btn-light">
                                        비회원 로그인
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}