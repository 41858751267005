export const STORE_DEFAULT = 'DEFAULT';

export const MENU_STATUS = {
    MAIN: "MAIN",
    SIDE: "SIDE",
};


export const DESC_STATUS = {
    COMMON: "COMMON",
    DATE: "BY_DATE",
    NONE:"NONE",
};


export const ACCOUNTING_CONTENTS = {
    PAYMENT_ORDER_INFO : "/paymentOrderInfo",
    PAYMENTINFO : "/paymentInfo",
    SALESINFO: "/salesInfo",
    SETTLEMENTINFO: "/settlementInfo",
    TAXBILL : "/taxbillInfo",
}




/**
 * 결제/주문 현황, 주문상태
 * API : 서버로 전달할 파라미터
 * CODE : 서버에서 정의한 CODE
 * DESCRIPTION : HTML 표출해야 하는 내용
 * ISVISIBLE : HTML 표출 활성화 여부
 */
export const SMART_ORDER_STATUS = {
    ALL : {
        API: "",
        CODE : -9,
        DESCRIPTION : "전체",
        ISVISIBLE : true
    },
    NONE : {
        API : "NONE",
        CODE : 6,
        DESCRIPTION : "주문 대기",
        ISVISIBLE : true
    },
    WAITING_ACCEPT : {
        API : "WAITING_ACCEPT",
        CODE : 1,
        DESCRIPTION : "주문 신청",
        ISVISIBLE : true
    },
    COOKING : {
        API : "COOKING",
        CODE : 2,
        DESCRIPTION : "조리중",
        ISVISIBLE : true
    },
    PICK_UP_REQUESTED : {
        API : "PICK_UP_REQUESTED",
        CODE : 3,
        DESCRIPTION : "픽업 요청",
        ISVISIBLE : false
    },
    CANCELLED : {
        API : "CANCELLED",
        CODE : 5,
        DESCRIPTION : "주문거절",
        ISVISIBLE : true
    },
    PICK_UP_COMPLETED : {
        API : "PICK_UP_COMPLETED",
        CODE : 4,
        DESCRIPTION : "픽업완료",
        ISVISIBLE : true
    },
}

export const INIT_PAGE_SIZE = 10;
// export const INIT_PAGE_SIZE = 1;
export const INIT_PAGENATION_SIZE = 5;

// 조회 기간 검색
export const DATE_RANGE_TYPE = {
    TODAY: "TODAY", // 오늘
    DAY: "BY_DAY", // 일자별
    MONTH: "BY_MONTH", // 월별
};

// 결제 수단
export const PAYTYPE_STATUS = {
    ALL: "", // 전체
    CARD: "CARD", // 카드
    EASY_PAY: "EASY_PAY", // 간편결제
    ACCOUNT_TRANSFER: "ACCOUNT_TRANSFER", // 계좌 이체
};