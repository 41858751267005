import { useNavigate } from "react-router-dom";
import "./PopupSequence.css";
import AdminHeader from "../../UserMain/components/AdminHeader";
import { usePopup } from "../../../hooks/popup/userPopup";
import { useEffect, useRef, useState } from "react";
import { getDeepCopy } from "../../../function/common";
import usePopupSequence from "../../../hooks/popup/usePopupSequence";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import LoadingPortal from "../../../portals/LoadingPortal";
import LoadingModal from "../../../components/loadingmodal/LoadingModal";

export default function PopupSequence() {
    const navigate = useNavigate();
    const draggingRef = useRef();
    const draggingOverRef = useRef();
    const originalValue = useRef();
    const [dragPopups, setDragPopups] = useState();
    const { data: popups } = usePopup();
    const { mutate, isLoading } = usePopupSequence();

    useEffect(() => {
        setDragPopups(popups);
    }, [popups]);

    if (!popups || !dragPopups) {
        return null;
    }

    const handleDragStart = (result) => {
        draggingRef.current = result.source.index;
        originalValue.current = {
            productId: dragPopups[result.source.index].productId,
            ordering: dragPopups[result.source.index].ordering,
        };
    };

    const handleDragUpdate = (result) => {
        if (!result.destination) {
            return;
        }

        draggingOverRef.current = result.destination.index;

        const copyProducts = getDeepCopy(dragPopups);
        const dragProductContent = copyProducts[draggingRef.current];

        copyProducts.splice(draggingRef.current, 1);
        copyProducts.splice(draggingOverRef.current, 0, dragProductContent);

        const temp = copyProducts[draggingRef.current].ordering;
        copyProducts[draggingRef.current].ordering =
            copyProducts[draggingOverRef.current].ordering;
        copyProducts[draggingOverRef.current].ordering = temp;

        draggingRef.current = draggingOverRef.current;
        draggingOverRef.current = null;

        setDragPopups(copyProducts);
    };

    const handleDragEnd = () => {
        draggingRef.current = null;
    };

    const handleSequenceClick = () => {
        if (isLoading) {
            return;
        }

        const mapedDragPopups = dragPopups.map(({ popupId, ordering }) => ({
            id: popupId,
            ordering,
        }));

        mutate({ orderings: mapedDragPopups });
    };

    return (
        <>
            <div className="demo">
                <div className="TPopupOrderPage">
                    <div className="container-fluid g-0">
                        <nav className="sub-navbar">
                            <p className="navbar-title">팝업 순서 편집</p>
                            <button
                                type="button"
                                className="btn-clear btn-back"
                                onClick={() => navigate("/storemain")}
                            >
                                <img
                                    className="img-fluid"
                                    alt="뒤로가기"
                                    src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                                />
                            </button>
                            <button
                                className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasNavbar"
                                aria-controls="offcanvasNavbar"
                            >
                                <img
                                    className="img-fluid"
                                    alt="메뉴"
                                    src="https://www.flexdaycdn.net/public/images/ticket/menu3.png"
                                />
                            </button>
                            <AdminHeader />
                        </nav>

                        <div className="PopupOrderBox py-4-5 px-4-5">
                            <DragDropContext
                                onDragStart={handleDragStart}
                                onDragUpdate={handleDragUpdate}
                                onDragEnd={handleDragEnd}
                            >
                                <Droppable droppableId="menulists">
                                    {(provided) => {
                                        return (
                                            <div
                                                className="menulists PopupOrder-Itembox"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {dragPopups.map(
                                                    (product, index) => {
                                                        const {
                                                            popupId,
                                                            ordering,
                                                            storeTitle,
                                                            startDate,
                                                            endDate,
                                                        } = product;

                                                        return (
                                                            <Draggable
                                                                draggableId={`${popupId}`}
                                                                index={index}
                                                                key={`${popupId}`}
                                                            >
                                                                {(
                                                                    provided,
                                                                    snapshot
                                                                ) => {
                                                                    return (
                                                                        <div
                                                                            className="PopupOrder-Item d-flex"
                                                                            ordering={
                                                                                ordering
                                                                            }
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            ref={
                                                                                provided.innerRef
                                                                            }
                                                                        >
                                                                            <div className="prd-infobox">
                                                                                <p className="prd-tit">
                                                                                    {
                                                                                        storeTitle
                                                                                    }
                                                                                </p>
                                                                                <p className="prd-desc">
                                                                                    {
                                                                                        startDate
                                                                                    }

                                                                                    ~
                                                                                    {
                                                                                        endDate
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                            <div className="prd-iconbox d-flex">
                                                                                <img
                                                                                    className="img-fluid"
                                                                                    alt=""
                                                                                    src="https://www.flexdaycdn.net/public/images/ticket/order_move_icon.png"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }}
                                                            </Draggable>
                                                        );
                                                    }
                                                )}
                                                {provided.placeholder}
                                            </div>
                                        );
                                    }}
                                </Droppable>
                            </DragDropContext>

                            <div className="PopupOrder-Btnbox">
                                <button
                                    type="button"
                                    className="btn btn-default"
                                    onClick={handleSequenceClick}
                                >
                                    등록
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && (
                <LoadingPortal>
                    <LoadingModal type="black" />
                </LoadingPortal>
            )}
        </>
    );
}
