import { useNavigate } from "react-router-dom";
import { apiClient } from "../api/apiClient";
import { useMutation } from "./common/useMutation";

export default function useSaleMenuModify(
  productId,
  setErrorText,
  setIsRemoveModalShowing
) {
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(
    async (params) =>
      await apiClient({
        url: `/products/${productId}`,
        method: "put",
        data: params,
      }),
    {
      onSuccess: () => {
        navigate("/salemenu");
      },
      onError: ({ response }) => {
        setErrorText(response.data.message);
        setIsRemoveModalShowing(true);
      },
    }
  );

  return { mutate, isLoading };
}
