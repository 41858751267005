import { apiClient } from "../../api/apiClient";
import { useMutation } from "../common/useMutation";

export default function useStoreInfoModify() {
    const { mutate: mutateStoreInfoModify } = useMutation(
        async (params) =>
            await apiClient({
                method: "put",
                url: "/store/me",
                data: params,
            }),
        {
            onError: ({ response }) => {
                alert(response.data.message);
            },
        }
    );

    return { mutateStoreInfoModify };
}
