import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

import { getApiUrl, PrintConsole, sleep } from '../../function/common';

import axios from 'axios';


import "./QR.css";

export default function QR(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    // const [searchParams, setSearchParams] = useSearchParams();
    // // const [Params] = useSearchParams();
    // console.log(searchParams.get('storeurl'));

    const { number } = useParams();
    
    const [item, setItem] = useState();

    function getOrderItem() {
        return new Promise( async function(resolve, reject) {
            var obj = {
                isUsed : true
            }
            const response = await axios.get( 
                getApiUrl('users/me/food-tickets/'+number),
                
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8"
            ).then(
                function(response){
                    if(response.status === 200){
                        setItem(response.data);
                        
                    }
                    
                }
            ).catch(function(error){
                console.log('error');
            })
            if (response) {
                return resolve(response);
            }
        });
    };

    

    useEffect( () => {
        setLoading(true);

        getOrderItem();
        console.log(item); 

        setLoading(false);
    },[])

    if (loading) return <></>

    
    

    //개발자 콘솔에서 반응형또는 기기 선택시 해당 os 반환
    // getVersion();
    // setAccessToken();
    
    return (
        <>
        <div className='demo'>

            <div className="TUseQRPage">
                <div className="container-fluid g-0">

                    <nav className="sub-navbar">
                        <p className="navbar-title">QR 사용하기</p>
                        <button type="button" className="btn-clear btn-close" onClick={() => {navigate(-1)}}>
                            <img className="img-fluid" alt="뒤로가기"
                                src="https://www.flexdaycdn.net/public/images/ticket/close5.png" />
                                
                        </button>
                    </nav>

                    <div className="QRBox px-4-5 pb-4">
                        <div className="QRBox-inner">
                            <div className="infobox px-4-5">
                                <div className="infoitem-date text-end">
                                    유효기간 2023-01-12
                                    {/* 유효기간 {item && item.expireDate} */}
                                </div>
                                <div className="infoitem">
                                    <div className="tit">
                                        상품
                                    </div>
                                    <div className="desc">
                                        C코너 / 1개
                                        {/* {item && item.productName} */}
                                    </div>
                                </div>
                                <div className="infoitem">
                                    <div className="tit">
                                        매장
                                    </div>
                                    <div className="desc">
                                        인제대학교 늘빛관(다인)
                                        {/* {item && item.storeName} */}
                                    </div>
                                </div>
                            </div>

                            <div className="imgbox text-center">
                                {/* <img className="img-fluid m-auto qr-img"
                                    src="https://www.flexdaycdn.net/public/images/ticket/qr_img.png" /> */}
                                <img className="img-fluid m-auto qr-img"
                                    src={"https://dev-api.flexday.kr/common/qrcode?data="+(item && item.qrcode)+"&size=380"} />
                            </div>

                            <div className="QRInfotxt text-center">
                                구매한 날짜에 사용하지 못하셨다면 <br />
                                유효기간 내 같은 금액의 식단으로 <br className="mob" />
                                사용이 가능합니다.
                            </div>  
                            
                            <div className="QRBox-bg">
                                <img className="img-fluid w-100"
                                    src="https://www.flexdaycdn.net/public/images/ticket/QR_use_bg4.png" />
                            </div>

                        </div>                    
                    </div>
                </div>
            </div>

        </div>

        </>
    )
}