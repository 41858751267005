import { useFetch } from "./common/useFetch";
import { apiClient } from "../api/apiClient";

export default function useEventDetail(eventId) {
  const { data, isLoading } = useFetch(
    [eventId],
    async () => {
      const { data } = await apiClient({
        url: `/events/${eventId}`,
      });

      return data;
    },
    {
      onError: ({ response }) => {
        alert(response.data.message);
      },
    }
  );

  return { data, isEventDetailLoading: isLoading };
}
