import { useFetch } from "./common/useFetch";
import { apiClient } from "../api/apiClient";

export default function useProductList() {
  const { data } = useFetch(
    [],
    async () => {
      const { data } = await apiClient({
        url: "/products",
      });

      return data;
    },
    {
      onError: ({ response }) => {
        alert(response.data.message);
      },
    }
  );

  return { products: data };
}
